import {
	MenuButton,
	Box,
	Grid,
	FormLabel,
	Input,
	Menu,
	MenuItem,
	MenuList,
	FocusLock,
	useColorModeValue
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

export const InputWithSuggestions = ({...props}) => {
  const suggestionsItems = props.suggestions

  return (
    <Grid w={'100%'}>
			<FormLabel marginLeft={3}>{props.title}</FormLabel>
			
			<Menu
				isOpen={props.showSuggestions}
				closeOnSelect
				autoSelect={false}
				onClose={() => props.setShowSuggestions(false)}
				matchWidth
			>

				<Input
					type="text"
					placeholder='Busque por nome'
					autoComplete="off"
					value={props.inputValue}
					onFocus={() => props.setShowSuggestions(true)}
					onChange={(e) => { props.setInputValue(e.target.value)}}

					autoFocus
					// onFocus={() => props.setShowSuggestions(true)}
					
				/>
					<MenuButton as={Box}  />
						<MenuList
							maxHeight="60vh"
							overflowY="auto"
							backgroundColor={props.bgColor}
							color={props.color}
						>
							{suggestionsItems.length > 0 ? (
								suggestionsItems.map((suggestion, index) => {
									console.log(suggestion[0])
									return (
									<MenuItem
										backgroundColor={props.bgColor}
										onClick={() => (props.addCityFilter(suggestion[0]), props.setInputValue(''), props.setShowSuggestions(false))}
										key={index}
										padding="1.5"
										_hover={ { backgroundColor: props.bgColorHover } }
									>
										{suggestion[1]}
									</MenuItem>
								)})
							) : (
								<MenuItem backgroundColor={props.bgColor} key={0} padding="1.5">{props.notFoundMessage}</MenuItem>
							)}
						</MenuList>
			</Menu>
		</Grid>
  )
}