import { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

export function ParticlesDesign() {
  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          // color: {
          //     value: "#0d47a1",
          // },
        },
        fpsLimit: 60,
        interactivity: {
          // events: {
          //     onClick: {
          //         enable: true,
          //         mode: "push",
          //     },
          //     onHover: {
          //         enable: true,
          //         mode: "repulse",
          //     },
          //     resize: true,
          // },
          // modes: {
          //     push: {
          //         quantity: 4,
          //     },
          //     repulse: {
          //         distance: 200,
          //         duration: 0.4,
          //     },
          // },
        },
        particles: {
          color: {
            value: ['#0873BA', '#02A879', '#E3C241', '#E94645'],
            // width: [1, 2, 1.3, 1.5, 1.8, 1, 1, 1],
          },
          links: {
            color: {
              value: '#223649', // ["#19435f", "#027353", "#9b842d","#9f302f"],
            },
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            directions: 'none',
            enable: true,
            outModes: {
              default: 'bounce',
            },
            random: false,
            speed: 0.5,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 1200,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: ['circle'],
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  );
}
