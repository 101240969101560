import React, { useEffect, useState, useRef } from 'react';
import { Box, Text, Button, Input, VStack, Image, HStack,Select, Flex } from '@chakra-ui/react';
import AuthorList from '../../Components/ObservatoryAdminPost/AuthorList';
import { useQuery } from 'react-query';
  import axios from 'axios';

const PostCreator = () => {
    const [items, setItems] = useState([]);
    const [imagemPost, setImagemPost] = useState("");
    const [categoriasPost, setCategoriasPost] = useState([{id:1, nome:"Interno"},{id:2, nome:"Comunidade"}]);
    const [eixosPost, setEixosPost] = useState([{id:1, nome:"Social"}, {id:2, nome:"Economico"}, {id:3, nome:"Ambiental"},{id:4, nome:"Institucional"}]);
    const [listaAutores, setListaAutores] = useState(["Henrique Menezes","Pascoal Gonçalves","Cristhian Cunha","Gustavo Dias"]);
    const [selectedEixoPost, setSelectedEixoPost] = useState("2");
    const [selectedCategoriaPost, setSelectedCategoriaPost] = useState("1");
    const [showAddAutor, setShowAddAutor] = useState(false);
    const [authorSelector, setAuthorSelector] = useState("1");
    const [selectedAuthors, setSelectedAuthors] = useState([]);
    const tituloRef = useRef();
    const descricaoRef = useRef();
    // Definindo a URL do seu backend
    const url = 'https://ods.ufpb.br/api/endpointpost';

    const RealizarPost = () => {
      const tituloPost = tituloRef.current.value;
      const descricaoPost = descricaoRef.current.value;      
      // Definindo os dados que serão enviados
      const dadosPost = {
        titulo: tituloPost,
        descricao: descricaoPost,
        imagem: imagemPost,
        eixoPost: selectedEixoPost,
        categoriaPost: selectedCategoriaPost,
        autores: selectedAuthors,
        conteudo: items
      };

      console.log(dadosPost)

    //   const opcoes = {
    //     method: 'POST', // Método HTTP
    //     headers: {
    //       // Definindo o tipo de conteúdo como JSON
    //       'Content-Type': 'application/json'
    //     },
    //     // Convertendo os dados do JavaScript para uma string JSON
    //     body: JSON.stringify(dadosPost)
    //   };

    // // Realizando a requisição POST
    //   fetch(url, opcoes)
    //     .then(response => {
    //       if (!response.ok) {
    //         throw new Error('Falha na requisição: ' + response.statusText);
    //       }
    //       return response.json(); // ou response.text() se a resposta não for JSON
    //     })
    //     .then(data => {
    //       console.log('Sucesso:', data);
    //     })
    //     .catch(error => {
    //       console.error('Erro na requisição:', error);
    //     });
    }

    const {
      data: autoresData,
      isFetching: isFetchingAutoresData,
      isError: isErrorAutoresData,
    } = useQuery(
      `autoresData`,
      async () => {
        const response = await axios.get(
          `http://ods.ufpb.br/api/autores`,
        );
        console.log(response.data);
        return response.data;
      },
      {
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 60, // 60 minutes
      },
    );

    const addItem = (type) => {
        const newItem = { type, content: '' };
        setItems([...items, newItem]);
    };

    const updateItem = (index, content) => {
        const newItems = [...items];
        newItems[index].content = content;
        console.log(content)
        setItems(newItems);
    };

    const handleEixoPost = (event) => {
        setSelectedEixoPost(event.target.value);
        console.log(event.target.value)
    };

    const handleCategoriaPost = (event) => {
      setSelectedCategoriaPost(event.target.value);
      console.log(event.target.value)
    };

    const showAddAutorHandler = () => {
      setShowAddAutor(!showAddAutor);
    }

    const handleAuthorSelector = (event) => {
      setAuthorSelector(event.target.value);
      console.log(event.target.value)
    }

    const addAuthorFromSelector = () => {
      if(selectedAuthors.includes(authorSelector)){
        console.log("Autor já adicionado")
        return
      }
      setSelectedAuthors([...selectedAuthors, authorSelector]);
      console.log(selectedAuthors)
    }
    // http://ods.ufpb.br/api/autores

    return (
      <VStack p="4">  
        <Text>Criar Publicação</Text>
        <VStack w={'100%'} maxW={'960'}>
          <HStack>
            <Select placeholder='Selecione uma categoria' onChange={(event) => handleCategoriaPost(event) }>
              {categoriasPost.map((categoria) => (
                <option key={categoria.id} value={categoria.id}>
                  {categoria.nome}
                </option>
              ))
              }
            </Select>
            <Select placeholder='Selecione um eixo' onChange={(event) => handleEixoPost(event) }>
              {eixosPost.map((eixo) => (
                <option key={eixo.id} value={eixo.id} >
                  {eixo.nome}
                </option>
              ))
              }
            </Select>
          </HStack>
          <Input placeholder="Insira o título da publicação" ref={tituloRef} />
          <Input placeholder="Insira a descrição da publicação" ref={descricaoRef} />
          <VStack alignItems={'center'} w='100%' marginTop={'8'}>
            <Text paddingX={'4'} fontSize={'medium'} >Capa da publicação</Text>
            <Box >
                          <Input
                              type="file"
                              accept="image/*"
                              padding={'1'}
                              onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                      const reader = new FileReader();
                                      reader.onloadend = () => {
                                        setImagemPost(reader.result);
                                      };
                                      reader.readAsDataURL(file);
                                  }
                              }}
                          />
            </Box>
          </VStack>
        
          <VStack alignItems={'center'} w='100%' marginTop={'8'}>
            <Text paddingX={'4'} fontSize={'medium'}>Autores</Text>
            <VStack>
              <Select defaultValue={"1"} onChange={(event) => handleAuthorSelector(event)}>
              {autoresData && autoresData.map((autor) => (
                  <option key={autor.id} value={autor.id} >
                    {autor.nome} {autor.sobrenome}
                  </option>
                ))
                }
              </Select>
              <Button onClick={() => addAuthorFromSelector()}> Adicionar autor</Button>
              <VStack>
              {autoresData && autoresData.filter((autor) => selectedAuthors.includes(autor.id.toString())).map((autor) => (
                <Flex flex='1' alignItems='flex-start' justifyContent={'flex-start'} padding={'1'}>
                  <AuthorList autor={autor} />
                
                </Flex>
                ))
              }
                {/* <AuthorList autores={selectedAuthors} /> */}
              </VStack>
            </VStack>
            {/* <Button onClick={() => showAddAutorHandler()}>Adicionar novo autor</Button> */}
            {showAddAutor ? formAddAuthor() : null}
          </VStack>
        </VStack> 
        <Text paddingTop={'8'}>Conteúdo do post</Text>
        <VStack spacing={4}>
            {items.map((item, index) => (
                item.type === 'text' ? (
                    <Input
                        key={index}
                        placeholder="Digite um paragráfo aqui"
                        value={item.content}
                        onChange={(e) => updateItem(index, e.target.value)}
                    />
                ) : (
                    <Box key={index}>
                        {item.content && <Image src={item.content} alt={`Imagem ${index}`} />}
                        <Input
                            type="file"
                            accept="image/*"
                            padding={'1'}
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                        updateItem(index, reader.result);
                                    };
                                    reader.readAsDataURL(file);
                                }
                            }}
                        />
                    </Box>
                )
            ))}
            <HStack>
                <Button onClick={() => addItem('text')}>Adicionar Texto</Button>
                <Button onClick={() => addItem('image')}>Adicionar Imagem</Button>
            </HStack>
        </VStack>
        <Button margin={'8'} onClick={() => RealizarPost()} >Publicar</Button>
      </VStack>
    );
};

export default PostCreator;
