import {
	Box,
	Center,
	useColorModeValue,
	Heading,
	Text,
	Stack,
	Image,
	useMediaQuery,
	Skeleton
} from '@chakra-ui/react';

import React, { useState } from 'react';

export default function TeamCard({ imgSrc, teamName }) {
	const textColor = useColorModeValue('idsm.darkBlueCoolors.7', 'gray.100');	// Hook to detect mobile screen
	const [isMobile] = useMediaQuery('(max-width: 480px)');

	return (
		<Center py={12}>
			<Box
				role="group"
				p={6}
				maxW="280px"
				w="full"
				bg={useColorModeValue('white', 'idsm.darkBlueCoolors.base')}
				boxShadow="2xl"
				rounded="lg"
				pos="relative"
				zIndex={1}
				minH="full"
			>
				<Box
					rounded="lg"
					mt={-12}
					pos="relative"
					height={isMobile ? '150px' : '220px'} // less height for mobile
					_after={{
						transition: 'all .3s ease',
						content: '""',
						w: 'full',
						h: 'full',
						pos: 'absolute',
						top: 2,
						left: 0,
						objectFit: 'cover',
						backgroundColor: 'idsm.lightBlueCoolors.8',
						filter: 'blur(8px)',
						zIndex: -1,
					}}
					_groupHover={{
						_after: {
							filter: 'blur(12px)',
						},
					}}
				>
					<Image
						rounded="lg"
						height={isMobile ? 160 : 230} // less height for mobile
						width={282}
						objectFit="cover"
						src={imgSrc}
					/>
					
				</Box>
				<Stack pt={10} align="center">
					<Heading
						fontSize="xl"
						fontFamily="body"
						fontWeight={500}
						color={textColor}
					>
						{teamName}
					</Heading>
					<Stack direction="row" align="center" />
				</Stack>
			</Box>
		</Center>
	);
}
