import { useState, useEffect } from 'react';
import { Checkbox, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, useColorModeValue } from '@chakra-ui/react';
function PopupAlert({bodyText = 'corpo padrão', titleText='titulo padrão'}, localStorageKey='localStoragePadrao') {
  const [isOpen, setIsOpen] = useState(false);
  const [checkB, setCheckB] = useState(false)
  
  useEffect(() => {
    if(localStorage.getItem(localStorageKey) == 'naomostrar'){
      console.log(localStorage.getItem(localStorageKey))
      setIsOpen(false);
      return;
    } else {
      console.log(localStorage.getItem(localStorageKey))
      setIsOpen(true);
    }
    
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    console.log(checkB)
    if(checkB == true ) {
      localStorage.setItem(localStorageKey,'naomostrar');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue('gray.50', 'idsm.darkBlueCoolors.base')} >
        <ModalHeader>{titleText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {bodyText}
        </ModalBody>
        <ModalFooter justifyContent={'space-between'}>
          <Checkbox isChecked={checkB} onChange={() => setCheckB(!checkB)}>não mostrar novamente</Checkbox>
          <Button colorScheme="blue" mr={3} onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PopupAlert;
