import {
	Box,
	Image,
	Text,
	Grid,
	GridItem,
	Button,
	Link,
} from '@chakra-ui/react';

import { Link as LinkReactRouterDom } from 'react-router-dom';
import { Element } from 'react-scroll';

export const HomeCardDesktop = ({heightPage= '100vh', sectionName, cardTitle, cardText, cardButtonText, imgLinkUrl, cardLinkUrl, imgAltText = "logo seção", isDesktop=true, isHome=false}) => {
  if(isDesktop) {    
    return (
    <Element
			name={sectionName}
			className="section"

      {...isHome ? {style: {minHeight: heightPage, height: '100vh'}} : {style: {minHeight: '100vh', height: '100vh'}}}
		>
			<Grid
				templateColumns="2.5fr 1.5fr"
				gap="24"
				alignItems="center"
        justifyContent={'center'}
				maxW={'1200'}
				h="100%"
				marginRight={24}
			>
				<GridItem zIndex={1}>
          {isHome ? null :
            <Text mt="4" fontSize="6xl">
						  {cardTitle}
					  </Text>
          }
					<Text
						mt="4"
						textAlign={'justify'}
						style={{ textIndent: '4em' }}
            fontSize={'1.1rem'}
					>
            {cardText}
					</Text>
          {isHome ? null :
            cardButtonText.map((text, index) => {
              return (
                <Button margin={'4'} key={index}>
                  <Link as={LinkReactRouterDom} to={cardLinkUrl[index]} key={index}>
                    {text}
                  </Link>
                </Button>
              )
            })
          }
				</GridItem>
				<GridItem zIndex={1}>
					<Image
						src={imgLinkUrl}
						alt={imgAltText}
						maxW="full"
						w={'400px'}
					/>
				</GridItem>
			</Grid>
		</Element>
    )
    } else {
      return(
        <Element
					name={sectionName}
					className="section"
					style={{ minHeight: '100vh'}}
				>
					<Grid
					templateColumns={'1fr'}
					gap="16"
					alignItems="center"
					justifyContent={'center'}
					maxW={'1200'}
					h="100%"
				  >
						<GridItem zIndex={1}>
							
              {isHome ? null :
                <Text mt="4" fontSize={['4xl', '5xl']}>
                  {cardTitle}
                </Text>
              }
						  <Box display="flex" justifyContent="center" mt="8">
							  <Image
								  src={imgLinkUrl}
								  alt={imgAltText}
								  maxW="full"
								  w={['70%', '320px']}
							  />
						  </Box>
						  <Text mt="8" fontSize={'1rem'} textAlign={'justify'}>
                {cardText}
						  </Text>
              {isHome ? null :
                cardButtonText.map((text, index) => {
                  return (
                    <Button margin={'4'} key={index}>
                      <Link as={LinkReactRouterDom} to={cardLinkUrl[index]} key={index}>
                        {text}
                      </Link>
                    </Button>
                  )
                })
              }
					  </GridItem>
					</Grid>
				</Element>
      )
    }
}