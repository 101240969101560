import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

const ChartLegend = ({ legendItems }) => {
  return (
    <Flex align="center" justify="center">
      {legendItems.map((item, index) => (
        <Flex key={index} align="center" m={2}>
          <Box w="20px" h="20px" bg={item.color} mr={2} />
          <Text fontSize="sm">{item.label}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default ChartLegend;
