export const pbData = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        id: '2500106',
        name: 'Água Branca',
        description: 'Água Branca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.7037601546, -7.3731762414],
            [-37.6923221715, -7.3789316639],
            [-37.6719736902, -7.3764651899],
            [-37.6494786532, -7.4022367778],
            [-37.641650307, -7.4119640252],
            [-37.6119253302, -7.4110296802],
            [-37.6085388856, -7.4107184445],
            [-37.5953212301, -7.4170073756],
            [-37.5929918014, -7.4285712765],
            [-37.5703127562, -7.4534823691],
            [-37.5699295473, -7.4539016767],
            [-37.5681986374, -7.4558030883],
            [-37.5736748347, -7.4588626178],
            [-37.5654147836, -7.4825074645],
            [-37.5714470389, -7.4886916125],
            [-37.5714383226, -7.4887060808],
            [-37.5863839038, -7.4987013277],
            [-37.609424848, -7.5076244139],
            [-37.6110274017, -7.5181609511],
            [-37.6195985696, -7.525472144],
            [-37.6300320777, -7.5321036347],
            [-37.6441957015, -7.529470874],
            [-37.6494814782, -7.5299032733],
            [-37.6591834592, -7.5227299678],
            [-37.6762883962, -7.5352063875],
            [-37.6818043504, -7.5479685062],
            [-37.7061598515, -7.5501762792],
            [-37.7097420287, -7.559118288],
            [-37.7062251895, -7.5690591348],
            [-37.7106646044, -7.5733453956],
            [-37.7206845884, -7.5814852006],
            [-37.7283852612, -7.5793224273],
            [-37.7346032968, -7.5968071907],
            [-37.7494153443, -7.5662349877],
            [-37.7494232327, -7.556327126],
            [-37.7320730976, -7.5448793626],
            [-37.7270117305, -7.5414996609],
            [-37.7052942352, -7.4759948791],
            [-37.7005479159, -7.4616872929],
            [-37.7109791446, -7.4362924428],
            [-37.7264611165, -7.4318823705],
            [-37.7243169838, -7.4087413043],
            [-37.7269126683, -7.3937490633],
            [-37.7201185461, -7.3844369027],
            [-37.7210160789, -7.381921767],
            [-37.7111934552, -7.3811083963],
            [-37.7065070986, -7.3850485924],
            [-37.7037601546, -7.3731762414],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2500205', name: 'Aguiar', description: 'Aguiar' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.2050358822, -7.0098057424],
            [-38.1562922235, -7.0094375272],
            [-38.1322238322, -7.0077362418],
            [-38.0896716242, -7.0124371674],
            [-38.0887476125, -7.0146317483],
            [-38.0582177878, -7.0870471685],
            [-38.0912735245, -7.0902680948],
            [-38.0946079239, -7.09077024],
            [-38.0979223255, -7.0864594293],
            [-38.1064438639, -7.0875245786],
            [-38.123621175, -7.0863244389],
            [-38.1297215025, -7.0901588646],
            [-38.1335411344, -7.087390803],
            [-38.1432092027, -7.0878412339],
            [-38.1531707468, -7.0989576354],
            [-38.160561963, -7.1080540878],
            [-38.1754316203, -7.1077982607],
            [-38.1810008959, -7.1185120965],
            [-38.186790977, -7.1179338574],
            [-38.1876060414, -7.1141185101],
            [-38.1980996766, -7.12030449],
            [-38.2041615937, -7.1198265415],
            [-38.2324883693, -7.1311331812],
            [-38.2313189405, -7.1463035203],
            [-38.2271360122, -7.1510627406],
            [-38.1717592648, -7.1605464484],
            [-38.2300981144, -7.1571372734],
            [-38.2426435124, -7.1669086937],
            [-38.2720321164, -7.1774041446],
            [-38.2912173432, -7.1748459785],
            [-38.2953557028, -7.1574942546],
            [-38.2954837782, -7.1564247015],
            [-38.2944346221, -7.1446878671],
            [-38.2839587381, -7.1291007647],
            [-38.3014740415, -7.1272298407],
            [-38.3309449134, -7.1421876326],
            [-38.3310545726, -7.1158690322],
            [-38.3310906128, -7.1070371524],
            [-38.3312760472, -7.1062615273],
            [-38.3215496995, -7.0962323883],
            [-38.3178999077, -7.0839730864],
            [-38.3095003898, -7.0823303145],
            [-38.3353457964, -7.0638536703],
            [-38.3223590445, -7.0623248647],
            [-38.2976509033, -7.0360412964],
            [-38.2838564357, -7.011640103],
            [-38.2771316075, -7.0039251053],
            [-38.2622779384, -6.9928333899],
            [-38.2536951109, -6.994296574],
            [-38.2299128824, -7.0027129498],
            [-38.2050358822, -7.0098057424],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2500304',
        name: 'Alagoa Grande',
        description: 'Alagoa Grande',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5346505475, -7.0093716626],
            [-35.5233622552, -7.0312944746],
            [-35.5150219957, -7.0304049193],
            [-35.5101336785, -7.0316403711],
            [-35.5066673223, -7.0254842982],
            [-35.5011092565, -7.0259115863],
            [-35.4968546149, -7.0331515734],
            [-35.4908357036, -7.0663608872],
            [-35.5142762725, -7.0620481716],
            [-35.5111150783, -7.0708094162],
            [-35.504566049, -7.0753756597],
            [-35.5070399987, -7.0838598128],
            [-35.4999671147, -7.0944495536],
            [-35.5232722771, -7.1049027484],
            [-35.5257881597, -7.1095910703],
            [-35.5267819384, -7.1143456721],
            [-35.5327631394, -7.1200591044],
            [-35.5692125639, -7.1223492932],
            [-35.6134964008, -7.1339246106],
            [-35.6137465071, -7.1339891913],
            [-35.6241248241, -7.1324476198],
            [-35.634956285, -7.1328783302],
            [-35.6474554743, -7.1449611946],
            [-35.652753658, -7.1395824016],
            [-35.6600435817, -7.1388730661],
            [-35.6719276454, -7.1469437812],
            [-35.6802077757, -7.1483501235],
            [-35.6883485083, -7.1351344613],
            [-35.7021518378, -7.1127186363],
            [-35.7076085353, -7.1038541885],
            [-35.7076321637, -7.0900546264],
            [-35.7109529895, -7.0885484665],
            [-35.7048653458, -7.0817522622],
            [-35.7050764908, -7.0728095738],
            [-35.7011691025, -7.0616199212],
            [-35.6958118279, -7.0635179881],
            [-35.6831847449, -7.0462424626],
            [-35.6713560439, -7.0479110331],
            [-35.6662750709, -7.0378623356],
            [-35.661506355, -7.0343534547],
            [-35.6781675386, -7.0396372392],
            [-35.6912455635, -7.0356519232],
            [-35.6927561032, -7.0274723261],
            [-35.683589364, -7.0258058331],
            [-35.6722474576, -7.0171032205],
            [-35.6653344334, -7.0123008639],
            [-35.6435119834, -6.9844860372],
            [-35.6367477806, -6.9803261889],
            [-35.6125978983, -6.9765442596],
            [-35.6006318676, -6.9675365005],
            [-35.5940512624, -6.9531636171],
            [-35.5933637848, -6.9478121819],
            [-35.5803820343, -6.9414901535],
            [-35.5736169361, -6.9694796863],
            [-35.5591649068, -6.9772618235],
            [-35.5588359061, -6.9776928493],
            [-35.556124465, -6.9812447672],
            [-35.5346505475, -7.0093716626],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2500403',
        name: 'Alagoa Nova',
        description: 'Alagoa Nova',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7956605978, -7.0233298805],
            [-35.7883797878, -7.0293064281],
            [-35.7791899636, -7.0273504191],
            [-35.7677619369, -7.0312422082],
            [-35.7627294431, -7.0329511804],
            [-35.7625969869, -7.033001411],
            [-35.7553786276, -7.0266318508],
            [-35.7532379256, -7.0308638238],
            [-35.7433885655, -7.0291836415],
            [-35.7355392872, -7.0216419455],
            [-35.7148045463, -7.0249944982],
            [-35.7115744081, -7.0232516639],
            [-35.6927561032, -7.0274723261],
            [-35.6912455635, -7.0356519232],
            [-35.6781675386, -7.0396372392],
            [-35.661506355, -7.0343534547],
            [-35.6662750709, -7.0378623356],
            [-35.6713560439, -7.0479110331],
            [-35.6831847449, -7.0462424626],
            [-35.6958118279, -7.0635179881],
            [-35.7011691025, -7.0616199212],
            [-35.7050764908, -7.0728095738],
            [-35.7048653458, -7.0817522622],
            [-35.7109529895, -7.0885484665],
            [-35.7181033657, -7.0906986429],
            [-35.7213553511, -7.0963665631],
            [-35.7226989493, -7.098538731],
            [-35.7527146339, -7.1080652228],
            [-35.769288693, -7.1041928811],
            [-35.7753175562, -7.1022350747],
            [-35.7947213647, -7.1062642298],
            [-35.7952779261, -7.1056326763],
            [-35.8046841372, -7.1014184863],
            [-35.8133040768, -7.1021266577],
            [-35.8115630306, -7.0951624233],
            [-35.8148092852, -7.0847170249],
            [-35.8178607963, -7.0766928318],
            [-35.8161140022, -7.0748868357],
            [-35.8233490631, -7.0718609506],
            [-35.8268983726, -7.0703759472],
            [-35.8336970111, -7.0601326551],
            [-35.8418312334, -7.0563115351],
            [-35.855413245, -7.0503150412],
            [-35.8552084325, -7.0501824653],
            [-35.8406942405, -7.0407857583],
            [-35.8320393219, -7.0291156557],
            [-35.8249941009, -7.030205063],
            [-35.8092376283, -7.031893364],
            [-35.8088577222, -7.0317905388],
            [-35.7956605978, -7.0233298805],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2500502',
        name: 'Alagoinha',
        description: 'Alagoinha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.563448683, -6.9076043834],
            [-35.5341058468, -6.9285429881],
            [-35.4790274554, -6.9231441642],
            [-35.4723656671, -6.9230677367],
            [-35.471653295, -6.9351610244],
            [-35.4655591843, -6.9522510224],
            [-35.4584040906, -6.9571770768],
            [-35.4599012842, -6.9636597116],
            [-35.4591823615, -6.9737424557],
            [-35.4633804927, -6.9814290715],
            [-35.4793948431, -6.9898977063],
            [-35.518955305, -6.9858251289],
            [-35.5236297008, -6.9913924235],
            [-35.5149435736, -7.0057518537],
            [-35.5197240183, -7.0185864313],
            [-35.514968867, -7.0222883045],
            [-35.5150219957, -7.0304049193],
            [-35.5233622552, -7.0312944746],
            [-35.5346505475, -7.0093716626],
            [-35.556124465, -6.9812447672],
            [-35.5588359061, -6.9776928493],
            [-35.5591649068, -6.9772618235],
            [-35.5736169361, -6.9694796863],
            [-35.5803820343, -6.9414901535],
            [-35.5797064502, -6.9408153063],
            [-35.5709090537, -6.9347516961],
            [-35.5823461504, -6.9192374662],
            [-35.563448683, -6.9076043834],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2500536', name: 'Alcantil', description: 'Alcantil' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.0324031559, -7.5869058336],
            [-36.0210260674, -7.5910291352],
            [-36.0185069404, -7.5953065329],
            [-36.0165736138, -7.5882206227],
            [-36.0061803829, -7.5899572883],
            [-36.0031940834, -7.5873931308],
            [-35.990135089, -7.5951224431],
            [-35.9908626013, -7.6011477913],
            [-35.9758542485, -7.6131179374],
            [-35.9710098363, -7.624453655],
            [-35.9723886601, -7.632671745],
            [-35.9625502778, -7.6395641932],
            [-35.9650229064, -7.6483528374],
            [-35.9595486527, -7.6522763818],
            [-35.9715608359, -7.6683765288],
            [-35.9770669839, -7.6844354807],
            [-35.9818952442, -7.6916985706],
            [-35.9810059925, -7.6942410175],
            [-35.9744581757, -7.6943746337],
            [-35.9758537185, -7.7064008659],
            [-35.9788403234, -7.7052808967],
            [-35.9809674235, -7.7173912689],
            [-35.9838893681, -7.7177114479],
            [-35.9872736188, -7.7263145102],
            [-35.9851937904, -7.7312517063],
            [-35.9921306337, -7.7309717205],
            [-35.9909948005, -7.7367469033],
            [-35.9849202802, -7.7373677087],
            [-35.9926312446, -7.7433361153],
            [-35.995987569, -7.7622220041],
            [-35.9999859263, -7.7628520215],
            [-36.0053809587, -7.7588095241],
            [-36.0076447567, -7.7611669547],
            [-36.0096626693, -7.7578008795],
            [-36.0110324075, -7.765557193],
            [-36.0146447591, -7.7648952652],
            [-36.0296866634, -7.7857647822],
            [-36.0312398826, -7.7945513919],
            [-36.0350796276, -7.7957804669],
            [-36.0429152544, -7.793251012],
            [-36.0535393386, -7.8009492758],
            [-36.0686587292, -7.7805620742],
            [-36.1030394742, -7.7790155233],
            [-36.1169440105, -7.7853746789],
            [-36.1264876787, -7.7837917795],
            [-36.1273599857, -7.7690433309],
            [-36.1189139809, -7.7515969803],
            [-36.1170649247, -7.7344850443],
            [-36.1071081613, -7.7249505338],
            [-36.1047359573, -7.7173203375],
            [-36.1309031811, -7.6679696591],
            [-36.1262175549, -7.659611564],
            [-36.1283545606, -7.6501457066],
            [-36.1257164945, -7.6442184467],
            [-36.1314684819, -7.6386580715],
            [-36.1234143155, -7.6359785841],
            [-36.1156065435, -7.6407019886],
            [-36.1098447379, -7.6361833676],
            [-36.1165990409, -7.6301544588],
            [-36.1110005086, -7.6230732623],
            [-36.1041575143, -7.6295475678],
            [-36.1034410204, -7.6196290967],
            [-36.0975462988, -7.6237915777],
            [-36.0963092767, -7.6160681342],
            [-36.0917071928, -7.6160635817],
            [-36.0894164282, -7.6125849392],
            [-36.087367242, -7.6160589669],
            [-36.0755127127, -7.613936354],
            [-36.0712920954, -7.6129480081],
            [-36.07132572, -7.6039162326],
            [-36.0704039976, -7.6018894278],
            [-36.0634672406, -7.6079591389],
            [-36.0629981208, -7.6033116302],
            [-36.0561677671, -7.6015283063],
            [-36.0590350864, -7.5972265639],
            [-36.0552534646, -7.5885975559],
            [-36.0506452361, -7.5914325074],
            [-36.0347504452, -7.5887666551],
            [-36.0324031559, -7.5869058336],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2500577',
        name: 'Algodão de Jandaíra',
        description: 'Algodão de Jandaíra',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8390752118, -6.8461582901],
            [-35.8286198338, -6.8563896856],
            [-35.8098386605, -6.8632165272],
            [-35.8025346948, -6.8658928843],
            [-35.8071057869, -6.8733909152],
            [-35.8091401945, -6.8797710252],
            [-35.8191453475, -6.873825592],
            [-35.8490456359, -6.855295096],
            [-35.8725391333, -6.8613045091],
            [-35.8929453136, -6.8764902335],
            [-35.9031636009, -6.8898914716],
            [-35.951618182, -6.890023335],
            [-35.9449869315, -6.9015198699],
            [-35.9514980948, -6.9090603163],
            [-35.9637691206, -6.9222110035],
            [-35.9798332777, -6.9242820519],
            [-35.9842343959, -6.9314330648],
            [-36.0108278778, -6.9437023473],
            [-36.0108025338, -6.9476008025],
            [-36.0175430481, -6.9520884961],
            [-36.0198829337, -6.9614709253],
            [-36.0489817773, -6.9771783566],
            [-36.0564967846, -6.9710007492],
            [-36.0776573225, -6.9707228827],
            [-36.1125168163, -6.9307651015],
            [-36.0981420496, -6.9234768461],
            [-36.0713770553, -6.9065228024],
            [-36.0551690406, -6.8933914456],
            [-36.0323937775, -6.8815011092],
            [-36.0244453005, -6.8792803685],
            [-36.0018812769, -6.8693562096],
            [-35.9385682654, -6.828564612],
            [-35.938420586, -6.8284506827],
            [-35.9232694536, -6.8149830764],
            [-35.9164554242, -6.7957820304],
            [-35.8973826916, -6.7932019355],
            [-35.8911791291, -6.7930344506],
            [-35.8689794615, -6.8165963021],
            [-35.8440259949, -6.8360174944],
            [-35.8390752118, -6.8461582901],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2500601', name: 'Alhandra', description: 'Alhandra' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.9260535685, -7.2726124545],
            [-34.9199007923, -7.2804215625],
            [-34.9166000368, -7.2888658255],
            [-34.9197228681, -7.2998165651],
            [-34.9182491737, -7.3031530402],
            [-34.8806156455, -7.3242585613],
            [-34.8737762864, -7.333396259],
            [-34.8653278758, -7.3383239925],
            [-34.8595293685, -7.3429337252],
            [-34.8696947164, -7.3545029789],
            [-34.8709134575, -7.3607388312],
            [-34.8740908031, -7.3647185995],
            [-34.896573341, -7.3928826883],
            [-34.910498816, -7.410331456],
            [-34.9007658841, -7.4165159739],
            [-34.8859235692, -7.4164138243],
            [-34.8812188393, -7.4169193812],
            [-34.870873913, -7.4293306332],
            [-34.8634188592, -7.4335125082],
            [-34.8624415165, -7.4290271702],
            [-34.8489178438, -7.4378239775],
            [-34.8491591007, -7.4384676405],
            [-34.8561512356, -7.4390858656],
            [-34.8602835344, -7.4542318085],
            [-34.8762395304, -7.4559934265],
            [-34.8855577376, -7.4594136854],
            [-34.9063928093, -7.4468174471],
            [-34.9099695187, -7.4449270147],
            [-34.9197225652, -7.4393207693],
            [-34.9240010272, -7.4311473973],
            [-34.94130104, -7.4220347492],
            [-34.9423707865, -7.4162881863],
            [-34.9542783206, -7.4048858196],
            [-34.9525384277, -7.3884927628],
            [-34.9577747082, -7.3810839262],
            [-34.9673086824, -7.3675897691],
            [-34.9558643801, -7.3496907832],
            [-34.9803747993, -7.3282683011],
            [-34.9833329579, -7.3259307809],
            [-35.0434832383, -7.2783739915],
            [-35.039276508, -7.2764032907],
            [-35.0174966485, -7.2699980664],
            [-34.9908259108, -7.2677072221],
            [-34.9738052964, -7.2738651864],
            [-34.9686142408, -7.2821279821],
            [-34.9625062761, -7.2815668425],
            [-34.9497229086, -7.2753499958],
            [-34.9435232956, -7.248536677],
            [-34.9343443695, -7.2521088453],
            [-34.9376346059, -7.26092577],
            [-34.9392751072, -7.2730139086],
            [-34.9260535685, -7.2726124545],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2500700',
        name: 'São João do Rio do Peixe',
        description: 'São João do Rio do Peixe',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3478254243, -6.5805750917],
            [-38.3417353376, -6.5817249329],
            [-38.334268993, -6.5779237738],
            [-38.3424400925, -6.5909318139],
            [-38.3424928223, -6.6029837492],
            [-38.3527355232, -6.614372343],
            [-38.3526158636, -6.6199606188],
            [-38.3411660131, -6.6285062039],
            [-38.3458748803, -6.6365747512],
            [-38.3505338953, -6.6592028521],
            [-38.3550639475, -6.6808108245],
            [-38.3550693869, -6.6812550407],
            [-38.3552243872, -6.6938205775],
            [-38.3559970342, -6.7573914818],
            [-38.3598930029, -6.7621482747],
            [-38.3721464377, -6.772302359],
            [-38.3533823361, -6.7715254876],
            [-38.3510717112, -6.7939355673],
            [-38.3517103956, -6.7958023531],
            [-38.3527353818, -6.7987935169],
            [-38.3630974239, -6.8241681659],
            [-38.3637508369, -6.8506591896],
            [-38.3638831828, -6.8560203049],
            [-38.3700873134, -6.863910551],
            [-38.3577935349, -6.8787665424],
            [-38.3484893157, -6.8806515826],
            [-38.3561632198, -6.8953439804],
            [-38.3631559775, -6.9098421449],
            [-38.369383279, -6.9141304003],
            [-38.3653752216, -6.9196135946],
            [-38.3673749205, -6.9211235705],
            [-38.3784964415, -6.9186995476],
            [-38.3805834185, -6.9238002416],
            [-38.3829503045, -6.928196148],
            [-38.4013720707, -6.9287780557],
            [-38.4113312492, -6.9258138429],
            [-38.4263049428, -6.9314146267],
            [-38.4269484086, -6.9366146583],
            [-38.4306525047, -6.9118429077],
            [-38.4324729339, -6.8984975015],
            [-38.4255973063, -6.8923256254],
            [-38.4187140851, -6.8670227135],
            [-38.4186673622, -6.8668493292],
            [-38.4179154383, -6.8488973766],
            [-38.4194472398, -6.8365208779],
            [-38.4228216228, -6.8092463392],
            [-38.4299362808, -6.8138904286],
            [-38.4657615841, -6.8098177634],
            [-38.4947038593, -6.805379911],
            [-38.5318619234, -6.8040618273],
            [-38.5621792943, -6.796475784],
            [-38.5468464577, -6.7910982546],
            [-38.5245157926, -6.7961187265],
            [-38.5170942995, -6.7970780071],
            [-38.5089388883, -6.7901092334],
            [-38.4946810559, -6.7855584836],
            [-38.4955897286, -6.7691345838],
            [-38.4890967314, -6.757067123],
            [-38.4900847514, -6.7494191072],
            [-38.4857355577, -6.7370262788],
            [-38.4896530556, -6.735890186],
            [-38.4889497306, -6.7259050056],
            [-38.4936802793, -6.7199015484],
            [-38.5027735485, -6.7174041532],
            [-38.5026052144, -6.7078529781],
            [-38.5221235431, -6.7039820789],
            [-38.5193809697, -6.6947028444],
            [-38.5242342699, -6.6735119547],
            [-38.5076717048, -6.6718060848],
            [-38.4966631013, -6.6621177167],
            [-38.4928095555, -6.6638297492],
            [-38.4828768659, -6.6598231778],
            [-38.4754142737, -6.6492386925],
            [-38.4757377952, -6.6358980944],
            [-38.4553532772, -6.6081856047],
            [-38.4492876022, -6.589885463],
            [-38.4360775304, -6.5742868344],
            [-38.4115326377, -6.6011464605],
            [-38.4098946789, -6.5999181573],
            [-38.3851963646, -6.5813997037],
            [-38.3604806156, -6.5684400114],
            [-38.3486820242, -6.5736963236],
            [-38.3478254243, -6.5805750917],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2500734', name: 'Amparo', description: 'Amparo' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.998984768, -7.6059865803],
            [-36.9818880732, -7.6198370213],
            [-36.9883985737, -7.6302923358],
            [-37.0451941548, -7.6086921226],
            [-37.05968674, -7.6112345895],
            [-37.0693334308, -7.6093129073],
            [-37.0755468559, -7.5994838979],
            [-37.0856432092, -7.5956165829],
            [-37.0956171532, -7.5721604107],
            [-37.0900567822, -7.5688035551],
            [-37.0937328999, -7.5595737034],
            [-37.0901157805, -7.5556734666],
            [-37.0858548562, -7.5173860057],
            [-37.0679671022, -7.5167010248],
            [-37.0534817101, -7.5041609336],
            [-37.0428169374, -7.5035063787],
            [-37.0300750156, -7.4980301268],
            [-37.0258208031, -7.4969170904],
            [-37.0256476016, -7.4968040162],
            [-37.024862048, -7.4962901868],
            [-37.0228562355, -7.5017739102],
            [-37.0117818496, -7.5039765933],
            [-36.9781543215, -7.5427202894],
            [-37.0001923326, -7.5638960688],
            [-37.0020548513, -7.5675668823],
            [-36.9988807334, -7.5747199522],
            [-37.0018400152, -7.5794109157],
            [-36.9982859667, -7.584821399],
            [-36.998984768, -7.6059865803],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2500775',
        name: 'Aparecida',
        description: 'Aparecida',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.0161044794, -6.670068508],
            [-38.0115638266, -6.6749909917],
            [-37.9978282153, -6.676742748],
            [-37.9828286787, -6.696492868],
            [-37.9858326594, -6.7115263199],
            [-38.0288224163, -6.7171782945],
            [-38.0245193405, -6.7635729892],
            [-38.024508148, -6.7636902346],
            [-38.0162063413, -6.7716750106],
            [-38.0141355327, -6.7807277539],
            [-38.0163297369, -6.7881039402],
            [-38.0096804199, -6.7972514123],
            [-37.9998670856, -6.8164038914],
            [-37.9897957027, -6.8265673316],
            [-37.9949656636, -6.8281732367],
            [-38.0026594815, -6.82382358],
            [-38.0099638006, -6.8309059606],
            [-38.0100276125, -6.8310787281],
            [-38.0116264443, -6.8478125864],
            [-38.0008202968, -6.8553431219],
            [-37.9971619818, -6.8724919698],
            [-38.0142942152, -6.8842633767],
            [-38.0263064887, -6.8813639952],
            [-38.0301161473, -6.8871646812],
            [-38.0406438107, -6.887273314],
            [-38.0681991423, -6.8793419511],
            [-38.0888705355, -6.887022648],
            [-38.105346304, -6.8866975431],
            [-38.1227888593, -6.8972219163],
            [-38.1339104201, -6.8924272375],
            [-38.1505283788, -6.8951327189],
            [-38.1371767226, -6.8723825327],
            [-38.1329855876, -6.8713903003],
            [-38.1309107518, -6.8600409595],
            [-38.1189089645, -6.8531936146],
            [-38.1295132782, -6.8176229612],
            [-38.1355955993, -6.7875385459],
            [-38.1396923821, -6.7530403645],
            [-38.1014748792, -6.7229457386],
            [-38.0915665508, -6.6887772022],
            [-38.0870880937, -6.6884259368],
            [-38.0861134553, -6.6845556015],
            [-38.0690094027, -6.6727407018],
            [-38.0487929796, -6.6752692683],
            [-38.0386555042, -6.6685991205],
            [-38.0161044794, -6.670068508],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2500809', name: 'Araçagi', description: 'Araçagi' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3230058364, -6.7985253596],
            [-35.3274253104, -6.8244819941],
            [-35.3056146273, -6.8315462608],
            [-35.3001012623, -6.8512003731],
            [-35.3184527943, -6.8604124659],
            [-35.3157630005, -6.8713660014],
            [-35.3053186031, -6.8836412385],
            [-35.305215094, -6.8960144416],
            [-35.3015403047, -6.9033742994],
            [-35.3020311726, -6.9082488051],
            [-35.2957394115, -6.9130112626],
            [-35.2922314479, -6.9252910774],
            [-35.287165704, -6.9430160514],
            [-35.2774772772, -6.9425788725],
            [-35.2739439928, -6.9475649024],
            [-35.2662136561, -6.9525099134],
            [-35.2628300031, -6.9547657269],
            [-35.2622604272, -6.9550287569],
            [-35.2626917993, -6.9554187901],
            [-35.2776232712, -6.9689116297],
            [-35.3159501129, -6.9849464993],
            [-35.3168474055, -6.9850628104],
            [-35.3168920054, -6.9849252343],
            [-35.3406465335, -6.955962574],
            [-35.342790393, -6.9420055731],
            [-35.3456065608, -6.9410791785],
            [-35.3624570508, -6.9397234179],
            [-35.3809905041, -6.9325420428],
            [-35.3821683747, -6.9236526485],
            [-35.3843425351, -6.9014393463],
            [-35.3851145693, -6.8935415875],
            [-35.3887513431, -6.8562899609],
            [-35.3917630799, -6.8539149577],
            [-35.3895420061, -6.8483685735],
            [-35.3931420948, -6.8454965587],
            [-35.3981825879, -6.8466897654],
            [-35.3999196761, -6.8442822052],
            [-35.4026106961, -6.8476884755],
            [-35.4034557843, -6.8454209112],
            [-35.4060902492, -6.8325126089],
            [-35.4096093457, -6.8350952142],
            [-35.4114056578, -6.830316003],
            [-35.4155883932, -6.8309062429],
            [-35.4188534033, -6.832530967],
            [-35.4174085223, -6.824799853],
            [-35.4223016491, -6.8226974127],
            [-35.4184077548, -6.8132985944],
            [-35.4279982104, -6.8151542949],
            [-35.4298317338, -6.812898309],
            [-35.4591752851, -6.8210880368],
            [-35.4644954662, -6.8116522811],
            [-35.461536938, -6.8093723556],
            [-35.4464301537, -6.7920865938],
            [-35.4352887124, -6.7873435408],
            [-35.4237552999, -6.7768708586],
            [-35.4170079777, -6.7743473542],
            [-35.408584614, -6.7654595887],
            [-35.3904156043, -6.7611671715],
            [-35.3741391204, -6.7559962446],
            [-35.3725695174, -6.7554697613],
            [-35.3720513709, -6.755296171],
            [-35.3585300692, -6.7569891783],
            [-35.3503209281, -6.7741644032],
            [-35.3501693015, -6.7742439805],
            [-35.3402101607, -6.7794166633],
            [-35.3248114867, -6.7794651882],
            [-35.320769688, -6.7884897256],
            [-35.3157870494, -6.7885565668],
            [-35.3230058364, -6.7985253596],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2500908', name: 'Arara', description: 'Arara' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7290981952, -6.8168585546],
            [-35.7197466169, -6.8247940569],
            [-35.7158930943, -6.8206738291],
            [-35.705770222, -6.8192086799],
            [-35.7019159792, -6.8146692947],
            [-35.7005482297, -6.8143185133],
            [-35.7016003466, -6.8328533688],
            [-35.7088167654, -6.8408222491],
            [-35.6991427641, -6.8460910213],
            [-35.6925618672, -6.8598065076],
            [-35.6875878498, -6.8618159399],
            [-35.6781400786, -6.8673277702],
            [-35.6739878146, -6.8662982764],
            [-35.6585728146, -6.8676978993],
            [-35.6530458637, -6.8816716928],
            [-35.6457401281, -6.8823004423],
            [-35.6452514251, -6.8830007088],
            [-35.6497438936, -6.9096255856],
            [-35.6562589182, -6.9098333751],
            [-35.6594036341, -6.9069510486],
            [-35.669483984, -6.8999374062],
            [-35.6797541146, -6.8907285307],
            [-35.7213699185, -6.8938485008],
            [-35.7268525859, -6.8942593451],
            [-35.7298524312, -6.8926587063],
            [-35.7500686021, -6.8826998377],
            [-35.7647023378, -6.8832305397],
            [-35.7672582596, -6.8747849583],
            [-35.7751389633, -6.8760762156],
            [-35.7829524386, -6.8735340691],
            [-35.7857690744, -6.8726176099],
            [-35.8004595796, -6.8640926589],
            [-35.8025346948, -6.8658928843],
            [-35.8098386605, -6.8632165272],
            [-35.8081480236, -6.8476108787],
            [-35.8116419626, -6.8429582544],
            [-35.8063305145, -6.8407753981],
            [-35.8050651422, -6.8341693296],
            [-35.7961434129, -6.8339266236],
            [-35.7920015004, -6.8263299285],
            [-35.7865216196, -6.8237796007],
            [-35.7624192243, -6.8237548833],
            [-35.75919821, -6.8241678771],
            [-35.7549548456, -6.8245172994],
            [-35.7521564823, -6.8249333855],
            [-35.742222931, -6.8131222274],
            [-35.7290981952, -6.8168585546],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501005', name: 'Araruna', description: 'Araruna' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.6569367064, -6.4453819066],
            [-35.6364723379, -6.45398821],
            [-35.653109445, -6.4914300143],
            [-35.6639579185, -6.5004002031],
            [-35.6871057958, -6.5335636313],
            [-35.6917131059, -6.5543107263],
            [-35.7127032285, -6.565419181],
            [-35.7207800759, -6.5655482335],
            [-35.7192582071, -6.5686889981],
            [-35.7083448933, -6.5891506541],
            [-35.6898150536, -6.5907318172],
            [-35.7068130267, -6.5994578708],
            [-35.7182399054, -6.6013625549],
            [-35.7225586762, -6.6109998525],
            [-35.7345980285, -6.6135337173],
            [-35.73666196, -6.6013468271],
            [-35.7402987005, -6.5970212271],
            [-35.7491485731, -6.5975158235],
            [-35.755352712, -6.5920981519],
            [-35.7765828074, -6.5958015695],
            [-35.7768839051, -6.595639738],
            [-35.8098577911, -6.5779781769],
            [-35.8209402367, -6.5733002377],
            [-35.8308707043, -6.5637169687],
            [-35.8587410615, -6.5466670052],
            [-35.8678627976, -6.5453818687],
            [-35.852762014, -6.4872133556],
            [-35.8169904781, -6.4850935881],
            [-35.7823015101, -6.4830359421],
            [-35.7795980783, -6.4828754057],
            [-35.7729957797, -6.4824436199],
            [-35.7498993031, -6.4780910502],
            [-35.7432332578, -6.473484507],
            [-35.7408275535, -6.4759035395],
            [-35.7258641158, -6.4692113422],
            [-35.7215288528, -6.4712651883],
            [-35.7109289801, -6.4706936789],
            [-35.6978539043, -6.4624908657],
            [-35.6816459178, -6.447528101],
            [-35.6710208669, -6.4513235205],
            [-35.664830523, -6.4501145669],
            [-35.6632768181, -6.4464296994],
            [-35.6569367064, -6.4453819066],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501104', name: 'Areia', description: 'Areia' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7829524386, -6.8735340691],
            [-35.7751389633, -6.8760762156],
            [-35.7672582596, -6.8747849583],
            [-35.7647023378, -6.8832305397],
            [-35.7500686021, -6.8826998377],
            [-35.7298524312, -6.8926587063],
            [-35.7268525859, -6.8942593451],
            [-35.7213699185, -6.8938485008],
            [-35.6797541146, -6.8907285307],
            [-35.669483984, -6.8999374062],
            [-35.6594036341, -6.9069510486],
            [-35.6562589182, -6.9098333751],
            [-35.6497438936, -6.9096255856],
            [-35.6494742483, -6.9095752304],
            [-35.6133478392, -6.9162340029],
            [-35.6045419182, -6.9171301195],
            [-35.5828006983, -6.9191930912],
            [-35.5823461504, -6.9192374662],
            [-35.5709090537, -6.9347516961],
            [-35.5797064502, -6.9408153063],
            [-35.5803820343, -6.9414901535],
            [-35.5933637848, -6.9478121819],
            [-35.5940512624, -6.9531636171],
            [-35.6006318676, -6.9675365005],
            [-35.6125978983, -6.9765442596],
            [-35.6367477806, -6.9803261889],
            [-35.6435119834, -6.9844860372],
            [-35.6653344334, -7.0123008639],
            [-35.6722474576, -7.0171032205],
            [-35.683589364, -7.0258058331],
            [-35.6927561032, -7.0274723261],
            [-35.7115744081, -7.0232516639],
            [-35.7148045463, -7.0249944982],
            [-35.7355392872, -7.0216419455],
            [-35.7433885655, -7.0291836415],
            [-35.7532379256, -7.0308638238],
            [-35.7553786276, -7.0266318508],
            [-35.7625969869, -7.033001411],
            [-35.7627294431, -7.0329511804],
            [-35.7677619369, -7.0312422082],
            [-35.7791899636, -7.0273504191],
            [-35.7784744811, -7.0211378541],
            [-35.7820793341, -7.0185473838],
            [-35.7777519113, -7.0033251322],
            [-35.7747300799, -6.9955008241],
            [-35.7741210188, -6.9888220425],
            [-35.7760685634, -6.9840400245],
            [-35.7760398081, -6.9837175646],
            [-35.7741289262, -6.9619369933],
            [-35.7910733946, -6.9377316149],
            [-35.7914976214, -6.9363071859],
            [-35.7920014918, -6.9150334041],
            [-35.792786931, -6.8961258232],
            [-35.8071057869, -6.8733909152],
            [-35.8025346948, -6.8658928843],
            [-35.8004595796, -6.8640926589],
            [-35.7857690744, -6.8726176099],
            [-35.7829524386, -6.8735340691],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2501153',
        name: 'Areia de Baraúnas',
        description: 'Areia de Baraúnas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.937213727, -7.0468128594],
            [-36.9292502974, -7.0574657417],
            [-36.916287199, -7.0802206219],
            [-36.9224374789, -7.0872714597],
            [-36.9237597069, -7.1051281228],
            [-36.9321825894, -7.109478885],
            [-36.9232506243, -7.1175824733],
            [-36.9259157694, -7.1217665774],
            [-36.9309514101, -7.1496673493],
            [-36.9440986424, -7.1513637463],
            [-36.9543383262, -7.1485170678],
            [-36.9544817312, -7.1485100146],
            [-36.9638642499, -7.1503655168],
            [-36.9763231426, -7.1600619449],
            [-36.9922790804, -7.179715761],
            [-37.0016016257, -7.1753310739],
            [-36.9999303331, -7.1739018906],
            [-36.9952661987, -7.1682370608],
            [-36.9939238879, -7.1570434866],
            [-36.9960960481, -7.1479415376],
            [-37.0028990745, -7.1450711684],
            [-36.999659236, -7.1359285792],
            [-37.003135863, -7.1307563951],
            [-37.0045020244, -7.1273399478],
            [-36.9951274886, -7.1263757366],
            [-36.9934177766, -7.1195313946],
            [-37.0056870846, -7.0746496499],
            [-37.0065939758, -7.0520879186],
            [-37.0048936774, -7.0514676731],
            [-36.9971036147, -7.0608704781],
            [-36.9930782571, -7.0612872989],
            [-36.9753641627, -7.0569240064],
            [-36.9590771961, -7.0476638517],
            [-36.948199623, -7.0477604058],
            [-36.9434942182, -7.0445666196],
            [-36.937213727, -7.0468128594],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501203', name: 'Areial', description: 'Areial' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.9528762247, -7.0146885697],
            [-35.9468113321, -7.0195632534],
            [-35.9278397228, -7.0172087021],
            [-35.9270171159, -7.0173276919],
            [-35.9147589652, -7.0286073583],
            [-35.9109125459, -7.0325883925],
            [-35.9037115114, -7.0388651073],
            [-35.9026486199, -7.0455911067],
            [-35.902864589, -7.0555183943],
            [-35.9120762893, -7.0705263211],
            [-35.9219519295, -7.0800058282],
            [-35.9271852346, -7.078379194],
            [-35.9616235167, -7.0688527572],
            [-35.9623975416, -7.0686380954],
            [-35.962625044, -7.068611991],
            [-35.9692456356, -7.0678678162],
            [-35.9652429174, -7.0620964489],
            [-35.9582937503, -7.0593984754],
            [-35.9586534799, -7.04564543],
            [-35.9501391237, -7.0291515734],
            [-35.9555065644, -7.0242186816],
            [-35.9625672569, -7.0244104351],
            [-35.957026528, -7.0167976172],
            [-35.95686983, -7.0164563704],
            [-35.9528762247, -7.0146885697],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501302', name: 'Aroeiras', description: 'Aroeiras' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5836457016, -7.4501839801],
            [-35.5821727015, -7.4616659414],
            [-35.5881267001, -7.4808300723],
            [-35.5911545253, -7.488664197],
            [-35.5922681074, -7.5047518498],
            [-35.5950704692, -7.524899892],
            [-35.5950759344, -7.5250241003],
            [-35.5943174901, -7.5401110654],
            [-35.6019248082, -7.5526756919],
            [-35.6006975577, -7.5788093442],
            [-35.5999911233, -7.5887135167],
            [-35.5897808236, -7.604406155],
            [-35.5949693423, -7.6164915714],
            [-35.5974155513, -7.6179337844],
            [-35.6101597023, -7.6177558448],
            [-35.6165449461, -7.6058102863],
            [-35.6240577793, -7.6080415501],
            [-35.6341121956, -7.612252031],
            [-35.6519486156, -7.612438997],
            [-35.6578419111, -7.6191797179],
            [-35.6701195118, -7.6219979616],
            [-35.6749446695, -7.6268140732],
            [-35.6766935176, -7.6361063455],
            [-35.6852772047, -7.6408094086],
            [-35.7111721689, -7.6415263749],
            [-35.7243685107, -7.6379674094],
            [-35.7332329638, -7.629574894],
            [-35.7292137652, -7.6259535523],
            [-35.7342574555, -7.6214567204],
            [-35.7336315, -7.6145837142],
            [-35.729408086, -7.6084125816],
            [-35.7337980025, -7.5974204385],
            [-35.7460627608, -7.592057073],
            [-35.7449358045, -7.5841004391],
            [-35.7573043008, -7.5722635212],
            [-35.7579715121, -7.5716239789],
            [-35.784540803, -7.5464047692],
            [-35.7904156148, -7.5408260905],
            [-35.7956583177, -7.5350297546],
            [-35.8062892936, -7.5308935817],
            [-35.8126789108, -7.5264553963],
            [-35.8296655006, -7.4937308458],
            [-35.8314835991, -7.4658849257],
            [-35.8245283087, -7.4599124611],
            [-35.8207135307, -7.4640196509],
            [-35.8137741905, -7.4601194128],
            [-35.8064190727, -7.4635150449],
            [-35.8090468018, -7.4658049532],
            [-35.8073914121, -7.4713979313],
            [-35.7958633799, -7.4710993706],
            [-35.7910512119, -7.4635376415],
            [-35.7864579605, -7.4660988883],
            [-35.7869310679, -7.4722040322],
            [-35.7863611843, -7.4751783607],
            [-35.7787993694, -7.4702390175],
            [-35.7729808547, -7.4736255017],
            [-35.7734193735, -7.4795539617],
            [-35.7704171797, -7.4813067054],
            [-35.764087052, -7.4769569601],
            [-35.7593266181, -7.4775071118],
            [-35.7543570849, -7.4694066401],
            [-35.7467487362, -7.474033408],
            [-35.7368991478, -7.467501623],
            [-35.7339521471, -7.4743665432],
            [-35.7227470086, -7.4653061222],
            [-35.7218536066, -7.4714329762],
            [-35.7198196169, -7.4739891824],
            [-35.7118026112, -7.4700801735],
            [-35.7074552711, -7.4722201113],
            [-35.6992335732, -7.4679921393],
            [-35.6931157741, -7.4769441568],
            [-35.686244984, -7.4758585041],
            [-35.6862512603, -7.4680714384],
            [-35.6792268435, -7.4676501389],
            [-35.6779022624, -7.4599796924],
            [-35.6669778643, -7.4657139181],
            [-35.6616180913, -7.459613278],
            [-35.6423765785, -7.4537683145],
            [-35.6312328757, -7.4495896348],
            [-35.6235847678, -7.4518019983],
            [-35.6173865573, -7.4490691975],
            [-35.6046969888, -7.4504867761],
            [-35.6007980724, -7.4495653397],
            [-35.5946530776, -7.4539820037],
            [-35.5898241886, -7.4471542569],
            [-35.5836457016, -7.4501839801],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501351', name: 'Assunção', description: 'Assunção' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.6715074288, -7.1003800515],
            [-36.6613742015, -7.1172234685],
            [-36.6609848734, -7.1266943809],
            [-36.6745928475, -7.1235452566],
            [-36.6937840313, -7.1257738752],
            [-36.6983670559, -7.134765858],
            [-36.7118684222, -7.1414488827],
            [-36.7290760421, -7.1238178273],
            [-36.7276766097, -7.114224415],
            [-36.7519356271, -7.1214062635],
            [-36.77812237, -7.1121251154],
            [-36.7511642394, -7.0798402964],
            [-36.7509922193, -7.0795068676],
            [-36.7500372071, -7.0582551099],
            [-36.7537184727, -7.0425360648],
            [-36.7424816584, -7.0325565108],
            [-36.7418167384, -7.024889044],
            [-36.7302520276, -7.0187920609],
            [-36.7252507716, -7.0034555753],
            [-36.7028246234, -7.0069363064],
            [-36.6861741943, -7.0091326776],
            [-36.6756544132, -7.0145498783],
            [-36.6572724269, -7.0495583879],
            [-36.6616160307, -7.0555438335],
            [-36.6575125734, -7.0616912786],
            [-36.6575714925, -7.0617608863],
            [-36.6650950793, -7.0706677141],
            [-36.657426347, -7.0734110087],
            [-36.6690874464, -7.0785698303],
            [-36.6663937658, -7.0976377413],
            [-36.6715074288, -7.1003800515],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2501401',
        name: 'Baía da Traição',
        description: 'Baía da Traição',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.9926497487, -6.5947694052],
            [-34.9782898078, -6.6002111074],
            [-34.9723154227, -6.5963942538],
            [-34.9724356598, -6.6005817022],
            [-34.9651787223, -6.6027149216],
            [-34.9619458165, -6.6397814812],
            [-34.956002986, -6.66214404],
            [-34.9468645049, -6.6806231034],
            [-34.9308378865, -6.68778975],
            [-34.9343877085, -6.6982732498],
            [-34.9337924227, -6.7101243461],
            [-34.9316271758, -6.7204626996],
            [-34.9438797876, -6.7143348025],
            [-34.9716372577, -6.7152135102],
            [-35.0078724106, -6.7043997261],
            [-35.0274180893, -6.7081179467],
            [-35.0312760245, -6.7035968766],
            [-35.0369315143, -6.6693002286],
            [-35.0215541111, -6.6238046141],
            [-35.0168712222, -6.6146556167],
            [-35.0054428323, -6.5976710654],
            [-35.0006693813, -6.5961823493],
            [-34.9926497487, -6.5947694052],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2501500',
        name: 'Bananeiras',
        description: 'Bananeiras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5601784156, -6.5996474439],
            [-35.5519559594, -6.6089453127],
            [-35.5453330133, -6.6097934406],
            [-35.5364694694, -6.6015850399],
            [-35.5286901397, -6.6050519917],
            [-35.5252107959, -6.6083520034],
            [-35.5256537177, -6.644248451],
            [-35.5256524287, -6.6448911511],
            [-35.5256118916, -6.6623454359],
            [-35.5307982082, -6.6737297959],
            [-35.5398888106, -6.6872960796],
            [-35.5396607477, -6.6952614635],
            [-35.5400295035, -6.7002776899],
            [-35.5450133112, -6.7092428761],
            [-35.5451292545, -6.7209186147],
            [-35.5420687467, -6.7228732906],
            [-35.5373994559, -6.7258552583],
            [-35.5374591484, -6.7370380963],
            [-35.531895373, -6.744912051],
            [-35.5213592175, -6.751576242],
            [-35.5212667001, -6.7581269633],
            [-35.5282136698, -6.7711257648],
            [-35.5283566063, -6.771013045],
            [-35.5359778055, -6.7705286564],
            [-35.5667832738, -6.7917418285],
            [-35.5675728421, -6.7914846593],
            [-35.5896710835, -6.7842856676],
            [-35.6068165027, -6.7811745154],
            [-35.61297983, -6.7800563782],
            [-35.643865886, -6.7745727665],
            [-35.6474251034, -6.7622030123],
            [-35.6484932881, -6.7584898652],
            [-35.6518802168, -6.754969154],
            [-35.663490605, -6.7466339926],
            [-35.6731686501, -6.7316262969],
            [-35.676165143, -6.7256139729],
            [-35.6726300031, -6.716796639],
            [-35.6726325085, -6.7034606019],
            [-35.6777919773, -6.6874924887],
            [-35.685190012, -6.6826927669],
            [-35.68857101, -6.6697957724],
            [-35.6833125196, -6.6591701904],
            [-35.6832293516, -6.6589554126],
            [-35.6789327312, -6.6508030211],
            [-35.6701879406, -6.6500942502],
            [-35.6679945076, -6.6446084638],
            [-35.6701182022, -6.6373089747],
            [-35.6604831597, -6.6364109262],
            [-35.6506740947, -6.6438359467],
            [-35.6418859667, -6.6430808255],
            [-35.626286277, -6.6559860009],
            [-35.6101056082, -6.6522077219],
            [-35.6021893096, -6.6366108373],
            [-35.5975501382, -6.6335333254],
            [-35.5940967173, -6.6271942607],
            [-35.587489231, -6.6165887278],
            [-35.5747667855, -6.6067695927],
            [-35.5709804226, -6.6074383847],
            [-35.5601784156, -6.5996474439],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501534', name: 'Baraúna', description: 'Baraúna' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.250690425, -6.6841650149],
            [-36.2657045414, -6.6988443529],
            [-36.2660988397, -6.6958409105],
            [-36.2712959603, -6.6861998995],
            [-36.2771257744, -6.6568350165],
            [-36.2739341298, -6.6429332381],
            [-36.2725701069, -6.6364231859],
            [-36.2725328582, -6.6051440355],
            [-36.2951183496, -6.5710780538],
            [-36.2986922767, -6.5659414074],
            [-36.3007932218, -6.5430399903],
            [-36.2908368829, -6.5578303402],
            [-36.2697815972, -6.5721979827],
            [-36.2520156326, -6.5789101153],
            [-36.2338915802, -6.5944373927],
            [-36.234002119, -6.5950240592],
            [-36.2427354161, -6.6414568723],
            [-36.2427904761, -6.6417541146],
            [-36.250690425, -6.6841650149],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2501575',
        name: 'Barra de Santana',
        description: 'Barra de Santana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.9578010949, -7.4932384769],
            [-35.9538840282, -7.4952177453],
            [-35.9512669258, -7.4928769248],
            [-35.937726181, -7.5053304269],
            [-35.9190646588, -7.512501595],
            [-35.9147190052, -7.5058409974],
            [-35.9023975856, -7.5077057017],
            [-35.9002714596, -7.5157131406],
            [-35.8908764384, -7.5099798687],
            [-35.8738991884, -7.5119068815],
            [-35.8739979242, -7.512163413],
            [-35.8734719347, -7.5192737117],
            [-35.8825618277, -7.5299768794],
            [-35.8836538192, -7.5401944961],
            [-35.8794077454, -7.5451317294],
            [-35.8708497396, -7.5448720118],
            [-35.863845918, -7.549987601],
            [-35.8573632049, -7.556870214],
            [-35.8467442376, -7.5564261866],
            [-35.8274391898, -7.5628557223],
            [-35.8285101085, -7.5702803325],
            [-35.8258161615, -7.5707482795],
            [-35.8266716292, -7.5734452635],
            [-35.8505967494, -7.5765988926],
            [-35.8644232165, -7.5888445183],
            [-35.8652777282, -7.5892604776],
            [-35.8719066482, -7.6040036638],
            [-35.8785931947, -7.6092955612],
            [-35.8669173231, -7.636877682],
            [-35.8643214139, -7.6424907121],
            [-35.8684728536, -7.6484687849],
            [-35.865724819, -7.6514068667],
            [-35.8655994653, -7.662912509],
            [-35.8894103202, -7.6628561903],
            [-35.8965443949, -7.6692714299],
            [-35.917594038, -7.6769482449],
            [-35.9222832778, -7.681725897],
            [-35.9314169378, -7.6810822032],
            [-35.9340509738, -7.6684895774],
            [-35.9451953306, -7.6620344726],
            [-35.9525410469, -7.6493535397],
            [-35.9595486527, -7.6522763818],
            [-35.9650229064, -7.6483528374],
            [-35.9625502778, -7.6395641932],
            [-35.9723886601, -7.632671745],
            [-35.9710098363, -7.624453655],
            [-35.9758542485, -7.6131179374],
            [-35.9908626013, -7.6011477913],
            [-35.990135089, -7.5951224431],
            [-36.0031940834, -7.5873931308],
            [-36.0061803829, -7.5899572883],
            [-36.0165736138, -7.5882206227],
            [-36.0185069404, -7.5953065329],
            [-36.0210260674, -7.5910291352],
            [-36.0324031559, -7.5869058336],
            [-36.0347504452, -7.5887666551],
            [-36.0506452361, -7.5914325074],
            [-36.0552534646, -7.5885975559],
            [-36.0590350864, -7.5972265639],
            [-36.0561677671, -7.6015283063],
            [-36.0629981208, -7.6033116302],
            [-36.0634672406, -7.6079591389],
            [-36.0704039976, -7.6018894278],
            [-36.07132572, -7.6039162326],
            [-36.0869535733, -7.6004594194],
            [-36.0949159255, -7.593952198],
            [-36.0951134517, -7.5812578176],
            [-36.1152428475, -7.5719621373],
            [-36.1175953913, -7.5599784772],
            [-36.1176045481, -7.5597629957],
            [-36.1131628041, -7.5554245112],
            [-36.1164166533, -7.5524790542],
            [-36.1105534721, -7.5496426679],
            [-36.1122080488, -7.5464059389],
            [-36.1097445732, -7.5450907167],
            [-36.099752182, -7.5444621338],
            [-36.0924424956, -7.5393863682],
            [-36.0834628656, -7.5406661593],
            [-36.0827795329, -7.5341835874],
            [-36.0734332575, -7.5308587663],
            [-36.0669696635, -7.530423327],
            [-36.0577871379, -7.5183490342],
            [-36.0488311278, -7.4824966992],
            [-36.0100260798, -7.4536824747],
            [-36.0094282384, -7.4532390794],
            [-35.9926848361, -7.4595736485],
            [-35.9913843215, -7.4671120848],
            [-35.9814252294, -7.4696165701],
            [-35.9726337565, -7.4792686477],
            [-35.9648657078, -7.4816218709],
            [-35.9637897852, -7.4882128857],
            [-35.9578010949, -7.4932384769],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2501609',
        name: 'Barra de Santa Rosa',
        description: 'Barra de Santa Rosa',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.9742321919, -6.6048788992],
            [-35.9944796504, -6.6403239566],
            [-36.0063793997, -6.6504738838],
            [-36.0040702742, -6.6533299944],
            [-36.0081881491, -6.6635453906],
            [-36.0061827221, -6.6719756482],
            [-35.9970572019, -6.6796832396],
            [-35.9982253532, -6.6847198794],
            [-35.9937852161, -6.6918526189],
            [-35.9761628893, -6.7054401154],
            [-35.9712285507, -6.7052707543],
            [-35.9621856015, -6.6969620567],
            [-35.9486816722, -6.6981441211],
            [-35.9420363355, -6.7052021764],
            [-35.9339020108, -6.7179027564],
            [-35.9223933137, -6.7199053965],
            [-35.922199698, -6.7309222455],
            [-35.8985476964, -6.7435650562],
            [-35.8961522524, -6.7499911375],
            [-35.9026167511, -6.757632692],
            [-35.8993493933, -6.7730097078],
            [-35.9053083742, -6.7781330424],
            [-35.9065675143, -6.7842621796],
            [-35.8973826916, -6.7932019355],
            [-35.9164554242, -6.7957820304],
            [-35.9232694536, -6.8149830764],
            [-35.938420586, -6.8284506827],
            [-35.9385682654, -6.828564612],
            [-36.0018812769, -6.8693562096],
            [-36.0244453005, -6.8792803685],
            [-36.0323937775, -6.8815011092],
            [-36.0551690406, -6.8933914456],
            [-36.0713770553, -6.9065228024],
            [-36.0981420496, -6.9234768461],
            [-36.1125168163, -6.9307651015],
            [-36.1462167269, -6.9432916807],
            [-36.1473626383, -6.9435374504],
            [-36.1699001504, -6.9411073071],
            [-36.1886568907, -6.9214754229],
            [-36.2612488989, -6.8454173989],
            [-36.2611053393, -6.8451221193],
            [-36.2485791362, -6.8307682813],
            [-36.1664037054, -6.7366551804],
            [-36.149439207, -6.7172496612],
            [-36.1493789519, -6.7171672853],
            [-36.1063410667, -6.6679235253],
            [-36.1061162124, -6.6676689198],
            [-36.064970493, -6.6515162045],
            [-36.0453354903, -6.6381871118],
            [-36.0444210721, -6.6379570843],
            [-36.0373303218, -6.638264182],
            [-36.0111515767, -6.6215447223],
            [-36.0004099503, -6.6180996426],
            [-35.98829006, -6.6068196931],
            [-35.9691768797, -6.5890328509],
            [-35.9742321919, -6.6048788992],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2501708',
        name: 'Barra de São Miguel',
        description: 'Barra de São Miguel',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.2200342308, -7.5975390886],
            [-36.2044511742, -7.6155615933],
            [-36.1970027008, -7.6395997242],
            [-36.1983009805, -7.6439643197],
            [-36.1976328724, -7.6450574126],
            [-36.1596635096, -7.688444338],
            [-36.1594616505, -7.6936921047],
            [-36.1627360895, -7.7306289934],
            [-36.1581520774, -7.7523054831],
            [-36.1535683124, -7.7569736932],
            [-36.1351398682, -7.7616592309],
            [-36.1273599857, -7.7690433309],
            [-36.1264876787, -7.7837917795],
            [-36.1430364055, -7.7811515854],
            [-36.1819404481, -7.7834267744],
            [-36.2006238717, -7.7894925374],
            [-36.2061504243, -7.7699672825],
            [-36.2162996287, -7.7636937561],
            [-36.2459705328, -7.7849001177],
            [-36.253861038, -7.7959761824],
            [-36.2540013611, -7.801282628],
            [-36.2392016808, -7.8108006758],
            [-36.2396772071, -7.8172701895],
            [-36.2529582371, -7.8295404844],
            [-36.2631722567, -7.8313300399],
            [-36.2707768004, -7.8301553508],
            [-36.2940266375, -7.8114874749],
            [-36.3087959691, -7.8062765257],
            [-36.3365489469, -7.8090134947],
            [-36.3603849171, -7.805307535],
            [-36.3731173955, -7.812209896],
            [-36.3960932291, -7.8082867258],
            [-36.3988645631, -7.7656737251],
            [-36.4408841615, -7.683031615],
            [-36.4454156137, -7.6741116947],
            [-36.3572506536, -7.674071344],
            [-36.3530528949, -7.6666352878],
            [-36.3595339898, -7.6613454959],
            [-36.3705487667, -7.6381613571],
            [-36.3658554024, -7.6358940862],
            [-36.3615004842, -7.6200796368],
            [-36.3667242773, -7.6199440919],
            [-36.3683052245, -7.6131270756],
            [-36.3613420415, -7.6037486661],
            [-36.3526366202, -7.5994273129],
            [-36.331560915, -7.6024466512],
            [-36.3191036258, -7.6103235342],
            [-36.3014655628, -7.5978297045],
            [-36.2957978225, -7.5924638825],
            [-36.289795557, -7.5856119742],
            [-36.297933469, -7.5846253278],
            [-36.2995083341, -7.5795387767],
            [-36.2902117651, -7.57376387],
            [-36.2842003575, -7.563157891],
            [-36.2619261184, -7.5523272106],
            [-36.2463705524, -7.5322372348],
            [-36.2369856977, -7.5300821439],
            [-36.2297599296, -7.535714387],
            [-36.2250984144, -7.5417756109],
            [-36.2240059263, -7.5543959134],
            [-36.2267724818, -7.5744761749],
            [-36.2200342308, -7.5975390886],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501807', name: 'Bayeux', description: 'Bayeux' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.8914455205, -7.1108961033],
            [-34.8953325615, -7.1222936368],
            [-34.9019730163, -7.1204521561],
            [-34.9042792481, -7.1266488189],
            [-34.9128636822, -7.1282373818],
            [-34.9188626482, -7.1336801694],
            [-34.9176351683, -7.1430776274],
            [-34.9109168662, -7.1486727968],
            [-34.9110023644, -7.1538401263],
            [-34.9165217063, -7.1655185032],
            [-34.9242813628, -7.1705249591],
            [-34.9270277139, -7.1671251862],
            [-34.9341972318, -7.16854739],
            [-34.9471638941, -7.1634976617],
            [-34.953059896, -7.1688438182],
            [-34.9640871422, -7.1675867781],
            [-34.9734694165, -7.1751786857],
            [-34.9539300853, -7.1445416411],
            [-34.9412539987, -7.1246710458],
            [-34.9258345876, -7.1184071057],
            [-34.921220238, -7.1224661822],
            [-34.9187506365, -7.1151887147],
            [-34.9150847303, -7.115764287],
            [-34.9115280299, -7.1067668452],
            [-34.9142526794, -7.1026082423],
            [-34.9085797571, -7.1021981513],
            [-34.9154541281, -7.0936387423],
            [-34.9118371888, -7.0787439142],
            [-34.9025231796, -7.0884051136],
            [-34.8916393853, -7.0950088644],
            [-34.8952139184, -7.1056655902],
            [-34.8914455205, -7.1108961033],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2501906', name: 'Belém', description: 'Belém' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4745424008, -6.7695626549],
            [-35.4830507836, -6.7742402954],
            [-35.4920685695, -6.7744070694],
            [-35.4983620937, -6.7722191847],
            [-35.500832783, -6.7705121985],
            [-35.5099084619, -6.7581651683],
            [-35.5212667001, -6.7581269633],
            [-35.5213592175, -6.751576242],
            [-35.531895373, -6.744912051],
            [-35.5374591484, -6.7370380963],
            [-35.5373994559, -6.7258552583],
            [-35.5420687467, -6.7228732906],
            [-35.5451292545, -6.7209186147],
            [-35.5450133112, -6.7092428761],
            [-35.5400295035, -6.7002776899],
            [-35.5396607477, -6.6952614635],
            [-35.5398888106, -6.6872960796],
            [-35.5307982082, -6.6737297959],
            [-35.5256118916, -6.6623454359],
            [-35.5256524287, -6.6448911511],
            [-35.5256537177, -6.644248451],
            [-35.5081073415, -6.6476606063],
            [-35.4920396538, -6.6512755736],
            [-35.4230591511, -6.6667822662],
            [-35.4464099573, -6.6759551239],
            [-35.45549368, -6.6889808868],
            [-35.4656610076, -6.70356406],
            [-35.4960418724, -6.7302187223],
            [-35.4981428987, -6.7320623355],
            [-35.4749484724, -6.7403093456],
            [-35.4431405537, -6.7513854061],
            [-35.4590120651, -6.7529308245],
            [-35.4745424008, -6.7695626549],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502003',
        name: 'Belém do Brejo do Cruz',
        description: 'Belém do Brejo do Cruz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.2315196221, -6.0271990258],
            [-37.2223466758, -6.0417520704],
            [-37.204057234, -6.0377557637],
            [-37.1939545614, -6.0476370749],
            [-37.1735911858, -6.0476197603],
            [-37.1752326058, -6.0545431335],
            [-37.1702203986, -6.0826587938],
            [-37.1723117578, -6.0984326507],
            [-37.1666351899, -6.1099590812],
            [-37.1747551094, -6.1417858194],
            [-37.1660408535, -6.1535118602],
            [-37.1565829346, -6.1520912878],
            [-37.1653433753, -6.1699774102],
            [-37.1738808173, -6.1797064667],
            [-37.1926032017, -6.1826827197],
            [-37.1938981047, -6.1978498171],
            [-37.2012399702, -6.2053061917],
            [-37.2339307433, -6.2128809615],
            [-37.2389454509, -6.20415187],
            [-37.248911199, -6.2015680089],
            [-37.2467780628, -6.1928644311],
            [-37.2514492675, -6.1904580083],
            [-37.2664551001, -6.1913104185],
            [-37.2703328878, -6.1865154522],
            [-37.2792076936, -6.1870352847],
            [-37.2888647279, -6.1856439416],
            [-37.2886161155, -6.1905404428],
            [-37.2922470385, -6.1869438397],
            [-37.3007512062, -6.1887802753],
            [-37.326949475, -6.1769140382],
            [-37.3326107253, -6.1703709333],
            [-37.3416384023, -6.1683187966],
            [-37.3663984244, -6.1736764136],
            [-37.3740444815, -6.1742000994],
            [-37.4009147151, -6.1923288138],
            [-37.4115418092, -6.1916873279],
            [-37.430081675, -6.1999770551],
            [-37.4347453579, -6.1992745166],
            [-37.4373856929, -6.2090289325],
            [-37.4452500697, -6.2164773704],
            [-37.4589012914, -6.2211946567],
            [-37.4665047196, -6.2325688432],
            [-37.4842250598, -6.2433643417],
            [-37.4953664258, -6.2429588678],
            [-37.518376719, -6.2414486483],
            [-37.5239025165, -6.246314943],
            [-37.5322254983, -6.2447386461],
            [-37.5333749981, -6.2428388222],
            [-37.5410004365, -6.243754445],
            [-37.5507263506, -6.2398107315],
            [-37.5760303279, -6.2463355611],
            [-37.5873959291, -6.2576406567],
            [-37.6066386668, -6.2635148268],
            [-37.6126800207, -6.2704499559],
            [-37.6152591454, -6.2599363524],
            [-37.6236969858, -6.2471575777],
            [-37.6169095809, -6.2299146592],
            [-37.6187059581, -6.2062849285],
            [-37.6159050387, -6.1758006785],
            [-37.5995692862, -6.1736141808],
            [-37.5847934518, -6.1624290035],
            [-37.5734271222, -6.1583770984],
            [-37.5733776264, -6.1582270118],
            [-37.5692767849, -6.1550420424],
            [-37.5603090735, -6.1641350729],
            [-37.5396515383, -6.1655335097],
            [-37.5360074011, -6.1524392782],
            [-37.5215336144, -6.1513943986],
            [-37.5025476811, -6.1517437642],
            [-37.5030532224, -6.1439999733],
            [-37.489026154, -6.1440411781],
            [-37.4826481976, -6.1512842677],
            [-37.4737530898, -6.1483417545],
            [-37.4586047357, -6.1446055347],
            [-37.4535698553, -6.1196771954],
            [-37.4453504971, -6.1118058403],
            [-37.4256116497, -6.1086006418],
            [-37.4074674962, -6.1123857526],
            [-37.3918414917, -6.1077768263],
            [-37.3819968048, -6.0851575119],
            [-37.3784410195, -6.0847363665],
            [-37.3712226642, -6.082382684],
            [-37.354092428, -6.086089654],
            [-37.3354379205, -6.0670633401],
            [-37.328481906, -6.0643082182],
            [-37.3088583789, -6.0573640045],
            [-37.3002529594, -6.0498638721],
            [-37.2952306763, -6.0496991411],
            [-37.2865755033, -6.057593405],
            [-37.2721363621, -6.0553064178],
            [-37.2632382688, -6.0459595636],
            [-37.2561331307, -6.0280751702],
            [-37.2502729262, -6.0255160296],
            [-37.2315196221, -6.0271990258],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502052',
        name: 'Bernardino Batista',
        description: 'Bernardino Batista',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5213656477, -6.4716790674],
            [-38.5130096074, -6.4929983495],
            [-38.5367284143, -6.491659218],
            [-38.5360034268, -6.5108627421],
            [-38.5420799093, -6.5116464435],
            [-38.5421954228, -6.5117415305],
            [-38.570936837, -6.5135626709],
            [-38.5917435452, -6.518865624],
            [-38.6016286821, -6.5256257215],
            [-38.6067315531, -6.5291158944],
            [-38.6101669641, -6.5318949622],
            [-38.6157584504, -6.5277463173],
            [-38.6119351786, -6.5127925196],
            [-38.5946195941, -6.5000435985],
            [-38.5700574744, -6.4697966402],
            [-38.5694639334, -6.4544775232],
            [-38.5653032544, -6.4511050529],
            [-38.5560523423, -6.4472442584],
            [-38.555092791, -6.4371115164],
            [-38.5454733276, -6.4343790585],
            [-38.5347218075, -6.4445670005],
            [-38.5213656477, -6.4716790674],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502102',
        name: 'Boa Ventura',
        description: 'Boa Ventura',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1179546582, -7.4163766324],
            [-38.0949915315, -7.4759974245],
            [-38.0947026328, -7.476746517],
            [-38.143689001, -7.4785386165],
            [-38.1536117905, -7.4634621248],
            [-38.1735262936, -7.4702191461],
            [-38.2216564797, -7.4865507408],
            [-38.2291847326, -7.4859324404],
            [-38.2571087712, -7.4836386167],
            [-38.2564349927, -7.4683659185],
            [-38.2615539378, -7.4610024507],
            [-38.2524030584, -7.4501810859],
            [-38.246377531, -7.4231078519],
            [-38.2443143117, -7.4123013213],
            [-38.2593090795, -7.3883814017],
            [-38.245151681, -7.3797984985],
            [-38.2457402046, -7.3681380792],
            [-38.2020099354, -7.3683146308],
            [-38.1740688474, -7.3687509124],
            [-38.1738034072, -7.3689720298],
            [-38.1414209028, -7.396005266],
            [-38.1406617197, -7.3966391322],
            [-38.1317917479, -7.4062617164],
            [-38.1189570218, -7.4114480172],
            [-38.1179546582, -7.4163766324],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502151',
        name: 'Boa Vista',
        description: 'Boa Vista',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.0920630395, -7.1953962581],
            [-36.0939448027, -7.2092430229],
            [-36.1022963697, -7.2197440958],
            [-36.1046130175, -7.2305490335],
            [-36.1025418348, -7.2355987562],
            [-36.1059055628, -7.238159965],
            [-36.1087356989, -7.2522856043],
            [-36.1106132417, -7.2564003625],
            [-36.1144426057, -7.2546969355],
            [-36.113952284, -7.2703524615],
            [-36.114026302, -7.2708032742],
            [-36.1190745659, -7.301743206],
            [-36.1282809415, -7.3582472853],
            [-36.147326461, -7.3458894724],
            [-36.1753909785, -7.3470105136],
            [-36.1955722425, -7.3613577763],
            [-36.2020318982, -7.362583168],
            [-36.2113888959, -7.3550508513],
            [-36.2255729637, -7.3695767959],
            [-36.2368876374, -7.3680943128],
            [-36.2386214391, -7.3659832035],
            [-36.2334587489, -7.3597729157],
            [-36.241232479, -7.354867017],
            [-36.2414826751, -7.3548885762],
            [-36.2515530993, -7.3624232341],
            [-36.2723129073, -7.3577008539],
            [-36.2809267183, -7.3694250943],
            [-36.3233159114, -7.3717365739],
            [-36.3453889105, -7.3674582035],
            [-36.3305119826, -7.3071258087],
            [-36.3433907223, -7.2827421487],
            [-36.3257731506, -7.1883499305],
            [-36.2853630575, -7.1926979677],
            [-36.2221835468, -7.20023245],
            [-36.2242299175, -7.1970202143],
            [-36.1703911136, -7.2014289494],
            [-36.1398035102, -7.163982165],
            [-36.1093994184, -7.1517513245],
            [-36.0945109649, -7.1536040903],
            [-36.0920630395, -7.1953962581],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502201',
        name: 'Bom Jesus',
        description: 'Bom Jesus',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5674355269, -6.7905317574],
            [-38.5735782849, -6.8014810239],
            [-38.5866217554, -6.8003679516],
            [-38.5858639445, -6.8040344401],
            [-38.5919494125, -6.803798049],
            [-38.5979668722, -6.8121857655],
            [-38.6066006759, -6.8137685262],
            [-38.6075918587, -6.8200264371],
            [-38.6181515512, -6.8316959302],
            [-38.6151594886, -6.8409425464],
            [-38.6272329114, -6.8411291129],
            [-38.6282854163, -6.8472140378],
            [-38.6410407331, -6.8544965272],
            [-38.6485461936, -6.8769888248],
            [-38.6497289347, -6.8759823919],
            [-38.6565399208, -6.8658086307],
            [-38.6717195875, -6.8544725802],
            [-38.6719207227, -6.8465764733],
            [-38.6699811125, -6.8429688241],
            [-38.6775910983, -6.8341123139],
            [-38.6752158929, -6.829810743],
            [-38.6614344433, -6.8232763524],
            [-38.6575198777, -6.8161594368],
            [-38.6552662573, -6.8137647743],
            [-38.6544150107, -6.8128607009],
            [-38.6480849323, -6.8069848196],
            [-38.6351061833, -6.8042195039],
            [-38.6419720473, -6.7912789347],
            [-38.6397791784, -6.7887947677],
            [-38.6291162625, -6.7895008853],
            [-38.6170593568, -6.7935774021],
            [-38.6135745027, -6.7820373882],
            [-38.5970533513, -6.7871907459],
            [-38.5674355269, -6.7905317574],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502300',
        name: 'Bom Sucesso',
        description: 'Bom Sucesso',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.95485841, -6.407663097],
            [-37.9450903112, -6.4222215081],
            [-37.9277699094, -6.4211943452],
            [-37.9190994716, -6.4112477623],
            [-37.914038429, -6.4132209602],
            [-37.9120680799, -6.4199249487],
            [-37.8996432214, -6.4208898032],
            [-37.8986957724, -6.4209629853],
            [-37.8638977682, -6.4497927889],
            [-37.8871924337, -6.4990775878],
            [-37.8923372055, -6.5122006689],
            [-37.8926669995, -6.513041266],
            [-37.9037275909, -6.5412614512],
            [-37.9160617593, -6.5326948648],
            [-37.9321044055, -6.5288693534],
            [-37.9323799879, -6.528823832],
            [-37.9795230429, -6.5312334182],
            [-37.9975094349, -6.5273387605],
            [-38.0148941803, -6.5272447346],
            [-38.0256407592, -6.5276042822],
            [-38.0269955787, -6.5248016308],
            [-38.0191389815, -6.5029647363],
            [-38.0169603188, -6.4793288395],
            [-38.0264107741, -6.4743576962],
            [-38.0214674103, -6.4680567858],
            [-38.0125988686, -6.468454636],
            [-38.0196210674, -6.4456062271],
            [-38.0078773533, -6.4317937454],
            [-38.014045224, -6.4299936414],
            [-38.0133479813, -6.4270287272],
            [-37.9886697926, -6.4282373565],
            [-37.9799116444, -6.4266862323],
            [-37.9787367436, -6.4262946378],
            [-37.9683309747, -6.4183448003],
            [-37.9676751205, -6.4047240641],
            [-37.9550484348, -6.3978085099],
            [-37.95485841, -6.407663097],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502409',
        name: 'Bonito de Santa Fé',
        description: 'Bonito de Santa Fé',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4440911206, -7.2126783104],
            [-38.4428742089, -7.2207603074],
            [-38.4310796112, -7.2264889159],
            [-38.4061838693, -7.2072049738],
            [-38.4056303471, -7.2212811282],
            [-38.4115861373, -7.2378117174],
            [-38.4031963294, -7.2482331195],
            [-38.4128035998, -7.253667066],
            [-38.4161007771, -7.2612444646],
            [-38.4194719278, -7.2699041644],
            [-38.4165528796, -7.2809928263],
            [-38.421380424, -7.2894220086],
            [-38.4216458286, -7.3030264101],
            [-38.4134845049, -7.305695778],
            [-38.4126750996, -7.321149948],
            [-38.4084685803, -7.3312494485],
            [-38.4148787464, -7.3431285972],
            [-38.4325746467, -7.3427530598],
            [-38.4403422129, -7.3579435812],
            [-38.4532811819, -7.3609871089],
            [-38.4628971724, -7.3705757827],
            [-38.476728371, -7.3745820192],
            [-38.480290442, -7.3816307457],
            [-38.4863116707, -7.3791270014],
            [-38.4868203856, -7.3789161784],
            [-38.4954086677, -7.3745424137],
            [-38.5027262977, -7.3750213195],
            [-38.5047716214, -7.3646409067],
            [-38.5159600802, -7.3668203574],
            [-38.5162974168, -7.3695157882],
            [-38.5198281324, -7.3645246683],
            [-38.5240408165, -7.3642609794],
            [-38.5280624282, -7.3495110402],
            [-38.5437590008, -7.3386650743],
            [-38.549029391, -7.3345923826],
            [-38.548729861, -7.3254162928],
            [-38.5500020706, -7.3150591868],
            [-38.5397573575, -7.3058127252],
            [-38.5342339256, -7.2933880828],
            [-38.5422781605, -7.2779865512],
            [-38.5417915355, -7.265496889],
            [-38.5517467812, -7.2631555133],
            [-38.5540918801, -7.2514526044],
            [-38.5508033345, -7.251001491],
            [-38.5453051116, -7.2482894342],
            [-38.5437529965, -7.2381392534],
            [-38.5306257206, -7.2305599389],
            [-38.5236651025, -7.2312067567],
            [-38.4992138192, -7.217124206],
            [-38.4803471518, -7.2166313924],
            [-38.4729862054, -7.2152567639],
            [-38.4670309436, -7.2074063995],
            [-38.4698095492, -7.1995325745],
            [-38.4598067111, -7.1925043714],
            [-38.4544596478, -7.2110749141],
            [-38.4440911206, -7.2126783104],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502508',
        name: 'Boqueirão',
        description: 'Boqueirão',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.0990017393, -7.3916562389],
            [-36.0968587218, -7.406180625],
            [-36.0958860039, -7.4098991399],
            [-36.101947625, -7.4194548545],
            [-36.1034677606, -7.4298584861],
            [-36.0978901798, -7.4365816137],
            [-36.1046901797, -7.4396077121],
            [-36.1044127607, -7.4440695835],
            [-36.0947771363, -7.4498424499],
            [-36.0945832635, -7.4551783135],
            [-36.067434713, -7.4713911523],
            [-36.0488311278, -7.4824966992],
            [-36.0577871379, -7.5183490342],
            [-36.0669696635, -7.530423327],
            [-36.0734332575, -7.5308587663],
            [-36.0827795329, -7.5341835874],
            [-36.0834628656, -7.5406661593],
            [-36.0924424956, -7.5393863682],
            [-36.099752182, -7.5444621338],
            [-36.1097445732, -7.5450907167],
            [-36.1122080488, -7.5464059389],
            [-36.1105534721, -7.5496426679],
            [-36.1164166533, -7.5524790542],
            [-36.1131628041, -7.5554245112],
            [-36.1176045481, -7.5597629957],
            [-36.1175953913, -7.5599784772],
            [-36.1152428475, -7.5719621373],
            [-36.0951134517, -7.5812578176],
            [-36.0949159255, -7.593952198],
            [-36.0869535733, -7.6004594194],
            [-36.1033382692, -7.6016301433],
            [-36.116080253, -7.6113116058],
            [-36.1205181455, -7.608643196],
            [-36.1269433931, -7.6111046304],
            [-36.1365213914, -7.6226011057],
            [-36.1423525363, -7.6199139154],
            [-36.1421702894, -7.623580011],
            [-36.1459261681, -7.6242417093],
            [-36.1527475467, -7.6310368924],
            [-36.1519070331, -7.6351806798],
            [-36.1575365392, -7.6382462879],
            [-36.1609735887, -7.6363368541],
            [-36.1693136092, -7.6400568841],
            [-36.173762926, -7.635349274],
            [-36.1791552397, -7.6400189143],
            [-36.1857740374, -7.6410828405],
            [-36.1894482964, -7.6382270574],
            [-36.1930055104, -7.6457639196],
            [-36.1976328724, -7.6450574126],
            [-36.1983009805, -7.6439643197],
            [-36.1970027008, -7.6395997242],
            [-36.2044511742, -7.6155615933],
            [-36.2200342308, -7.5975390886],
            [-36.2267724818, -7.5744761749],
            [-36.2240059263, -7.5543959134],
            [-36.2209110558, -7.5319428527],
            [-36.2180857531, -7.5114350838],
            [-36.2205932814, -7.4577961676],
            [-36.2229099623, -7.433525666],
            [-36.2166824548, -7.4447605254],
            [-36.2068472956, -7.4424297063],
            [-36.2024007806, -7.4265193784],
            [-36.1937988342, -7.4241018182],
            [-36.1912524551, -7.4074476619],
            [-36.196759811, -7.4052138549],
            [-36.1947307746, -7.3965034274],
            [-36.1887555432, -7.3979785877],
            [-36.1457119535, -7.3910885527],
            [-36.1421509061, -7.3557109306],
            [-36.147326461, -7.3458894724],
            [-36.1282809415, -7.3582472853],
            [-36.1230634123, -7.3729918142],
            [-36.1110580947, -7.3849431487],
            [-36.0990017393, -7.3916562389],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2502607', name: 'Igaracy', description: 'Igaracy' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.123621175, -7.0863244389],
            [-38.1064438639, -7.0875245786],
            [-38.0979223255, -7.0864594293],
            [-38.0946079239, -7.09077024],
            [-38.0912735245, -7.0902680948],
            [-38.0582177878, -7.0870471685],
            [-38.0578332568, -7.0947125089],
            [-38.0649595454, -7.1149909144],
            [-38.063224518, -7.1186636186],
            [-38.070774655, -7.1216963248],
            [-38.0775570466, -7.1394173364],
            [-38.0762541877, -7.1492993115],
            [-38.0670256508, -7.1671078265],
            [-38.0526864956, -7.1808557733],
            [-38.0524570234, -7.1915261059],
            [-38.0507817411, -7.1985885775],
            [-38.0558281463, -7.2023992339],
            [-38.0529288949, -7.2074313939],
            [-38.0571522757, -7.2135994883],
            [-38.0561775653, -7.219196456],
            [-38.0640705805, -7.2212657069],
            [-38.0667689375, -7.2186586657],
            [-38.0692701017, -7.2210894194],
            [-38.0842984498, -7.2183205433],
            [-38.0915126251, -7.2349423394],
            [-38.1174310764, -7.224785638],
            [-38.1333224672, -7.2232379551],
            [-38.1557760233, -7.2210504824],
            [-38.1576332754, -7.1888477593],
            [-38.1576800809, -7.188754299],
            [-38.1717592648, -7.1605464484],
            [-38.2271360122, -7.1510627406],
            [-38.2313189405, -7.1463035203],
            [-38.2324883693, -7.1311331812],
            [-38.2041615937, -7.1198265415],
            [-38.1980996766, -7.12030449],
            [-38.1876060414, -7.1141185101],
            [-38.186790977, -7.1179338574],
            [-38.1810008959, -7.1185120965],
            [-38.1754316203, -7.1077982607],
            [-38.160561963, -7.1080540878],
            [-38.1531707468, -7.0989576354],
            [-38.1432092027, -7.0878412339],
            [-38.1335411344, -7.087390803],
            [-38.1297215025, -7.0901588646],
            [-38.123621175, -7.0863244389],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502706',
        name: 'Borborema',
        description: 'Borborema',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5282136698, -6.7711257648],
            [-35.5667613779, -6.8004518702],
            [-35.5743651812, -6.8060465131],
            [-35.5988365276, -6.8108438472],
            [-35.5997789134, -6.8110286356],
            [-35.6059404909, -6.8110047882],
            [-35.6168239573, -6.8094687687],
            [-35.6280893352, -6.8066676467],
            [-35.6364569976, -6.8003095789],
            [-35.643865886, -6.7745727665],
            [-35.61297983, -6.7800563782],
            [-35.6068165027, -6.7811745154],
            [-35.5896710835, -6.7842856676],
            [-35.5675728421, -6.7914846593],
            [-35.5667832738, -6.7917418285],
            [-35.5359778055, -6.7705286564],
            [-35.5283566063, -6.771013045],
            [-35.5282136698, -6.7711257648],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502805',
        name: 'Brejo do Cruz',
        description: 'Brejo do Cruz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.5507263506, -6.2398107315],
            [-37.5410004365, -6.243754445],
            [-37.5333749981, -6.2428388222],
            [-37.5322254983, -6.2447386461],
            [-37.5239025165, -6.246314943],
            [-37.518376719, -6.2414486483],
            [-37.4953664258, -6.2429588678],
            [-37.4842250598, -6.2433643417],
            [-37.4804430548, -6.2587632257],
            [-37.4705836509, -6.2696702454],
            [-37.4479694593, -6.2796233592],
            [-37.442312246, -6.2919988472],
            [-37.4396017839, -6.2877947592],
            [-37.4332898679, -6.2864865745],
            [-37.4165591601, -6.285016732],
            [-37.4105295963, -6.2906685443],
            [-37.3942824203, -6.2863198423],
            [-37.3867655844, -6.2771278917],
            [-37.383567514, -6.2813471984],
            [-37.3706726755, -6.2822816443],
            [-37.3513619182, -6.2980248253],
            [-37.3464426223, -6.2988127399],
            [-37.3438133377, -6.3054261905],
            [-37.3336162887, -6.3079419246],
            [-37.3630112997, -6.3217674499],
            [-37.3769867311, -6.3439942917],
            [-37.3775045331, -6.3500402197],
            [-37.3771988358, -6.3689335778],
            [-37.3821036817, -6.3730129546],
            [-37.3882066744, -6.3793353473],
            [-37.3884887732, -6.3905806622],
            [-37.4123610527, -6.3904760198],
            [-37.4593339057, -6.3946249571],
            [-37.4596932902, -6.3946690722],
            [-37.4778933984, -6.4039052263],
            [-37.4900621551, -6.4056488012],
            [-37.5100840681, -6.4117396344],
            [-37.5241564177, -6.4068046658],
            [-37.5405166142, -6.4129023198],
            [-37.5500221201, -6.4151987058],
            [-37.561531803, -6.4110139732],
            [-37.5718131176, -6.4144170265],
            [-37.5769324749, -6.4205035582],
            [-37.5773742673, -6.4117216008],
            [-37.5841670116, -6.4050905576],
            [-37.599658593, -6.3878188031],
            [-37.6035990237, -6.3697560534],
            [-37.6055809624, -6.3576330851],
            [-37.6019006359, -6.3427480759],
            [-37.6063565999, -6.3347554607],
            [-37.6060223852, -6.3257575094],
            [-37.6060265183, -6.3256298461],
            [-37.6055542119, -6.3126107046],
            [-37.6050792459, -6.2879151686],
            [-37.6055824103, -6.2837133666],
            [-37.6126046321, -6.2791644118],
            [-37.6126800207, -6.2704499559],
            [-37.6066386668, -6.2635148268],
            [-37.5873959291, -6.2576406567],
            [-37.5760303279, -6.2463355611],
            [-37.5507263506, -6.2398107315],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2502904',
        name: 'Brejo dos Santos',
        description: 'Brejo dos Santos',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.794958466, -6.3664326716],
            [-37.8060660971, -6.4063626744],
            [-37.8179748596, -6.447943503],
            [-37.8196051883, -6.447399787],
            [-37.835449388, -6.4398818922],
            [-37.8516230965, -6.4442916891],
            [-37.8638977682, -6.4497927889],
            [-37.8986957724, -6.4209629853],
            [-37.8996432214, -6.4208898032],
            [-37.9120680799, -6.4199249487],
            [-37.914038429, -6.4132209602],
            [-37.9190994716, -6.4112477623],
            [-37.9011853352, -6.3992803143],
            [-37.8899096878, -6.3997366825],
            [-37.8859029298, -6.3966022095],
            [-37.8839154043, -6.3912777285],
            [-37.8881645276, -6.3868105971],
            [-37.887878068, -6.3639713496],
            [-37.8594811397, -6.3744790895],
            [-37.8538959222, -6.3659182997],
            [-37.8402976982, -6.3609419025],
            [-37.8437705361, -6.3539587617],
            [-37.8339138709, -6.3419873735],
            [-37.8428490989, -6.3326901981],
            [-37.8102677784, -6.3473501867],
            [-37.7954289442, -6.3659474408],
            [-37.794958466, -6.3664326716],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2503001', name: 'Caaporã', description: 'Caaporã' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.9063928093, -7.4468174471],
            [-34.8855577376, -7.4594136854],
            [-34.8762395304, -7.4559934265],
            [-34.8602835344, -7.4542318085],
            [-34.8561512356, -7.4390858656],
            [-34.8491591007, -7.4384676405],
            [-34.8545945146, -7.4632405314],
            [-34.8550646444, -7.5072016088],
            [-34.8549685401, -7.5077405474],
            [-34.8504336201, -7.5332572368],
            [-34.8535213984, -7.5392372091],
            [-34.8576981847, -7.5375406971],
            [-34.870391034, -7.5487486459],
            [-34.8732688945, -7.5428379329],
            [-34.8884138391, -7.535493866],
            [-34.8952169827, -7.5446647708],
            [-34.9017170961, -7.5431530684],
            [-34.8995743064, -7.5370213143],
            [-34.910165403, -7.5377272335],
            [-34.9106961335, -7.5430324156],
            [-34.9169866828, -7.5413597813],
            [-34.9241220997, -7.5322006164],
            [-34.9323565927, -7.5281106914],
            [-34.950291547, -7.5372122562],
            [-34.9604610929, -7.5383555053],
            [-34.969114993, -7.5206550593],
            [-34.9787395768, -7.5092880236],
            [-34.9838978877, -7.5074778468],
            [-34.9848306797, -7.5003779059],
            [-34.9848121312, -7.4996012688],
            [-34.9846573701, -7.4989898405],
            [-34.9771398376, -7.4692141473],
            [-34.9812600643, -7.4563647556],
            [-34.9782710809, -7.4411424113],
            [-34.9759760175, -7.4362524341],
            [-34.9689392298, -7.4337493446],
            [-34.9542783206, -7.4048858196],
            [-34.9423707865, -7.4162881863],
            [-34.94130104, -7.4220347492],
            [-34.9240010272, -7.4311473973],
            [-34.9197225652, -7.4393207693],
            [-34.9099695187, -7.4449270147],
            [-34.9063928093, -7.4468174471],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2503100',
        name: 'Cabaceiras',
        description: 'Cabaceiras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.3453889105, -7.3674582035],
            [-36.3233159114, -7.3717365739],
            [-36.2809267183, -7.3694250943],
            [-36.2723129073, -7.3577008539],
            [-36.2515530993, -7.3624232341],
            [-36.2414826751, -7.3548885762],
            [-36.241232479, -7.354867017],
            [-36.2334587489, -7.3597729157],
            [-36.2386214391, -7.3659832035],
            [-36.2368876374, -7.3680943128],
            [-36.2255729637, -7.3695767959],
            [-36.2113888959, -7.3550508513],
            [-36.2020318982, -7.362583168],
            [-36.1955722425, -7.3613577763],
            [-36.1753909785, -7.3470105136],
            [-36.147326461, -7.3458894724],
            [-36.1421509061, -7.3557109306],
            [-36.1457119535, -7.3910885527],
            [-36.1887555432, -7.3979785877],
            [-36.1947307746, -7.3965034274],
            [-36.196759811, -7.4052138549],
            [-36.1912524551, -7.4074476619],
            [-36.1937988342, -7.4241018182],
            [-36.2024007806, -7.4265193784],
            [-36.2068472956, -7.4424297063],
            [-36.2166824548, -7.4447605254],
            [-36.2229099623, -7.433525666],
            [-36.2205932814, -7.4577961676],
            [-36.2180857531, -7.5114350838],
            [-36.2209110558, -7.5319428527],
            [-36.2240059263, -7.5543959134],
            [-36.2250984144, -7.5417756109],
            [-36.2297599296, -7.535714387],
            [-36.2369856977, -7.5300821439],
            [-36.2463705524, -7.5322372348],
            [-36.2619261184, -7.5523272106],
            [-36.2842003575, -7.563157891],
            [-36.2902117651, -7.57376387],
            [-36.2995083341, -7.5795387767],
            [-36.297933469, -7.5846253278],
            [-36.289795557, -7.5856119742],
            [-36.2957978225, -7.5924638825],
            [-36.3014655628, -7.5978297045],
            [-36.3151115653, -7.5921617581],
            [-36.3332499534, -7.5650076976],
            [-36.3282774454, -7.5490742176],
            [-36.3479469667, -7.5484508991],
            [-36.3526640905, -7.5418666275],
            [-36.3583544129, -7.5403061885],
            [-36.3667768183, -7.5261834548],
            [-36.3706663431, -7.5255463373],
            [-36.371857752, -7.5136774462],
            [-36.3818887903, -7.5109864403],
            [-36.3849134372, -7.5061038586],
            [-36.386551624, -7.5094526082],
            [-36.3903886709, -7.5054862426],
            [-36.3943378433, -7.5069221758],
            [-36.3947485257, -7.5009129146],
            [-36.4008647991, -7.5016933277],
            [-36.4086688908, -7.4941239796],
            [-36.4208643993, -7.4943640862],
            [-36.427070636, -7.4883165419],
            [-36.3813651124, -7.4025792855],
            [-36.3305119826, -7.3071258087],
            [-36.3453889105, -7.3674582035],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2503209', name: 'Cabedelo', description: 'Cabedelo' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.8636659744, -6.9804063003],
            [-34.8579490294, -6.9839079887],
            [-34.8473924017, -6.981641014],
            [-34.849308326, -6.999559352],
            [-34.8588355978, -7.0152153981],
            [-34.8652758008, -7.0042999431],
            [-34.8671629407, -6.9872818777],
            [-34.8636659744, -6.9804063003],
          ],
          [
            [-34.8254684241, -7.0065962406],
            [-34.8298639975, -7.030473581],
            [-34.8405292664, -7.0422910209],
            [-34.8433577961, -7.0554186969],
            [-34.8496962444, -7.0616418702],
            [-34.8450803384, -7.0994328214],
            [-34.8571858579, -7.0948512135],
            [-34.865776887, -7.0804829976],
            [-34.859642406, -7.0746003284],
            [-34.8586608293, -7.0672507596],
            [-34.8573584628, -7.0633797877],
            [-34.8652184889, -7.0577169754],
            [-34.8592774996, -7.0267111996],
            [-34.8548075126, -7.027110961],
            [-34.8483085086, -7.0110030335],
            [-34.8348314232, -6.9983413183],
            [-34.8351922897, -6.9788726833],
            [-34.8415071902, -6.966146439],
            [-34.8298934377, -6.9662120489],
            [-34.8254684241, -7.0065962406],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2503308',
        name: 'Cachoeira dos Índios',
        description: 'Cachoeira dos Índios',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.6565399208, -6.8658086307],
            [-38.6497289347, -6.8759823919],
            [-38.6485461936, -6.8769888248],
            [-38.6536815657, -6.8882757951],
            [-38.6485755103, -6.8915296512],
            [-38.6524358142, -6.9003129386],
            [-38.6454133061, -6.925241527],
            [-38.6519540523, -6.9369762256],
            [-38.6487874769, -6.9422857612],
            [-38.6554978333, -6.9518947957],
            [-38.6605798312, -6.9521944371],
            [-38.6609884534, -6.9562740427],
            [-38.6577130534, -6.9627709606],
            [-38.6627099198, -6.9667488701],
            [-38.6583911345, -6.9697918023],
            [-38.6596073134, -6.9745161748],
            [-38.6571188365, -6.9758297821],
            [-38.6588175104, -6.9820737408],
            [-38.6648283614, -6.9860245555],
            [-38.6620143372, -6.9901698741],
            [-38.6720460515, -6.9965993688],
            [-38.6680699766, -7.0008558134],
            [-38.6700351697, -7.0121900605],
            [-38.6621799067, -7.0180772258],
            [-38.6735110796, -7.0387188905],
            [-38.6726617473, -7.0458276162],
            [-38.6894473188, -7.0398266207],
            [-38.6910870418, -7.0287368839],
            [-38.7131627703, -7.0176805715],
            [-38.716759624, -7.0165388628],
            [-38.7302292797, -7.0116253432],
            [-38.7506372952, -6.9969088792],
            [-38.7583310335, -6.9980586714],
            [-38.7646443757, -6.9934926227],
            [-38.7606102204, -6.9896518288],
            [-38.7515337414, -6.9881803088],
            [-38.7425237967, -6.9746011517],
            [-38.7477222617, -6.9663024057],
            [-38.7466110266, -6.9601778858],
            [-38.7569468511, -6.9577745855],
            [-38.7649949009, -6.9475355728],
            [-38.7553627592, -6.9397268417],
            [-38.7571367297, -6.9342153292],
            [-38.7620547447, -6.9261423612],
            [-38.7584125172, -6.924876715],
            [-38.7585725975, -6.9162245649],
            [-38.764858111, -6.9105122381],
            [-38.7559246557, -6.903189941],
            [-38.7299342711, -6.8964118247],
            [-38.7311780883, -6.8888700272],
            [-38.7283035031, -6.8869740835],
            [-38.7136586546, -6.8857450548],
            [-38.7138068535, -6.8763821357],
            [-38.7087766761, -6.8740240756],
            [-38.7000558722, -6.8762007334],
            [-38.6863371702, -6.8653902219],
            [-38.6781652584, -6.8636641559],
            [-38.6750629898, -6.8614279073],
            [-38.6768472843, -6.8539264398],
            [-38.6719207227, -6.8465764733],
            [-38.6717195875, -6.8544725802],
            [-38.6565399208, -6.8658086307],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2503407',
        name: 'Cacimba de Areia',
        description: 'Cacimba de Areia',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.1893625856, -7.0588857813],
            [-37.1767711067, -7.0663450208],
            [-37.170118462, -7.0650074875],
            [-37.141372257, -7.0770025103],
            [-37.1301986279, -7.0858734393],
            [-37.0960821826, -7.0994204034],
            [-37.0866377335, -7.1087284957],
            [-37.0974161526, -7.116912241],
            [-37.0951479697, -7.1283735629],
            [-37.0885044055, -7.1687008654],
            [-37.0870482125, -7.1815613054],
            [-37.0902582818, -7.1867733133],
            [-37.0987089546, -7.1865707344],
            [-37.1055682898, -7.1786043878],
            [-37.1149909589, -7.1784953396],
            [-37.1325473568, -7.1874179529],
            [-37.1338511072, -7.1887063299],
            [-37.1480663669, -7.1940987014],
            [-37.1602164176, -7.1914808792],
            [-37.1718797016, -7.1987149639],
            [-37.1690092679, -7.2073621775],
            [-37.166217934, -7.2111978416],
            [-37.1702231133, -7.2177172295],
            [-37.1738844013, -7.2236753901],
            [-37.1711812831, -7.2271114638],
            [-37.1759725482, -7.230254904],
            [-37.2101360107, -7.2044162012],
            [-37.2469335152, -7.197353383],
            [-37.2468165897, -7.1930315946],
            [-37.2446338362, -7.1133037105],
            [-37.2445471688, -7.1101619485],
            [-37.2169793897, -7.0846708535],
            [-37.2167569736, -7.0844652707],
            [-37.2133437048, -7.0813094723],
            [-37.1893625856, -7.0588857813],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2503506',
        name: 'Cacimba de Dentro',
        description: 'Cacimba de Dentro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7768839051, -6.595639738],
            [-35.7765828074, -6.5958015695],
            [-35.755352712, -6.5920981519],
            [-35.7491485731, -6.5975158235],
            [-35.7402987005, -6.5970212271],
            [-35.73666196, -6.6013468271],
            [-35.7345980285, -6.6135337173],
            [-35.7389829623, -6.6150884953],
            [-35.7428409004, -6.6124091786],
            [-35.7538129209, -6.6254213977],
            [-35.7422800781, -6.6767402686],
            [-35.7444479043, -6.6768744625],
            [-35.7445436087, -6.676880423],
            [-35.7488870622, -6.685468948],
            [-35.7599058694, -6.6905695228],
            [-35.7799356778, -6.6906615142],
            [-35.7803819497, -6.6908719816],
            [-35.8039983878, -6.6811663456],
            [-35.8286444462, -6.6761756123],
            [-35.8332908951, -6.6669061565],
            [-35.8399772679, -6.6699442185],
            [-35.8474741381, -6.6669276495],
            [-35.8514955124, -6.6706040947],
            [-35.849092221, -6.6827854607],
            [-35.8667267981, -6.6791938818],
            [-35.8713122626, -6.6324134641],
            [-35.8719375283, -6.6260151975],
            [-35.8752057011, -6.592580223],
            [-35.8766194993, -6.5780891165],
            [-35.8678627976, -6.5453818687],
            [-35.8587410615, -6.5466670052],
            [-35.8308707043, -6.5637169687],
            [-35.8209402367, -6.5733002377],
            [-35.8098577911, -6.5779781769],
            [-35.7768839051, -6.595639738],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2503555', name: 'Cacimbas', description: 'Cacimbas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.0016016257, -7.1753310739],
            [-37.0188145603, -7.1900526573],
            [-37.0343006058, -7.1895159274],
            [-37.0292936098, -7.2049225504],
            [-37.0292700138, -7.2049945514],
            [-37.0145175614, -7.2503422075],
            [-37.0295301269, -7.2485869644],
            [-37.0341087403, -7.2546562711],
            [-37.0405972891, -7.2554652273],
            [-37.0407027952, -7.255478543],
            [-37.0519186487, -7.2561487271],
            [-37.0540001807, -7.2526153689],
            [-37.0626378531, -7.250768887],
            [-37.0662573183, -7.2442051307],
            [-37.0708448693, -7.2438076724],
            [-37.0731583733, -7.2350424721],
            [-37.0772933971, -7.2335643675],
            [-37.0874647234, -7.2371723448],
            [-37.123827645, -7.2416232211],
            [-37.1454993492, -7.2460732332],
            [-37.1718238561, -7.2370248197],
            [-37.1759725482, -7.230254904],
            [-37.1711812831, -7.2271114638],
            [-37.1738844013, -7.2236753901],
            [-37.1702231133, -7.2177172295],
            [-37.166217934, -7.2111978416],
            [-37.1690092679, -7.2073621775],
            [-37.1718797016, -7.1987149639],
            [-37.1602164176, -7.1914808792],
            [-37.1480663669, -7.1940987014],
            [-37.1338511072, -7.1887063299],
            [-37.1325473568, -7.1874179529],
            [-37.1149909589, -7.1784953396],
            [-37.1055682898, -7.1786043878],
            [-37.0987089546, -7.1865707344],
            [-37.0902582818, -7.1867733133],
            [-37.0870482125, -7.1815613054],
            [-37.0885044055, -7.1687008654],
            [-37.0735471568, -7.1639274234],
            [-37.0698435849, -7.1577715631],
            [-37.0629705111, -7.1545422573],
            [-37.0447670228, -7.1575639933],
            [-36.9999303331, -7.1739018906],
            [-37.0016016257, -7.1753310739],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2503605', name: 'Caiçara', description: 'Caiçara' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3893360999, -6.5984806414],
            [-35.3757220378, -6.6029229204],
            [-35.3720273459, -6.5981052716],
            [-35.361212106, -6.5982911045],
            [-35.3538734677, -6.5959525709],
            [-35.3474837552, -6.597969111],
            [-35.3406233503, -6.5891938315],
            [-35.33486625, -6.5918181309],
            [-35.3305765906, -6.5886538517],
            [-35.330901459, -6.5963144169],
            [-35.3263128846, -6.6021893004],
            [-35.3283793556, -6.6208270533],
            [-35.347462953, -6.6203562615],
            [-35.3566794031, -6.612954001],
            [-35.3573484123, -6.6160012675],
            [-35.3592583259, -6.6316818946],
            [-35.3747201523, -6.6277554131],
            [-35.3778521495, -6.6432766565],
            [-35.3787320307, -6.6476387712],
            [-35.3909011546, -6.649180426],
            [-35.3927431284, -6.662081937],
            [-35.4024025167, -6.6592702968],
            [-35.4147177626, -6.6731551537],
            [-35.4157773475, -6.6740186713],
            [-35.4230591511, -6.6667822662],
            [-35.4920396538, -6.6512755736],
            [-35.5081073415, -6.6476606063],
            [-35.5033038158, -6.644521731],
            [-35.5030460162, -6.6323603787],
            [-35.4941357619, -6.6351508291],
            [-35.4903869761, -6.6227846877],
            [-35.4812847544, -6.6162718778],
            [-35.4726390687, -6.6160387291],
            [-35.469890801, -6.611181143],
            [-35.4665094306, -6.6107276579],
            [-35.4647106911, -6.611026939],
            [-35.4538414293, -6.6118804234],
            [-35.4476489289, -6.6281356769],
            [-35.44389329, -6.6297515639],
            [-35.4292012231, -6.6165458692],
            [-35.4246863709, -6.5776432669],
            [-35.4232752843, -6.5642031555],
            [-35.4062627167, -6.5299336249],
            [-35.4074337763, -6.4891830007],
            [-35.4004245512, -6.4912024445],
            [-35.3794961632, -6.5025260678],
            [-35.3641881912, -6.5116399632],
            [-35.3758872062, -6.5300792564],
            [-35.3964026473, -6.5624215225],
            [-35.3946460684, -6.572971439],
            [-35.3893360999, -6.5984806414],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2503704',
        name: 'Cajazeiras',
        description: 'Cajazeiras',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5621792943, -6.796475784],
            [-38.5318619234, -6.8040618273],
            [-38.4947038593, -6.805379911],
            [-38.4657615841, -6.8098177634],
            [-38.4299362808, -6.8138904286],
            [-38.4228216228, -6.8092463392],
            [-38.4194472398, -6.8365208779],
            [-38.4179154383, -6.8488973766],
            [-38.4186673622, -6.8668493292],
            [-38.4187140851, -6.8670227135],
            [-38.4255973063, -6.8923256254],
            [-38.4324729339, -6.8984975015],
            [-38.4306525047, -6.9118429077],
            [-38.4269484086, -6.9366146583],
            [-38.4234257608, -6.9623741759],
            [-38.4224724388, -6.9659866315],
            [-38.4046042462, -6.9677046113],
            [-38.3873667086, -6.9865759408],
            [-38.4328044931, -6.9861565119],
            [-38.4468063593, -6.9870799341],
            [-38.4530469685, -6.9844305094],
            [-38.4533041826, -6.9843217317],
            [-38.4718292377, -6.9885823498],
            [-38.5033040229, -6.9894077925],
            [-38.5036841619, -6.9962243843],
            [-38.5140915748, -6.9987830967],
            [-38.5213347931, -7.0088060233],
            [-38.5292040675, -7.0117184174],
            [-38.5462252737, -7.0321276756],
            [-38.561275407, -7.0252023362],
            [-38.5668197837, -7.0258554024],
            [-38.5669898595, -7.0259944231],
            [-38.5760973443, -7.0337325157],
            [-38.588690607, -7.054526471],
            [-38.600412807, -7.0548222042],
            [-38.6118529853, -7.0546204327],
            [-38.6338606829, -7.0448647436],
            [-38.6397738979, -7.0463776304],
            [-38.6632893042, -7.0356372208],
            [-38.6694951117, -7.047301726],
            [-38.6726617473, -7.0458276162],
            [-38.6735110796, -7.0387188905],
            [-38.6621799067, -7.0180772258],
            [-38.6700351697, -7.0121900605],
            [-38.6680699766, -7.0008558134],
            [-38.6720460515, -6.9965993688],
            [-38.6620143372, -6.9901698741],
            [-38.6648283614, -6.9860245555],
            [-38.6588175104, -6.9820737408],
            [-38.6571188365, -6.9758297821],
            [-38.6596073134, -6.9745161748],
            [-38.6583911345, -6.9697918023],
            [-38.6627099198, -6.9667488701],
            [-38.6577130534, -6.9627709606],
            [-38.6609884534, -6.9562740427],
            [-38.6605798312, -6.9521944371],
            [-38.6554978333, -6.9518947957],
            [-38.6487874769, -6.9422857612],
            [-38.6519540523, -6.9369762256],
            [-38.6454133061, -6.925241527],
            [-38.6524358142, -6.9003129386],
            [-38.6485755103, -6.8915296512],
            [-38.6536815657, -6.8882757951],
            [-38.6485461936, -6.8769888248],
            [-38.6410407331, -6.8544965272],
            [-38.6282854163, -6.8472140378],
            [-38.6272329114, -6.8411291129],
            [-38.6151594886, -6.8409425464],
            [-38.6181515512, -6.8316959302],
            [-38.6075918587, -6.8200264371],
            [-38.6066006759, -6.8137685262],
            [-38.5979668722, -6.8121857655],
            [-38.5919494125, -6.803798049],
            [-38.5858639445, -6.8040344401],
            [-38.5866217554, -6.8003679516],
            [-38.5735782849, -6.8014810239],
            [-38.5674355269, -6.7905317574],
            [-38.5621792943, -6.796475784],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2503753',
        name: 'Cajazeirinhas',
        description: 'Cajazeirinhas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.8202224246, -6.8749071899],
            [-37.804677237, -6.8764815214],
            [-37.8009472993, -6.8741418908],
            [-37.7952829535, -6.8792658303],
            [-37.7853957356, -6.8794335721],
            [-37.7848574434, -6.8796570635],
            [-37.7540833001, -6.8903909229],
            [-37.753748308, -6.8978719514],
            [-37.7496703516, -6.9009314476],
            [-37.7508564137, -6.9088624279],
            [-37.7429200583, -6.911647121],
            [-37.744446695, -6.9289601057],
            [-37.7400878329, -6.9402537325],
            [-37.739661355, -6.9537282203],
            [-37.7272775617, -6.9914868733],
            [-37.694323009, -6.9595329942],
            [-37.676920754, -6.9699593092],
            [-37.6670264805, -7.0083991859],
            [-37.676298679, -7.0146294556],
            [-37.7053927774, -7.0209093694],
            [-37.7484765926, -7.0239852681],
            [-37.7785509419, -7.0189906616],
            [-37.8212710567, -7.0188431784],
            [-37.8326410697, -7.0218227593],
            [-37.8702299741, -7.0185309537],
            [-37.8775716149, -7.0079603366],
            [-37.8849002837, -7.0055982893],
            [-37.9000464554, -6.9887376538],
            [-37.9054994162, -6.9900035708],
            [-37.9161969791, -6.9790889194],
            [-37.9191392895, -6.9711137447],
            [-37.9077794571, -6.9634972631],
            [-37.9063621209, -6.9525481923],
            [-37.8949053292, -6.9475781634],
            [-37.890841387, -6.9452034022],
            [-37.8879780958, -6.9411937913],
            [-37.8898630458, -6.9320617906],
            [-37.8766065097, -6.9253522117],
            [-37.8760761905, -6.9123985628],
            [-37.8708367132, -6.9022498989],
            [-37.8566448386, -6.8990389717],
            [-37.8443582102, -6.8707073611],
            [-37.8398179582, -6.8683118199],
            [-37.8202224246, -6.8749071899],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2503803',
        name: 'Caldas Brandão',
        description: 'Caldas Brandão',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3134196104, -7.1111026406],
            [-35.3155033912, -7.1179375586],
            [-35.3102455443, -7.1152885032],
            [-35.3058850339, -7.1185965769],
            [-35.2991231294, -7.1121910968],
            [-35.2913475224, -7.1123990194],
            [-35.2874933917, -7.1085551955],
            [-35.3058625564, -7.1414189914],
            [-35.31163873, -7.1512116703],
            [-35.3265239701, -7.1564293667],
            [-35.3323669277, -7.1746255755],
            [-35.3335776668, -7.1794516591],
            [-35.33700158, -7.1866245796],
            [-35.3491377128, -7.1939518628],
            [-35.3494482766, -7.1938718812],
            [-35.3543563399, -7.1761281211],
            [-35.3526280845, -7.1695121014],
            [-35.3525257273, -7.167983682],
            [-35.3541978244, -7.1660378628],
            [-35.3578505272, -7.1547830863],
            [-35.3535004821, -7.1440613479],
            [-35.3544861702, -7.1279801095],
            [-35.3568114142, -7.1206282908],
            [-35.3503068339, -7.1156141242],
            [-35.3530674079, -7.103397895],
            [-35.3631344677, -7.0779501355],
            [-35.3752564999, -7.0782265405],
            [-35.3755374445, -7.0327171403],
            [-35.3317231958, -7.0968252193],
            [-35.3252150276, -7.0981470686],
            [-35.3212951975, -7.1018295314],
            [-35.3232204266, -7.1092602712],
            [-35.3134196104, -7.1111026406],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2503902', name: 'Camalaú', description: 'Camalaú' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.7281038085, -7.7888221297],
            [-36.7277592445, -7.8070660243],
            [-36.7222576996, -7.8132127687],
            [-36.7190903101, -7.8186183416],
            [-36.7062361063, -7.8262962841],
            [-36.7024309473, -7.8252957058],
            [-36.6889330856, -7.8403555142],
            [-36.6808333432, -7.8438959324],
            [-36.666916353, -7.8660095617],
            [-36.6562151647, -7.8762832172],
            [-36.6460677306, -7.8823224345],
            [-36.623673279, -7.8922824126],
            [-36.6048092403, -7.9104710704],
            [-36.6010174063, -7.9132748905],
            [-36.572252092, -7.9355627033],
            [-36.6734983254, -7.9439036443],
            [-36.7376131314, -7.9505730923],
            [-36.81442288, -7.9562696043],
            [-36.8381979645, -7.9831189725],
            [-36.8465547364, -7.9876557622],
            [-36.8680537987, -8.0118397621],
            [-36.8792438415, -8.0169803515],
            [-36.8930969894, -8.0135962744],
            [-36.9049621267, -8.0188166563],
            [-36.9105184498, -8.0271352495],
            [-36.9182058862, -8.0287813618],
            [-36.9248038171, -8.0365681513],
            [-36.9289416738, -7.9645101599],
            [-36.9291096267, -7.9615717368],
            [-36.9341358168, -7.8733076794],
            [-36.9359235633, -7.8705103714],
            [-36.9613529157, -7.8307051678],
            [-36.8288170204, -7.8121424714],
            [-36.83360111, -7.84230217],
            [-36.8154135627, -7.8305409548],
            [-36.8032621769, -7.8276709033],
            [-36.7943406505, -7.829609612],
            [-36.7731661043, -7.8193664158],
            [-36.7692642618, -7.8167858883],
            [-36.760314462, -7.8177233524],
            [-36.7586924985, -7.8029987967],
            [-36.7524867687, -7.7998168547],
            [-36.7502560265, -7.8022321258],
            [-36.7463905216, -7.8023168134],
            [-36.7417738791, -7.7926404553],
            [-36.7337359251, -7.7882840156],
            [-36.725660935, -7.7754044831],
            [-36.7241510791, -7.7761155705],
            [-36.7281038085, -7.7888221297],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2504009',
        name: 'Campina Grande',
        description: 'Campina Grande',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.0452637166, -7.1653747466],
            [-36.0338356728, -7.1732618166],
            [-36.0333127364, -7.1729075154],
            [-36.0326087925, -7.1661915046],
            [-36.0293886743, -7.1651188875],
            [-35.9964317292, -7.1640042294],
            [-35.986714503, -7.1604754459],
            [-35.978576331, -7.1734274706],
            [-35.9788552945, -7.1758601572],
            [-35.9786561816, -7.1761891287],
            [-35.9638346934, -7.1942336305],
            [-35.9553933619, -7.1971450591],
            [-35.9543185668, -7.1957505069],
            [-35.9540526846, -7.1954049957],
            [-35.947283717, -7.1927736047],
            [-35.9379386922, -7.1940938524],
            [-35.9392490917, -7.1860765751],
            [-35.939867401, -7.1744428582],
            [-35.9376602049, -7.1748887805],
            [-35.9047624231, -7.1815382629],
            [-35.9042989072, -7.1813733843],
            [-35.8952808022, -7.1678607438],
            [-35.8876292735, -7.1563973744],
            [-35.8843048382, -7.1737426906],
            [-35.8729884177, -7.1868875538],
            [-35.8725316835, -7.1936986535],
            [-35.865665277, -7.2018661132],
            [-35.8648663573, -7.2108115888],
            [-35.8518044397, -7.2023044674],
            [-35.8434457305, -7.1919560932],
            [-35.8408048611, -7.195475124],
            [-35.8339519275, -7.1945058565],
            [-35.8143446102, -7.2070697309],
            [-35.8346934562, -7.2402768672],
            [-35.8400787987, -7.2496347247],
            [-35.8387447076, -7.2511476622],
            [-35.835548752, -7.252408636],
            [-35.8125635774, -7.2570024081],
            [-35.8002523203, -7.2668533371],
            [-35.7880903596, -7.2694175134],
            [-35.7826090637, -7.2754444988],
            [-35.7731951203, -7.2761471807],
            [-35.7699262121, -7.2805744164],
            [-35.7644720352, -7.2809011544],
            [-35.7518593331, -7.2721287984],
            [-35.7439964024, -7.2776164115],
            [-35.7320040181, -7.2769508022],
            [-35.7159576257, -7.2633092924],
            [-35.70381155, -7.2598254776],
            [-35.7264474588, -7.3051011368],
            [-35.735733413, -7.3236810894],
            [-35.7397980604, -7.3176603858],
            [-35.7562720287, -7.3261909901],
            [-35.7689652452, -7.3227834076],
            [-35.7833399178, -7.3180480209],
            [-35.7954742491, -7.3208019219],
            [-35.8085565639, -7.3193422343],
            [-35.823613586, -7.3282129875],
            [-35.8381266573, -7.3306000184],
            [-35.8382717306, -7.3293866563],
            [-35.8416665946, -7.3009794847],
            [-35.8719571643, -7.2997867483],
            [-35.8879806822, -7.2916847558],
            [-35.8877496527, -7.286465295],
            [-35.9091940063, -7.2861959032],
            [-35.9392318581, -7.2858162478],
            [-35.9446727595, -7.2846234691],
            [-35.9441708726, -7.2797734739],
            [-35.9506315705, -7.2793178542],
            [-35.9570180716, -7.287239102],
            [-35.9620130168, -7.2881743479],
            [-35.9616786676, -7.2977708538],
            [-35.9825784668, -7.2957756093],
            [-35.9768729362, -7.3014582947],
            [-35.977632137, -7.3133746831],
            [-35.9775760235, -7.3198882518],
            [-35.9827800692, -7.3219511096],
            [-35.9795559572, -7.3391963526],
            [-35.9829953934, -7.3401000462],
            [-35.9818143141, -7.3439145436],
            [-35.9871342629, -7.3453276901],
            [-35.9860662589, -7.3481001144],
            [-35.9862370905, -7.367755026],
            [-36.003567626, -7.3805622836],
            [-36.003014839, -7.3842691945],
            [-36.0084194712, -7.3830101475],
            [-36.0135932235, -7.3860640931],
            [-36.0164193713, -7.379557176],
            [-36.0190885545, -7.3814587312],
            [-36.034356696, -7.369600438],
            [-36.0391588803, -7.3672550698],
            [-36.1282809415, -7.3582472853],
            [-36.1190745659, -7.301743206],
            [-36.114026302, -7.2708032742],
            [-36.113952284, -7.2703524615],
            [-36.1144426057, -7.2546969355],
            [-36.1106132417, -7.2564003625],
            [-36.1087356989, -7.2522856043],
            [-36.1059055628, -7.238159965],
            [-36.1025418348, -7.2355987562],
            [-36.1046130175, -7.2305490335],
            [-36.1022963697, -7.2197440958],
            [-36.0939448027, -7.2092430229],
            [-36.0920630395, -7.1953962581],
            [-36.0945109649, -7.1536040903],
            [-36.0889599251, -7.155381562],
            [-36.0629268851, -7.1657743416],
            [-36.0452637166, -7.1653747466],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504033', name: 'Capim', description: 'Capim' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.165175996, -6.8513208144],
            [-35.1667422481, -6.8563330886],
            [-35.1577388918, -6.8843831806],
            [-35.1592048936, -6.8931064019],
            [-35.1296403205, -6.8982834256],
            [-35.1242766599, -6.8953754905],
            [-35.1006473786, -6.9381812344],
            [-35.1062003432, -6.9368284006],
            [-35.1323175014, -6.9404208617],
            [-35.148942577, -6.9421827839],
            [-35.1547486065, -6.9400271144],
            [-35.1785516092, -6.9524059815],
            [-35.1787575547, -6.9502663483],
            [-35.1809723542, -6.9420969061],
            [-35.1780730989, -6.933170512],
            [-35.1774773769, -6.9285183597],
            [-35.1935738493, -6.918593639],
            [-35.2052293966, -6.9042111163],
            [-35.2135474719, -6.8931263964],
            [-35.223442998, -6.8875472405],
            [-35.2270032578, -6.8686522999],
            [-35.2211327095, -6.8657886365],
            [-35.2164031734, -6.8628996512],
            [-35.2176124767, -6.8575777725],
            [-35.1983882431, -6.8510664922],
            [-35.1937463218, -6.8542062851],
            [-35.1872113614, -6.85065109],
            [-35.1819013495, -6.8463625309],
            [-35.1687658849, -6.8499651105],
            [-35.165175996, -6.8513208144],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504074', name: 'Caraúbas', description: 'Caraúbas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.5580475021, -7.6323567585],
            [-36.5585171109, -7.6359865818],
            [-36.5413273101, -7.6349151162],
            [-36.5284016262, -7.6409656442],
            [-36.5126619503, -7.6372792508],
            [-36.5122564973, -7.6334061296],
            [-36.4944798399, -7.6323167029],
            [-36.491837756, -7.6433971308],
            [-36.4789466271, -7.6529901476],
            [-36.4586939108, -7.6591477344],
            [-36.4567450666, -7.6552647696],
            [-36.4529610033, -7.6573643726],
            [-36.4499676705, -7.6522448126],
            [-36.4429801679, -7.6534326668],
            [-36.4454440473, -7.6740185396],
            [-36.4454156137, -7.6741116947],
            [-36.4408841615, -7.683031615],
            [-36.3988645631, -7.7656737251],
            [-36.3960932291, -7.8082867258],
            [-36.4070871569, -7.8136649937],
            [-36.4237110437, -7.8153675504],
            [-36.4296267437, -7.8267007199],
            [-36.4287206803, -7.8666261448],
            [-36.4400468962, -7.8778398298],
            [-36.4323856154, -7.8795747843],
            [-36.4295214891, -7.8846575291],
            [-36.4340417977, -7.8959069837],
            [-36.4454184625, -7.9077568589],
            [-36.4433596225, -7.9139194353],
            [-36.4459642385, -7.9159684814],
            [-36.463762921, -7.9149386765],
            [-36.4797983584, -7.924351764],
            [-36.4877782346, -7.9149732513],
            [-36.4990514862, -7.9199034721],
            [-36.5125189475, -7.9144602458],
            [-36.5361286943, -7.9011981535],
            [-36.5676341708, -7.8612951535],
            [-36.5608822507, -7.8418669756],
            [-36.5561219625, -7.8281721186],
            [-36.5593552173, -7.8173922968],
            [-36.555386847, -7.8068532949],
            [-36.5701849245, -7.800742371],
            [-36.589269517, -7.7712173835],
            [-36.6135790489, -7.7609662783],
            [-36.6208411279, -7.7517270569],
            [-36.6157464581, -7.7429961917],
            [-36.6184312373, -7.7402004059],
            [-36.624353511, -7.7282939398],
            [-36.616976268, -7.7049455559],
            [-36.6139500506, -7.702006365],
            [-36.6076680226, -7.708492708],
            [-36.5955772665, -7.7099258005],
            [-36.5785317425, -7.7174750849],
            [-36.5646118773, -7.6903303519],
            [-36.5606651869, -7.6850404591],
            [-36.5518907453, -7.6819046946],
            [-36.5469349857, -7.6714234631],
            [-36.5891228887, -7.6334512844],
            [-36.576811841, -7.6255147312],
            [-36.5580475021, -7.6323567585],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2504108',
        name: 'Carrapateira',
        description: 'Carrapateira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3215496995, -7.0962323883],
            [-38.3312760472, -7.1062615273],
            [-38.3422417929, -7.0874661891],
            [-38.366946817, -7.0432972916],
            [-38.3623017287, -6.9883108339],
            [-38.3145984993, -6.9930294059],
            [-38.3112874912, -6.993017254],
            [-38.2622779384, -6.9928333899],
            [-38.2771316075, -7.0039251053],
            [-38.2838564357, -7.011640103],
            [-38.2976509033, -7.0360412964],
            [-38.3223590445, -7.0623248647],
            [-38.3353457964, -7.0638536703],
            [-38.3095003898, -7.0823303145],
            [-38.3178999077, -7.0839730864],
            [-38.3215496995, -7.0962323883],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2504157',
        name: 'Casserengue',
        description: 'Casserengue',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8474741381, -6.6669276495],
            [-35.8399772679, -6.6699442185],
            [-35.8332908951, -6.6669061565],
            [-35.8286444462, -6.6761756123],
            [-35.8039983878, -6.6811663456],
            [-35.7803819497, -6.6908719816],
            [-35.7792774476, -6.6973965925],
            [-35.7821701365, -6.7007444251],
            [-35.7798683325, -6.7045695554],
            [-35.7854632134, -6.7149922246],
            [-35.788840665, -6.7486159324],
            [-35.7942260846, -6.7491168186],
            [-35.797506626, -6.7526268089],
            [-35.7937936398, -6.756068056],
            [-35.809771311, -6.769194912],
            [-35.8039969124, -6.7868733232],
            [-35.7889172628, -6.8187215773],
            [-35.7865216196, -6.8237796007],
            [-35.7920015004, -6.8263299285],
            [-35.7961434129, -6.8339266236],
            [-35.8050651422, -6.8341693296],
            [-35.8063305145, -6.8407753981],
            [-35.8116419626, -6.8429582544],
            [-35.8081480236, -6.8476108787],
            [-35.8098386605, -6.8632165272],
            [-35.8286198338, -6.8563896856],
            [-35.8390752118, -6.8461582901],
            [-35.8440259949, -6.8360174944],
            [-35.8689794615, -6.8165963021],
            [-35.8911791291, -6.7930344506],
            [-35.8973826916, -6.7932019355],
            [-35.9065675143, -6.7842621796],
            [-35.9053083742, -6.7781330424],
            [-35.8993493933, -6.7730097078],
            [-35.9026167511, -6.757632692],
            [-35.8961522524, -6.7499911375],
            [-35.8985476964, -6.7435650562],
            [-35.922199698, -6.7309222455],
            [-35.9223933137, -6.7199053965],
            [-35.9108723226, -6.7170832022],
            [-35.9068514176, -6.7092210179],
            [-35.9010223985, -6.7059778813],
            [-35.8985745205, -6.7046158832],
            [-35.9014165784, -6.6995300359],
            [-35.8929434917, -6.6906640468],
            [-35.8741046328, -6.6914095397],
            [-35.8667267981, -6.6791938818],
            [-35.849092221, -6.6827854607],
            [-35.8514955124, -6.6706040947],
            [-35.8474741381, -6.6669276495],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2504207',
        name: 'Catingueira',
        description: 'Catingueira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.6265194077, -6.9929407138],
            [-37.6032206659, -6.9979399884],
            [-37.5881203749, -6.9935504726],
            [-37.5192680045, -7.0001441186],
            [-37.5188572861, -7.0064079446],
            [-37.5135562147, -7.0869539285],
            [-37.5120183811, -7.1102199139],
            [-37.5428718693, -7.1092512773],
            [-37.4940820695, -7.2268888268],
            [-37.494061602, -7.2270488943],
            [-37.4974469368, -7.2376009423],
            [-37.5113626202, -7.2494599226],
            [-37.506720718, -7.2587830192],
            [-37.514420428, -7.2624762338],
            [-37.5230948324, -7.2668085855],
            [-37.522422777, -7.2765715058],
            [-37.5470831778, -7.2916548825],
            [-37.5587886035, -7.3100486769],
            [-37.5663692139, -7.3145586522],
            [-37.5699933868, -7.316653138],
            [-37.5802993762, -7.307666796],
            [-37.5850784412, -7.2877623262],
            [-37.5933099157, -7.2841558129],
            [-37.5956484792, -7.2713921107],
            [-37.6143938985, -7.2698082233],
            [-37.6150200367, -7.2643063975],
            [-37.6234859106, -7.2618705224],
            [-37.6298139818, -7.2486488723],
            [-37.6477858191, -7.2371321591],
            [-37.6485479508, -7.2329873477],
            [-37.6571151356, -7.2314546923],
            [-37.6603183476, -7.2338499825],
            [-37.6614148984, -7.2254502579],
            [-37.6730214719, -7.2132194956],
            [-37.6685890372, -7.2037448213],
            [-37.6758767693, -7.1905198615],
            [-37.6769082254, -7.1717351157],
            [-37.7034925837, -7.1750549804],
            [-37.704426159, -7.174818905],
            [-37.6680800019, -7.1543894446],
            [-37.6716319651, -7.1154976013],
            [-37.6755414859, -7.0288699971],
            [-37.676298679, -7.0146294556],
            [-37.6670264805, -7.0083991859],
            [-37.6537474177, -6.9998586387],
            [-37.6265194077, -6.9929407138],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2504306',
        name: 'Catolé do Rocha',
        description: 'Catolé do Rocha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.6797469824, -6.1887557574],
            [-37.6463675584, -6.1889953832],
            [-37.6381313037, -6.1794401273],
            [-37.6159050387, -6.1758006785],
            [-37.6187059581, -6.2062849285],
            [-37.6169095809, -6.2299146592],
            [-37.6236969858, -6.2471575777],
            [-37.6152591454, -6.2599363524],
            [-37.6126800207, -6.2704499559],
            [-37.6126046321, -6.2791644118],
            [-37.6055824103, -6.2837133666],
            [-37.6050792459, -6.2879151686],
            [-37.6055542119, -6.3126107046],
            [-37.6060265183, -6.3256298461],
            [-37.6060223852, -6.3257575094],
            [-37.6063565999, -6.3347554607],
            [-37.6019006359, -6.3427480759],
            [-37.6055809624, -6.3576330851],
            [-37.6035990237, -6.3697560534],
            [-37.599658593, -6.3878188031],
            [-37.5841670116, -6.4050905576],
            [-37.5773742673, -6.4117216008],
            [-37.5769324749, -6.4205035582],
            [-37.5784340806, -6.4335577141],
            [-37.6385480317, -6.4168860286],
            [-37.7235870899, -6.3932717076],
            [-37.7335296421, -6.3905080198],
            [-37.7333108123, -6.4355082592],
            [-37.7332709885, -6.4435871295],
            [-37.7330441752, -6.487857835],
            [-37.739949216, -6.4812378821],
            [-37.7456093941, -6.4775316008],
            [-37.7425355625, -6.465090446],
            [-37.8013631399, -6.4558669255],
            [-37.8088285891, -6.4503182364],
            [-37.8179748596, -6.447943503],
            [-37.8060660971, -6.4063626744],
            [-37.794958466, -6.3664326716],
            [-37.7954289442, -6.3659474408],
            [-37.8102677784, -6.3473501867],
            [-37.8428490989, -6.3326901981],
            [-37.8333855399, -6.3213808374],
            [-37.8223271523, -6.3173812682],
            [-37.8144090467, -6.3147453661],
            [-37.8141900647, -6.3119142919],
            [-37.8236841144, -6.3056359541],
            [-37.8100751347, -6.2912603903],
            [-37.7941722997, -6.2886353348],
            [-37.7713529923, -6.2905503783],
            [-37.757317103, -6.2903745279],
            [-37.7574513147, -6.2873834075],
            [-37.7710764107, -6.2726676475],
            [-37.7701323609, -6.2652387204],
            [-37.7601709416, -6.2536932545],
            [-37.7762676676, -6.245477635],
            [-37.7627209162, -6.2407383264],
            [-37.7624267988, -6.234940233],
            [-37.7752709715, -6.2309463758],
            [-37.7635003724, -6.2185025231],
            [-37.7527342062, -6.2140332338],
            [-37.7531893015, -6.1980721399],
            [-37.7420286635, -6.1885785666],
            [-37.7160635865, -6.1788397064],
            [-37.7086517066, -6.176607236],
            [-37.6978378964, -6.1862062707],
            [-37.6798140329, -6.188750782],
            [-37.6797469824, -6.1887557574],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504355', name: 'Caturité', description: 'Caturité' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.034356696, -7.369600438],
            [-36.0190885545, -7.3814587312],
            [-36.0164193713, -7.379557176],
            [-36.0135932235, -7.3860640931],
            [-36.0084194712, -7.3830101475],
            [-36.003014839, -7.3842691945],
            [-35.9990662106, -7.3899687702],
            [-36.0080282235, -7.3964698157],
            [-36.0088836549, -7.4024131298],
            [-36.0088199254, -7.4025711611],
            [-35.9994032624, -7.4077914082],
            [-35.9983111519, -7.4123453485],
            [-36.0035023289, -7.4106561325],
            [-36.0169234943, -7.4203805618],
            [-36.0170848204, -7.4218510844],
            [-36.0044681344, -7.4238487493],
            [-36.0076177072, -7.4379535476],
            [-36.01629986, -7.4380125766],
            [-36.0152152677, -7.4423721148],
            [-36.0210104377, -7.4435810214],
            [-36.0087613441, -7.4494002966],
            [-36.0094282384, -7.4532390794],
            [-36.0100260798, -7.4536824747],
            [-36.0488311278, -7.4824966992],
            [-36.067434713, -7.4713911523],
            [-36.0945832635, -7.4551783135],
            [-36.0947771363, -7.4498424499],
            [-36.1044127607, -7.4440695835],
            [-36.1046901797, -7.4396077121],
            [-36.0978901798, -7.4365816137],
            [-36.1034677606, -7.4298584861],
            [-36.101947625, -7.4194548545],
            [-36.0958860039, -7.4098991399],
            [-36.0968587218, -7.406180625],
            [-36.0990017393, -7.3916562389],
            [-36.1110580947, -7.3849431487],
            [-36.1230634123, -7.3729918142],
            [-36.1282809415, -7.3582472853],
            [-36.0391588803, -7.3672550698],
            [-36.034356696, -7.369600438],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2504405',
        name: 'Conceição',
        description: 'Conceição',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5198281324, -7.3645246683],
            [-38.5162974168, -7.3695157882],
            [-38.5159600802, -7.3668203574],
            [-38.5047716214, -7.3646409067],
            [-38.5027262977, -7.3750213195],
            [-38.4954086677, -7.3745424137],
            [-38.4868203856, -7.3789161784],
            [-38.4863116707, -7.3791270014],
            [-38.480290442, -7.3816307457],
            [-38.476728371, -7.3745820192],
            [-38.4628971724, -7.3705757827],
            [-38.4532811819, -7.3609871089],
            [-38.4403422129, -7.3579435812],
            [-38.4325746467, -7.3427530598],
            [-38.4148787464, -7.3431285972],
            [-38.4127133465, -7.3420986264],
            [-38.4122657718, -7.3472993282],
            [-38.4061960957, -7.3464264388],
            [-38.4131366012, -7.3621823476],
            [-38.4103674859, -7.3652068768],
            [-38.4139336216, -7.3685001483],
            [-38.4137524688, -7.3716090304],
            [-38.4075903268, -7.370653832],
            [-38.402228818, -7.3749707124],
            [-38.4097069067, -7.3910754463],
            [-38.410395696, -7.3981856317],
            [-38.4071198917, -7.4015416889],
            [-38.3961151727, -7.4081709164],
            [-38.4008539143, -7.4159327937],
            [-38.4008285758, -7.4239664464],
            [-38.4043732814, -7.4255807184],
            [-38.4068366385, -7.4231034735],
            [-38.4263151535, -7.4227599497],
            [-38.4412201347, -7.4291761509],
            [-38.4760272595, -7.4326660104],
            [-38.4912759079, -7.4381172962],
            [-38.4908456681, -7.4403256848],
            [-38.4752417379, -7.5203390979],
            [-38.4799328377, -7.5232216048],
            [-38.4813954212, -7.5296878843],
            [-38.4707280315, -7.5382054018],
            [-38.4411782533, -7.5421628956],
            [-38.4409847079, -7.5422487819],
            [-38.4238789655, -7.5519523475],
            [-38.4231271294, -7.5650421902],
            [-38.4363956579, -7.5833044081],
            [-38.4392778804, -7.585334843],
            [-38.4459532403, -7.5813995035],
            [-38.4453253277, -7.5881380498],
            [-38.4502402026, -7.5936756849],
            [-38.4484778638, -7.5949923627],
            [-38.4393086918, -7.6018431924],
            [-38.4385144421, -7.6070725495],
            [-38.4459089835, -7.6184309679],
            [-38.4451668353, -7.6246199113],
            [-38.4382518576, -7.6332683031],
            [-38.4462160775, -7.6439613084],
            [-38.4500311706, -7.6680708118],
            [-38.4338636543, -7.7200431038],
            [-38.4497194697, -7.7169639109],
            [-38.4567308259, -7.7176988005],
            [-38.4493867844, -7.7066062174],
            [-38.449872497, -7.7025174818],
            [-38.4547303876, -7.6966200851],
            [-38.4660221231, -7.6949118389],
            [-38.4749860282, -7.6866119787],
            [-38.4771687951, -7.6783325136],
            [-38.4699451864, -7.6652638544],
            [-38.4734437314, -7.6587755034],
            [-38.4700760223, -7.6517392422],
            [-38.4758512783, -7.6515191182],
            [-38.4806631783, -7.649443259],
            [-38.4964732951, -7.6515786055],
            [-38.500216782, -7.6552134872],
            [-38.5091611742, -7.6485083969],
            [-38.525425326, -7.6340086198],
            [-38.5230091215, -7.6153139469],
            [-38.5302734813, -7.6002005448],
            [-38.5435347708, -7.5969248328],
            [-38.5683105883, -7.5996884488],
            [-38.5834517083, -7.5962020726],
            [-38.599503422, -7.6034137988],
            [-38.6201099978, -7.5980767349],
            [-38.631448399, -7.5866864055],
            [-38.6675001905, -7.5856875819],
            [-38.6794901708, -7.5743161293],
            [-38.6741133356, -7.5689244314],
            [-38.6549708979, -7.5651361326],
            [-38.6587766803, -7.5602133013],
            [-38.6532179716, -7.5541615826],
            [-38.6561334557, -7.5457867763],
            [-38.6523566341, -7.5392689858],
            [-38.6511380638, -7.5273963568],
            [-38.6543036035, -7.5218153266],
            [-38.646121234, -7.5171342533],
            [-38.6456416319, -7.5015196094],
            [-38.630550001, -7.4869235389],
            [-38.6354693867, -7.4746115487],
            [-38.6413367123, -7.4645613598],
            [-38.645818537, -7.4629329679],
            [-38.6445646797, -7.4596127649],
            [-38.6029734746, -7.4571858665],
            [-38.6024905238, -7.4531522347],
            [-38.6054495065, -7.4441667886],
            [-38.5957208318, -7.4382244208],
            [-38.5946647173, -7.4335314724],
            [-38.5838972458, -7.4327719695],
            [-38.5841440698, -7.4159452174],
            [-38.5957717852, -7.4143497862],
            [-38.5900306313, -7.400776054],
            [-38.5832482409, -7.3977981119],
            [-38.5761281523, -7.3762034188],
            [-38.5608303989, -7.3578587066],
            [-38.551858185, -7.3401951213],
            [-38.549029391, -7.3345923826],
            [-38.5437590008, -7.3386650743],
            [-38.5280624282, -7.3495110402],
            [-38.5240408165, -7.3642609794],
            [-38.5198281324, -7.3645246683],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504504', name: 'Condado', description: 'Condado' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.6384175056, -6.7217321917],
            [-37.6287039833, -6.7308167165],
            [-37.6047161817, -6.7297789701],
            [-37.5991564707, -6.7974336962],
            [-37.5921130262, -6.8042245013],
            [-37.5759180931, -6.8064468098],
            [-37.5756536463, -6.8120982058],
            [-37.5645488182, -6.8945392063],
            [-37.5561361176, -6.904631857],
            [-37.556048749, -6.9050018684],
            [-37.5553380353, -6.9080119755],
            [-37.5524034257, -6.9244222969],
            [-37.5429192502, -6.933721183],
            [-37.5383106982, -6.9431799852],
            [-37.5448441539, -6.9689553545],
            [-37.5192680045, -7.0001441186],
            [-37.5881203749, -6.9935504726],
            [-37.6032206659, -6.9979399884],
            [-37.6265194077, -6.9929407138],
            [-37.6537474177, -6.9998586387],
            [-37.6670264805, -7.0083991859],
            [-37.676920754, -6.9699593092],
            [-37.694323009, -6.9595329942],
            [-37.6961139641, -6.9439597759],
            [-37.6767896215, -6.9381638139],
            [-37.6778562127, -6.9273802666],
            [-37.6849709037, -6.9174483273],
            [-37.6800066315, -6.912543658],
            [-37.6804802007, -6.9059099849],
            [-37.6806139482, -6.9051968397],
            [-37.6792018493, -6.8980358306],
            [-37.6684476109, -6.8840921925],
            [-37.6583768695, -6.878936585],
            [-37.6447255992, -6.8630276685],
            [-37.6360510681, -6.8589537131],
            [-37.6325282412, -6.8521992547],
            [-37.6330167325, -6.8408563756],
            [-37.627006364, -6.8262380011],
            [-37.6306958367, -6.8062183831],
            [-37.6325400171, -6.796368323],
            [-37.6242720938, -6.7871290597],
            [-37.6223614092, -6.7797207431],
            [-37.6329626685, -6.7674971532],
            [-37.6306088044, -6.756678139],
            [-37.6438262641, -6.7436101929],
            [-37.6471628737, -6.7286496742],
            [-37.6567726865, -6.7224146555],
            [-37.6570040806, -6.7222646825],
            [-37.6384175056, -6.7217321917],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504603', name: 'Conde', description: 'Conde' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.8723923097, -7.2206871527],
            [-34.8695179567, -7.2274340363],
            [-34.8653357184, -7.2258525978],
            [-34.8621697544, -7.2298268722],
            [-34.8529460703, -7.2301731683],
            [-34.8491117853, -7.2347515519],
            [-34.8450859734, -7.2319156082],
            [-34.8464461786, -7.2348193918],
            [-34.8421193778, -7.2363782382],
            [-34.8399452425, -7.2340371592],
            [-34.8384685606, -7.2373379655],
            [-34.8380007284, -7.2314188766],
            [-34.8319718511, -7.2281954337],
            [-34.8225236603, -7.2301510379],
            [-34.8253321901, -7.2341052028],
            [-34.8183641584, -7.2338700478],
            [-34.8178592708, -7.2386084876],
            [-34.8067780258, -7.2362539869],
            [-34.8083359748, -7.2430713535],
            [-34.8052515429, -7.2396909862],
            [-34.8042629109, -7.2602954978],
            [-34.7986981083, -7.2775910722],
            [-34.8004677932, -7.2939790909],
            [-34.7975550662, -7.3001966274],
            [-34.7993457768, -7.3060198504],
            [-34.8014637075, -7.3154384342],
            [-34.7988230225, -7.3209585579],
            [-34.7936142087, -7.3291463707],
            [-34.799668633, -7.3572477988],
            [-34.7968358646, -7.3665036205],
            [-34.8019761826, -7.3830706056],
            [-34.8083358146, -7.3762037352],
            [-34.8099447389, -7.3669345102],
            [-34.8125817188, -7.367752484],
            [-34.8129772048, -7.3638672559],
            [-34.8176098732, -7.3645355871],
            [-34.8153105882, -7.3543930346],
            [-34.8198386079, -7.3493676507],
            [-34.8438845362, -7.3435292127],
            [-34.8518865156, -7.3472215049],
            [-34.8571417167, -7.3413783973],
            [-34.8595293685, -7.3429337252],
            [-34.8653278758, -7.3383239925],
            [-34.8737762864, -7.333396259],
            [-34.8806156455, -7.3242585613],
            [-34.9182491737, -7.3031530402],
            [-34.9197228681, -7.2998165651],
            [-34.9166000368, -7.2888658255],
            [-34.9199007923, -7.2804215625],
            [-34.9260535685, -7.2726124545],
            [-34.9392751072, -7.2730139086],
            [-34.9376346059, -7.26092577],
            [-34.9343443695, -7.2521088453],
            [-34.9435232956, -7.248536677],
            [-34.9410051261, -7.2412879255],
            [-34.9349408247, -7.2386327809],
            [-34.9295268927, -7.2255095248],
            [-34.9115039702, -7.2128047007],
            [-34.9041714319, -7.2124360908],
            [-34.8723923097, -7.2206871527],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504702', name: 'Congo', description: 'Congo' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.6689077931, -7.6834313117],
            [-36.6583253601, -7.6843366982],
            [-36.6473645955, -7.6832040179],
            [-36.6388189673, -7.6920710324],
            [-36.6386915552, -7.6921240968],
            [-36.6314105126, -7.6951565406],
            [-36.6252255908, -7.7048321588],
            [-36.616976268, -7.7049455559],
            [-36.624353511, -7.7282939398],
            [-36.6184312373, -7.7402004059],
            [-36.6157464581, -7.7429961917],
            [-36.6208411279, -7.7517270569],
            [-36.6135790489, -7.7609662783],
            [-36.589269517, -7.7712173835],
            [-36.5701849245, -7.800742371],
            [-36.555386847, -7.8068532949],
            [-36.5593552173, -7.8173922968],
            [-36.5561219625, -7.8281721186],
            [-36.5608822507, -7.8418669756],
            [-36.5676341708, -7.8612951535],
            [-36.5361286943, -7.9011981535],
            [-36.5516824317, -7.8983028019],
            [-36.568368141, -7.9027444267],
            [-36.574473025, -7.9070893424],
            [-36.577861928, -7.9148362989],
            [-36.572252092, -7.9355627033],
            [-36.6010174063, -7.9132748905],
            [-36.6048092403, -7.9104710704],
            [-36.623673279, -7.8922824126],
            [-36.6460677306, -7.8823224345],
            [-36.6562151647, -7.8762832172],
            [-36.666916353, -7.8660095617],
            [-36.6808333432, -7.8438959324],
            [-36.6889330856, -7.8403555142],
            [-36.7024309473, -7.8252957058],
            [-36.7062361063, -7.8262962841],
            [-36.7190903101, -7.8186183416],
            [-36.7222576996, -7.8132127687],
            [-36.7277592445, -7.8070660243],
            [-36.7281038085, -7.7888221297],
            [-36.7241510791, -7.7761155705],
            [-36.725660935, -7.7754044831],
            [-36.7638000507, -7.7283767019],
            [-36.7539095785, -7.7152127205],
            [-36.74496126, -7.7140530942],
            [-36.7297505979, -7.7049220849],
            [-36.7284357818, -7.7010391517],
            [-36.7125220567, -7.6948706352],
            [-36.7072241686, -7.6965669162],
            [-36.6689077931, -7.6834313117],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504801', name: 'Coremas', description: 'Coremas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.8702299741, -7.0185309537],
            [-37.8326410697, -7.0218227593],
            [-37.8212710567, -7.0188431784],
            [-37.8222790605, -7.0562342395],
            [-37.8232918848, -7.0940579212],
            [-37.8410391046, -7.0991405813],
            [-37.8541636695, -7.08692834],
            [-37.8717886355, -7.0852929237],
            [-37.8936671532, -7.0890580768],
            [-37.8962740776, -7.0888228954],
            [-37.9161361411, -7.0856581221],
            [-37.9495060813, -7.0891904043],
            [-37.9530063917, -7.0994449419],
            [-37.9700847505, -7.1038664999],
            [-37.973445978, -7.1177948066],
            [-37.978107733, -7.1205005223],
            [-37.9844045832, -7.1435481077],
            [-37.981493721, -7.1527585584],
            [-37.9898843403, -7.1623154143],
            [-37.990426142, -7.1733386379],
            [-38.0168247397, -7.1748147014],
            [-38.0172341851, -7.1848051477],
            [-38.0244277449, -7.1798585109],
            [-38.0254034895, -7.1747685141],
            [-38.0347980318, -7.1653783765],
            [-38.0256060103, -7.1579461882],
            [-38.0253239571, -7.1502100603],
            [-38.0308325093, -7.1386847303],
            [-38.0502834965, -7.1230620285],
            [-38.0522905727, -7.1170086692],
            [-38.049999924, -7.1115332545],
            [-38.0582177878, -7.0870471685],
            [-38.0887476125, -7.0146317483],
            [-38.0896716242, -7.0124371674],
            [-38.0887475003, -7.0084952015],
            [-38.069384096, -7.0024888047],
            [-38.0726386899, -6.9902453067],
            [-38.0726678251, -6.9901364653],
            [-38.0615127162, -6.9797382465],
            [-38.0347639263, -6.9743352499],
            [-38.0260527489, -6.956091669],
            [-38.0140476636, -6.9492194753],
            [-38.0060705827, -6.943898428],
            [-37.9997217755, -6.948753854],
            [-37.9777205519, -6.9628671148],
            [-37.9483607787, -6.9816900037],
            [-37.9292879968, -6.9939131412],
            [-37.8916164236, -7.0098743718],
            [-37.8702299741, -7.0185309537],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2504850', name: 'Coxixola', description: 'Coxixola' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.5891228887, -7.6334512844],
            [-36.5469349857, -7.6714234631],
            [-36.5518907453, -7.6819046946],
            [-36.5606651869, -7.6850404591],
            [-36.5646118773, -7.6903303519],
            [-36.5785317425, -7.7174750849],
            [-36.5955772665, -7.7099258005],
            [-36.6076680226, -7.708492708],
            [-36.6139500506, -7.702006365],
            [-36.616976268, -7.7049455559],
            [-36.6252255908, -7.7048321588],
            [-36.6314105126, -7.6951565406],
            [-36.6386915552, -7.6921240968],
            [-36.6388189673, -7.6920710324],
            [-36.6473645955, -7.6832040179],
            [-36.6583253601, -7.6843366982],
            [-36.6613707483, -7.6799715849],
            [-36.6703767819, -7.6827936873],
            [-36.6794796715, -7.6771917573],
            [-36.6882983062, -7.6757221627],
            [-36.6932133616, -7.678522178],
            [-36.699003219, -7.6747855746],
            [-36.7087943886, -7.6211840528],
            [-36.7139825415, -7.5927293602],
            [-36.7119602776, -7.5918219229],
            [-36.6395557656, -7.5854164825],
            [-36.5999977935, -7.5819144484],
            [-36.5891228887, -7.6334512844],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2504900',
        name: 'Cruz do Espírito Santo',
        description: 'Cruz do Espírito Santo',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.0539957902, -7.1838529499],
            [-35.0671827114, -7.2288858822],
            [-35.0778619453, -7.2282334598],
            [-35.0930234903, -7.2319517554],
            [-35.1101098522, -7.2299751904],
            [-35.126293739, -7.2349186318],
            [-35.1364780104, -7.2513319328],
            [-35.1388668741, -7.2164276499],
            [-35.1508949677, -7.2127720616],
            [-35.1550801319, -7.2066081542],
            [-35.158702745, -7.2092614979],
            [-35.1699035933, -7.1967081373],
            [-35.1819345864, -7.1832207123],
            [-35.1862270846, -7.1830047713],
            [-35.1870648431, -7.1769705866],
            [-35.1788895311, -7.1734438843],
            [-35.1723108122, -7.1639105888],
            [-35.1678272856, -7.1601852469],
            [-35.1521106049, -7.1490096926],
            [-35.1427470091, -7.1405916061],
            [-35.1396151468, -7.1317218623],
            [-35.1281799869, -7.099346237],
            [-35.1179180034, -7.0703087411],
            [-35.1376706329, -7.052431289],
            [-35.1457124641, -7.0453251687],
            [-35.1610091686, -7.0318047807],
            [-35.058895778, -7.080692224],
            [-35.05168468, -7.1012184354],
            [-35.0466496059, -7.1155415658],
            [-35.0448365562, -7.1206979703],
            [-35.0473973149, -7.1275833315],
            [-35.0389819753, -7.128799214],
            [-35.0432238442, -7.1362822348],
            [-35.0421137948, -7.1433113533],
            [-35.0539957902, -7.1838529499],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2505006', name: 'Cubati', description: 'Cubati' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.3165200352, -6.7898094501],
            [-36.2860019843, -6.808312525],
            [-36.2748109953, -6.8065007433],
            [-36.2701445085, -6.8102261626],
            [-36.2587932641, -6.8091797692],
            [-36.2611053393, -6.8451221193],
            [-36.2612488989, -6.8454173989],
            [-36.2829766448, -6.890425846],
            [-36.2912874856, -6.9053313305],
            [-36.2988140707, -6.9313308643],
            [-36.308877974, -6.9389490803],
            [-36.3200720014, -6.9391393445],
            [-36.325772359, -6.9385256426],
            [-36.3314477215, -6.9303415096],
            [-36.3624667959, -6.9129171545],
            [-36.3766768, -6.8937366473],
            [-36.382189032, -6.8860096714],
            [-36.3690893429, -6.8728336378],
            [-36.3639343133, -6.8604600263],
            [-36.3701066978, -6.8549554784],
            [-36.3645365086, -6.85038372],
            [-36.3618029482, -6.8405699769],
            [-36.3625185119, -6.8320403426],
            [-36.3685383375, -6.821214742],
            [-36.36514981, -6.8204249622],
            [-36.365005499, -6.8147464333],
            [-36.3583081729, -6.8126625945],
            [-36.3516860415, -6.8106021712],
            [-36.3415493162, -6.8176909699],
            [-36.3387107621, -6.8166983224],
            [-36.3357078111, -6.8225710764],
            [-36.3301618279, -6.8221939118],
            [-36.3163379992, -6.7960324333],
            [-36.3165200352, -6.7898094501],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2505105', name: 'Cuité', description: 'Cuité' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.0734320927, -6.4144719891],
            [-36.0668948133, -6.4146878675],
            [-36.0614075417, -6.4098458453],
            [-36.0509181607, -6.4318030793],
            [-36.036380601, -6.4438651644],
            [-36.0129548172, -6.4546408516],
            [-36.0080072435, -6.464719459],
            [-36.0118789406, -6.4735325045],
            [-36.0070259025, -6.4741216163],
            [-36.0050183791, -6.4806530007],
            [-35.9773175315, -6.4885054017],
            [-35.9719109849, -6.4871380397],
            [-35.9649118896, -6.4839592286],
            [-35.943854269, -6.49260575],
            [-35.870031799, -6.4882365545],
            [-35.852762014, -6.4872133556],
            [-35.8678627976, -6.5453818687],
            [-35.8766194993, -6.5780891165],
            [-35.8781473514, -6.5624484555],
            [-35.9662086688, -6.5843367052],
            [-35.9691768797, -6.5890328509],
            [-35.98829006, -6.6068196931],
            [-36.0004099503, -6.6180996426],
            [-36.0111515767, -6.6215447223],
            [-36.0373303218, -6.638264182],
            [-36.0444210721, -6.6379570843],
            [-36.0453354903, -6.6381871118],
            [-36.064970493, -6.6515162045],
            [-36.1061162124, -6.6676689198],
            [-36.1063410667, -6.6679235253],
            [-36.1392371847, -6.6793886755],
            [-36.1488841043, -6.6770299818],
            [-36.1569122273, -6.6801061235],
            [-36.1724818568, -6.6786550802],
            [-36.1878811725, -6.6746832728],
            [-36.1901956965, -6.6736035472],
            [-36.2118781348, -6.6623274871],
            [-36.2275002548, -6.6468756798],
            [-36.2345188788, -6.6413060253],
            [-36.2427354161, -6.6414568723],
            [-36.234002119, -6.5950240592],
            [-36.2338915802, -6.5944373927],
            [-36.2247690041, -6.5590914764],
            [-36.2247319796, -6.5589658772],
            [-36.1802793388, -6.4695270191],
            [-36.1802138674, -6.4691113096],
            [-36.1741334557, -6.430364842],
            [-36.1434195109, -6.4249248551],
            [-36.1098571897, -6.4287808031],
            [-36.1071426313, -6.4258769543],
            [-36.1027834972, -6.42114757],
            [-36.0982408544, -6.4195248103],
            [-36.0961502428, -6.4198598086],
            [-36.0957734558, -6.4197989208],
            [-36.0864137604, -6.4154364266],
            [-36.0796829069, -6.404789768],
            [-36.0723769964, -6.4100226781],
            [-36.0734320927, -6.4144719891],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2505204', name: 'Cuitegi', description: 'Cuitegi' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4723587131, -6.9227689602],
            [-35.4723656671, -6.9230677367],
            [-35.4790274554, -6.9231441642],
            [-35.5341058468, -6.9285429881],
            [-35.563448683, -6.9076043834],
            [-35.5650222562, -6.8868550154],
            [-35.5650834582, -6.8860482116],
            [-35.5494061954, -6.8832834507],
            [-35.5305884241, -6.8799653368],
            [-35.5038820792, -6.8752560276],
            [-35.5026593322, -6.8806480147],
            [-35.4987415921, -6.8894743887],
            [-35.4933067966, -6.893334317],
            [-35.4822037305, -6.9012168004],
            [-35.482118462, -6.9173206505],
            [-35.4742258841, -6.9172952643],
            [-35.4723587131, -6.9227689602],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2505238',
        name: 'Cuité de Mamanguape',
        description: 'Cuité de Mamanguape',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.2635673667, -6.8510737807],
            [-35.2603175196, -6.8533645899],
            [-35.2542972796, -6.858868517],
            [-35.2412533738, -6.8626702315],
            [-35.2394933615, -6.8706762782],
            [-35.2326405408, -6.8694127166],
            [-35.2270032578, -6.8686522999],
            [-35.223442998, -6.8875472405],
            [-35.2135474719, -6.8931263964],
            [-35.2052293966, -6.9042111163],
            [-35.1935738493, -6.918593639],
            [-35.1774773769, -6.9285183597],
            [-35.1780730989, -6.933170512],
            [-35.1809723542, -6.9420969061],
            [-35.1787575547, -6.9502663483],
            [-35.1839613992, -6.954482677],
            [-35.2070447074, -6.940451521],
            [-35.2192260591, -6.9422155404],
            [-35.2214829231, -6.9459389252],
            [-35.2217832055, -6.946433068],
            [-35.2221062793, -6.9469675911],
            [-35.2437704728, -6.9538057457],
            [-35.2563140901, -6.9525125136],
            [-35.2622604272, -6.9550287569],
            [-35.2628300031, -6.9547657269],
            [-35.2662136561, -6.9525099134],
            [-35.2739439928, -6.9475649024],
            [-35.2774772772, -6.9425788725],
            [-35.287165704, -6.9430160514],
            [-35.2922314479, -6.9252910774],
            [-35.2957394115, -6.9130112626],
            [-35.3020311726, -6.9082488051],
            [-35.3015403047, -6.9033742994],
            [-35.305215094, -6.8960144416],
            [-35.3053186031, -6.8836412385],
            [-35.3157630005, -6.8713660014],
            [-35.3184527943, -6.8604124659],
            [-35.3001012623, -6.8512003731],
            [-35.2943778369, -6.8543474637],
            [-35.2799343993, -6.8538128334],
            [-35.2718450041, -6.8452385568],
            [-35.2635673667, -6.8510737807],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2505279',
        name: 'Curral de Cima',
        description: 'Curral de Cima',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.2192356416, -6.7072982125],
            [-35.2147164135, -6.7159580234],
            [-35.2140503222, -6.7181191814],
            [-35.2206695876, -6.732479167],
            [-35.2200262852, -6.7509035792],
            [-35.2310771111, -6.761307256],
            [-35.2400465846, -6.7515918954],
            [-35.246305449, -6.7507011081],
            [-35.2631701689, -6.7574308748],
            [-35.2742312319, -6.7598123226],
            [-35.2885871534, -6.7524697662],
            [-35.3169976237, -6.762433269],
            [-35.3501693015, -6.7742439805],
            [-35.3503209281, -6.7741644032],
            [-35.3585300692, -6.7569891783],
            [-35.3720513709, -6.755296171],
            [-35.349879664, -6.749002726],
            [-35.3497709774, -6.7488672455],
            [-35.3456603808, -6.7421973534],
            [-35.346949001, -6.7248550409],
            [-35.3343289808, -6.7033008015],
            [-35.3284913324, -6.7019148268],
            [-35.3249366737, -6.7096589552],
            [-35.319795896, -6.7078157668],
            [-35.3125041324, -6.7082317552],
            [-35.3095325299, -6.7137804119],
            [-35.2974366233, -6.717183677],
            [-35.2914200386, -6.7138234697],
            [-35.2849018509, -6.7070567478],
            [-35.2823631367, -6.7080604193],
            [-35.2719695036, -6.706644685],
            [-35.2631062681, -6.7094879295],
            [-35.2533807401, -6.7045948004],
            [-35.2441593267, -6.6999557017],
            [-35.2425578453, -6.6950458875],
            [-35.237414439, -6.6943069982],
            [-35.2325813484, -6.6875294225],
            [-35.2274923723, -6.6880709884],
            [-35.2283966671, -6.6928047601],
            [-35.2230645783, -6.6972212076],
            [-35.2192356416, -6.7072982125],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2505303',
        name: 'Curral Velho',
        description: 'Curral Velho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1536117905, -7.4634621248],
            [-38.143689001, -7.4785386165],
            [-38.0947026328, -7.476746517],
            [-38.0733698752, -7.5277195398],
            [-38.1079920439, -7.5492171087],
            [-38.0999793573, -7.5563997541],
            [-38.1020109176, -7.5689464155],
            [-38.1034158743, -7.5701428439],
            [-38.1051010623, -7.571145174],
            [-38.125119217, -7.5910905171],
            [-38.1351219845, -7.5994844201],
            [-38.1692587476, -7.6127943145],
            [-38.1831551496, -7.6129450785],
            [-38.186098506, -7.6114808413],
            [-38.2280374481, -7.5922682807],
            [-38.2258881398, -7.5856723299],
            [-38.2426134097, -7.581016054],
            [-38.2492011969, -7.5739797636],
            [-38.2473192007, -7.5687795847],
            [-38.2427137955, -7.5478022681],
            [-38.2452179999, -7.5443352215],
            [-38.2320869092, -7.5361588673],
            [-38.234811652, -7.530430309],
            [-38.2315591734, -7.5267426452],
            [-38.2433074001, -7.5175572191],
            [-38.2413617934, -7.5131901597],
            [-38.2463619054, -7.5104632368],
            [-38.2487888967, -7.4951001081],
            [-38.2571087712, -7.4836386167],
            [-38.2291847326, -7.4859324404],
            [-38.2216564797, -7.4865507408],
            [-38.1735262936, -7.4702191461],
            [-38.1536117905, -7.4634621248],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2505352', name: 'Damião', description: 'Damião' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8667267981, -6.6791938818],
            [-35.8741046328, -6.6914095397],
            [-35.8929434917, -6.6906640468],
            [-35.9014165784, -6.6995300359],
            [-35.8985745205, -6.7046158832],
            [-35.9010223985, -6.7059778813],
            [-35.9068514176, -6.7092210179],
            [-35.9108723226, -6.7170832022],
            [-35.9223933137, -6.7199053965],
            [-35.9339020108, -6.7179027564],
            [-35.9420363355, -6.7052021764],
            [-35.9486816722, -6.6981441211],
            [-35.9621856015, -6.6969620567],
            [-35.9712285507, -6.7052707543],
            [-35.9761628893, -6.7054401154],
            [-35.9937852161, -6.6918526189],
            [-35.9982253532, -6.6847198794],
            [-35.9970572019, -6.6796832396],
            [-36.0061827221, -6.6719756482],
            [-36.0081881491, -6.6635453906],
            [-36.0040702742, -6.6533299944],
            [-36.0063793997, -6.6504738838],
            [-35.9944796504, -6.6403239566],
            [-35.9742321919, -6.6048788992],
            [-35.9691768797, -6.5890328509],
            [-35.9662086688, -6.5843367052],
            [-35.8781473514, -6.5624484555],
            [-35.8766194993, -6.5780891165],
            [-35.8752057011, -6.592580223],
            [-35.8719375283, -6.6260151975],
            [-35.8713122626, -6.6324134641],
            [-35.8667267981, -6.6791938818],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2505402', name: 'Desterro', description: 'Desterro' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.0540001807, -7.2526153689],
            [-37.0519186487, -7.2561487271],
            [-37.0407027952, -7.255478543],
            [-37.0405972891, -7.2554652273],
            [-37.0341087403, -7.2546562711],
            [-37.0295301269, -7.2485869644],
            [-37.0145175614, -7.2503422075],
            [-37.0116834468, -7.2830505303],
            [-37.0270364749, -7.2865363647],
            [-37.0287560235, -7.2926987458],
            [-37.0389865259, -7.3293773196],
            [-37.0528446833, -7.3790915725],
            [-37.0690907607, -7.3819771921],
            [-37.0726082329, -7.3678687813],
            [-37.0810568436, -7.3631277223],
            [-37.0852815322, -7.3578498325],
            [-37.0970055915, -7.3535620086],
            [-37.101522571, -7.3562245513],
            [-37.1063631501, -7.3534838606],
            [-37.1212225005, -7.3505078427],
            [-37.1276367753, -7.3435955208],
            [-37.1349735921, -7.3462580763],
            [-37.1399957688, -7.341719078],
            [-37.1446022213, -7.3346390803],
            [-37.1567257325, -7.3378074931],
            [-37.1649995712, -7.3361889937],
            [-37.1677688925, -7.3315557447],
            [-37.1681890462, -7.3195454063],
            [-37.176583938, -7.3091464475],
            [-37.1498704471, -7.2728824078],
            [-37.1468612191, -7.263260365],
            [-37.1454993492, -7.2460732332],
            [-37.123827645, -7.2416232211],
            [-37.0874647234, -7.2371723448],
            [-37.0772933971, -7.2335643675],
            [-37.0731583733, -7.2350424721],
            [-37.0708448693, -7.2438076724],
            [-37.0662573183, -7.2442051307],
            [-37.0626378531, -7.250768887],
            [-37.0540001807, -7.2526153689],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2505501',
        name: 'Vista Serrana',
        description: 'Vista Serrana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.540431456, -6.7237032704],
            [-37.5481882812, -6.7377762875],
            [-37.5426628535, -6.7423786196],
            [-37.539153104, -6.7522373834],
            [-37.5442414728, -6.7654651065],
            [-37.5521660156, -6.7745041251],
            [-37.5558534142, -6.7874162992],
            [-37.5538441245, -6.8000664815],
            [-37.5596610768, -6.8001581522],
            [-37.5756536463, -6.8120982058],
            [-37.5759180931, -6.8064468098],
            [-37.5921130262, -6.8042245013],
            [-37.5991564707, -6.7974336962],
            [-37.6047161817, -6.7297789701],
            [-37.6287039833, -6.7308167165],
            [-37.6384175056, -6.7217321917],
            [-37.556488401, -6.7170075406],
            [-37.5182655528, -6.7120253461],
            [-37.540431456, -6.7237032704],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2505600', name: 'Diamante', description: 'Diamante' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3420224405, -7.3163179754],
            [-38.3345868295, -7.321294732],
            [-38.3265354895, -7.3205036386],
            [-38.3242388235, -7.3257020839],
            [-38.3224883922, -7.3296625022],
            [-38.3219144532, -7.3396001165],
            [-38.3093179231, -7.3420922294],
            [-38.2979988397, -7.3567713791],
            [-38.2923145627, -7.3593130854],
            [-38.2877219303, -7.3567136143],
            [-38.2852149215, -7.359509113],
            [-38.2593090795, -7.3883814017],
            [-38.2443143117, -7.4123013213],
            [-38.246377531, -7.4231078519],
            [-38.2524030584, -7.4501810859],
            [-38.2615539378, -7.4610024507],
            [-38.2564349927, -7.4683659185],
            [-38.2571087712, -7.4836386167],
            [-38.2487888967, -7.4951001081],
            [-38.2463619054, -7.5104632368],
            [-38.2413617934, -7.5131901597],
            [-38.2433074001, -7.5175572191],
            [-38.2315591734, -7.5267426452],
            [-38.234811652, -7.530430309],
            [-38.2320869092, -7.5361588673],
            [-38.2452179999, -7.5443352215],
            [-38.2427137955, -7.5478022681],
            [-38.2473192007, -7.5687795847],
            [-38.2527732142, -7.5744348669],
            [-38.2647317305, -7.5697733753],
            [-38.2679832126, -7.5747764979],
            [-38.2726850396, -7.5726958652],
            [-38.2704160223, -7.5657494448],
            [-38.27628476, -7.5634448433],
            [-38.2791430295, -7.5439871155],
            [-38.2821880342, -7.5419930202],
            [-38.2838377303, -7.5304762565],
            [-38.2937197644, -7.533070519],
            [-38.296221729, -7.5313381775],
            [-38.2954752729, -7.5228164256],
            [-38.3096667432, -7.5184880928],
            [-38.3137153314, -7.50029537],
            [-38.327861788, -7.4865874921],
            [-38.3279811667, -7.4776992884],
            [-38.3224509906, -7.4743601549],
            [-38.3188879201, -7.4571713879],
            [-38.3145462527, -7.4552710657],
            [-38.3405636603, -7.4369735547],
            [-38.3449884796, -7.4374855002],
            [-38.3513132812, -7.4325266118],
            [-38.3521003191, -7.4248705042],
            [-38.3538286983, -7.4228237467],
            [-38.3544712521, -7.420799408],
            [-38.3614342887, -7.4240044291],
            [-38.3649569535, -7.4184608424],
            [-38.3744577366, -7.4264833734],
            [-38.3775494361, -7.4187928821],
            [-38.3978097794, -7.425834783],
            [-38.4008285758, -7.4239664464],
            [-38.4008539143, -7.4159327937],
            [-38.3961151727, -7.4081709164],
            [-38.4071198917, -7.4015416889],
            [-38.410395696, -7.3981856317],
            [-38.4097069067, -7.3910754463],
            [-38.402228818, -7.3749707124],
            [-38.4075903268, -7.370653832],
            [-38.4137524688, -7.3716090304],
            [-38.4139336216, -7.3685001483],
            [-38.4103674859, -7.3652068768],
            [-38.4131366012, -7.3621823476],
            [-38.4061960957, -7.3464264388],
            [-38.4122657718, -7.3472993282],
            [-38.4127133465, -7.3420986264],
            [-38.3683385097, -7.3184132702],
            [-38.3517858439, -7.3166940323],
            [-38.3483866416, -7.3192081595],
            [-38.3420224405, -7.3163179754],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2505709',
        name: 'Dona Inês',
        description: 'Dona Inês',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.6021572213, -6.5439717008],
            [-35.578831041, -6.5523513974],
            [-35.5580406993, -6.5457562321],
            [-35.5681603129, -6.5586045167],
            [-35.5669159424, -6.5664363273],
            [-35.5651432148, -6.570385478],
            [-35.556037889, -6.571811635],
            [-35.5513714167, -6.5759937132],
            [-35.5364694694, -6.6015850399],
            [-35.5453330133, -6.6097934406],
            [-35.5519559594, -6.6089453127],
            [-35.5601784156, -6.5996474439],
            [-35.5709804226, -6.6074383847],
            [-35.5747667855, -6.6067695927],
            [-35.587489231, -6.6165887278],
            [-35.5940967173, -6.6271942607],
            [-35.5975501382, -6.6335333254],
            [-35.6021893096, -6.6366108373],
            [-35.6101056082, -6.6522077219],
            [-35.626286277, -6.6559860009],
            [-35.6418859667, -6.6430808255],
            [-35.6506740947, -6.6438359467],
            [-35.6604831597, -6.6364109262],
            [-35.6701182022, -6.6373089747],
            [-35.6679945076, -6.6446084638],
            [-35.6701879406, -6.6500942502],
            [-35.6789327312, -6.6508030211],
            [-35.6832293516, -6.6589554126],
            [-35.6879148464, -6.6538693405],
            [-35.6975445157, -6.6574443486],
            [-35.7105891907, -6.6537984696],
            [-35.7145976981, -6.6636015674],
            [-35.7216946165, -6.6701954179],
            [-35.7387434675, -6.6733094232],
            [-35.7422800781, -6.6767402686],
            [-35.7538129209, -6.6254213977],
            [-35.7428409004, -6.6124091786],
            [-35.7389829623, -6.6150884953],
            [-35.7345980285, -6.6135337173],
            [-35.7225586762, -6.6109998525],
            [-35.7182399054, -6.6013625549],
            [-35.7068130267, -6.5994578708],
            [-35.6898150536, -6.5907318172],
            [-35.6748399639, -6.582783486],
            [-35.6600125134, -6.5810137943],
            [-35.6568494395, -6.5754023511],
            [-35.639786457, -6.5670703296],
            [-35.6210452079, -6.5658024683],
            [-35.6161458101, -6.5473924229],
            [-35.6124973964, -6.5460154838],
            [-35.6021572213, -6.5439717008],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2505808',
        name: 'Duas Estradas',
        description: 'Duas Estradas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3725695174, -6.7554697613],
            [-35.3741391204, -6.7559962446],
            [-35.3720139955, -6.7476892417],
            [-35.3814281903, -6.7406777788],
            [-35.412167639, -6.7321460513],
            [-35.4166903073, -6.7272356973],
            [-35.4168472421, -6.727065931],
            [-35.4189246705, -6.7257976631],
            [-35.4253248555, -6.7135887044],
            [-35.4336781972, -6.7070797944],
            [-35.4305537754, -6.7065020996],
            [-35.4214782687, -6.6888907082],
            [-35.4182518832, -6.6829597851],
            [-35.4157773475, -6.6740186713],
            [-35.4084134377, -6.6762553749],
            [-35.4069938147, -6.6842790288],
            [-35.4057445636, -6.6913387302],
            [-35.3997595229, -6.7092146827],
            [-35.3792740969, -6.7184589586],
            [-35.3705742995, -6.7230425855],
            [-35.3497709774, -6.7488672455],
            [-35.349879664, -6.749002726],
            [-35.3720513709, -6.755296171],
            [-35.3725695174, -6.7554697613],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2505907', name: 'Emas', description: 'Emas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.7785509419, -7.0189906616],
            [-37.7484765926, -7.0239852681],
            [-37.7053927774, -7.0209093694],
            [-37.676298679, -7.0146294556],
            [-37.6755414859, -7.0288699971],
            [-37.6716319651, -7.1154976013],
            [-37.6680800019, -7.1543894446],
            [-37.704426159, -7.174818905],
            [-37.7408314756, -7.1738291972],
            [-37.7486077248, -7.1609885283],
            [-37.7540990649, -7.1410005949],
            [-37.7761273935, -7.1416296856],
            [-37.7813008268, -7.1385961815],
            [-37.7913361889, -7.1222994835],
            [-37.7991686837, -7.131568376],
            [-37.80553801, -7.1233899177],
            [-37.8239325079, -7.1179697113],
            [-37.8232918848, -7.0940579212],
            [-37.8222790605, -7.0562342395],
            [-37.8212710567, -7.0188431784],
            [-37.7785509419, -7.0189906616],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2506004',
        name: 'Esperança',
        description: 'Esperança',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8177666311, -6.9997037845],
            [-35.8108515367, -7.0101073534],
            [-35.8058053969, -7.0136174928],
            [-35.8042194929, -7.0200922674],
            [-35.7939339363, -7.014027995],
            [-35.7820793341, -7.0185473838],
            [-35.7784744811, -7.0211378541],
            [-35.7791899636, -7.0273504191],
            [-35.7883797878, -7.0293064281],
            [-35.7956605978, -7.0233298805],
            [-35.8088577222, -7.0317905388],
            [-35.8092376283, -7.031893364],
            [-35.8249941009, -7.030205063],
            [-35.8320393219, -7.0291156557],
            [-35.8406942405, -7.0407857583],
            [-35.8552084325, -7.0501824653],
            [-35.855413245, -7.0503150412],
            [-35.8639292864, -7.0568526482],
            [-35.8715462653, -7.0578200281],
            [-35.8809189815, -7.0526957016],
            [-35.8859277498, -7.0629355926],
            [-35.8863646361, -7.0638294656],
            [-35.8953625577, -7.0822269766],
            [-35.8961692932, -7.0838770376],
            [-35.9096326913, -7.0840229498],
            [-35.9219519295, -7.0800058282],
            [-35.9120762893, -7.0705263211],
            [-35.902864589, -7.0555183943],
            [-35.9026486199, -7.0455911067],
            [-35.9037115114, -7.0388651073],
            [-35.9109125459, -7.0325883925],
            [-35.9147589652, -7.0286073583],
            [-35.9270171159, -7.0173276919],
            [-35.9278397228, -7.0172087021],
            [-35.9468113321, -7.0195632534],
            [-35.9528762247, -7.0146885697],
            [-35.95686983, -7.0164563704],
            [-35.9788765697, -6.9842493872],
            [-35.9667095304, -6.9742803013],
            [-35.9646598911, -6.9658256382],
            [-35.9390919282, -6.9475896759],
            [-35.9408579318, -6.9377027872],
            [-35.9408810686, -6.9375751316],
            [-35.9187916893, -6.9319981393],
            [-35.9048885322, -6.9374273093],
            [-35.8983887759, -6.9442309041],
            [-35.8849564962, -6.9510529424],
            [-35.865215401, -6.9541908369],
            [-35.8516675039, -6.9665813116],
            [-35.8327359918, -6.9743049724],
            [-35.8233324527, -6.9795149371],
            [-35.8230935422, -6.9796466533],
            [-35.8145305938, -6.9843895386],
            [-35.8173576268, -6.9873711309],
            [-35.8177666311, -6.9997037845],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2506103', name: 'Fagundes', description: 'Fagundes' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7833399178, -7.3180480209],
            [-35.7689652452, -7.3227834076],
            [-35.7562720287, -7.3261909901],
            [-35.7397980604, -7.3176603858],
            [-35.735733413, -7.3236810894],
            [-35.7447130298, -7.3416529307],
            [-35.7229385633, -7.3328452071],
            [-35.7023535958, -7.3376980664],
            [-35.7129121254, -7.3644945408],
            [-35.7129604536, -7.3646161709],
            [-35.7333008724, -7.4162662402],
            [-35.7543570849, -7.4694066401],
            [-35.7593266181, -7.4775071118],
            [-35.764087052, -7.4769569601],
            [-35.7704171797, -7.4813067054],
            [-35.7734193735, -7.4795539617],
            [-35.7729808547, -7.4736255017],
            [-35.7787993694, -7.4702390175],
            [-35.7863611843, -7.4751783607],
            [-35.7869310679, -7.4722040322],
            [-35.7864579605, -7.4660988883],
            [-35.7910512119, -7.4635376415],
            [-35.7958633799, -7.4710993706],
            [-35.8073914121, -7.4713979313],
            [-35.8090468018, -7.4658049532],
            [-35.8064190727, -7.4635150449],
            [-35.8137741905, -7.4601194128],
            [-35.8207135307, -7.4640196509],
            [-35.8245283087, -7.4599124611],
            [-35.8274127367, -7.4532290658],
            [-35.8371031264, -7.4554057185],
            [-35.8351511408, -7.4502939081],
            [-35.8378663206, -7.4396868759],
            [-35.8317528702, -7.4278844342],
            [-35.8200815151, -7.4217657484],
            [-35.8291014234, -7.4052497947],
            [-35.8365577911, -7.394984522],
            [-35.8400317149, -7.3821614166],
            [-35.8399694218, -7.3817253767],
            [-35.8371654556, -7.3620125287],
            [-35.8377362768, -7.3596259877],
            [-35.836552777, -7.3559518631],
            [-35.8368833621, -7.3409743582],
            [-35.8381266573, -7.3306000184],
            [-35.823613586, -7.3282129875],
            [-35.8085565639, -7.3193422343],
            [-35.7954742491, -7.3208019219],
            [-35.7833399178, -7.3180480209],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2506202',
        name: 'Frei Martinho',
        description: 'Frei Martinho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.394228048, -6.2935762196],
            [-36.3622717272, -6.3095800973],
            [-36.343654977, -6.302136045],
            [-36.347323228, -6.3087785642],
            [-36.3434447348, -6.3139059607],
            [-36.3383587413, -6.3143036225],
            [-36.3356366152, -6.3208495617],
            [-36.3413400092, -6.3280024016],
            [-36.3502146622, -6.3403916899],
            [-36.3521250663, -6.3503467578],
            [-36.3703713474, -6.3482533066],
            [-36.3759386304, -6.3537123719],
            [-36.3674094041, -6.3596656883],
            [-36.3765443724, -6.3778109342],
            [-36.37565316, -6.3847985277],
            [-36.3746927622, -6.3923282062],
            [-36.3901077732, -6.4116228235],
            [-36.3961028186, -6.4055797916],
            [-36.4159575034, -6.4002430016],
            [-36.4224198813, -6.4064415751],
            [-36.4384814327, -6.4313632213],
            [-36.4351859338, -6.4360731991],
            [-36.4198534682, -6.4359254003],
            [-36.4225874552, -6.4414416445],
            [-36.4298505661, -6.4441224414],
            [-36.4393713875, -6.4567622832],
            [-36.4251109667, -6.4597946356],
            [-36.4189817427, -6.4644162641],
            [-36.4201576098, -6.4760614982],
            [-36.4592105399, -6.5180131746],
            [-36.4688563396, -6.5486956071],
            [-36.4789336394, -6.539469035],
            [-36.4822961851, -6.519137704],
            [-36.5189385037, -6.5021499925],
            [-36.5255121331, -6.5007612106],
            [-36.5278762804, -6.47888422],
            [-36.5304905779, -6.4714315941],
            [-36.5306368773, -6.4516718256],
            [-36.5071574456, -6.3997173571],
            [-36.5040431445, -6.3860516598],
            [-36.4995138356, -6.3577658638],
            [-36.4484518583, -6.361551858],
            [-36.4444485317, -6.3617763246],
            [-36.4299723245, -6.3618559382],
            [-36.394228048, -6.2935762196],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2506251',
        name: 'Gado Bravo',
        description: 'Gado Bravo',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8296655006, -7.4937308458],
            [-35.8126789108, -7.5264553963],
            [-35.8062892936, -7.5308935817],
            [-35.7956583177, -7.5350297546],
            [-35.7904156148, -7.5408260905],
            [-35.784540803, -7.5464047692],
            [-35.7579715121, -7.5716239789],
            [-35.7573043008, -7.5722635212],
            [-35.7449358045, -7.5841004391],
            [-35.7460627608, -7.592057073],
            [-35.7337980025, -7.5974204385],
            [-35.729408086, -7.6084125816],
            [-35.7336315, -7.6145837142],
            [-35.7342574555, -7.6214567204],
            [-35.7292137652, -7.6259535523],
            [-35.7332329638, -7.629574894],
            [-35.7243685107, -7.6379674094],
            [-35.7111721689, -7.6415263749],
            [-35.711466608, -7.6415761628],
            [-35.7365295911, -7.6458266595],
            [-35.7496102125, -7.6513641401],
            [-35.7613488265, -7.6477866701],
            [-35.7630836348, -7.6385987625],
            [-35.7744674774, -7.6351091155],
            [-35.7780881198, -7.6429676717],
            [-35.7976886936, -7.6552130285],
            [-35.8076866764, -7.6425710143],
            [-35.811512758, -7.6457667785],
            [-35.813047395, -7.659647125],
            [-35.8169759156, -7.6625112015],
            [-35.8346182253, -7.6606101819],
            [-35.8563801001, -7.6656257633],
            [-35.8655994653, -7.662912509],
            [-35.865724819, -7.6514068667],
            [-35.8684728536, -7.6484687849],
            [-35.8643214139, -7.6424907121],
            [-35.8669173231, -7.636877682],
            [-35.8785931947, -7.6092955612],
            [-35.8719066482, -7.6040036638],
            [-35.8652777282, -7.5892604776],
            [-35.8644232165, -7.5888445183],
            [-35.8505967494, -7.5765988926],
            [-35.8266716292, -7.5734452635],
            [-35.8258161615, -7.5707482795],
            [-35.8285101085, -7.5702803325],
            [-35.8274391898, -7.5628557223],
            [-35.8467442376, -7.5564261866],
            [-35.8573632049, -7.556870214],
            [-35.863845918, -7.549987601],
            [-35.8708497396, -7.5448720118],
            [-35.8794077454, -7.5451317294],
            [-35.8836538192, -7.5401944961],
            [-35.8825618277, -7.5299768794],
            [-35.8734719347, -7.5192737117],
            [-35.8739979242, -7.512163413],
            [-35.8738991884, -7.5119068815],
            [-35.8717967489, -7.5096521049],
            [-35.8714819434, -7.5124498333],
            [-35.8688440537, -7.5080037134],
            [-35.864119382, -7.510146709],
            [-35.8592233041, -7.5038443802],
            [-35.8536228928, -7.5057079417],
            [-35.846963443, -7.4922186945],
            [-35.8359549452, -7.4896841415],
            [-35.8367997651, -7.4683683426],
            [-35.8245283087, -7.4599124611],
            [-35.8314835991, -7.4658849257],
            [-35.8296655006, -7.4937308458],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2506301',
        name: 'Guarabira',
        description: 'Guarabira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4644954662, -6.8116522811],
            [-35.4591752851, -6.8210880368],
            [-35.4298317338, -6.812898309],
            [-35.4279982104, -6.8151542949],
            [-35.4184077548, -6.8132985944],
            [-35.4223016491, -6.8226974127],
            [-35.4174085223, -6.824799853],
            [-35.4188534033, -6.832530967],
            [-35.4155883932, -6.8309062429],
            [-35.4114056578, -6.830316003],
            [-35.4096093457, -6.8350952142],
            [-35.4060902492, -6.8325126089],
            [-35.4034557843, -6.8454209112],
            [-35.4026106961, -6.8476884755],
            [-35.3999196761, -6.8442822052],
            [-35.3981825879, -6.8466897654],
            [-35.3931420948, -6.8454965587],
            [-35.3895420061, -6.8483685735],
            [-35.3917630799, -6.8539149577],
            [-35.3887513431, -6.8562899609],
            [-35.3851145693, -6.8935415875],
            [-35.3843425351, -6.9014393463],
            [-35.3821683747, -6.9236526485],
            [-35.3809905041, -6.9325420428],
            [-35.3932519549, -6.9289984218],
            [-35.4107417968, -6.9306373319],
            [-35.4265303808, -6.9347946492],
            [-35.4276580763, -6.9416863329],
            [-35.4410950145, -6.9441492641],
            [-35.4445818246, -6.9531791775],
            [-35.454310351, -6.9630684374],
            [-35.4599012842, -6.9636597116],
            [-35.4584040906, -6.9571770768],
            [-35.4655591843, -6.9522510224],
            [-35.471653295, -6.9351610244],
            [-35.4723656671, -6.9230677367],
            [-35.4723587131, -6.9227689602],
            [-35.4742258841, -6.9172952643],
            [-35.482118462, -6.9173206505],
            [-35.4822037305, -6.9012168004],
            [-35.4933067966, -6.893334317],
            [-35.4987415921, -6.8894743887],
            [-35.5026593322, -6.8806480147],
            [-35.5038820792, -6.8752560276],
            [-35.5305884241, -6.8799653368],
            [-35.5305931386, -6.8586192009],
            [-35.5200278155, -6.8471012382],
            [-35.5178272765, -6.8447018223],
            [-35.5082118398, -6.8342205411],
            [-35.5079785652, -6.8229801648],
            [-35.5028914718, -6.8192627791],
            [-35.4849719426, -6.8186532766],
            [-35.4644954662, -6.8116522811],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2506400', name: 'Gurinhém', description: 'Gurinhém' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3755374445, -7.0327171403],
            [-35.3752564999, -7.0782265405],
            [-35.3631344677, -7.0779501355],
            [-35.3530674079, -7.103397895],
            [-35.3503068339, -7.1156141242],
            [-35.3568114142, -7.1206282908],
            [-35.3544861702, -7.1279801095],
            [-35.3535004821, -7.1440613479],
            [-35.3578505272, -7.1547830863],
            [-35.3541978244, -7.1660378628],
            [-35.3525257273, -7.167983682],
            [-35.3526280845, -7.1695121014],
            [-35.3543563399, -7.1761281211],
            [-35.3494482766, -7.1938718812],
            [-35.3843716824, -7.20853494],
            [-35.3755350326, -7.2389316688],
            [-35.3979463789, -7.2390382218],
            [-35.404840069, -7.2425657183],
            [-35.4188712705, -7.2393845382],
            [-35.4312422862, -7.2496098269],
            [-35.4352113172, -7.2463686574],
            [-35.4496102245, -7.2545969026],
            [-35.4508031367, -7.2539919781],
            [-35.4486825726, -7.246480189],
            [-35.4556636497, -7.2291093201],
            [-35.4534955358, -7.219713538],
            [-35.4585502897, -7.2161440723],
            [-35.4624757248, -7.2132216447],
            [-35.4650422187, -7.189372969],
            [-35.4577627624, -7.1792338372],
            [-35.4654470457, -7.1768421866],
            [-35.4704411622, -7.1796184375],
            [-35.4789633498, -7.1785078942],
            [-35.4819500013, -7.1892770328],
            [-35.4881140726, -7.191726943],
            [-35.4881331643, -7.1955936635],
            [-35.4917876588, -7.1945553327],
            [-35.5069248121, -7.1786131037],
            [-35.5165399994, -7.1684843327],
            [-35.533686521, -7.1606756133],
            [-35.547386059, -7.157648138],
            [-35.5292987902, -7.1511307618],
            [-35.5218525356, -7.138039539],
            [-35.5329117644, -7.1274962392],
            [-35.5327631394, -7.1200591044],
            [-35.5267819384, -7.1143456721],
            [-35.5257881597, -7.1095910703],
            [-35.5232722771, -7.1049027484],
            [-35.4999671147, -7.0944495536],
            [-35.5070399987, -7.0838598128],
            [-35.504566049, -7.0753756597],
            [-35.5111150783, -7.0708094162],
            [-35.5142762725, -7.0620481716],
            [-35.4908357036, -7.0663608872],
            [-35.4519198292, -7.0735181263],
            [-35.4421618448, -7.0702272179],
            [-35.4221263716, -7.0561389598],
            [-35.4166298375, -7.0432689154],
            [-35.4061490394, -7.0429304617],
            [-35.3909160854, -7.0440907285],
            [-35.3755409588, -7.0321927439],
            [-35.3755374445, -7.0327171403],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2506509', name: 'Gurjão', description: 'Gurjão' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.4181924328, -7.1990529181],
            [-36.4057226107, -7.2178896965],
            [-36.3257731506, -7.1883499305],
            [-36.3433907223, -7.2827421487],
            [-36.3305119826, -7.3071258087],
            [-36.3392207825, -7.3126483041],
            [-36.3485866058, -7.3133453776],
            [-36.3824538093, -7.2983266495],
            [-36.3924944241, -7.302733575],
            [-36.4034284951, -7.2998680238],
            [-36.422591168, -7.3012626841],
            [-36.4335238722, -7.3090016159],
            [-36.4425780874, -7.3095824801],
            [-36.4460495671, -7.3056804309],
            [-36.4778605574, -7.3142310139],
            [-36.4978442789, -7.3300673316],
            [-36.5371892531, -7.3509282287],
            [-36.6009684051, -7.3511344948],
            [-36.6069748172, -7.3458958337],
            [-36.6004201676, -7.3393528498],
            [-36.5838024086, -7.3358371658],
            [-36.575110002, -7.3229763528],
            [-36.5667323504, -7.3232812742],
            [-36.5603133978, -7.2689147394],
            [-36.5738836849, -7.2471314104],
            [-36.5585898775, -7.2449943245],
            [-36.5492800671, -7.2336052307],
            [-36.5261193621, -7.2283429985],
            [-36.5433872645, -7.2183367061],
            [-36.551809326, -7.1892710988],
            [-36.5570507439, -7.1855393537],
            [-36.5677040587, -7.1788669033],
            [-36.5144998731, -7.187053526],
            [-36.486665268, -7.1895118628],
            [-36.4859906411, -7.1895711057],
            [-36.4181924328, -7.1990529181],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2506608', name: 'Ibiara', description: 'Ibiara' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3775494361, -7.4187928821],
            [-38.3744577366, -7.4264833734],
            [-38.3649569535, -7.4184608424],
            [-38.3614342887, -7.4240044291],
            [-38.3544712521, -7.420799408],
            [-38.3538286983, -7.4228237467],
            [-38.3521003191, -7.4248705042],
            [-38.3513132812, -7.4325266118],
            [-38.3449884796, -7.4374855002],
            [-38.3405636603, -7.4369735547],
            [-38.3145462527, -7.4552710657],
            [-38.3188879201, -7.4571713879],
            [-38.3224509906, -7.4743601549],
            [-38.3279811667, -7.4776992884],
            [-38.327861788, -7.4865874921],
            [-38.3137153314, -7.50029537],
            [-38.3096667432, -7.5184880928],
            [-38.3220509524, -7.5272445618],
            [-38.345132663, -7.5339434228],
            [-38.3717792804, -7.5365896959],
            [-38.3839210615, -7.5353138916],
            [-38.3853050383, -7.5395008138],
            [-38.3925459114, -7.547099483],
            [-38.3859736426, -7.5689852015],
            [-38.389934165, -7.5801483132],
            [-38.3883962465, -7.5944307197],
            [-38.4294299274, -7.593930607],
            [-38.4502402026, -7.5936756849],
            [-38.4453253277, -7.5881380498],
            [-38.4459532403, -7.5813995035],
            [-38.4392778804, -7.585334843],
            [-38.4363956579, -7.5833044081],
            [-38.4231271294, -7.5650421902],
            [-38.4238789655, -7.5519523475],
            [-38.4409847079, -7.5422487819],
            [-38.4411782533, -7.5421628956],
            [-38.4707280315, -7.5382054018],
            [-38.4813954212, -7.5296878843],
            [-38.4799328377, -7.5232216048],
            [-38.4752417379, -7.5203390979],
            [-38.4908456681, -7.4403256848],
            [-38.4912759079, -7.4381172962],
            [-38.4760272595, -7.4326660104],
            [-38.4412201347, -7.4291761509],
            [-38.4263151535, -7.4227599497],
            [-38.4068366385, -7.4231034735],
            [-38.4043732814, -7.4255807184],
            [-38.4008285758, -7.4239664464],
            [-38.3978097794, -7.425834783],
            [-38.3775494361, -7.4187928821],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2506707',
        name: 'Imaculada',
        description: 'Imaculada',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.3981183518, -7.3147598143],
            [-37.3927279843, -7.3192174182],
            [-37.3956406892, -7.3333153225],
            [-37.3952803328, -7.3362318014],
            [-37.3853699292, -7.356287545],
            [-37.4077960512, -7.3576979748],
            [-37.412307074, -7.3614672924],
            [-37.4251682012, -7.3525100057],
            [-37.4314777325, -7.3562519878],
            [-37.444411196, -7.3602710339],
            [-37.4655785313, -7.3576374115],
            [-37.4784063999, -7.3595798188],
            [-37.4855498989, -7.3616614336],
            [-37.4976947206, -7.3664363591],
            [-37.4866140289, -7.3946479927],
            [-37.4943284331, -7.3986153474],
            [-37.49663692, -7.4030570739],
            [-37.4991908492, -7.4069574635],
            [-37.5053495314, -7.4187533024],
            [-37.5132947128, -7.4243204817],
            [-37.5138313272, -7.4326275667],
            [-37.521361236, -7.4390715087],
            [-37.5270590282, -7.4479738135],
            [-37.5282456112, -7.4596610929],
            [-37.5276134331, -7.4649036762],
            [-37.5327217022, -7.4725461614],
            [-37.5521919498, -7.4792079238],
            [-37.5714383226, -7.4887060808],
            [-37.5714470389, -7.4886916125],
            [-37.5654147836, -7.4825074645],
            [-37.5736748347, -7.4588626178],
            [-37.5681986374, -7.4558030883],
            [-37.5699295473, -7.4539016767],
            [-37.5703127562, -7.4534823691],
            [-37.5929918014, -7.4285712765],
            [-37.5953212301, -7.4170073756],
            [-37.6085388856, -7.4107184445],
            [-37.6119253302, -7.4110296802],
            [-37.641650307, -7.4119640252],
            [-37.6494786532, -7.4022367778],
            [-37.6719736902, -7.3764651899],
            [-37.6923221715, -7.3789316639],
            [-37.7037601546, -7.3731762414],
            [-37.7065070986, -7.3850485924],
            [-37.7111934552, -7.3811083963],
            [-37.7210160789, -7.381921767],
            [-37.736924775, -7.3782479261],
            [-37.7392637386, -7.3657497199],
            [-37.7238741139, -7.3608623363],
            [-37.7244009293, -7.3449110325],
            [-37.7217626318, -7.3429240729],
            [-37.7153929621, -7.3429072217],
            [-37.7050319397, -7.3520952967],
            [-37.6973962213, -7.3350455786],
            [-37.6825983103, -7.3239010512],
            [-37.6620144171, -7.3292602619],
            [-37.6502909375, -7.328173126],
            [-37.6400991752, -7.3230917924],
            [-37.6248165732, -7.3315852653],
            [-37.6138062857, -7.3299926595],
            [-37.6136458342, -7.3299696474],
            [-37.6076192235, -7.3274341648],
            [-37.5974128664, -7.3321363439],
            [-37.583972056, -7.3315787412],
            [-37.5699933868, -7.316653138],
            [-37.5663692139, -7.3145586522],
            [-37.5511108645, -7.3186772123],
            [-37.5313868452, -7.3205158672],
            [-37.4925635883, -7.3223439964],
            [-37.4768007404, -7.3207221455],
            [-37.4579220509, -7.3127857539],
            [-37.4479382135, -7.3172544293],
            [-37.4449557662, -7.3103143951],
            [-37.3973227272, -7.3075987406],
            [-37.3981183518, -7.3147598143],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2506806', name: 'Ingá', description: 'Ingá' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.6021804492, -7.1958860787],
            [-35.5902984724, -7.1984258549],
            [-35.5755889493, -7.1873311898],
            [-35.571006005, -7.1901300307],
            [-35.5642536832, -7.1881517556],
            [-35.5450596422, -7.2003739385],
            [-35.5395017408, -7.1968126238],
            [-35.529389018, -7.2016452187],
            [-35.5244949032, -7.1993521855],
            [-35.5153865293, -7.2016184779],
            [-35.5153897587, -7.2055617455],
            [-35.5142430481, -7.2128281886],
            [-35.5275198763, -7.2203084777],
            [-35.5214028503, -7.2341722414],
            [-35.5262029088, -7.2363300226],
            [-35.5354009864, -7.2527933422],
            [-35.5375661281, -7.2753919062],
            [-35.5649144187, -7.3031458488],
            [-35.5570342929, -7.3248113602],
            [-35.5476076192, -7.3507486025],
            [-35.5361782025, -7.3872802626],
            [-35.5444367936, -7.3822143055],
            [-35.5503972983, -7.3824899573],
            [-35.5581984653, -7.3699952534],
            [-35.6049092187, -7.3529890995],
            [-35.6173584241, -7.3355500693],
            [-35.6275892968, -7.3357011906],
            [-35.6482364629, -7.341037789],
            [-35.6783522307, -7.3434343852],
            [-35.7023535958, -7.3376980664],
            [-35.7229385633, -7.3328452071],
            [-35.7447130298, -7.3416529307],
            [-35.735733413, -7.3236810894],
            [-35.7264474588, -7.3051011368],
            [-35.70381155, -7.2598254776],
            [-35.6782118855, -7.268795027],
            [-35.6709551322, -7.2657932521],
            [-35.6646285383, -7.258220498],
            [-35.6446435808, -7.2506968483],
            [-35.6354904088, -7.2499203677],
            [-35.6305959784, -7.2537199041],
            [-35.6319869115, -7.2421742803],
            [-35.6291111367, -7.2332814839],
            [-35.6221461712, -7.2332560743],
            [-35.622122951, -7.2292831114],
            [-35.6253780346, -7.225228362],
            [-35.6311135135, -7.2274796036],
            [-35.635683212, -7.222385637],
            [-35.6475020928, -7.2187029909],
            [-35.671350921, -7.207373079],
            [-35.6639576053, -7.1949215706],
            [-35.6638685617, -7.1947528195],
            [-35.6615882523, -7.1904424586],
            [-35.6405378538, -7.183951507],
            [-35.6226601751, -7.1784395725],
            [-35.6075926154, -7.1682756367],
            [-35.6162756384, -7.17818332],
            [-35.6147128135, -7.1836962034],
            [-35.6021804492, -7.1958860787],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2506905',
        name: 'Itabaiana',
        description: 'Itabaiana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3809850608, -7.2521129727],
            [-35.3681519559, -7.264925546],
            [-35.3531869233, -7.2546668254],
            [-35.330246896, -7.2813825712],
            [-35.3219732431, -7.2912872481],
            [-35.3215499681, -7.2917942021],
            [-35.3108750539, -7.3045675881],
            [-35.3084083133, -7.3224833069],
            [-35.2917821694, -7.3208324866],
            [-35.2825335912, -7.3403112099],
            [-35.2713534024, -7.3631141424],
            [-35.2734891697, -7.368743895],
            [-35.2861513324, -7.378298876],
            [-35.2798516509, -7.3872472872],
            [-35.3061922748, -7.4075081267],
            [-35.3158288744, -7.4052816103],
            [-35.3237701341, -7.4109116984],
            [-35.3346923784, -7.4063955793],
            [-35.3325626087, -7.4149243871],
            [-35.3331896699, -7.4223482116],
            [-35.3422950352, -7.4216249083],
            [-35.3435016176, -7.4220651713],
            [-35.3478257329, -7.4218508033],
            [-35.3520256592, -7.4219891545],
            [-35.3482729697, -7.4345323671],
            [-35.3507758645, -7.4398529363],
            [-35.3625798832, -7.4423888444],
            [-35.3686267734, -7.4403937577],
            [-35.3756314353, -7.4484766752],
            [-35.3803626744, -7.4484138764],
            [-35.3826326599, -7.4266875844],
            [-35.3889211984, -7.4262725377],
            [-35.3917955484, -7.4079209333],
            [-35.4033801197, -7.4116420747],
            [-35.4177768428, -7.3687173249],
            [-35.410701897, -7.3453728374],
            [-35.4099956353, -7.3397050439],
            [-35.4018332156, -7.3351864341],
            [-35.4174707299, -7.3224601887],
            [-35.4316940482, -7.3186560184],
            [-35.4003743516, -7.3167401582],
            [-35.4108468904, -7.2992275789],
            [-35.417358808, -7.2761411109],
            [-35.4127901653, -7.2689226877],
            [-35.3969587453, -7.2622832717],
            [-35.3848673505, -7.2595931117],
            [-35.3809850608, -7.2521129727],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2507002',
        name: 'Itaporanga',
        description: 'Itaporanga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1717592648, -7.1605464484],
            [-38.1576800809, -7.188754299],
            [-38.1576332754, -7.1888477593],
            [-38.1557760233, -7.2210504824],
            [-38.1333224672, -7.2232379551],
            [-38.1174310764, -7.224785638],
            [-38.0915126251, -7.2349423394],
            [-38.0903460917, -7.2533933598],
            [-38.0876410757, -7.2871808725],
            [-38.0863106508, -7.3037653722],
            [-38.084716529, -7.3236227654],
            [-38.0525184161, -7.3170607111],
            [-38.056202096, -7.3495620937],
            [-38.0640604503, -7.3507016479],
            [-38.0700586143, -7.3569851652],
            [-38.0800625361, -7.3626026991],
            [-38.1406617197, -7.3966391322],
            [-38.1414209028, -7.396005266],
            [-38.1738034072, -7.3689720298],
            [-38.1740688474, -7.3687509124],
            [-38.2020099354, -7.3683146308],
            [-38.2457402046, -7.3681380792],
            [-38.245151681, -7.3797984985],
            [-38.2593090795, -7.3883814017],
            [-38.2852149215, -7.359509113],
            [-38.2877219303, -7.3567136143],
            [-38.2923145627, -7.3593130854],
            [-38.2979988397, -7.3567713791],
            [-38.3093179231, -7.3420922294],
            [-38.3219144532, -7.3396001165],
            [-38.3224883922, -7.3296625022],
            [-38.3051539282, -7.3118217513],
            [-38.297990235, -7.3094780262],
            [-38.2823219855, -7.3052934456],
            [-38.2735770247, -7.2897907311],
            [-38.2617992317, -7.2898377925],
            [-38.257095538, -7.2857351626],
            [-38.247698366, -7.2708825259],
            [-38.2529642389, -7.2695475221],
            [-38.2686259383, -7.2674918192],
            [-38.2740205151, -7.2628209731],
            [-38.2674324296, -7.2334524118],
            [-38.2728342127, -7.2342654609],
            [-38.2739949313, -7.2018416063],
            [-38.2903483154, -7.1979068735],
            [-38.2912173432, -7.1748459785],
            [-38.2720321164, -7.1774041446],
            [-38.2426435124, -7.1669086937],
            [-38.2300981144, -7.1571372734],
            [-38.1717592648, -7.1605464484],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2507101',
        name: 'Itapororoca',
        description: 'Itapororoca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.2400465846, -6.7515918954],
            [-35.2310771111, -6.761307256],
            [-35.2264825459, -6.7753204455],
            [-35.2198851912, -6.7764218801],
            [-35.2112093748, -6.7847392877],
            [-35.1989679779, -6.8079527002],
            [-35.1981392493, -6.8090164664],
            [-35.165175996, -6.8513208144],
            [-35.1687658849, -6.8499651105],
            [-35.1819013495, -6.8463625309],
            [-35.1872113614, -6.85065109],
            [-35.1937463218, -6.8542062851],
            [-35.1983882431, -6.8510664922],
            [-35.2176124767, -6.8575777725],
            [-35.2164031734, -6.8628996512],
            [-35.2211327095, -6.8657886365],
            [-35.2270032578, -6.8686522999],
            [-35.2326405408, -6.8694127166],
            [-35.2394933615, -6.8706762782],
            [-35.2412533738, -6.8626702315],
            [-35.2542972796, -6.858868517],
            [-35.2603175196, -6.8533645899],
            [-35.2635673667, -6.8510737807],
            [-35.2718450041, -6.8452385568],
            [-35.2799343993, -6.8538128334],
            [-35.2943778369, -6.8543474637],
            [-35.3001012623, -6.8512003731],
            [-35.3056146273, -6.8315462608],
            [-35.3274253104, -6.8244819941],
            [-35.3230058364, -6.7985253596],
            [-35.3157870494, -6.7885565668],
            [-35.320769688, -6.7884897256],
            [-35.3248114867, -6.7794651882],
            [-35.3402101607, -6.7794166633],
            [-35.3501693015, -6.7742439805],
            [-35.3169976237, -6.762433269],
            [-35.2885871534, -6.7524697662],
            [-35.2742312319, -6.7598123226],
            [-35.2631701689, -6.7574308748],
            [-35.246305449, -6.7507011081],
            [-35.2400465846, -6.7515918954],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2507200', name: 'Itatuba', description: 'Itatuba' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7023535958, -7.3376980664],
            [-35.6783522307, -7.3434343852],
            [-35.6482364629, -7.341037789],
            [-35.6275892968, -7.3357011906],
            [-35.6173584241, -7.3355500693],
            [-35.6049092187, -7.3529890995],
            [-35.5581984653, -7.3699952534],
            [-35.5503972983, -7.3824899573],
            [-35.5444367936, -7.3822143055],
            [-35.5361782025, -7.3872802626],
            [-35.5345080777, -7.392665476],
            [-35.5323628789, -7.3925869907],
            [-35.5391009836, -7.3978688339],
            [-35.5412686143, -7.4049702877],
            [-35.5430981307, -7.4096871478],
            [-35.5437406964, -7.4277328066],
            [-35.5510808584, -7.4411685895],
            [-35.5567097497, -7.4440997507],
            [-35.5679566664, -7.4382125223],
            [-35.5836457016, -7.4501839801],
            [-35.5898241886, -7.4471542569],
            [-35.5946530776, -7.4539820037],
            [-35.6007980724, -7.4495653397],
            [-35.6046969888, -7.4504867761],
            [-35.6173865573, -7.4490691975],
            [-35.6235847678, -7.4518019983],
            [-35.6312328757, -7.4495896348],
            [-35.6423765785, -7.4537683145],
            [-35.6616180913, -7.459613278],
            [-35.6669778643, -7.4657139181],
            [-35.6779022624, -7.4599796924],
            [-35.6792268435, -7.4676501389],
            [-35.6862512603, -7.4680714384],
            [-35.686244984, -7.4758585041],
            [-35.6931157741, -7.4769441568],
            [-35.6992335732, -7.4679921393],
            [-35.7074552711, -7.4722201113],
            [-35.7118026112, -7.4700801735],
            [-35.7198196169, -7.4739891824],
            [-35.7218536066, -7.4714329762],
            [-35.7227470086, -7.4653061222],
            [-35.7339521471, -7.4743665432],
            [-35.7368991478, -7.467501623],
            [-35.7467487362, -7.474033408],
            [-35.7543570849, -7.4694066401],
            [-35.7333008724, -7.4162662402],
            [-35.7129604536, -7.3646161709],
            [-35.7129121254, -7.3644945408],
            [-35.7023535958, -7.3376980664],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2507309', name: 'Jacaraú', description: 'Jacaraú' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3023913969, -6.5437519949],
            [-35.2742413332, -6.5434766331],
            [-35.2728951184, -6.5430749799],
            [-35.2528872638, -6.5336092052],
            [-35.2320252902, -6.5313753488],
            [-35.2241063719, -6.5212316514],
            [-35.2231374308, -6.5158310849],
            [-35.2179115907, -6.5182036934],
            [-35.2069870269, -6.5433171579],
            [-35.1991517875, -6.5495427119],
            [-35.1936839619, -6.5499177759],
            [-35.2059715903, -6.5977013877],
            [-35.2060705093, -6.5980880286],
            [-35.1712536625, -6.6304239754],
            [-35.1441040525, -6.6579060703],
            [-35.1549744585, -6.6623899642],
            [-35.1577492741, -6.658155415],
            [-35.1596116609, -6.6616399004],
            [-35.1627793062, -6.6577689544],
            [-35.1720037496, -6.6631887123],
            [-35.1816926262, -6.6619169605],
            [-35.1886950367, -6.6688336867],
            [-35.2030005363, -6.6753556811],
            [-35.2110849132, -6.6722982149],
            [-35.2149076902, -6.6800930985],
            [-35.2262586775, -6.6880039452],
            [-35.2274923723, -6.6880709884],
            [-35.2325813484, -6.6875294225],
            [-35.237414439, -6.6943069982],
            [-35.2425578453, -6.6950458875],
            [-35.2589806345, -6.6644008114],
            [-35.2450948924, -6.6644644659],
            [-35.2462261173, -6.659336492],
            [-35.2591264889, -6.6485463896],
            [-35.2611368518, -6.6413822157],
            [-35.2689111056, -6.6363331684],
            [-35.2873102299, -6.6256300517],
            [-35.2921165827, -6.6274349136],
            [-35.3164777621, -6.624306621],
            [-35.3283793556, -6.6208270533],
            [-35.3263128846, -6.6021893004],
            [-35.330901459, -6.5963144169],
            [-35.3305765906, -6.5886538517],
            [-35.33486625, -6.5918181309],
            [-35.3406233503, -6.5891938315],
            [-35.3474837552, -6.597969111],
            [-35.3538734677, -6.5959525709],
            [-35.361212106, -6.5982911045],
            [-35.3720273459, -6.5981052716],
            [-35.3757220378, -6.6029229204],
            [-35.3893360999, -6.5984806414],
            [-35.3946460684, -6.572971439],
            [-35.3964026473, -6.5624215225],
            [-35.3758872062, -6.5300792564],
            [-35.3641881912, -6.5116399632],
            [-35.3409399209, -6.5382160118],
            [-35.3304488812, -6.5439401603],
            [-35.3023913969, -6.5437519949],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2507408', name: 'Jericó', description: 'Jericó' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.8018521681, -6.5845400775],
            [-37.800180061, -6.5948376312],
            [-37.8310106805, -6.5913816202],
            [-37.8269990083, -6.5831673959],
            [-37.8263647038, -6.5586201257],
            [-37.8371688236, -6.5565758381],
            [-37.8499016393, -6.5551583223],
            [-37.8630047912, -6.5417915311],
            [-37.9037275909, -6.5412614512],
            [-37.8926669995, -6.513041266],
            [-37.8923372055, -6.5122006689],
            [-37.8871924337, -6.4990775878],
            [-37.8638977682, -6.4497927889],
            [-37.8516230965, -6.4442916891],
            [-37.835449388, -6.4398818922],
            [-37.8196051883, -6.447399787],
            [-37.8179748596, -6.447943503],
            [-37.8088285891, -6.4503182364],
            [-37.8013631399, -6.4558669255],
            [-37.7425355625, -6.465090446],
            [-37.7456093941, -6.4775316008],
            [-37.739949216, -6.4812378821],
            [-37.7330441752, -6.487857835],
            [-37.7472446504, -6.4903498074],
            [-37.7508565692, -6.4938626805],
            [-37.7547245908, -6.5261113667],
            [-37.7651901736, -6.5543560602],
            [-37.7836373896, -6.5636915676],
            [-37.7948494908, -6.5641126795],
            [-37.7964327178, -6.5672718153],
            [-37.8018521681, -6.5845400775],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2507507',
        name: 'João Pessoa',
        description: 'João Pessoa',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.8336898577, -7.0979675376],
            [-34.8219732646, -7.1105151812],
            [-34.8220028353, -7.1304092766],
            [-34.8097468927, -7.144802542],
            [-34.7970010802, -7.1463914668],
            [-34.79333473, -7.1540906511],
            [-34.7944765175, -7.1953560049],
            [-34.802689877, -7.2101462231],
            [-34.8052515429, -7.2396909862],
            [-34.8083359748, -7.2430713535],
            [-34.8067780258, -7.2362539869],
            [-34.8178592708, -7.2386084876],
            [-34.8183641584, -7.2338700478],
            [-34.8253321901, -7.2341052028],
            [-34.8225236603, -7.2301510379],
            [-34.8319718511, -7.2281954337],
            [-34.8380007284, -7.2314188766],
            [-34.8384685606, -7.2373379655],
            [-34.8399452425, -7.2340371592],
            [-34.8421193778, -7.2363782382],
            [-34.8464461786, -7.2348193918],
            [-34.8450859734, -7.2319156082],
            [-34.8491117853, -7.2347515519],
            [-34.8529460703, -7.2301731683],
            [-34.8621697544, -7.2298268722],
            [-34.8653357184, -7.2258525978],
            [-34.8695179567, -7.2274340363],
            [-34.8723923097, -7.2206871527],
            [-34.9041714319, -7.2124360908],
            [-34.9115039702, -7.2128047007],
            [-34.9295268927, -7.2255095248],
            [-34.937166662, -7.212797354],
            [-34.9635903005, -7.2140500398],
            [-34.9678984149, -7.2155366241],
            [-34.970247671, -7.1987112693],
            [-34.9734694165, -7.1751786857],
            [-34.9640871422, -7.1675867781],
            [-34.953059896, -7.1688438182],
            [-34.9471638941, -7.1634976617],
            [-34.9341972318, -7.16854739],
            [-34.9270277139, -7.1671251862],
            [-34.9242813628, -7.1705249591],
            [-34.9165217063, -7.1655185032],
            [-34.9110023644, -7.1538401263],
            [-34.9109168662, -7.1486727968],
            [-34.9176351683, -7.1430776274],
            [-34.9188626482, -7.1336801694],
            [-34.9128636822, -7.1282373818],
            [-34.9042792481, -7.1266488189],
            [-34.9019730163, -7.1204521561],
            [-34.8953325615, -7.1222936368],
            [-34.8914455205, -7.1108961033],
            [-34.8952139184, -7.1056655902],
            [-34.8916393853, -7.0950088644],
            [-34.8652184889, -7.0577169754],
            [-34.8573584628, -7.0633797877],
            [-34.8586608293, -7.0672507596],
            [-34.859642406, -7.0746003284],
            [-34.865776887, -7.0804829976],
            [-34.8571858579, -7.0948512135],
            [-34.8450803384, -7.0994328214],
            [-34.8496962444, -7.0616418702],
            [-34.8433577961, -7.0554186969],
            [-34.8380010399, -7.0680049446],
            [-34.8301169563, -7.0730015118],
            [-34.8336898577, -7.0979675376],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2507606',
        name: 'Juarez Távora',
        description: 'Juarez Távora',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5329117644, -7.1274962392],
            [-35.5218525356, -7.138039539],
            [-35.5292987902, -7.1511307618],
            [-35.547386059, -7.157648138],
            [-35.533686521, -7.1606756133],
            [-35.5165399994, -7.1684843327],
            [-35.5069248121, -7.1786131037],
            [-35.4917876588, -7.1945553327],
            [-35.5025180569, -7.1955511157],
            [-35.5065695642, -7.2001737889],
            [-35.5105496941, -7.1984576564],
            [-35.5153865293, -7.2016184779],
            [-35.5244949032, -7.1993521855],
            [-35.529389018, -7.2016452187],
            [-35.5395017408, -7.1968126238],
            [-35.5450596422, -7.2003739385],
            [-35.5642536832, -7.1881517556],
            [-35.571006005, -7.1901300307],
            [-35.5755889493, -7.1873311898],
            [-35.5902984724, -7.1984258549],
            [-35.6021804492, -7.1958860787],
            [-35.6147128135, -7.1836962034],
            [-35.6162756384, -7.17818332],
            [-35.6075926154, -7.1682756367],
            [-35.610437323, -7.1606359563],
            [-35.6202774161, -7.1533823352],
            [-35.6241248241, -7.1324476198],
            [-35.6137465071, -7.1339891913],
            [-35.6134964008, -7.1339246106],
            [-35.5692125639, -7.1223492932],
            [-35.5327631394, -7.1200591044],
            [-35.5329117644, -7.1274962392],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2507705',
        name: 'Juazeirinho',
        description: 'Juazeirinho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.4759791675, -7.0000293945],
            [-36.4881806868, -7.026764672],
            [-36.4876350704, -7.0319114693],
            [-36.4873134452, -7.0349379974],
            [-36.471989516, -7.1216310602],
            [-36.4697124794, -7.1324599567],
            [-36.483351346, -7.1442792945],
            [-36.4881348238, -7.160001829],
            [-36.4859906411, -7.1895711057],
            [-36.486665268, -7.1895118628],
            [-36.5144998731, -7.187053526],
            [-36.5677040587, -7.1788669033],
            [-36.5913075447, -7.1722079728],
            [-36.6129939444, -7.1286287677],
            [-36.636705581, -7.1294098404],
            [-36.6619797524, -7.1302425761],
            [-36.6609848734, -7.1266943809],
            [-36.6613742015, -7.1172234685],
            [-36.6715074288, -7.1003800515],
            [-36.6663937658, -7.0976377413],
            [-36.6690874464, -7.0785698303],
            [-36.657426347, -7.0734110087],
            [-36.6650950793, -7.0706677141],
            [-36.6575714925, -7.0617608863],
            [-36.6575125734, -7.0616912786],
            [-36.6616160307, -7.0555438335],
            [-36.6572724269, -7.0495583879],
            [-36.6756544132, -7.0145498783],
            [-36.6613702958, -7.0135537367],
            [-36.6531828294, -7.0092865813],
            [-36.6349433511, -7.0195714344],
            [-36.6321964302, -7.0138827377],
            [-36.6301869883, -7.0078992061],
            [-36.6181469057, -7.0013613955],
            [-36.6219386995, -6.9961864227],
            [-36.6145676376, -6.9903230443],
            [-36.5992029653, -6.9867615467],
            [-36.5960347258, -6.97878716],
            [-36.5748430082, -6.9776948332],
            [-36.5702500195, -6.9726783568],
            [-36.5745426971, -6.9379531885],
            [-36.5704091104, -6.9335801625],
            [-36.5709905882, -6.9197015012],
            [-36.5559377178, -6.9094403899],
            [-36.5541423481, -6.8999675796],
            [-36.557686872, -6.8887350332],
            [-36.5442626905, -6.899622066],
            [-36.4749798602, -6.9480463692],
            [-36.4731154004, -6.9493492932],
            [-36.4763734139, -6.9938052198],
            [-36.4759791675, -7.0000293945],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2507804',
        name: 'Junco do Seridó',
        description: 'Junco do Seridó',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.7307342798, -6.9757835279],
            [-36.718217172, -6.9823309994],
            [-36.7121030246, -6.9796154154],
            [-36.6993659138, -6.9479373612],
            [-36.6943347122, -6.9340868178],
            [-36.6868817458, -6.9255593032],
            [-36.6545422194, -6.9278345529],
            [-36.6596321698, -6.9342213912],
            [-36.6614171175, -6.9611467975],
            [-36.6668601619, -6.9691924835],
            [-36.6714516749, -6.9715045604],
            [-36.6858302363, -6.9688963226],
            [-36.6958804382, -6.9762406588],
            [-36.7014769184, -6.985619415],
            [-36.6948405231, -7.000951983],
            [-36.6861741943, -7.0091326776],
            [-36.7028246234, -7.0069363064],
            [-36.7252507716, -7.0034555753],
            [-36.7302520276, -7.0187920609],
            [-36.7418167384, -7.024889044],
            [-36.7700452387, -7.0375454911],
            [-36.7810521521, -7.0340161777],
            [-36.7870162876, -7.0360648092],
            [-36.8041423658, -7.0303931209],
            [-36.820459389, -7.0482899073],
            [-36.8580764049, -7.064141345],
            [-36.8663523082, -7.0606273657],
            [-36.8662382862, -7.0124005559],
            [-36.7913148179, -6.9323211173],
            [-36.7744540822, -6.9145296229],
            [-36.7596611444, -6.9141366298],
            [-36.7662068129, -6.9354642212],
            [-36.755465496, -6.953863072],
            [-36.7370386653, -6.9724049134],
            [-36.7307342798, -6.9757835279],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2507903',
        name: 'Juripiranga',
        description: 'Juripiranga',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.220026282, -7.2996978082],
            [-35.2169310509, -7.3042868345],
            [-35.2113816701, -7.303688494],
            [-35.1931469328, -7.3107339034],
            [-35.1964958719, -7.317889407],
            [-35.1844547286, -7.3375969071],
            [-35.1812707109, -7.3514196384],
            [-35.1707181877, -7.3514371201],
            [-35.1507219201, -7.3716887137],
            [-35.1563834118, -7.3771285728],
            [-35.1534658103, -7.3920485206],
            [-35.1717399301, -7.3876320822],
            [-35.1804800441, -7.3954934978],
            [-35.1894202281, -7.3968544756],
            [-35.1904180291, -7.3974829975],
            [-35.1997789365, -7.3871970046],
            [-35.2242500389, -7.3788665072],
            [-35.2289873736, -7.377961646],
            [-35.2340482495, -7.3777854465],
            [-35.2457406639, -7.3741987619],
            [-35.2798516509, -7.3872472872],
            [-35.2861513324, -7.378298876],
            [-35.2734891697, -7.368743895],
            [-35.2713534024, -7.3631141424],
            [-35.269851848, -7.3549638186],
            [-35.2569370467, -7.3459199597],
            [-35.2550285237, -7.3392213431],
            [-35.25148968, -7.3388025715],
            [-35.2498744721, -7.3340959383],
            [-35.2489005825, -7.3362574438],
            [-35.2450329395, -7.3337639659],
            [-35.2466353427, -7.3300254791],
            [-35.2400783296, -7.3264631209],
            [-35.2381929166, -7.3141941098],
            [-35.2286483268, -7.3055181821],
            [-35.2284630662, -7.3019610482],
            [-35.2238691308, -7.3033108216],
            [-35.220026282, -7.2996978082],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2508000', name: 'Juru', description: 'Juru' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.7269126683, -7.3937490633],
            [-37.7243169838, -7.4087413043],
            [-37.7264611165, -7.4318823705],
            [-37.7109791446, -7.4362924428],
            [-37.7005479159, -7.4616872929],
            [-37.7052942352, -7.4759948791],
            [-37.7270117305, -7.5414996609],
            [-37.7320730976, -7.5448793626],
            [-37.7494232327, -7.556327126],
            [-37.7494153443, -7.5662349877],
            [-37.7346032968, -7.5968071907],
            [-37.7411058488, -7.5971249627],
            [-37.7437539213, -7.6036031881],
            [-37.7453593059, -7.608923145],
            [-37.7555004732, -7.6191138055],
            [-37.757631389, -7.6337117777],
            [-37.7736496442, -7.6255099642],
            [-37.780496601, -7.6151178646],
            [-37.7970282451, -7.6145545413],
            [-37.7971836108, -7.6145470755],
            [-37.7972868733, -7.6096103416],
            [-37.8022728621, -7.6104816005],
            [-37.8121163065, -7.6058119314],
            [-37.8167328636, -7.5968097424],
            [-37.8354681665, -7.5877101109],
            [-37.8406224645, -7.5848483542],
            [-37.8409393969, -7.5847891694],
            [-37.8445504882, -7.5855903685],
            [-37.8470593878, -7.5792480511],
            [-37.8635716301, -7.5806881996],
            [-37.8613827654, -7.5657026897],
            [-37.8637539016, -7.5611395533],
            [-37.8973101608, -7.5614788166],
            [-37.899571148, -7.5485362658],
            [-37.8946158288, -7.5382420037],
            [-37.898057918, -7.5362035088],
            [-37.9006696509, -7.5292764513],
            [-37.9209521498, -7.4754366951],
            [-37.8858582682, -7.4441467492],
            [-37.8634117972, -7.4366965144],
            [-37.8551239561, -7.4335506087],
            [-37.8461813229, -7.42661931],
            [-37.839429864, -7.4173102225],
            [-37.8201887696, -7.4121843893],
            [-37.8039841467, -7.3983097272],
            [-37.7920411795, -7.3969842223],
            [-37.7837055309, -7.3898815875],
            [-37.7691519928, -7.3976797663],
            [-37.7659962398, -7.4001093909],
            [-37.7567201615, -7.3920908263],
            [-37.7451858402, -7.3949748869],
            [-37.7201185461, -7.3844369027],
            [-37.7269126683, -7.3937490633],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2508109', name: 'Lagoa', description: 'Lagoa' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.7857755744, -6.6058954609],
            [-37.7891902288, -6.6171704262],
            [-37.7821703886, -6.6369302678],
            [-37.7844809225, -6.6425682055],
            [-37.8109421038, -6.6414769147],
            [-37.8449534199, -6.6400734772],
            [-37.8450313564, -6.6400698297],
            [-37.8618027351, -6.6393762957],
            [-37.877170239, -6.6263582466],
            [-37.8907923028, -6.6315038545],
            [-37.906033016, -6.6163440095],
            [-37.9255764112, -6.6224369159],
            [-37.9440893971, -6.6259180684],
            [-37.9530729841, -6.6222625674],
            [-37.9602182554, -6.6275502507],
            [-37.9661569795, -6.6136247649],
            [-37.9621115288, -6.6092017123],
            [-37.9629601381, -6.6048782766],
            [-37.9732946658, -6.5929315753],
            [-37.9686994417, -6.5780739884],
            [-37.9619695652, -6.5694603019],
            [-37.9795230429, -6.5312334182],
            [-37.9323799879, -6.528823832],
            [-37.9321044055, -6.5288693534],
            [-37.9160617593, -6.5326948648],
            [-37.9037275909, -6.5412614512],
            [-37.8630047912, -6.5417915311],
            [-37.8499016393, -6.5551583223],
            [-37.8371688236, -6.5565758381],
            [-37.8263647038, -6.5586201257],
            [-37.8269990083, -6.5831673959],
            [-37.8310106805, -6.5913816202],
            [-37.800180061, -6.5948376312],
            [-37.7877686257, -6.5961751784],
            [-37.7857755744, -6.6058954609],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2508208',
        name: 'Lagoa de Dentro',
        description: 'Lagoa de Dentro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3283793556, -6.6208270533],
            [-35.3348403942, -6.637027606],
            [-35.335171684, -6.6378612093],
            [-35.3403613873, -6.6508780986],
            [-35.333312386, -6.6520806445],
            [-35.3224534319, -6.6664516653],
            [-35.3192155616, -6.6761310594],
            [-35.319795896, -6.7078157668],
            [-35.3249366737, -6.7096589552],
            [-35.3284913324, -6.7019148268],
            [-35.3343289808, -6.7033008015],
            [-35.346949001, -6.7248550409],
            [-35.3456603808, -6.7421973534],
            [-35.3497709774, -6.7488672455],
            [-35.3705742995, -6.7230425855],
            [-35.3792740969, -6.7184589586],
            [-35.3997595229, -6.7092146827],
            [-35.4057445636, -6.6913387302],
            [-35.4069938147, -6.6842790288],
            [-35.4084134377, -6.6762553749],
            [-35.4157773475, -6.6740186713],
            [-35.4147177626, -6.6731551537],
            [-35.4024025167, -6.6592702968],
            [-35.3927431284, -6.662081937],
            [-35.3909011546, -6.649180426],
            [-35.3787320307, -6.6476387712],
            [-35.3778521495, -6.6432766565],
            [-35.3747201523, -6.6277554131],
            [-35.3592583259, -6.6316818946],
            [-35.3573484123, -6.6160012675],
            [-35.3566794031, -6.612954001],
            [-35.347462953, -6.6203562615],
            [-35.3283793556, -6.6208270533],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2508307',
        name: 'Lagoa Seca',
        description: 'Lagoa Seca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8682696262, -7.1120856276],
            [-35.8634035617, -7.1195732822],
            [-35.8520428902, -7.1166549202],
            [-35.8454118246, -7.1201008954],
            [-35.8417343861, -7.1156921751],
            [-35.8392064205, -7.1236348846],
            [-35.8325125484, -7.1238075921],
            [-35.8296197465, -7.1256791974],
            [-35.8263423971, -7.1339405126],
            [-35.8187625969, -7.1376365252],
            [-35.8123383045, -7.1263082347],
            [-35.8063130089, -7.1314551121],
            [-35.8061454661, -7.1314091807],
            [-35.8009040663, -7.1286425387],
            [-35.7939225285, -7.1329067103],
            [-35.7868409928, -7.1332052004],
            [-35.7829609167, -7.1367899231],
            [-35.7890209744, -7.1428846581],
            [-35.7857713479, -7.1506475422],
            [-35.8021672956, -7.15554378],
            [-35.8091462028, -7.1616991159],
            [-35.824860246, -7.1744218557],
            [-35.8226456087, -7.1869648477],
            [-35.8339519275, -7.1945058565],
            [-35.8408048611, -7.195475124],
            [-35.8434457305, -7.1919560932],
            [-35.8518044397, -7.2023044674],
            [-35.8648663573, -7.2108115888],
            [-35.865665277, -7.2018661132],
            [-35.8725316835, -7.1936986535],
            [-35.8729884177, -7.1868875538],
            [-35.8843048382, -7.1737426906],
            [-35.8876292735, -7.1563973744],
            [-35.8952808022, -7.1678607438],
            [-35.9042989072, -7.1813733843],
            [-35.9047624231, -7.1815382629],
            [-35.9103245481, -7.1655762148],
            [-35.9204918762, -7.1557642908],
            [-35.9218183474, -7.1497894529],
            [-35.9266439409, -7.1434692446],
            [-35.9283277966, -7.1409553214],
            [-35.9331995482, -7.1358308006],
            [-35.9295652121, -7.1200453755],
            [-35.929741536, -7.107318775],
            [-35.9286409212, -7.1067785495],
            [-35.9211695375, -7.1023667312],
            [-35.8961692932, -7.0838770376],
            [-35.8890149214, -7.0881397812],
            [-35.8739471502, -7.1064038104],
            [-35.8712090189, -7.1091433396],
            [-35.8682696262, -7.1120856276],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2508406', name: 'Lastro', description: 'Lastro' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1919148038, -6.4979298571],
            [-38.1728381575, -6.4994333259],
            [-38.1608237795, -6.4987146039],
            [-38.147533207, -6.49108861],
            [-38.1351687002, -6.5180769472],
            [-38.1198631646, -6.5219815876],
            [-38.1253320508, -6.5326275759],
            [-38.1400723057, -6.540159698],
            [-38.1519238424, -6.5531007124],
            [-38.1525210494, -6.5576771809],
            [-38.1525934268, -6.5582354452],
            [-38.1547215685, -6.5745386232],
            [-38.1421153741, -6.581063303],
            [-38.1270231097, -6.5968591165],
            [-38.1453989509, -6.6049795887],
            [-38.1632776405, -6.6218183414],
            [-38.180295819, -6.5977949099],
            [-38.185283609, -6.5977643392],
            [-38.2459486396, -6.5973913205],
            [-38.2241860098, -6.579986664],
            [-38.2118340963, -6.5551959105],
            [-38.1950826103, -6.5542561658],
            [-38.1966634342, -6.5504588883],
            [-38.2267698705, -6.4835489209],
            [-38.2002022361, -6.4878804807],
            [-38.1919148038, -6.4979298571],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2508505',
        name: 'Livramento',
        description: 'Livramento',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.835976786, -7.3264153406],
            [-36.80140651, -7.3303511791],
            [-36.797075387, -7.3207327327],
            [-36.7978315494, -7.3098349607],
            [-36.7873606441, -7.310723031],
            [-36.7737149086, -7.3005635998],
            [-36.7739203849, -7.3712603385],
            [-36.8047968998, -7.3690881591],
            [-36.8365211552, -7.3713569198],
            [-36.8600993978, -7.367451295],
            [-36.8648050259, -7.3741712209],
            [-36.8762461947, -7.3762011916],
            [-36.8839761465, -7.3838265527],
            [-36.8966167447, -7.3826743079],
            [-36.9030506743, -7.386338882],
            [-36.9188476063, -7.3857118535],
            [-36.9317673545, -7.3811155208],
            [-36.9562877592, -7.3831709541],
            [-36.9796791778, -7.3887173253],
            [-37.0133617965, -7.4033856454],
            [-37.0275840026, -7.3854576273],
            [-37.0496464247, -7.3771817155],
            [-37.0528446833, -7.3790915725],
            [-37.0389865259, -7.3293773196],
            [-37.0287560235, -7.2926987458],
            [-37.0270364749, -7.2865363647],
            [-37.0116834468, -7.2830505303],
            [-37.0145175614, -7.2503422075],
            [-36.9796949953, -7.2795985559],
            [-36.9586680973, -7.2864326839],
            [-36.9551574165, -7.2875732792],
            [-36.872902964, -7.314280148],
            [-36.8702591684, -7.315137924],
            [-36.835976786, -7.3264153406],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2508554',
        name: 'Logradouro',
        description: 'Logradouro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4292012231, -6.6165458692],
            [-35.44389329, -6.6297515639],
            [-35.4476489289, -6.6281356769],
            [-35.4538414293, -6.6118804234],
            [-35.4480963711, -6.6034751179],
            [-35.4434505922, -6.5760307636],
            [-35.4492202027, -6.5706687302],
            [-35.435344936, -6.5600187945],
            [-35.4322112379, -6.5355236539],
            [-35.4345964781, -6.5318654972],
            [-35.429546491, -6.5288445585],
            [-35.4273138075, -6.5089358138],
            [-35.4403000751, -6.5001452565],
            [-35.4417744918, -6.4861914155],
            [-35.4236463952, -6.4882536239],
            [-35.4074337763, -6.4891830007],
            [-35.4062627167, -6.5299336249],
            [-35.4232752843, -6.5642031555],
            [-35.4246863709, -6.5776432669],
            [-35.4292012231, -6.6165458692],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2508604', name: 'Lucena', description: 'Lucena' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.8667118257, -6.9364438295],
            [-34.8610784906, -6.9521754621],
            [-34.8504633237, -6.9641941453],
            [-34.854775311, -6.9717468233],
            [-34.8626864581, -6.9757517613],
            [-34.8685273211, -6.9789793055],
            [-34.8704832952, -6.9657512805],
            [-34.8817135913, -6.9642480788],
            [-34.878820003, -6.959113416],
            [-34.8823218894, -6.9555821714],
            [-34.8905722608, -6.9594966235],
            [-34.8984549541, -6.9568900199],
            [-34.8992138625, -6.9538871626],
            [-34.9020495466, -6.9551612061],
            [-34.9054617803, -6.9501729073],
            [-34.9073096191, -6.9537996006],
            [-34.91063714, -6.9493396588],
            [-34.9215680344, -6.9553867255],
            [-34.9338721931, -6.9466813421],
            [-34.9696978606, -6.9213219298],
            [-34.9888238663, -6.9077766204],
            [-34.9751948113, -6.9015121466],
            [-34.9633450515, -6.9000205421],
            [-34.9496671698, -6.8920340495],
            [-34.9344959138, -6.8898276675],
            [-34.9276902208, -6.8834609869],
            [-34.9169775441, -6.8718349547],
            [-34.9024413113, -6.8648635938],
            [-34.8876946578, -6.8835476799],
            [-34.8684146357, -6.8974135738],
            [-34.8532977893, -6.8999481542],
            [-34.8626930678, -6.9155344995],
            [-34.862947039, -6.9167609645],
            [-34.8667118257, -6.9364438295],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2508703',
        name: "Mãe d'Água",
        description: "Mãe d'Água",
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.3653402675, -7.1738645612],
            [-37.352799848, -7.1852848816],
            [-37.3526767517, -7.1853544126],
            [-37.3396978626, -7.197413423],
            [-37.3344627599, -7.2076731936],
            [-37.3352139355, -7.2139851058],
            [-37.3220282253, -7.2142299928],
            [-37.324982655, -7.2250589048],
            [-37.3340996081, -7.2277956129],
            [-37.3456348057, -7.2296940467],
            [-37.3558138431, -7.2401735209],
            [-37.3567770158, -7.2409907379],
            [-37.3707593715, -7.2414840934],
            [-37.3871819733, -7.2539300735],
            [-37.3992549603, -7.2572283729],
            [-37.405104549, -7.2642818232],
            [-37.4077274779, -7.2691441705],
            [-37.3943633237, -7.2770414656],
            [-37.3859101508, -7.2906247916],
            [-37.3805715026, -7.2955300136],
            [-37.3973227272, -7.3075987406],
            [-37.4449557662, -7.3103143951],
            [-37.4479382135, -7.3172544293],
            [-37.4579220509, -7.3127857539],
            [-37.4768007404, -7.3207221455],
            [-37.4925635883, -7.3223439964],
            [-37.5313868452, -7.3205158672],
            [-37.5511108645, -7.3186772123],
            [-37.5663692139, -7.3145586522],
            [-37.5587886035, -7.3100486769],
            [-37.5470831778, -7.2916548825],
            [-37.522422777, -7.2765715058],
            [-37.5230948324, -7.2668085855],
            [-37.514420428, -7.2624762338],
            [-37.506720718, -7.2587830192],
            [-37.5113626202, -7.2494599226],
            [-37.4974469368, -7.2376009423],
            [-37.494061602, -7.2270488943],
            [-37.4940820695, -7.2268888268],
            [-37.4940029647, -7.2267748339],
            [-37.4800658461, -7.2066823465],
            [-37.4721705274, -7.206861865],
            [-37.471546585, -7.1968747558],
            [-37.4623525175, -7.1947102167],
            [-37.4513901878, -7.1897907717],
            [-37.4461660118, -7.1813969943],
            [-37.4369276082, -7.185277566],
            [-37.4303665796, -7.1809943431],
            [-37.4262139211, -7.1754603533],
            [-37.4246130023, -7.1622750313],
            [-37.387432382, -7.1635122492],
            [-37.3659026546, -7.1651275432],
            [-37.3653402675, -7.1738645612],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2508802', name: 'Malta', description: 'Malta' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.5186688507, -6.7698616036],
            [-37.5062285859, -6.7802728365],
            [-37.5059982893, -6.8100820398],
            [-37.5091144188, -6.8143737897],
            [-37.5264197648, -6.8206424582],
            [-37.5259165156, -6.8290406047],
            [-37.5170588232, -6.8366321207],
            [-37.5088000223, -6.8527070259],
            [-37.4993864482, -6.8613263442],
            [-37.5003099485, -6.865722749],
            [-37.5085525403, -6.8672605045],
            [-37.503512612, -6.8713034536],
            [-37.4940803636, -6.8852305684],
            [-37.4966278623, -6.8997491308],
            [-37.4846639202, -6.9124857342],
            [-37.4826362263, -6.9152144651],
            [-37.4720344873, -6.9294804137],
            [-37.459334573, -6.9309317985],
            [-37.4654323471, -6.9498453783],
            [-37.4522079523, -6.9564664089],
            [-37.4518919264, -6.9594854182],
            [-37.4593365687, -6.9658818051],
            [-37.4706242912, -6.9687565801],
            [-37.4829083975, -6.9696123845],
            [-37.4920752288, -6.9569512088],
            [-37.5090330092, -6.9601341633],
            [-37.5105905362, -6.9679523793],
            [-37.5216483057, -6.9723730706],
            [-37.5237104102, -6.9872044237],
            [-37.5154858582, -6.991012891],
            [-37.5178648656, -7.0000299677],
            [-37.5192680045, -7.0001441186],
            [-37.5448441539, -6.9689553545],
            [-37.5383106982, -6.9431799852],
            [-37.5429192502, -6.933721183],
            [-37.5524034257, -6.9244222969],
            [-37.5553380353, -6.9080119755],
            [-37.556048749, -6.9050018684],
            [-37.5561361176, -6.904631857],
            [-37.5645488182, -6.8945392063],
            [-37.5756536463, -6.8120982058],
            [-37.5596610768, -6.8001581522],
            [-37.5538441245, -6.8000664815],
            [-37.5558534142, -6.7874162992],
            [-37.5521660156, -6.7745041251],
            [-37.5410851221, -6.7780017041],
            [-37.5186688507, -6.7698616036],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2508901',
        name: 'Mamanguape',
        description: 'Mamanguape',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.1193004782, -6.5447093334],
            [-35.1335274437, -6.5573565168],
            [-35.1310178498, -6.5650725756],
            [-35.1362367663, -6.5762488494],
            [-35.1330112034, -6.5838773506],
            [-35.1281016563, -6.5880519271],
            [-35.1034490526, -6.5898224132],
            [-35.1040502316, -6.6064242536],
            [-35.0949373323, -6.6137861215],
            [-35.0877978778, -6.6253809707],
            [-35.0939650561, -6.6273800054],
            [-35.1058888185, -6.640720454],
            [-35.1214030756, -6.6479367789],
            [-35.1215906312, -6.6506773487],
            [-35.1155625209, -6.6660927575],
            [-35.113085508, -6.6750735628],
            [-35.1178310241, -6.7072399654],
            [-35.1200102042, -6.7220241781],
            [-35.1341681549, -6.7477249374],
            [-35.1341806945, -6.7480216456],
            [-35.1341630971, -6.7481669766],
            [-35.1339457026, -6.7660197711],
            [-35.1320339184, -6.7765412887],
            [-35.1130650837, -6.8211683483],
            [-35.109485376, -6.8243976378],
            [-35.1058709568, -6.8245131189],
            [-35.0904759719, -6.8250025378],
            [-35.0878533181, -6.8250860893],
            [-35.0879191659, -6.8309873199],
            [-35.0917245953, -6.8331869243],
            [-35.0885566358, -6.8382088024],
            [-35.091699832, -6.8433260368],
            [-35.0926927185, -6.8471303127],
            [-35.0975385192, -6.8525002678],
            [-35.1005501709, -6.8649735928],
            [-35.0989079727, -6.8856148685],
            [-35.1033318962, -6.8895749652],
            [-35.1195961637, -6.8930763377],
            [-35.1242020948, -6.8953168774],
            [-35.1242766599, -6.8953754905],
            [-35.1296403205, -6.8982834256],
            [-35.1592048936, -6.8931064019],
            [-35.1577388918, -6.8843831806],
            [-35.1667422481, -6.8563330886],
            [-35.165175996, -6.8513208144],
            [-35.1981392493, -6.8090164664],
            [-35.1989679779, -6.8079527002],
            [-35.2112093748, -6.7847392877],
            [-35.2198851912, -6.7764218801],
            [-35.2264825459, -6.7753204455],
            [-35.2310771111, -6.761307256],
            [-35.2200262852, -6.7509035792],
            [-35.2206695876, -6.732479167],
            [-35.2140503222, -6.7181191814],
            [-35.2147164135, -6.7159580234],
            [-35.2192356416, -6.7072982125],
            [-35.2230645783, -6.6972212076],
            [-35.2283966671, -6.6928047601],
            [-35.2274923723, -6.6880709884],
            [-35.2262586775, -6.6880039452],
            [-35.2149076902, -6.6800930985],
            [-35.2110849132, -6.6722982149],
            [-35.2030005363, -6.6753556811],
            [-35.1886950367, -6.6688336867],
            [-35.1816926262, -6.6619169605],
            [-35.1720037496, -6.6631887123],
            [-35.1627793062, -6.6577689544],
            [-35.1596116609, -6.6616399004],
            [-35.1577492741, -6.658155415],
            [-35.1549744585, -6.6623899642],
            [-35.1441040525, -6.6579060703],
            [-35.1712536625, -6.6304239754],
            [-35.2060705093, -6.5980880286],
            [-35.2059715903, -6.5977013877],
            [-35.1936839619, -6.5499177759],
            [-35.1699763136, -6.5579911734],
            [-35.1571624997, -6.5513068281],
            [-35.1454847753, -6.5523581575],
            [-35.1323084646, -6.5353607962],
            [-35.1231315526, -6.5294594986],
            [-35.1122276018, -6.5047164232],
            [-35.1119101216, -6.5074489589],
            [-35.1193004782, -6.5447093334],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509008', name: 'Manaíra', description: 'Manaíra' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.122163617, -7.597336796],
            [-38.132770289, -7.62502829],
            [-38.1103754889, -7.6613806697],
            [-38.1253242824, -7.6737133704],
            [-38.1275616852, -7.6798672565],
            [-38.1213598764, -7.6853108756],
            [-38.1137647431, -7.6867783124],
            [-38.1203549995, -7.7077654004],
            [-38.1290776679, -7.7252620382],
            [-38.1255809846, -7.7298965829],
            [-38.1283514656, -7.735242087],
            [-38.1155265812, -7.737103554],
            [-38.1195959276, -7.7558766542],
            [-38.1169676973, -7.762337485],
            [-38.1247923498, -7.7707228951],
            [-38.1309928945, -7.7950256972],
            [-38.1310756476, -7.7953495031],
            [-38.1434129766, -7.7829963822],
            [-38.1620533268, -7.7795522981],
            [-38.1623221675, -7.779544532],
            [-38.1836393172, -7.7951400897],
            [-38.2055119973, -7.8072780841],
            [-38.2215721369, -7.819979007],
            [-38.2351135908, -7.825549097],
            [-38.2382190051, -7.8303168641],
            [-38.2566859228, -7.8306550544],
            [-38.2645924476, -7.8275672956],
            [-38.2758755998, -7.8334105407],
            [-38.2864410251, -7.8298150076],
            [-38.2973943375, -7.8135378602],
            [-38.3022224923, -7.7879899334],
            [-38.2951846281, -7.7791791187],
            [-38.3009001826, -7.7682951734],
            [-38.2994650711, -7.7627869272],
            [-38.3164075376, -7.7641988111],
            [-38.3018042735, -7.7447957791],
            [-38.3105256077, -7.738669285],
            [-38.2982072102, -7.7255164752],
            [-38.283721122, -7.7027374275],
            [-38.2715096689, -7.6970225719],
            [-38.2561501487, -7.6802368337],
            [-38.2441194243, -7.667459546],
            [-38.2465204864, -7.6611466663],
            [-38.2413884771, -7.654585629],
            [-38.2293522497, -7.6464995419],
            [-38.1993686979, -7.6427860305],
            [-38.1792141017, -7.6402897131],
            [-38.1831551496, -7.6129450785],
            [-38.1692587476, -7.6127943145],
            [-38.1351219845, -7.5994844201],
            [-38.125119217, -7.5910905171],
            [-38.1051010623, -7.571145174],
            [-38.122163617, -7.597336796],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509057', name: 'Marcação', description: 'Marcação' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.9328801662, -6.7563641639],
            [-34.9316514333, -6.766036135],
            [-34.9369559293, -6.7703618227],
            [-34.9503611504, -6.764885234],
            [-34.9538567393, -6.7796785576],
            [-34.9561393757, -6.7830260042],
            [-34.9719739355, -6.793008084],
            [-34.9808446011, -6.7842813944],
            [-34.9930275762, -6.7851633109],
            [-34.995967617, -6.7871543167],
            [-34.9960892389, -6.7946231792],
            [-35.0011045304, -6.7973188571],
            [-35.0065558885, -6.7936825001],
            [-35.0117216293, -6.7948623096],
            [-35.0150332529, -6.7889165647],
            [-35.0213619534, -6.7908851687],
            [-35.0247051271, -6.7856490355],
            [-35.0447300996, -6.7743020642],
            [-35.0598941983, -6.7741490728],
            [-35.0602476844, -6.7737578614],
            [-35.066695167, -6.7666287706],
            [-35.0919934352, -6.7386416796],
            [-35.0863029983, -6.7249340773],
            [-35.0813404492, -6.7221440957],
            [-35.056041509, -6.7190425286],
            [-35.0558559734, -6.7190194112],
            [-35.0274180893, -6.7081179467],
            [-35.0078724106, -6.7043997261],
            [-34.9716372577, -6.7152135102],
            [-34.9438797876, -6.7143348025],
            [-34.9316271758, -6.7204626996],
            [-34.9343807433, -6.7313421493],
            [-34.9288591404, -6.739475173],
            [-34.9387780025, -6.7529931853],
            [-34.9328801662, -6.7563641639],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509107', name: 'Mari', description: 'Mari' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.2874933917, -7.1085551955],
            [-35.2913475224, -7.1123990194],
            [-35.2991231294, -7.1121910968],
            [-35.3058850339, -7.1185965769],
            [-35.3102455443, -7.1152885032],
            [-35.3155033912, -7.1179375586],
            [-35.3134196104, -7.1111026406],
            [-35.3232204266, -7.1092602712],
            [-35.3212951975, -7.1018295314],
            [-35.3252150276, -7.0981470686],
            [-35.3317231958, -7.0968252193],
            [-35.3755374445, -7.0327171403],
            [-35.3755409588, -7.0321927439],
            [-35.3753757017, -7.0231929956],
            [-35.37064092, -7.0215954653],
            [-35.3706785921, -7.0071659265],
            [-35.3705882337, -7.006918324],
            [-35.3641855656, -6.9978380997],
            [-35.3234295994, -6.987452842],
            [-35.3168474055, -6.9850628104],
            [-35.3159501129, -6.9849464993],
            [-35.2776232712, -6.9689116297],
            [-35.2626917993, -6.9554187901],
            [-35.2622604272, -6.9550287569],
            [-35.2633362063, -6.9639385531],
            [-35.2527758449, -6.9854507068],
            [-35.2557188301, -7.0128335306],
            [-35.2556773895, -7.026331262],
            [-35.2583947516, -7.037239114],
            [-35.2479585578, -7.0508281441],
            [-35.2531954256, -7.0578019559],
            [-35.2544144189, -7.0677979615],
            [-35.2609363616, -7.0763332433],
            [-35.2610279287, -7.0765266679],
            [-35.278667707, -7.1047030252],
            [-35.2874933917, -7.1085551955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2509156',
        name: 'Marizópolis',
        description: 'Marizópolis',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3244583084, -6.8750663626],
            [-38.3484893157, -6.8806515826],
            [-38.3577935349, -6.8787665424],
            [-38.3700873134, -6.863910551],
            [-38.3638831828, -6.8560203049],
            [-38.3637508369, -6.8506591896],
            [-38.3630974239, -6.8241681659],
            [-38.3527353818, -6.7987935169],
            [-38.3517103956, -6.7958023531],
            [-38.3510717112, -6.7939355673],
            [-38.3533823361, -6.7715254876],
            [-38.2900909893, -6.7669988942],
            [-38.306985322, -6.8041436848],
            [-38.3092667133, -6.8091589272],
            [-38.3177051989, -6.8277086026],
            [-38.3167017532, -6.8421909907],
            [-38.3244583084, -6.8750663626],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2509206',
        name: 'Massaranduba',
        description: 'Massaranduba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7021518378, -7.1127186363],
            [-35.6883485083, -7.1351344613],
            [-35.6802077757, -7.1483501235],
            [-35.6878391606, -7.1558481871],
            [-35.6949519367, -7.1558823443],
            [-35.6968436606, -7.159906778],
            [-35.7107538267, -7.1668166546],
            [-35.7108383465, -7.1706325697],
            [-35.709362719, -7.1842036268],
            [-35.7036016233, -7.1914118913],
            [-35.6946859893, -7.2033783928],
            [-35.6981636861, -7.2184155744],
            [-35.6991562006, -7.2218749445],
            [-35.6995961113, -7.2254596783],
            [-35.6996272812, -7.2257123993],
            [-35.70381155, -7.2598254776],
            [-35.7159576257, -7.2633092924],
            [-35.7320040181, -7.2769508022],
            [-35.7439964024, -7.2776164115],
            [-35.7518593331, -7.2721287984],
            [-35.7644720352, -7.2809011544],
            [-35.7699262121, -7.2805744164],
            [-35.7731951203, -7.2761471807],
            [-35.7826090637, -7.2754444988],
            [-35.7880903596, -7.2694175134],
            [-35.8002523203, -7.2668533371],
            [-35.8125635774, -7.2570024081],
            [-35.835548752, -7.252408636],
            [-35.8387447076, -7.2511476622],
            [-35.8400787987, -7.2496347247],
            [-35.8346934562, -7.2402768672],
            [-35.8143446102, -7.2070697309],
            [-35.8339519275, -7.1945058565],
            [-35.8226456087, -7.1869648477],
            [-35.824860246, -7.1744218557],
            [-35.8091462028, -7.1616991159],
            [-35.8021672956, -7.15554378],
            [-35.7857713479, -7.1506475422],
            [-35.7703699131, -7.1391488451],
            [-35.7643376595, -7.139418153],
            [-35.7521039737, -7.1335139046],
            [-35.7396791797, -7.1220993386],
            [-35.7342187586, -7.1236541142],
            [-35.7294866137, -7.1201644783],
            [-35.7076085353, -7.1038541885],
            [-35.7021518378, -7.1127186363],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509305', name: 'Mataraca', description: 'Mataraca' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.9671827276, -6.4919639079],
            [-34.9651787223, -6.6027149216],
            [-34.9724356598, -6.6005817022],
            [-34.9723154227, -6.5963942538],
            [-34.9782898078, -6.6002111074],
            [-34.9926497487, -6.5947694052],
            [-35.0006693813, -6.5961823493],
            [-35.0201971323, -6.593666334],
            [-35.0258305382, -6.5922571641],
            [-35.0399913819, -6.5972639481],
            [-35.0577008979, -6.6090792144],
            [-35.0877978778, -6.6253809707],
            [-35.0949373323, -6.6137861215],
            [-35.1040502316, -6.6064242536],
            [-35.1034490526, -6.5898224132],
            [-35.1281016563, -6.5880519271],
            [-35.1330112034, -6.5838773506],
            [-35.1362367663, -6.5762488494],
            [-35.1310178498, -6.5650725756],
            [-35.1335274437, -6.5573565168],
            [-35.1193004782, -6.5447093334],
            [-35.1119101216, -6.5074489589],
            [-35.1122276018, -6.5047164232],
            [-35.1033593589, -6.5054441333],
            [-35.1013369597, -6.4997503473],
            [-35.095220011, -6.4972766926],
            [-35.0668245211, -6.5242589133],
            [-35.057410624, -6.5273899062],
            [-35.0366419124, -6.516724107],
            [-35.0282196965, -6.5051214846],
            [-35.0184801042, -6.502660408],
            [-35.010795622, -6.5057219998],
            [-34.9917467102, -6.5010938577],
            [-34.9793718609, -6.4897317556],
            [-34.9712534272, -6.4848386196],
            [-34.9684613935, -6.489725032],
            [-34.9671827276, -6.4919639079],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509339', name: 'Matinhas', description: 'Matinhas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7076085353, -7.1038541885],
            [-35.7294866137, -7.1201644783],
            [-35.7342187586, -7.1236541142],
            [-35.7396791797, -7.1220993386],
            [-35.7521039737, -7.1335139046],
            [-35.7643376595, -7.139418153],
            [-35.7703699131, -7.1391488451],
            [-35.7857713479, -7.1506475422],
            [-35.7890209744, -7.1428846581],
            [-35.7829609167, -7.1367899231],
            [-35.7868409928, -7.1332052004],
            [-35.7939225285, -7.1329067103],
            [-35.8009040663, -7.1286425387],
            [-35.8061454661, -7.1314091807],
            [-35.8063130089, -7.1314551121],
            [-35.8123383045, -7.1263082347],
            [-35.8187625969, -7.1376365252],
            [-35.8263423971, -7.1339405126],
            [-35.8296197465, -7.1256791974],
            [-35.8248258854, -7.121773548],
            [-35.8178063335, -7.1092649369],
            [-35.8155576568, -7.1067951791],
            [-35.8133040768, -7.1021266577],
            [-35.8046841372, -7.1014184863],
            [-35.7952779261, -7.1056326763],
            [-35.7947213647, -7.1062642298],
            [-35.7753175562, -7.1022350747],
            [-35.769288693, -7.1041928811],
            [-35.7527146339, -7.1080652228],
            [-35.7226989493, -7.098538731],
            [-35.7213553511, -7.0963665631],
            [-35.7181033657, -7.0906986429],
            [-35.7109529895, -7.0885484665],
            [-35.7076321637, -7.0900546264],
            [-35.7076085353, -7.1038541885],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2509370',
        name: 'Mato Grosso',
        description: 'Mato Grosso',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.6916830237, -6.5189398487],
            [-37.6905275905, -6.5248866108],
            [-37.6846149307, -6.5242930248],
            [-37.6840638955, -6.5273449633],
            [-37.6659830697, -6.5367535418],
            [-37.665493464, -6.5412897643],
            [-37.6655312201, -6.547585141],
            [-37.6891270657, -6.5485371152],
            [-37.6972590092, -6.5459834615],
            [-37.6900166432, -6.5569871256],
            [-37.7037012338, -6.5632569707],
            [-37.715315394, -6.5616863998],
            [-37.7133856718, -6.5707660225],
            [-37.717596269, -6.5782599211],
            [-37.726296719, -6.5802928832],
            [-37.7438847117, -6.5782464919],
            [-37.7526128019, -6.5846578054],
            [-37.7544588728, -6.5947159332],
            [-37.7739379974, -6.597580961],
            [-37.7877686257, -6.5961751784],
            [-37.800180061, -6.5948376312],
            [-37.8018521681, -6.5845400775],
            [-37.7964327178, -6.5672718153],
            [-37.7948494908, -6.5641126795],
            [-37.7836373896, -6.5636915676],
            [-37.7651901736, -6.5543560602],
            [-37.7547245908, -6.5261113667],
            [-37.7508565692, -6.4938626805],
            [-37.7472446504, -6.4903498074],
            [-37.7330441752, -6.487857835],
            [-37.7299657972, -6.4963161044],
            [-37.7174569098, -6.4990528457],
            [-37.7099613573, -6.5002429627],
            [-37.701598667, -6.5140433894],
            [-37.6916830237, -6.5189398487],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509396', name: 'Maturéia', description: 'Maturéia' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.3154354155, -7.279293467],
            [-37.3136180832, -7.2889547139],
            [-37.3322508332, -7.2893261048],
            [-37.3456958743, -7.308560202],
            [-37.3569398356, -7.3227085576],
            [-37.3491674034, -7.3366223192],
            [-37.3853699292, -7.356287545],
            [-37.3952803328, -7.3362318014],
            [-37.3956406892, -7.3333153225],
            [-37.3927279843, -7.3192174182],
            [-37.3981183518, -7.3147598143],
            [-37.3973227272, -7.3075987406],
            [-37.3805715026, -7.2955300136],
            [-37.3859101508, -7.2906247916],
            [-37.3943633237, -7.2770414656],
            [-37.4077274779, -7.2691441705],
            [-37.405104549, -7.2642818232],
            [-37.3992549603, -7.2572283729],
            [-37.3871819733, -7.2539300735],
            [-37.3707593715, -7.2414840934],
            [-37.3567770158, -7.2409907379],
            [-37.3558138431, -7.2401735209],
            [-37.3456348057, -7.2296940467],
            [-37.3340996081, -7.2277956129],
            [-37.3244919378, -7.2339851391],
            [-37.3226427605, -7.2415149709],
            [-37.3156279037, -7.2413193843],
            [-37.3042072008, -7.2514507258],
            [-37.309077876, -7.2532852952],
            [-37.3056657364, -7.2617855604],
            [-37.3117732047, -7.2713835744],
            [-37.3154354155, -7.279293467],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509404', name: 'Mogeiro', description: 'Mogeiro' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4654470457, -7.1768421866],
            [-35.4577627624, -7.1792338372],
            [-35.4650422187, -7.189372969],
            [-35.4624757248, -7.2132216447],
            [-35.4585502897, -7.2161440723],
            [-35.4534955358, -7.219713538],
            [-35.4556636497, -7.2291093201],
            [-35.4486825726, -7.246480189],
            [-35.4508031367, -7.2539919781],
            [-35.4496102245, -7.2545969026],
            [-35.417358808, -7.2761411109],
            [-35.4108468904, -7.2992275789],
            [-35.4003743516, -7.3167401582],
            [-35.4316940482, -7.3186560184],
            [-35.4377543783, -7.3189090524],
            [-35.4553812879, -7.3286160352],
            [-35.4481186554, -7.3468452287],
            [-35.4427412522, -7.3534954922],
            [-35.4444966059, -7.3539293575],
            [-35.46348444, -7.3602913935],
            [-35.4829821438, -7.3645202224],
            [-35.4860996867, -7.3647512966],
            [-35.4931951777, -7.3672798148],
            [-35.5044586662, -7.3744370648],
            [-35.5130296582, -7.3754989628],
            [-35.5217551239, -7.3831604055],
            [-35.5222408496, -7.3841563825],
            [-35.5227881896, -7.3852795874],
            [-35.523251427, -7.3887809636],
            [-35.5323628789, -7.3925869907],
            [-35.5345080777, -7.392665476],
            [-35.5361782025, -7.3872802626],
            [-35.5476076192, -7.3507486025],
            [-35.5570342929, -7.3248113602],
            [-35.5649144187, -7.3031458488],
            [-35.5375661281, -7.2753919062],
            [-35.5354009864, -7.2527933422],
            [-35.5262029088, -7.2363300226],
            [-35.5214028503, -7.2341722414],
            [-35.5275198763, -7.2203084777],
            [-35.5142430481, -7.2128281886],
            [-35.5153897587, -7.2055617455],
            [-35.5153865293, -7.2016184779],
            [-35.5105496941, -7.1984576564],
            [-35.5065695642, -7.2001737889],
            [-35.5025180569, -7.1955511157],
            [-35.4917876588, -7.1945553327],
            [-35.4881331643, -7.1955936635],
            [-35.4881140726, -7.191726943],
            [-35.4819500013, -7.1892770328],
            [-35.4789633498, -7.1785078942],
            [-35.4704411622, -7.1796184375],
            [-35.4654470457, -7.1768421866],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509503', name: 'Montadas', description: 'Montadas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.929741536, -7.107318775],
            [-35.9472237032, -7.1090730798],
            [-35.9684337315, -7.1089858628],
            [-35.9685344822, -7.1089585488],
            [-35.9791589891, -7.1004712797],
            [-35.9739049831, -7.07899519],
            [-35.9623975416, -7.0686380954],
            [-35.9616235167, -7.0688527572],
            [-35.9271852346, -7.078379194],
            [-35.9219519295, -7.0800058282],
            [-35.9096326913, -7.0840229498],
            [-35.8961692932, -7.0838770376],
            [-35.9211695375, -7.1023667312],
            [-35.9286409212, -7.1067785495],
            [-35.929741536, -7.107318775],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2509602',
        name: 'Monte Horebe',
        description: 'Monte Horebe',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.518203666, -7.1822848531],
            [-38.453902127, -7.1842044505],
            [-38.4507224997, -7.1947828183],
            [-38.4471964961, -7.1965535665],
            [-38.4321360829, -7.1981981181],
            [-38.4119339551, -7.1908130167],
            [-38.4063335343, -7.1974459492],
            [-38.4049843453, -7.1999123983],
            [-38.4061838693, -7.2072049738],
            [-38.4310796112, -7.2264889159],
            [-38.4428742089, -7.2207603074],
            [-38.4440911206, -7.2126783104],
            [-38.4544596478, -7.2110749141],
            [-38.4598067111, -7.1925043714],
            [-38.4698095492, -7.1995325745],
            [-38.4670309436, -7.2074063995],
            [-38.4729862054, -7.2152567639],
            [-38.4803471518, -7.2166313924],
            [-38.4992138192, -7.217124206],
            [-38.5236651025, -7.2312067567],
            [-38.5306257206, -7.2305599389],
            [-38.5437529965, -7.2381392534],
            [-38.5453051116, -7.2482894342],
            [-38.5508033345, -7.251001491],
            [-38.5540918801, -7.2514526044],
            [-38.5516081579, -7.2430739684],
            [-38.5565785886, -7.2392772896],
            [-38.5827301976, -7.2479781601],
            [-38.5947596051, -7.2461244537],
            [-38.6002908898, -7.2343622839],
            [-38.6050711087, -7.2364705696],
            [-38.6150144135, -7.2297814775],
            [-38.6190629747, -7.2203175212],
            [-38.6226976176, -7.2072660462],
            [-38.6194411361, -7.1966515529],
            [-38.6244491231, -7.1909174602],
            [-38.6361972652, -7.1933509981],
            [-38.643849825, -7.18335548],
            [-38.6346746549, -7.1829460483],
            [-38.6153096179, -7.1823681802],
            [-38.5695823284, -7.1840530403],
            [-38.5644572727, -7.174569917],
            [-38.5551540149, -7.1722611353],
            [-38.5547654405, -7.1722074785],
            [-38.5219799384, -7.1704150026],
            [-38.5177041595, -7.1732193232],
            [-38.518203666, -7.1822848531],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509701', name: 'Monteiro', description: 'Monteiro' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.0126011239, -7.7506197836],
            [-36.9988697832, -7.7720906313],
            [-36.975694571, -7.8083064684],
            [-36.9613529157, -7.8307051678],
            [-36.9359235633, -7.8705103714],
            [-36.9341358168, -7.8733076794],
            [-36.9291096267, -7.9615717368],
            [-36.9289416738, -7.9645101599],
            [-36.9248038171, -8.0365681513],
            [-36.9332782647, -8.0439801231],
            [-36.9459661021, -8.0475684897],
            [-36.9687732057, -8.0601955738],
            [-36.9775985693, -8.0531975623],
            [-36.9881899174, -8.0533524714],
            [-37.0248045442, -8.0411185108],
            [-37.0380482664, -8.0436363491],
            [-37.0681950378, -8.0332984913],
            [-37.0790699967, -8.0456286933],
            [-37.0926521766, -8.0505652],
            [-37.1049026413, -8.0480060469],
            [-37.1115368176, -8.0404452126],
            [-37.1247964637, -8.039227012],
            [-37.1298675771, -8.0241723368],
            [-37.1492289992, -8.023326569],
            [-37.1493117197, -8.0224029103],
            [-37.1514523505, -8.0090033002],
            [-37.160076616, -7.9967414924],
            [-37.1749674974, -7.9964470287],
            [-37.1780506443, -7.9901746896],
            [-37.185128509, -7.9900325913],
            [-37.1879326989, -7.9825475563],
            [-37.1930520038, -7.9824554413],
            [-37.1923330094, -7.9601870643],
            [-37.2070514148, -7.9517896588],
            [-37.2125266937, -7.9545040092],
            [-37.2169373172, -7.9559799467],
            [-37.2210943425, -7.9619978621],
            [-37.2192460198, -7.9723805134],
            [-37.228440008, -7.9721830228],
            [-37.2304184982, -7.9689858681],
            [-37.2369985527, -7.9728957356],
            [-37.246640047, -7.9689451904],
            [-37.2631923518, -7.9677661634],
            [-37.277059324, -7.971567702],
            [-37.2952328934, -7.9645099247],
            [-37.3029932684, -7.9698012704],
            [-37.3166499946, -7.9676541505],
            [-37.3394398608, -7.9793924654],
            [-37.3409402889, -7.9741177195],
            [-37.3555278411, -7.9754540309],
            [-37.3558360682, -7.9723194063],
            [-37.3475795536, -7.9550005987],
            [-37.3498728639, -7.9481828063],
            [-37.3343870102, -7.9426355405],
            [-37.3172803782, -7.9423590905],
            [-37.314359885, -7.9355231886],
            [-37.3086327394, -7.9337662923],
            [-37.3077106423, -7.9266497984],
            [-37.3110571334, -7.9212381828],
            [-37.3076406855, -7.9145756743],
            [-37.3129158665, -7.9109749615],
            [-37.3224263848, -7.9125871487],
            [-37.3184238235, -7.9085459273],
            [-37.3069354134, -7.9068970564],
            [-37.3033083247, -7.9037650503],
            [-37.2870510781, -7.9034658633],
            [-37.2530406749, -7.8798327715],
            [-37.2437496445, -7.8734351231],
            [-37.2465248121, -7.8677685781],
            [-37.2445826748, -7.8611745885],
            [-37.2500270155, -7.8532448554],
            [-37.2468072835, -7.8463937421],
            [-37.221750163, -7.84183984],
            [-37.2278120843, -7.8211082781],
            [-37.2401189871, -7.822684375],
            [-37.2433132157, -7.8185917864],
            [-37.2298343139, -7.8126991255],
            [-37.230218976, -7.8063310719],
            [-37.2226551825, -7.8036523485],
            [-37.2170733562, -7.8062759724],
            [-37.1976152803, -7.7985062087],
            [-37.191196109, -7.7907596179],
            [-37.1826929052, -7.7903074321],
            [-37.1753978362, -7.7959184819],
            [-37.1703258977, -7.7998199007],
            [-37.1710238477, -7.7947614385],
            [-37.16791281, -7.7947105189],
            [-37.1594710584, -7.7928064503],
            [-37.151608628, -7.7794353178],
            [-37.1360583476, -7.7818339273],
            [-37.12746422, -7.7736462722],
            [-37.1181741454, -7.7758778108],
            [-37.1147663681, -7.7695439732],
            [-37.104056885, -7.7737704677],
            [-37.0978022288, -7.7727925392],
            [-37.0880039109, -7.7608313417],
            [-37.0794361951, -7.7612783773],
            [-37.0588422192, -7.7513312788],
            [-37.0540667967, -7.743604348],
            [-37.0435574923, -7.7374018151],
            [-37.0248680794, -7.7174433125],
            [-37.0126011239, -7.7506197836],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509800', name: 'Mulungu', description: 'Mulungu' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3406465335, -6.955962574],
            [-35.3168920054, -6.9849252343],
            [-35.3168474055, -6.9850628104],
            [-35.3234295994, -6.987452842],
            [-35.3641855656, -6.9978380997],
            [-35.3705882337, -7.006918324],
            [-35.3706785921, -7.0071659265],
            [-35.37064092, -7.0215954653],
            [-35.3753757017, -7.0231929956],
            [-35.3755409588, -7.0321927439],
            [-35.3909160854, -7.0440907285],
            [-35.4061490394, -7.0429304617],
            [-35.4166298375, -7.0432689154],
            [-35.4221263716, -7.0561389598],
            [-35.4421618448, -7.0702272179],
            [-35.4519198292, -7.0735181263],
            [-35.4908357036, -7.0663608872],
            [-35.4968546149, -7.0331515734],
            [-35.5011092565, -7.0259115863],
            [-35.5066673223, -7.0254842982],
            [-35.5101336785, -7.0316403711],
            [-35.5150219957, -7.0304049193],
            [-35.514968867, -7.0222883045],
            [-35.5197240183, -7.0185864313],
            [-35.5149435736, -7.0057518537],
            [-35.5236297008, -6.9913924235],
            [-35.518955305, -6.9858251289],
            [-35.4793948431, -6.9898977063],
            [-35.4633804927, -6.9814290715],
            [-35.4591823615, -6.9737424557],
            [-35.4599012842, -6.9636597116],
            [-35.454310351, -6.9630684374],
            [-35.4445818246, -6.9531791775],
            [-35.4410950145, -6.9441492641],
            [-35.4276580763, -6.9416863329],
            [-35.4265303808, -6.9347946492],
            [-35.4107417968, -6.9306373319],
            [-35.3932519549, -6.9289984218],
            [-35.3809905041, -6.9325420428],
            [-35.3624570508, -6.9397234179],
            [-35.3456065608, -6.9410791785],
            [-35.342790393, -6.9420055731],
            [-35.3406465335, -6.955962574],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2509909', name: 'Natuba', description: 'Natuba' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5679566664, -7.4382125223],
            [-35.5567097497, -7.4440997507],
            [-35.5510808584, -7.4411685895],
            [-35.5479628484, -7.4477903051],
            [-35.5433928698, -7.4493246042],
            [-35.5500251391, -7.4650453437],
            [-35.5459728423, -7.471106157],
            [-35.5522974374, -7.4753657482],
            [-35.5523983406, -7.4812036312],
            [-35.5408908807, -7.4934651012],
            [-35.5376123936, -7.4968866678],
            [-35.5036907461, -7.499351428],
            [-35.5015960343, -7.5121019727],
            [-35.5045025471, -7.5136333395],
            [-35.5088549626, -7.5170825621],
            [-35.5104249038, -7.527841799],
            [-35.5193750804, -7.5350358977],
            [-35.5195862467, -7.5499725197],
            [-35.5238320962, -7.5583977963],
            [-35.5268818677, -7.5695519969],
            [-35.5369448772, -7.580233977],
            [-35.5369522188, -7.5803143278],
            [-35.5369804373, -7.5803120237],
            [-35.5375102716, -7.5862316403],
            [-35.5306379154, -7.5895993534],
            [-35.5148647964, -7.5846114494],
            [-35.5175856784, -7.5892961647],
            [-35.5208278146, -7.5959964209],
            [-35.5177700629, -7.6060214022],
            [-35.5254816141, -7.6071542778],
            [-35.5313587143, -7.6212562927],
            [-35.5147412541, -7.6428371265],
            [-35.5266238729, -7.6447146729],
            [-35.5324826594, -7.6544074602],
            [-35.5475728626, -7.6569274954],
            [-35.5530946515, -7.6582363325],
            [-35.5604609143, -7.6594601808],
            [-35.565474325, -7.656249822],
            [-35.5730948601, -7.6546916317],
            [-35.5887410944, -7.6503915125],
            [-35.5971587801, -7.6539255381],
            [-35.6093321603, -7.6533694249],
            [-35.6154967913, -7.6585361671],
            [-35.6191798332, -7.6636720825],
            [-35.6516261263, -7.6442676143],
            [-35.6502872821, -7.6371772413],
            [-35.6400976362, -7.6365601094],
            [-35.638745972, -7.6286686994],
            [-35.6298283944, -7.6232786129],
            [-35.6240577793, -7.6080415501],
            [-35.6165449461, -7.6058102863],
            [-35.6101597023, -7.6177558448],
            [-35.5974155513, -7.6179337844],
            [-35.5949693423, -7.6164915714],
            [-35.5897808236, -7.604406155],
            [-35.5999911233, -7.5887135167],
            [-35.6006975577, -7.5788093442],
            [-35.6019248082, -7.5526756919],
            [-35.5943174901, -7.5401110654],
            [-35.5950759344, -7.5250241003],
            [-35.5950704692, -7.524899892],
            [-35.5922681074, -7.5047518498],
            [-35.5911545253, -7.488664197],
            [-35.5881267001, -7.4808300723],
            [-35.5821727015, -7.4616659414],
            [-35.5836457016, -7.4501839801],
            [-35.5679566664, -7.4382125223],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2510006',
        name: 'Nazarezinho',
        description: 'Nazarezinho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3129727499, -6.897207527],
            [-38.2914682565, -6.9091733454],
            [-38.2810263806, -6.9059841674],
            [-38.2758554182, -6.9103339322],
            [-38.2569391162, -6.9042255097],
            [-38.2524353808, -6.90477891],
            [-38.245117412, -6.9386007904],
            [-38.2536951109, -6.994296574],
            [-38.2622779384, -6.9928333899],
            [-38.3112874912, -6.993017254],
            [-38.3145984993, -6.9930294059],
            [-38.3623017287, -6.9883108339],
            [-38.3873667086, -6.9865759408],
            [-38.4046042462, -6.9677046113],
            [-38.4224724388, -6.9659866315],
            [-38.4234257608, -6.9623741759],
            [-38.4269484086, -6.9366146583],
            [-38.4263049428, -6.9314146267],
            [-38.4113312492, -6.9258138429],
            [-38.4013720707, -6.9287780557],
            [-38.3829503045, -6.928196148],
            [-38.3805834185, -6.9238002416],
            [-38.3784964415, -6.9186995476],
            [-38.3673749205, -6.9211235705],
            [-38.3653752216, -6.9196135946],
            [-38.369383279, -6.9141304003],
            [-38.3631559775, -6.9098421449],
            [-38.3561632198, -6.8953439804],
            [-38.3484893157, -6.8806515826],
            [-38.3244583084, -6.8750663626],
            [-38.3129727499, -6.897207527],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2510105',
        name: 'Nova Floresta',
        description: 'Nova Floresta',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.1802793388, -6.4695270191],
            [-36.2247319796, -6.5589658772],
            [-36.2250437109, -6.513282836],
            [-36.2254847749, -6.4832615704],
            [-36.2257179779, -6.4672324962],
            [-36.2259236986, -6.4518518988],
            [-36.2259434595, -6.4517048995],
            [-36.2262029528, -6.4336285945],
            [-36.2038558502, -6.4318200779],
            [-36.1741334557, -6.430364842],
            [-36.1802138674, -6.4691113096],
            [-36.1802793388, -6.4695270191],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2510204',
        name: 'Nova Olinda',
        description: 'Nova Olinda',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.982262074, -7.5343789778],
            [-37.9939638856, -7.5376747489],
            [-38.0009454209, -7.5304509376],
            [-38.0253841151, -7.5244390327],
            [-38.0463510394, -7.5142910541],
            [-38.0374366602, -7.5065489364],
            [-38.0349989567, -7.4992237413],
            [-38.041118765, -7.4833612606],
            [-38.0444934183, -7.4809676971],
            [-38.0448546684, -7.4804208763],
            [-38.0502700817, -7.4734695135],
            [-38.0475430283, -7.463385159],
            [-38.0511933156, -7.4541682549],
            [-38.0456572934, -7.450016514],
            [-38.0477532243, -7.4431218373],
            [-38.0531965336, -7.435441123],
            [-38.0520513014, -7.4287397561],
            [-38.0547325414, -7.4242054741],
            [-38.0606850122, -7.4228105204],
            [-38.0691544183, -7.4092307023],
            [-38.0657319246, -7.4034222403],
            [-38.0791251345, -7.3889160743],
            [-38.0529905563, -7.3938552787],
            [-38.0458115072, -7.4030193268],
            [-38.0498661789, -7.41084814],
            [-38.0327997437, -7.4282586955],
            [-38.0226898325, -7.4348906232],
            [-38.004046884, -7.4407599389],
            [-38.0040348554, -7.4408640636],
            [-37.9960214096, -7.450927854],
            [-37.9857263206, -7.4758178914],
            [-37.9718226627, -7.4865336507],
            [-37.9537721493, -7.5009292328],
            [-37.9795871223, -7.5253609979],
            [-37.9737474042, -7.532213015],
            [-37.982262074, -7.5343789778],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2510303',
        name: 'Nova Palmeira',
        description: 'Nova Palmeira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.4731463425, -6.6140056258],
            [-36.4601838049, -6.6339923944],
            [-36.4490147294, -6.6237964696],
            [-36.4378883315, -6.6288822906],
            [-36.4307109681, -6.6356881288],
            [-36.4085590173, -6.6566930793],
            [-36.4057068722, -6.6593965155],
            [-36.3971421447, -6.6407197651],
            [-36.3846212609, -6.6299712871],
            [-36.3850802966, -6.6024334116],
            [-36.3799620619, -6.5930403156],
            [-36.3753114672, -6.5964118561],
            [-36.3495420091, -6.6150952724],
            [-36.3297750691, -6.6294245984],
            [-36.3075056043, -6.6455647273],
            [-36.281366198, -6.6777648417],
            [-36.290767543, -6.684266654],
            [-36.3096452508, -6.6858704186],
            [-36.3148284948, -6.6974178964],
            [-36.3396001007, -6.7122305127],
            [-36.3445604024, -6.7426036388],
            [-36.3685240441, -6.73860469],
            [-36.3791071083, -6.7480130577],
            [-36.382808947, -6.7463172462],
            [-36.4022130137, -6.7419637792],
            [-36.4457763487, -6.725230024],
            [-36.4460962721, -6.7251450305],
            [-36.4587408733, -6.7193741952],
            [-36.4720978872, -6.7243698075],
            [-36.4814835723, -6.7224316514],
            [-36.4893469377, -6.7269878519],
            [-36.4962520143, -6.7245537601],
            [-36.523432113, -6.7279456684],
            [-36.5266745825, -6.7256728314],
            [-36.533183202, -6.7330841172],
            [-36.543869473, -6.73080156],
            [-36.5585452834, -6.7087057331],
            [-36.5570092669, -6.7056995243],
            [-36.5470807173, -6.6947472244],
            [-36.5414107808, -6.6656618309],
            [-36.520407925, -6.6102000721],
            [-36.5231838489, -6.60180241],
            [-36.5215763453, -6.5966867261],
            [-36.5134761333, -6.5939921231],
            [-36.5076253848, -6.5950499341],
            [-36.5015766279, -6.6010775058],
            [-36.4731463425, -6.6140056258],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2510402',
        name: "Olho d'Água",
        description: "Olho d'Água",
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.704426159, -7.174818905],
            [-37.7034925837, -7.1750549804],
            [-37.6769082254, -7.1717351157],
            [-37.6758767693, -7.1905198615],
            [-37.6685890372, -7.2037448213],
            [-37.6730214719, -7.2132194956],
            [-37.6614148984, -7.2254502579],
            [-37.6603183476, -7.2338499825],
            [-37.6571151356, -7.2314546923],
            [-37.6485479508, -7.2329873477],
            [-37.6477858191, -7.2371321591],
            [-37.6298139818, -7.2486488723],
            [-37.6234859106, -7.2618705224],
            [-37.6150200367, -7.2643063975],
            [-37.6143938985, -7.2698082233],
            [-37.5956484792, -7.2713921107],
            [-37.5933099157, -7.2841558129],
            [-37.5850784412, -7.2877623262],
            [-37.5802993762, -7.307666796],
            [-37.5699933868, -7.316653138],
            [-37.583972056, -7.3315787412],
            [-37.5974128664, -7.3321363439],
            [-37.6076192235, -7.3274341648],
            [-37.6136458342, -7.3299696474],
            [-37.6138062857, -7.3299926595],
            [-37.6248165732, -7.3315852653],
            [-37.6400991752, -7.3230917924],
            [-37.6502909375, -7.328173126],
            [-37.6620144171, -7.3292602619],
            [-37.6825983103, -7.3239010512],
            [-37.6973962213, -7.3350455786],
            [-37.7050319397, -7.3520952967],
            [-37.7153929621, -7.3429072217],
            [-37.7217626318, -7.3429240729],
            [-37.7244009293, -7.3449110325],
            [-37.7238741139, -7.3608623363],
            [-37.7392637386, -7.3657497199],
            [-37.736924775, -7.3782479261],
            [-37.7210160789, -7.381921767],
            [-37.7201185461, -7.3844369027],
            [-37.7451858402, -7.3949748869],
            [-37.7567201615, -7.3920908263],
            [-37.7659962398, -7.4001093909],
            [-37.7691519928, -7.3976797663],
            [-37.7837055309, -7.3898815875],
            [-37.7920411795, -7.3969842223],
            [-37.8039841467, -7.3983097272],
            [-37.8201887696, -7.4121843893],
            [-37.839429864, -7.4173102225],
            [-37.8461813229, -7.42661931],
            [-37.8551239561, -7.4335506087],
            [-37.8569226005, -7.4325540868],
            [-37.8587437056, -7.4304256665],
            [-37.8538011065, -7.4204210802],
            [-37.8607510805, -7.4111787549],
            [-37.8612095575, -7.3846960166],
            [-37.8651364594, -7.3446649795],
            [-37.8747961174, -7.3346984519],
            [-37.8815652073, -7.3372681873],
            [-37.8925739459, -7.3290542317],
            [-37.8990294207, -7.3357803118],
            [-37.910478884, -7.340504657],
            [-37.9109253486, -7.3347867536],
            [-37.9022183384, -7.3252130735],
            [-37.9067519503, -7.3178578881],
            [-37.900008262, -7.3085074977],
            [-37.9015220675, -7.2843127549],
            [-37.9075997338, -7.2728151804],
            [-37.8746218525, -7.2562837596],
            [-37.8272967911, -7.2325651507],
            [-37.8192155573, -7.1946767884],
            [-37.8117799134, -7.1610920227],
            [-37.7761273935, -7.1416296856],
            [-37.7540990649, -7.1410005949],
            [-37.7486077248, -7.1609885283],
            [-37.7408314756, -7.1738291972],
            [-37.704426159, -7.174818905],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2510501', name: 'Olivedos', description: 'Olivedos' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.1886568907, -6.9214754229],
            [-36.1699001504, -6.9411073071],
            [-36.1473626383, -6.9435374504],
            [-36.1529002941, -6.9694313766],
            [-36.1672532596, -6.9783804649],
            [-36.1755282829, -6.9791589363],
            [-36.1800732391, -6.9847547388],
            [-36.1870507151, -6.9942404731],
            [-36.1868957511, -7.0019017288],
            [-36.1938856209, -7.0178487762],
            [-36.1994474566, -7.0249127744],
            [-36.201372681, -7.0624031366],
            [-36.2046762932, -7.0673776618],
            [-36.2294005149, -7.0691385783],
            [-36.2363623315, -7.074288464],
            [-36.2402414807, -7.072853432],
            [-36.2446077478, -7.0801263015],
            [-36.2401488037, -7.088438819],
            [-36.2334769163, -7.0891799548],
            [-36.2332606238, -7.1046195839],
            [-36.2812592909, -7.091205762],
            [-36.3051047605, -7.044993584],
            [-36.351196868, -6.9555396602],
            [-36.3342146156, -6.9470176465],
            [-36.325772359, -6.9385256426],
            [-36.3200720014, -6.9391393445],
            [-36.308877974, -6.9389490803],
            [-36.2988140707, -6.9313308643],
            [-36.2912874856, -6.9053313305],
            [-36.2829766448, -6.890425846],
            [-36.2612488989, -6.8454173989],
            [-36.1886568907, -6.9214754229],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2510600',
        name: 'Ouro Velho',
        description: 'Ouro Velho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.0861950528, -7.6297994674],
            [-37.0867167349, -7.6429739931],
            [-37.1140517082, -7.640404831],
            [-37.1266403637, -7.6479145445],
            [-37.1324855651, -7.6612248935],
            [-37.1394146851, -7.664648915],
            [-37.1512028011, -7.6710576803],
            [-37.1505739655, -7.6824350743],
            [-37.1546970541, -7.692935371],
            [-37.1606977668, -7.697557853],
            [-37.173715945, -7.6838860003],
            [-37.1797747633, -7.6666964826],
            [-37.1947888977, -7.6516540162],
            [-37.1969794664, -7.6450264838],
            [-37.1963305644, -7.6291731195],
            [-37.18376121, -7.6154071149],
            [-37.1802171977, -7.6017333981],
            [-37.1801795958, -7.6015853265],
            [-37.1728854781, -7.5874890958],
            [-37.1554276033, -7.5674726348],
            [-37.1333910741, -7.5496365932],
            [-37.1211823982, -7.5357110102],
            [-37.1057633102, -7.5307194292],
            [-37.0858548562, -7.5173860057],
            [-37.0901157805, -7.5556734666],
            [-37.0937328999, -7.5595737034],
            [-37.0900567822, -7.5688035551],
            [-37.0956171532, -7.5721604107],
            [-37.0856432092, -7.5956165829],
            [-37.093709335, -7.6060652593],
            [-37.0870683816, -7.615903485],
            [-37.0915472706, -7.6238251151],
            [-37.0861950528, -7.6297994674],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2510659', name: 'Parari', description: 'Parari' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.6808070045, -7.2408668867],
            [-36.668831508, -7.2511732038],
            [-36.6606390344, -7.247536444],
            [-36.6577757643, -7.251645654],
            [-36.6575788472, -7.2577850215],
            [-36.6633929716, -7.2587938302],
            [-36.6616136948, -7.2641986041],
            [-36.6552236803, -7.264415868],
            [-36.660952175, -7.2678454577],
            [-36.6617523744, -7.2794393358],
            [-36.6558332065, -7.2783325833],
            [-36.6512233455, -7.2825849557],
            [-36.6220722575, -7.3374961105],
            [-36.6069748172, -7.3458958337],
            [-36.6009684051, -7.3511344948],
            [-36.6023584839, -7.3782035038],
            [-36.6345871072, -7.393439319],
            [-36.6354891195, -7.3923122393],
            [-36.6639698525, -7.3891298652],
            [-36.6829056214, -7.3528208688],
            [-36.7550257758, -7.3274495368],
            [-36.7567203336, -7.3054366052],
            [-36.7493906993, -7.3045561304],
            [-36.7371919419, -7.3137033724],
            [-36.719421507, -7.3163946639],
            [-36.7152220973, -7.3137554741],
            [-36.7135877666, -7.3176705823],
            [-36.701273195, -7.3181410624],
            [-36.7074137657, -7.2912250882],
            [-36.7162744899, -7.2809349819],
            [-36.6834167751, -7.2411644906],
            [-36.6795831438, -7.2365250851],
            [-36.6808070045, -7.2408668867],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2510709', name: 'Passagem', description: 'Passagem' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.9960960481, -7.1479415376],
            [-36.9939238879, -7.1570434866],
            [-36.9952661987, -7.1682370608],
            [-36.9999303331, -7.1739018906],
            [-37.0447670228, -7.1575639933],
            [-37.0629705111, -7.1545422573],
            [-37.0698435849, -7.1577715631],
            [-37.0735471568, -7.1639274234],
            [-37.0885044055, -7.1687008654],
            [-37.0951479697, -7.1283735629],
            [-37.0974161526, -7.116912241],
            [-37.0866377335, -7.1087284957],
            [-37.0930830362, -7.0995654351],
            [-37.0887904472, -7.0922345264],
            [-37.0882033012, -7.0808203867],
            [-37.0828085633, -7.0722642703],
            [-37.064458053, -7.0713213616],
            [-37.0599462006, -7.0670776569],
            [-37.0449550455, -7.063412472],
            [-37.0394379288, -7.056776367],
            [-37.0065939758, -7.0520879186],
            [-37.0056870846, -7.0746496499],
            [-36.9934177766, -7.1195313946],
            [-36.9951274886, -7.1263757366],
            [-37.0045020244, -7.1273399478],
            [-37.003135863, -7.1307563951],
            [-36.999659236, -7.1359285792],
            [-37.0028990745, -7.1450711684],
            [-36.9960960481, -7.1479415376],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2510808', name: 'Patos', description: 'Patos' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.4846639202, -6.9124857342],
            [-37.4725643372, -6.9146641086],
            [-37.4616935038, -6.9128583781],
            [-37.4340858253, -6.91653608],
            [-37.4092004954, -6.929253011],
            [-37.4068356189, -6.9316423914],
            [-37.4065606326, -6.9318112382],
            [-37.3961596935, -6.9360927317],
            [-37.3863630553, -6.9360598038],
            [-37.3773315799, -6.9309498576],
            [-37.346692763, -6.9271538897],
            [-37.2991451314, -6.9153774897],
            [-37.2851286948, -6.9144201463],
            [-37.2849921132, -6.9144164132],
            [-37.2557751336, -6.9132508661],
            [-37.2453873435, -6.9088278176],
            [-37.2193312004, -6.9092673807],
            [-37.2122134327, -6.9059842536],
            [-37.1979512308, -6.9058458999],
            [-37.190133115, -6.9171797101],
            [-37.1784585028, -6.9180535186],
            [-37.1778187197, -6.9214496527],
            [-37.193304812, -6.9347316254],
            [-37.183113741, -6.9357781702],
            [-37.1729883095, -6.9440708454],
            [-37.1806355869, -6.964319659],
            [-37.1739903137, -6.9729241768],
            [-37.1726724428, -6.9818058551],
            [-37.2038070077, -7.0001738727],
            [-37.2276966063, -7.019275243],
            [-37.2238039853, -7.038305159],
            [-37.230232304, -7.0464947908],
            [-37.2330119499, -7.0454386974],
            [-37.2386700057, -7.056527364],
            [-37.2165140462, -7.0731655741],
            [-37.2133437048, -7.0813094723],
            [-37.2167569736, -7.0844652707],
            [-37.2169793897, -7.0846708535],
            [-37.2445471688, -7.1101619485],
            [-37.287132178, -7.0762950133],
            [-37.2873179021, -7.0761468761],
            [-37.2874793688, -7.076017871],
            [-37.3211833691, -7.0815016166],
            [-37.3140795829, -7.0844402484],
            [-37.3160707258, -7.0906277967],
            [-37.313774157, -7.0942849167],
            [-37.3233866392, -7.111094936],
            [-37.3284851867, -7.1138379875],
            [-37.328015716, -7.1228727342],
            [-37.3338635022, -7.1294646822],
            [-37.336543003, -7.1395006857],
            [-37.3527228809, -7.1438357382],
            [-37.3562080052, -7.1417601007],
            [-37.3806248138, -7.153250802],
            [-37.3878075556, -7.1504759486],
            [-37.3883227615, -7.1443109349],
            [-37.3895094931, -7.1301161045],
            [-37.3595259018, -7.1258876814],
            [-37.3612045403, -7.1120956801],
            [-37.3497876464, -7.1136844935],
            [-37.3466545213, -7.0842387973],
            [-37.3484816637, -7.052839905],
            [-37.3520665543, -7.0531067692],
            [-37.3556315784, -7.0418189408],
            [-37.368917436, -6.9997149339],
            [-37.3874654497, -6.9985705038],
            [-37.410370425, -7.0054194041],
            [-37.4171116289, -7.0043221733],
            [-37.4240874803, -6.9979795137],
            [-37.4330186195, -6.9976438863],
            [-37.4365824983, -6.9968564904],
            [-37.4484303426, -6.9972985114],
            [-37.4574695811, -6.9913192662],
            [-37.4698082005, -6.9913825728],
            [-37.4931954622, -6.9990730524],
            [-37.5178648656, -7.0000299677],
            [-37.5154858582, -6.991012891],
            [-37.5237104102, -6.9872044237],
            [-37.5216483057, -6.9723730706],
            [-37.5105905362, -6.9679523793],
            [-37.5090330092, -6.9601341633],
            [-37.4920752288, -6.9569512088],
            [-37.4829083975, -6.9696123845],
            [-37.4706242912, -6.9687565801],
            [-37.4593365687, -6.9658818051],
            [-37.4518919264, -6.9594854182],
            [-37.4522079523, -6.9564664089],
            [-37.4654323471, -6.9498453783],
            [-37.459334573, -6.9309317985],
            [-37.4720344873, -6.9294804137],
            [-37.4826362263, -6.9152144651],
            [-37.4846639202, -6.9124857342],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2510907', name: 'Paulista', description: 'Paulista' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.4735989401, -6.5375685426],
            [-37.48278518, -6.5500109942],
            [-37.4802396016, -6.559420119],
            [-37.4744718814, -6.5640508777],
            [-37.4797754834, -6.5937502523],
            [-37.4800408947, -6.6048175174],
            [-37.4756571211, -6.6077135715],
            [-37.4741888422, -6.617513291],
            [-37.4768727196, -6.6415106654],
            [-37.4658478067, -6.6595587821],
            [-37.4748583569, -6.6724215256],
            [-37.4706384769, -6.6799357979],
            [-37.4843562435, -6.7096362472],
            [-37.5182655528, -6.7120253461],
            [-37.556488401, -6.7170075406],
            [-37.6384175056, -6.7217321917],
            [-37.6570040806, -6.7222646825],
            [-37.6582617575, -6.7212962906],
            [-37.6784335456, -6.7085646734],
            [-37.7171707487, -6.6841029732],
            [-37.7844809225, -6.6425682055],
            [-37.7821703886, -6.6369302678],
            [-37.7891902288, -6.6171704262],
            [-37.7857755744, -6.6058954609],
            [-37.7877686257, -6.5961751784],
            [-37.7739379974, -6.597580961],
            [-37.7544588728, -6.5947159332],
            [-37.7526128019, -6.5846578054],
            [-37.7438847117, -6.5782464919],
            [-37.726296719, -6.5802928832],
            [-37.717596269, -6.5782599211],
            [-37.7133856718, -6.5707660225],
            [-37.715315394, -6.5616863998],
            [-37.7037012338, -6.5632569707],
            [-37.6900166432, -6.5569871256],
            [-37.6972590092, -6.5459834615],
            [-37.6891270657, -6.5485371152],
            [-37.6655312201, -6.547585141],
            [-37.6487640308, -6.5465694808],
            [-37.6438785583, -6.5322243967],
            [-37.6290768019, -6.528332899],
            [-37.6287453575, -6.5283164604],
            [-37.6202632152, -6.5278975815],
            [-37.6179493448, -6.5151763054],
            [-37.6138615095, -6.5145793178],
            [-37.6036529173, -6.536303066],
            [-37.5806509867, -6.547329444],
            [-37.5723618756, -6.5504459757],
            [-37.5393035698, -6.5437871447],
            [-37.5296057211, -6.5402664506],
            [-37.5083324786, -6.5261721837],
            [-37.494699723, -6.5235694876],
            [-37.4925132687, -6.509571566],
            [-37.4890414897, -6.5123724513],
            [-37.4643815232, -6.5322639392],
            [-37.4735989401, -6.5375685426],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2511004',
        name: 'Pedra Branca',
        description: 'Pedra Branca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.0860557949, -7.5596768838],
            [-38.1020109176, -7.5689464155],
            [-38.0999793573, -7.5563997541],
            [-38.1079920439, -7.5492171087],
            [-38.0733698752, -7.5277195398],
            [-38.0947026328, -7.476746517],
            [-38.0949915315, -7.4759974245],
            [-38.1179546582, -7.4163766324],
            [-38.1189570218, -7.4114480172],
            [-38.1317917479, -7.4062617164],
            [-38.1406617197, -7.3966391322],
            [-38.0800625361, -7.3626026991],
            [-38.0700586143, -7.3569851652],
            [-38.0702442099, -7.3661502892],
            [-38.0801253442, -7.3755628085],
            [-38.082569645, -7.3795701037],
            [-38.0791251345, -7.3889160743],
            [-38.0657319246, -7.4034222403],
            [-38.0691544183, -7.4092307023],
            [-38.0606850122, -7.4228105204],
            [-38.0547325414, -7.4242054741],
            [-38.0520513014, -7.4287397561],
            [-38.0531965336, -7.435441123],
            [-38.0477532243, -7.4431218373],
            [-38.0456572934, -7.450016514],
            [-38.0511933156, -7.4541682549],
            [-38.0475430283, -7.463385159],
            [-38.0502700817, -7.4734695135],
            [-38.0448546684, -7.4804208763],
            [-38.0444934183, -7.4809676971],
            [-38.041118765, -7.4833612606],
            [-38.0349989567, -7.4992237413],
            [-38.0374366602, -7.5065489364],
            [-38.0463510394, -7.5142910541],
            [-38.0466582222, -7.5412031407],
            [-38.0511125771, -7.5504745216],
            [-38.0860557949, -7.5596768838],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2511103',
        name: 'Pedra Lavrada',
        description: 'Pedra Lavrada',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.2660988397, -6.6958409105],
            [-36.2657045414, -6.6988443529],
            [-36.250690425, -6.6841650149],
            [-36.2526576967, -6.7143976035],
            [-36.2526621541, -6.7144720345],
            [-36.2560794058, -6.7671717652],
            [-36.2563541382, -6.7714100893],
            [-36.2587932641, -6.8091797692],
            [-36.2701445085, -6.8102261626],
            [-36.2748109953, -6.8065007433],
            [-36.2860019843, -6.808312525],
            [-36.3165200352, -6.7898094501],
            [-36.3163379992, -6.7960324333],
            [-36.3301618279, -6.8221939118],
            [-36.3357078111, -6.8225710764],
            [-36.3387107621, -6.8166983224],
            [-36.3415493162, -6.8176909699],
            [-36.3516860415, -6.8106021712],
            [-36.3583081729, -6.8126625945],
            [-36.365005499, -6.8147464333],
            [-36.36514981, -6.8204249622],
            [-36.3685383375, -6.821214742],
            [-36.3625185119, -6.8320403426],
            [-36.3618029482, -6.8405699769],
            [-36.3645365086, -6.85038372],
            [-36.3701066978, -6.8549554784],
            [-36.3746471659, -6.8471843154],
            [-36.3788394071, -6.8489870859],
            [-36.38461052, -6.8454563136],
            [-36.3874004702, -6.8480958863],
            [-36.393745335, -6.8456938515],
            [-36.3991480147, -6.8500360861],
            [-36.4056764827, -6.8458414526],
            [-36.4081436627, -6.8546745652],
            [-36.4083691218, -6.854673813],
            [-36.4137113532, -6.8529071857],
            [-36.4244507362, -6.8423723405],
            [-36.4265865206, -6.8481991357],
            [-36.4330241007, -6.8483488037],
            [-36.438542408, -6.8430820122],
            [-36.4475669156, -6.8483110206],
            [-36.4644039751, -6.846153168],
            [-36.4663804349, -6.8484096266],
            [-36.4686502101, -6.8455445951],
            [-36.4758051928, -6.8483533753],
            [-36.4758696764, -6.8447628322],
            [-36.4813306054, -6.8454597405],
            [-36.482383683, -6.8391684086],
            [-36.4889121448, -6.8361466625],
            [-36.5042978164, -6.8495039157],
            [-36.5115121691, -6.8444456748],
            [-36.5124893754, -6.8470404628],
            [-36.5175339381, -6.8453970498],
            [-36.5188544896, -6.8399743446],
            [-36.5268318371, -6.8373461133],
            [-36.5296105669, -6.8410336826],
            [-36.5343256322, -6.8367973958],
            [-36.5380357771, -6.8434149782],
            [-36.5433328603, -6.841695869],
            [-36.5288312155, -6.8249953179],
            [-36.5096274555, -6.8175461473],
            [-36.5051570168, -6.8084215194],
            [-36.5009701585, -6.7771669304],
            [-36.5044187713, -6.753535973],
            [-36.520230848, -6.7452794706],
            [-36.5248792542, -6.7363160119],
            [-36.533183202, -6.7330841172],
            [-36.5266745825, -6.7256728314],
            [-36.523432113, -6.7279456684],
            [-36.4962520143, -6.7245537601],
            [-36.4893469377, -6.7269878519],
            [-36.4814835723, -6.7224316514],
            [-36.4720978872, -6.7243698075],
            [-36.4587408733, -6.7193741952],
            [-36.4460962721, -6.7251450305],
            [-36.4457763487, -6.725230024],
            [-36.4022130137, -6.7419637792],
            [-36.382808947, -6.7463172462],
            [-36.3791071083, -6.7480130577],
            [-36.3685240441, -6.73860469],
            [-36.3445604024, -6.7426036388],
            [-36.3396001007, -6.7122305127],
            [-36.3148284948, -6.6974178964],
            [-36.3096452508, -6.6858704186],
            [-36.290767543, -6.684266654],
            [-36.281366198, -6.6777648417],
            [-36.2660988397, -6.6958409105],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2511202',
        name: 'Pedras de Fogo',
        description: 'Pedras de Fogo',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.1388668741, -7.2164276499],
            [-35.1364780104, -7.2513319328],
            [-35.126293739, -7.2349186318],
            [-35.1101098522, -7.2299751904],
            [-35.0930234903, -7.2319517554],
            [-35.0778619453, -7.2282334598],
            [-35.0671827114, -7.2288858822],
            [-35.0553740734, -7.2667489618],
            [-35.0446282076, -7.2744310587],
            [-35.0448503951, -7.2771157082],
            [-35.0434832383, -7.2783739915],
            [-34.9833329579, -7.3259307809],
            [-34.9803747993, -7.3282683011],
            [-34.9558643801, -7.3496907832],
            [-34.9673086824, -7.3675897691],
            [-34.9577747082, -7.3810839262],
            [-34.9525384277, -7.3884927628],
            [-34.9542783206, -7.4048858196],
            [-34.9689392298, -7.4337493446],
            [-34.9759760175, -7.4362524341],
            [-34.9782710809, -7.4411424113],
            [-34.9812600643, -7.4563647556],
            [-34.9771398376, -7.4692141473],
            [-34.9846573701, -7.4989898405],
            [-34.9848121312, -7.4996012688],
            [-34.9898569268, -7.4884693072],
            [-34.9838799681, -7.4689119929],
            [-34.985089954, -7.4615975726],
            [-35.0113042558, -7.4447569922],
            [-35.0292308425, -7.4254119174],
            [-35.0477734935, -7.4174028753],
            [-35.0700185105, -7.398467357],
            [-35.0748583442, -7.3970035583],
            [-35.0786941196, -7.3958976079],
            [-35.0845797897, -7.4022657286],
            [-35.0974645628, -7.4079185616],
            [-35.1089915871, -7.406830429],
            [-35.1250911797, -7.4021932693],
            [-35.1258333438, -7.3995262483],
            [-35.1259417221, -7.3994945338],
            [-35.1480573215, -7.3941249648],
            [-35.1534658103, -7.3920485206],
            [-35.1563834118, -7.3771285728],
            [-35.1507219201, -7.3716887137],
            [-35.1707181877, -7.3514371201],
            [-35.1812707109, -7.3514196384],
            [-35.1844547286, -7.3375969071],
            [-35.1964958719, -7.317889407],
            [-35.1931469328, -7.3107339034],
            [-35.1916766572, -7.2980118815],
            [-35.1816572316, -7.2954205495],
            [-35.1774068454, -7.2858550061],
            [-35.1713874217, -7.2723114359],
            [-35.1709213264, -7.2637464976],
            [-35.1584517678, -7.2292938342],
            [-35.1508949677, -7.2127720616],
            [-35.1388668741, -7.2164276499],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2511301', name: 'Piancó', description: 'Piancó' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.0244277449, -7.1798585109],
            [-38.0172341851, -7.1848051477],
            [-38.0168247397, -7.1748147014],
            [-37.990426142, -7.1733386379],
            [-37.9898843403, -7.1623154143],
            [-37.981493721, -7.1527585584],
            [-37.9844045832, -7.1435481077],
            [-37.978107733, -7.1205005223],
            [-37.973445978, -7.1177948066],
            [-37.9700847505, -7.1038664999],
            [-37.9530063917, -7.0994449419],
            [-37.9495060813, -7.0891904043],
            [-37.9161361411, -7.0856581221],
            [-37.8962740776, -7.0888228954],
            [-37.8936671532, -7.0890580768],
            [-37.8717886355, -7.0852929237],
            [-37.8541636695, -7.08692834],
            [-37.8410391046, -7.0991405813],
            [-37.8232918848, -7.0940579212],
            [-37.8239325079, -7.1179697113],
            [-37.80553801, -7.1233899177],
            [-37.7991686837, -7.131568376],
            [-37.7913361889, -7.1222994835],
            [-37.7813008268, -7.1385961815],
            [-37.7761273935, -7.1416296856],
            [-37.8117799134, -7.1610920227],
            [-37.8192155573, -7.1946767884],
            [-37.8272967911, -7.2325651507],
            [-37.8746218525, -7.2562837596],
            [-37.9075997338, -7.2728151804],
            [-37.9015220675, -7.2843127549],
            [-37.900008262, -7.3085074977],
            [-37.9569014905, -7.3160685821],
            [-37.9665519354, -7.3127968635],
            [-37.9666556096, -7.31276209],
            [-38.0094422316, -7.284653784],
            [-38.0235632698, -7.2908677978],
            [-38.0525184161, -7.3170607111],
            [-38.084716529, -7.3236227654],
            [-38.0863106508, -7.3037653722],
            [-38.0876410757, -7.2871808725],
            [-38.0903460917, -7.2533933598],
            [-38.0915126251, -7.2349423394],
            [-38.0842984498, -7.2183205433],
            [-38.0692701017, -7.2210894194],
            [-38.0667689375, -7.2186586657],
            [-38.0640705805, -7.2212657069],
            [-38.0561775653, -7.219196456],
            [-38.0571522757, -7.2135994883],
            [-38.0529288949, -7.2074313939],
            [-38.0558281463, -7.2023992339],
            [-38.0507817411, -7.1985885775],
            [-38.0524570234, -7.1915261059],
            [-38.0526864956, -7.1808557733],
            [-38.0670256508, -7.1671078265],
            [-38.0762541877, -7.1492993115],
            [-38.0775570466, -7.1394173364],
            [-38.070774655, -7.1216963248],
            [-38.063224518, -7.1186636186],
            [-38.0649595454, -7.1149909144],
            [-38.0578332568, -7.0947125089],
            [-38.0582177878, -7.0870471685],
            [-38.049999924, -7.1115332545],
            [-38.0522905727, -7.1170086692],
            [-38.0502834965, -7.1230620285],
            [-38.0308325093, -7.1386847303],
            [-38.0253239571, -7.1502100603],
            [-38.0256060103, -7.1579461882],
            [-38.0347980318, -7.1653783765],
            [-38.0254034895, -7.1747685141],
            [-38.0244277449, -7.1798585109],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2511400', name: 'Picuí', description: 'Picuí' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.2788033106, -6.3172285555],
            [-36.278145055, -6.3489580214],
            [-36.2960059832, -6.3700111428],
            [-36.2989119854, -6.381657785],
            [-36.2948888722, -6.3941610826],
            [-36.2825874136, -6.3970187803],
            [-36.2687622742, -6.4014881909],
            [-36.2619117482, -6.4018755338],
            [-36.2508307179, -6.4040239466],
            [-36.2494399125, -6.436960115],
            [-36.2262029528, -6.4336285945],
            [-36.2259434595, -6.4517048995],
            [-36.2259236986, -6.4518518988],
            [-36.2257179779, -6.4672324962],
            [-36.2254847749, -6.4832615704],
            [-36.2250437109, -6.513282836],
            [-36.2247319796, -6.5589658772],
            [-36.2247690041, -6.5590914764],
            [-36.2338915802, -6.5944373927],
            [-36.2520156326, -6.5789101153],
            [-36.2697815972, -6.5721979827],
            [-36.2908368829, -6.5578303402],
            [-36.3007932218, -6.5430399903],
            [-36.2986922767, -6.5659414074],
            [-36.2951183496, -6.5710780538],
            [-36.2725328582, -6.6051440355],
            [-36.2725701069, -6.6364231859],
            [-36.2739341298, -6.6429332381],
            [-36.2771257744, -6.6568350165],
            [-36.2712959603, -6.6861998995],
            [-36.2660988397, -6.6958409105],
            [-36.281366198, -6.6777648417],
            [-36.3075056043, -6.6455647273],
            [-36.3297750691, -6.6294245984],
            [-36.3495420091, -6.6150952724],
            [-36.3753114672, -6.5964118561],
            [-36.3799620619, -6.5930403156],
            [-36.3850802966, -6.6024334116],
            [-36.3846212609, -6.6299712871],
            [-36.3971421447, -6.6407197651],
            [-36.4057068722, -6.6593965155],
            [-36.4085590173, -6.6566930793],
            [-36.4307109681, -6.6356881288],
            [-36.4378883315, -6.6288822906],
            [-36.4355241958, -6.625476784],
            [-36.4408365176, -6.5990357714],
            [-36.4440563094, -6.5579189658],
            [-36.4488393626, -6.5535135627],
            [-36.4619215317, -6.5499296239],
            [-36.4623658973, -6.5498502205],
            [-36.4688563396, -6.5486956071],
            [-36.4592105399, -6.5180131746],
            [-36.4201576098, -6.4760614982],
            [-36.4189817427, -6.4644162641],
            [-36.4251109667, -6.4597946356],
            [-36.4393713875, -6.4567622832],
            [-36.4298505661, -6.4441224414],
            [-36.4225874552, -6.4414416445],
            [-36.4198534682, -6.4359254003],
            [-36.4351859338, -6.4360731991],
            [-36.4384814327, -6.4313632213],
            [-36.4224198813, -6.4064415751],
            [-36.4159575034, -6.4002430016],
            [-36.3961028186, -6.4055797916],
            [-36.3901077732, -6.4116228235],
            [-36.3746927622, -6.3923282062],
            [-36.37565316, -6.3847985277],
            [-36.3765443724, -6.3778109342],
            [-36.3674094041, -6.3596656883],
            [-36.3759386304, -6.3537123719],
            [-36.3703713474, -6.3482533066],
            [-36.3521250663, -6.3503467578],
            [-36.3502146622, -6.3403916899],
            [-36.3413400092, -6.3280024016],
            [-36.3356366152, -6.3208495617],
            [-36.3383587413, -6.3143036225],
            [-36.3434447348, -6.3139059607],
            [-36.347323228, -6.3087785642],
            [-36.343654977, -6.302136045],
            [-36.3431311599, -6.3018322729],
            [-36.3072586915, -6.2810994385],
            [-36.294647148, -6.2917083603],
            [-36.2867138851, -6.3006656901],
            [-36.2795403545, -6.3084627707],
            [-36.2788033106, -6.3172285555],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2511509', name: 'Pilar', description: 'Pilar' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.2322282478, -7.2051194319],
            [-35.2321962775, -7.2167040589],
            [-35.2387822758, -7.2254762323],
            [-35.2417595143, -7.2406852583],
            [-35.2334595632, -7.2639347075],
            [-35.2233793474, -7.2921551273],
            [-35.220026282, -7.2996978082],
            [-35.2238691308, -7.3033108216],
            [-35.2284630662, -7.3019610482],
            [-35.2286483268, -7.3055181821],
            [-35.2381929166, -7.3141941098],
            [-35.2400783296, -7.3264631209],
            [-35.2466353427, -7.3300254791],
            [-35.2450329395, -7.3337639659],
            [-35.2489005825, -7.3362574438],
            [-35.2498744721, -7.3340959383],
            [-35.25148968, -7.3388025715],
            [-35.2550285237, -7.3392213431],
            [-35.2569370467, -7.3459199597],
            [-35.269851848, -7.3549638186],
            [-35.2713534024, -7.3631141424],
            [-35.2825335912, -7.3403112099],
            [-35.2917821694, -7.3208324866],
            [-35.3084083133, -7.3224833069],
            [-35.3108750539, -7.3045675881],
            [-35.3215499681, -7.2917942021],
            [-35.3219732431, -7.2912872481],
            [-35.3169091797, -7.2845537488],
            [-35.31180837, -7.2839383405],
            [-35.3005201957, -7.2904092223],
            [-35.2946162851, -7.2880014904],
            [-35.2869211018, -7.2657250508],
            [-35.2908284197, -7.2475236438],
            [-35.2960463087, -7.234176089],
            [-35.3025591217, -7.2262482684],
            [-35.3056288266, -7.2271982614],
            [-35.3044831209, -7.2162337842],
            [-35.3044206825, -7.2156347756],
            [-35.3034451636, -7.2141956468],
            [-35.2895151497, -7.2164111462],
            [-35.2857111651, -7.2124011276],
            [-35.2820804007, -7.2159282575],
            [-35.2813686709, -7.2126360849],
            [-35.2737485363, -7.2101013093],
            [-35.2698497487, -7.2137715709],
            [-35.2542565539, -7.2108220842],
            [-35.2519331052, -7.2132246144],
            [-35.2375975103, -7.2109623906],
            [-35.2358898753, -7.2051676247],
            [-35.2322282478, -7.2051194319],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2511608', name: 'Pilões', description: 'Pilões' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5874891319, -6.8388810989],
            [-35.5783803506, -6.8580615515],
            [-35.5782884121, -6.8582561647],
            [-35.5650834582, -6.8860482116],
            [-35.5650222562, -6.8868550154],
            [-35.563448683, -6.9076043834],
            [-35.5823461504, -6.9192374662],
            [-35.5828006983, -6.9191930912],
            [-35.6045419182, -6.9171301195],
            [-35.6133478392, -6.9162340029],
            [-35.6494742483, -6.9095752304],
            [-35.6497438936, -6.9096255856],
            [-35.6452514251, -6.8830007088],
            [-35.6457401281, -6.8823004423],
            [-35.6530458637, -6.8816716928],
            [-35.6585728146, -6.8676978993],
            [-35.6414216933, -6.8542746926],
            [-35.6313205414, -6.8533965344],
            [-35.6304341669, -6.8535106378],
            [-35.6176019067, -6.8517072791],
            [-35.5874891319, -6.8388810989],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2511707',
        name: 'Pilõezinhos',
        description: 'Pilõezinhos',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5494061954, -6.8832834507],
            [-35.5650834582, -6.8860482116],
            [-35.5782884121, -6.8582561647],
            [-35.5783803506, -6.8580615515],
            [-35.5874891319, -6.8388810989],
            [-35.581441348, -6.8251342971],
            [-35.5800283685, -6.821922321],
            [-35.5724537771, -6.8216617661],
            [-35.5526024, -6.8209779929],
            [-35.5267840342, -6.8200875459],
            [-35.5028914718, -6.8192627791],
            [-35.5079785652, -6.8229801648],
            [-35.5082118398, -6.8342205411],
            [-35.5178272765, -6.8447018223],
            [-35.5200278155, -6.8471012382],
            [-35.5305931386, -6.8586192009],
            [-35.5305884241, -6.8799653368],
            [-35.5494061954, -6.8832834507],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2511806',
        name: 'Pirpirituba',
        description: 'Pirpirituba',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4465081959, -6.7556702406],
            [-35.4451143506, -6.7681825262],
            [-35.4330042832, -6.7526662924],
            [-35.4254313434, -6.7575718421],
            [-35.4176602888, -6.7578644875],
            [-35.4124050274, -6.7637812761],
            [-35.408584614, -6.7654595887],
            [-35.4170079777, -6.7743473542],
            [-35.4237552999, -6.7768708586],
            [-35.4352887124, -6.7873435408],
            [-35.4464301537, -6.7920865938],
            [-35.461536938, -6.8093723556],
            [-35.4644954662, -6.8116522811],
            [-35.4849719426, -6.8186532766],
            [-35.5028914718, -6.8192627791],
            [-35.5267840342, -6.8200875459],
            [-35.5526024, -6.8209779929],
            [-35.5724537771, -6.8216617661],
            [-35.5800283685, -6.821922321],
            [-35.5781105814, -6.8165462695],
            [-35.5743651812, -6.8060465131],
            [-35.5667613779, -6.8004518702],
            [-35.5282136698, -6.7711257648],
            [-35.5212667001, -6.7581269633],
            [-35.5099084619, -6.7581651683],
            [-35.500832783, -6.7705121985],
            [-35.4983620937, -6.7722191847],
            [-35.4920685695, -6.7744070694],
            [-35.4830507836, -6.7742402954],
            [-35.4745424008, -6.7695626549],
            [-35.4590120651, -6.7529308245],
            [-35.4431405537, -6.7513854061],
            [-35.4433251995, -6.7520285622],
            [-35.4465081959, -6.7556702406],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2511905', name: 'Pitimbu', description: 'Pitimbu' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.8571417167, -7.3413783973],
            [-34.8518865156, -7.3472215049],
            [-34.8438845362, -7.3435292127],
            [-34.8198386079, -7.3493676507],
            [-34.8153105882, -7.3543930346],
            [-34.8176098732, -7.3645355871],
            [-34.8129772048, -7.3638672559],
            [-34.8125817188, -7.367752484],
            [-34.8099447389, -7.3669345102],
            [-34.8083358146, -7.3762037352],
            [-34.8019761826, -7.3830706056],
            [-34.8111351488, -7.4590588737],
            [-34.8033302679, -7.471685671],
            [-34.8107202056, -7.4778647016],
            [-34.8146081705, -7.4863177212],
            [-34.8094454377, -7.5115206419],
            [-34.8199963336, -7.5281664489],
            [-34.8258312229, -7.5473690372],
            [-34.8319414177, -7.5487225312],
            [-34.8382652863, -7.5438886558],
            [-34.8400378813, -7.543423707],
            [-34.8535213984, -7.5392372091],
            [-34.8504336201, -7.5332572368],
            [-34.8549685401, -7.5077405474],
            [-34.8550646444, -7.5072016088],
            [-34.8545945146, -7.4632405314],
            [-34.8491591007, -7.4384676405],
            [-34.8489178438, -7.4378239775],
            [-34.8624415165, -7.4290271702],
            [-34.8634188592, -7.4335125082],
            [-34.870873913, -7.4293306332],
            [-34.8812188393, -7.4169193812],
            [-34.8859235692, -7.4164138243],
            [-34.9007658841, -7.4165159739],
            [-34.910498816, -7.410331456],
            [-34.896573341, -7.3928826883],
            [-34.8740908031, -7.3647185995],
            [-34.8709134575, -7.3607388312],
            [-34.8696947164, -7.3545029789],
            [-34.8595293685, -7.3429337252],
            [-34.8571417167, -7.3413783973],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2512002', name: 'Pocinhos', description: 'Pocinhos' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.9646598911, -6.9658256382],
            [-35.9667095304, -6.9742803013],
            [-35.9788765697, -6.9842493872],
            [-35.95686983, -7.0164563704],
            [-35.957026528, -7.0167976172],
            [-35.9625672569, -7.0244104351],
            [-35.9555065644, -7.0242186816],
            [-35.9501391237, -7.0291515734],
            [-35.9586534799, -7.04564543],
            [-35.9582937503, -7.0593984754],
            [-35.9652429174, -7.0620964489],
            [-35.9692456356, -7.0678678162],
            [-35.962625044, -7.068611991],
            [-35.9623975416, -7.0686380954],
            [-35.9739049831, -7.07899519],
            [-35.9791589891, -7.1004712797],
            [-36.0014570209, -7.1291638541],
            [-36.0086699083, -7.1384482497],
            [-36.0087707069, -7.1385770952],
            [-36.0293886743, -7.1651188875],
            [-36.0326087925, -7.1661915046],
            [-36.0333127364, -7.1729075154],
            [-36.0338356728, -7.1732618166],
            [-36.0452637166, -7.1653747466],
            [-36.0629268851, -7.1657743416],
            [-36.0889599251, -7.155381562],
            [-36.0945109649, -7.1536040903],
            [-36.1093994184, -7.1517513245],
            [-36.1398035102, -7.163982165],
            [-36.1703911136, -7.2014289494],
            [-36.2242299175, -7.1970202143],
            [-36.220019494, -7.1878567416],
            [-36.2184301901, -7.1773767713],
            [-36.2211094816, -7.1669881263],
            [-36.2182531106, -7.1616358609],
            [-36.2218333122, -7.1611910233],
            [-36.2196705803, -7.1545740845],
            [-36.226254932, -7.1369105657],
            [-36.2227699869, -7.1345843778],
            [-36.2250925284, -7.1212938038],
            [-36.2312324625, -7.1149392562],
            [-36.2332402737, -7.1047216216],
            [-36.2332606238, -7.1046195839],
            [-36.2334769163, -7.0891799548],
            [-36.2401488037, -7.088438819],
            [-36.2446077478, -7.0801263015],
            [-36.2402414807, -7.072853432],
            [-36.2363623315, -7.074288464],
            [-36.2294005149, -7.0691385783],
            [-36.2046762932, -7.0673776618],
            [-36.201372681, -7.0624031366],
            [-36.1994474566, -7.0249127744],
            [-36.1938856209, -7.0178487762],
            [-36.1868957511, -7.0019017288],
            [-36.1870507151, -6.9942404731],
            [-36.1800732391, -6.9847547388],
            [-36.1755282829, -6.9791589363],
            [-36.1672532596, -6.9783804649],
            [-36.1529002941, -6.9694313766],
            [-36.1473626383, -6.9435374504],
            [-36.1462167269, -6.9432916807],
            [-36.1125168163, -6.9307651015],
            [-36.0776573225, -6.9707228827],
            [-36.0564967846, -6.9710007492],
            [-36.0489817773, -6.9771783566],
            [-36.0198829337, -6.9614709253],
            [-36.0175430481, -6.9520884961],
            [-36.0108025338, -6.9476008025],
            [-36.0108278778, -6.9437023473],
            [-35.9842343959, -6.9314330648],
            [-35.9798332777, -6.9242820519],
            [-35.9637691206, -6.9222110035],
            [-35.9514980948, -6.9090603163],
            [-35.950269421, -6.920003133],
            [-35.9408810686, -6.9375751316],
            [-35.9408579318, -6.9377027872],
            [-35.9390919282, -6.9475896759],
            [-35.9646598911, -6.9658256382],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512036',
        name: 'Poço Dantas',
        description: 'Poço Dantas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4756878288, -6.3453297562],
            [-38.4901819163, -6.3656777032],
            [-38.4827308372, -6.3807153057],
            [-38.485882286, -6.3983536984],
            [-38.465457493, -6.3960119023],
            [-38.4704883102, -6.3997788971],
            [-38.4684771889, -6.4114242299],
            [-38.4806832163, -6.4363012925],
            [-38.4884316996, -6.4391330068],
            [-38.4894629887, -6.439211243],
            [-38.5009526371, -6.4405133486],
            [-38.5047341226, -6.4462091307],
            [-38.5120291182, -6.4476328633],
            [-38.5347218075, -6.4445670005],
            [-38.5454733276, -6.4343790585],
            [-38.555092791, -6.4371115164],
            [-38.5539129193, -6.4323648486],
            [-38.5241645252, -6.4066848943],
            [-38.5175572083, -6.408333659],
            [-38.5259616681, -6.3943291831],
            [-38.5304809449, -6.3927283593],
            [-38.5423631538, -6.4015821121],
            [-38.5511701053, -6.3977692289],
            [-38.5730613044, -6.4034013138],
            [-38.577372321, -6.4024854745],
            [-38.5820046005, -6.3999127668],
            [-38.5811938846, -6.3963344031],
            [-38.58896109, -6.3951812918],
            [-38.5937751933, -6.3964628243],
            [-38.6016944251, -6.3892243628],
            [-38.5945906314, -6.3861722321],
            [-38.5921126267, -6.3778483499],
            [-38.5826106804, -6.3736463391],
            [-38.5727545852, -6.3745197058],
            [-38.5757227023, -6.3622942762],
            [-38.5670007411, -6.3611278158],
            [-38.5649076428, -6.3562957254],
            [-38.5629557057, -6.3556344055],
            [-38.5447127165, -6.3533598313],
            [-38.549497177, -6.3503048724],
            [-38.5446609012, -6.3441911373],
            [-38.5337551218, -6.3415286433],
            [-38.516247229, -6.3458368394],
            [-38.5050692085, -6.356111462],
            [-38.493823554, -6.3444197414],
            [-38.4845631543, -6.3397374714],
            [-38.4808772076, -6.3369750576],
            [-38.4746325715, -6.3328480029],
            [-38.4580349289, -6.3298160636],
            [-38.4756878288, -6.3453297562],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512077',
        name: 'Poço de José de Moura',
        description: 'Poço de José de Moura',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.533127171, -6.5150688151],
            [-38.5263652715, -6.5218242452],
            [-38.5032535958, -6.5164027762],
            [-38.4987421041, -6.5274826548],
            [-38.4947351704, -6.5562273999],
            [-38.4925324131, -6.5720093533],
            [-38.45774172, -6.5683585166],
            [-38.4360775304, -6.5742868344],
            [-38.4492876022, -6.589885463],
            [-38.4553532772, -6.6081856047],
            [-38.4757377952, -6.6358980944],
            [-38.4754142737, -6.6492386925],
            [-38.4828768659, -6.6598231778],
            [-38.4928095555, -6.6638297492],
            [-38.4966631013, -6.6621177167],
            [-38.508751464, -6.6602620509],
            [-38.5159455687, -6.6453738928],
            [-38.5155768901, -6.6380704691],
            [-38.5154507148, -6.6355672267],
            [-38.5262371529, -6.6361122725],
            [-38.5276042843, -6.6185159233],
            [-38.5324051643, -6.6091610933],
            [-38.5387843377, -6.5984613825],
            [-38.5360862119, -6.5943129044],
            [-38.5451893063, -6.5903453424],
            [-38.5430180164, -6.5787283318],
            [-38.5384990329, -6.5738202872],
            [-38.5408061407, -6.5498108474],
            [-38.5408212169, -6.549705651],
            [-38.5319253783, -6.5489305177],
            [-38.5360995043, -6.5225035814],
            [-38.5323775137, -6.5217761467],
            [-38.533127171, -6.5150688151],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2512101', name: 'Pombal', description: 'Pombal' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.9661569795, -6.6136247649],
            [-37.9602182554, -6.6275502507],
            [-37.9530729841, -6.6222625674],
            [-37.9440893971, -6.6259180684],
            [-37.9255764112, -6.6224369159],
            [-37.906033016, -6.6163440095],
            [-37.8907923028, -6.6315038545],
            [-37.877170239, -6.6263582466],
            [-37.8618027351, -6.6393762957],
            [-37.8450313564, -6.6400698297],
            [-37.8449534199, -6.6400734772],
            [-37.8109421038, -6.6414769147],
            [-37.7844809225, -6.6425682055],
            [-37.7171707487, -6.6841029732],
            [-37.6784335456, -6.7085646734],
            [-37.6582617575, -6.7212962906],
            [-37.6570040806, -6.7222646825],
            [-37.6567726865, -6.7224146555],
            [-37.6471628737, -6.7286496742],
            [-37.6438262641, -6.7436101929],
            [-37.6306088044, -6.756678139],
            [-37.6329626685, -6.7674971532],
            [-37.6223614092, -6.7797207431],
            [-37.6242720938, -6.7871290597],
            [-37.6325400171, -6.796368323],
            [-37.6306958367, -6.8062183831],
            [-37.7013349218, -6.8011400982],
            [-37.7032332305, -6.801003252],
            [-37.7331339471, -6.7980924989],
            [-37.7378163243, -6.802022015],
            [-37.7512301747, -6.8132775588],
            [-37.7508738195, -6.8393257308],
            [-37.7839792939, -6.8489743347],
            [-37.7848574434, -6.8796570635],
            [-37.7853957356, -6.8794335721],
            [-37.7952829535, -6.8792658303],
            [-37.8009472993, -6.8741418908],
            [-37.804677237, -6.8764815214],
            [-37.8202224246, -6.8749071899],
            [-37.8398179582, -6.8683118199],
            [-37.8443582102, -6.8707073611],
            [-37.8566448386, -6.8990389717],
            [-37.8708367132, -6.9022498989],
            [-37.8760761905, -6.9123985628],
            [-37.8766065097, -6.9253522117],
            [-37.8898630458, -6.9320617906],
            [-37.8879780958, -6.9411937913],
            [-37.890841387, -6.9452034022],
            [-37.8949053292, -6.9475781634],
            [-37.9063621209, -6.9525481923],
            [-37.9077794571, -6.9634972631],
            [-37.9191392895, -6.9711137447],
            [-37.9161969791, -6.9790889194],
            [-37.9054994162, -6.9900035708],
            [-37.9000464554, -6.9887376538],
            [-37.8849002837, -7.0055982893],
            [-37.8775716149, -7.0079603366],
            [-37.8702299741, -7.0185309537],
            [-37.8916164236, -7.0098743718],
            [-37.9292879968, -6.9939131412],
            [-37.9483607787, -6.9816900037],
            [-37.9777205519, -6.9628671148],
            [-37.9997217755, -6.948753854],
            [-38.0060705827, -6.943898428],
            [-38.0142942152, -6.8842633767],
            [-37.9971619818, -6.8724919698],
            [-37.9440993422, -6.8708304936],
            [-37.9416646076, -6.8635835303],
            [-37.9331938902, -6.8604055832],
            [-37.9280653961, -6.8549644348],
            [-37.9175260984, -6.8417045264],
            [-37.8903665353, -6.8406602834],
            [-37.8734572621, -6.8231767204],
            [-37.8766570742, -6.8223333885],
            [-37.8740072587, -6.8159351867],
            [-37.8759330104, -6.8120945203],
            [-37.8921396922, -6.807288523],
            [-37.8916154453, -6.8030868231],
            [-37.8901470083, -6.7929001793],
            [-37.8970827028, -6.788372077],
            [-37.8959341217, -6.7840198188],
            [-37.8996700461, -6.7753416577],
            [-37.8958506251, -6.7682933755],
            [-37.897356412, -6.7618049348],
            [-37.9064224689, -6.7570299582],
            [-37.8940963945, -6.7439665317],
            [-37.8974592739, -6.7341372984],
            [-37.9171634592, -6.7357561761],
            [-37.9813299716, -6.7484329722],
            [-38.0096131376, -6.761291471],
            [-38.024508148, -6.7636902346],
            [-38.0245193405, -6.7635729892],
            [-38.0288224163, -6.7171782945],
            [-37.9858326594, -6.7115263199],
            [-37.9828286787, -6.696492868],
            [-37.9978282153, -6.676742748],
            [-38.0115638266, -6.6749909917],
            [-38.012871017, -6.6682838912],
            [-38.0171276629, -6.6464409341],
            [-37.9661569795, -6.6136247649],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2512200', name: 'Prata', description: 'Prata' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.0856432092, -7.5956165829],
            [-37.0759508074, -7.6124718415],
            [-37.0708008349, -7.6117845338],
            [-37.0531128952, -7.6247845496],
            [-37.0462468767, -7.6244772406],
            [-37.0354357178, -7.6353458837],
            [-37.0251413886, -7.633691267],
            [-37.0242929242, -7.65802919],
            [-37.0222397459, -7.7165427946],
            [-37.0248680794, -7.7174433125],
            [-37.0435574923, -7.7374018151],
            [-37.0540667967, -7.743604348],
            [-37.0588422192, -7.7513312788],
            [-37.0794361951, -7.7612783773],
            [-37.0880039109, -7.7608313417],
            [-37.0978022288, -7.7727925392],
            [-37.104056885, -7.7737704677],
            [-37.1147663681, -7.7695439732],
            [-37.1181741454, -7.7758778108],
            [-37.12746422, -7.7736462722],
            [-37.1360583476, -7.7818339273],
            [-37.151608628, -7.7794353178],
            [-37.1564388962, -7.776458598],
            [-37.156690141, -7.7725906332],
            [-37.1386027537, -7.7451081631],
            [-37.1502564222, -7.7286229045],
            [-37.1501554078, -7.7155230873],
            [-37.1541999771, -7.7059945659],
            [-37.1606977668, -7.697557853],
            [-37.1546970541, -7.692935371],
            [-37.1505739655, -7.6824350743],
            [-37.1512028011, -7.6710576803],
            [-37.1394146851, -7.664648915],
            [-37.1324855651, -7.6612248935],
            [-37.1266403637, -7.6479145445],
            [-37.1140517082, -7.640404831],
            [-37.0867167349, -7.6429739931],
            [-37.0861950528, -7.6297994674],
            [-37.0915472706, -7.6238251151],
            [-37.0870683816, -7.615903485],
            [-37.093709335, -7.6060652593],
            [-37.0856432092, -7.5956165829],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512309',
        name: 'Princesa Isabel',
        description: 'Princesa Isabel',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.0009454209, -7.5304509376],
            [-37.9939638856, -7.5376747489],
            [-37.982262074, -7.5343789778],
            [-37.9737474042, -7.532213015],
            [-37.9738839908, -7.5378703684],
            [-37.982104924, -7.5392918342],
            [-37.9837761748, -7.5370265649],
            [-37.9846592084, -7.540034369],
            [-37.9827906166, -7.5457493273],
            [-37.976483905, -7.5466504853],
            [-37.968580793, -7.5537764659],
            [-37.9582735555, -7.5520610123],
            [-37.9535267771, -7.5584780348],
            [-37.9398823935, -7.5556222891],
            [-37.9293485482, -7.5607411426],
            [-37.9328487724, -7.5708845585],
            [-37.9561878115, -7.5844350051],
            [-37.9535572936, -7.6182952803],
            [-37.9545059402, -7.6195050298],
            [-37.9527324801, -7.6233866624],
            [-37.956192712, -7.6281206543],
            [-37.9562221492, -7.6382546466],
            [-37.946802886, -7.6457289696],
            [-37.9433909025, -7.6509303294],
            [-37.9450392022, -7.6577946363],
            [-37.9373375833, -7.6630446654],
            [-37.9336810436, -7.6724347774],
            [-37.9227892744, -7.6791018069],
            [-37.916188011, -7.6825775257],
            [-37.9113321929, -7.6936421244],
            [-37.9045026383, -7.696837108],
            [-37.9029783497, -7.6976911932],
            [-37.89194939, -7.7105287967],
            [-37.8939867048, -7.7132168167],
            [-37.9127188601, -7.709063518],
            [-37.916926477, -7.7113207287],
            [-37.9195701522, -7.7127384366],
            [-37.9206178356, -7.7133006423],
            [-37.9229017874, -7.7156912662],
            [-37.9220025768, -7.7232289213],
            [-37.9427529685, -7.7406271888],
            [-37.9443291475, -7.7460098393],
            [-37.9471965825, -7.7558129773],
            [-37.9580787629, -7.7655749749],
            [-37.9644532537, -7.7722772847],
            [-37.9663261071, -7.7739229928],
            [-37.9709788235, -7.777150762],
            [-37.9826194008, -7.7722387838],
            [-37.9899189584, -7.7784673874],
            [-38.0003707958, -7.7785516843],
            [-38.0051042263, -7.7773530166],
            [-38.0077971663, -7.7664602601],
            [-38.0121141375, -7.7653968655],
            [-38.0295656599, -7.7494264822],
            [-38.0392326189, -7.7477103047],
            [-38.0691290484, -7.7719154272],
            [-38.0685568591, -7.7593028507],
            [-38.0802693151, -7.738618414],
            [-38.0788989932, -7.7250903915],
            [-38.0662228554, -7.7155077693],
            [-38.0607143576, -7.6954027161],
            [-38.0642166842, -7.6773321904],
            [-38.0511900405, -7.6429922993],
            [-38.0551186368, -7.6417116414],
            [-38.0673910706, -7.641857881],
            [-38.0842740953, -7.6420577574],
            [-38.0780541084, -7.6111205024],
            [-38.0729194716, -7.6160536588],
            [-38.0660996798, -7.6159375417],
            [-38.0643504103, -7.6108375548],
            [-38.0683028747, -7.6053415945],
            [-38.0650493539, -7.6025074641],
            [-38.068591174, -7.5929435437],
            [-38.0821276534, -7.5885583558],
            [-38.085213077, -7.5840723766],
            [-38.0893174295, -7.584813193],
            [-38.0879949438, -7.5812132258],
            [-38.0928045306, -7.5736491388],
            [-38.1034158743, -7.5701428439],
            [-38.1020109176, -7.5689464155],
            [-38.0860557949, -7.5596768838],
            [-38.0511125771, -7.5504745216],
            [-38.0466582222, -7.5412031407],
            [-38.0463510394, -7.5142910541],
            [-38.0253841151, -7.5244390327],
            [-38.0009454209, -7.5304509376],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2512408', name: 'Puxinanã', description: 'Puxinanã' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.9684337315, -7.1089858628],
            [-35.9472237032, -7.1090730798],
            [-35.929741536, -7.107318775],
            [-35.9295652121, -7.1200453755],
            [-35.9331995482, -7.1358308006],
            [-35.9283277966, -7.1409553214],
            [-35.9266439409, -7.1434692446],
            [-35.9218183474, -7.1497894529],
            [-35.9204918762, -7.1557642908],
            [-35.9103245481, -7.1655762148],
            [-35.9047624231, -7.1815382629],
            [-35.9376602049, -7.1748887805],
            [-35.939867401, -7.1744428582],
            [-35.9392490917, -7.1860765751],
            [-35.9379386922, -7.1940938524],
            [-35.947283717, -7.1927736047],
            [-35.9540526846, -7.1954049957],
            [-35.9543185668, -7.1957505069],
            [-35.9553933619, -7.1971450591],
            [-35.9638346934, -7.1942336305],
            [-35.9786561816, -7.1761891287],
            [-35.9788552945, -7.1758601572],
            [-35.978576331, -7.1734274706],
            [-35.986714503, -7.1604754459],
            [-35.9964317292, -7.1640042294],
            [-36.0293886743, -7.1651188875],
            [-36.0087707069, -7.1385770952],
            [-36.0086699083, -7.1384482497],
            [-36.0014570209, -7.1291638541],
            [-35.9791589891, -7.1004712797],
            [-35.9685344822, -7.1089585488],
            [-35.9684337315, -7.1089858628],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512507',
        name: 'Queimadas',
        description: 'Queimadas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8879806822, -7.2916847558],
            [-35.8719571643, -7.2997867483],
            [-35.8416665946, -7.3009794847],
            [-35.8382717306, -7.3293866563],
            [-35.8381266573, -7.3306000184],
            [-35.8368833621, -7.3409743582],
            [-35.836552777, -7.3559518631],
            [-35.8377362768, -7.3596259877],
            [-35.8371654556, -7.3620125287],
            [-35.8399694218, -7.3817253767],
            [-35.8400317149, -7.3821614166],
            [-35.8365577911, -7.394984522],
            [-35.8291014234, -7.4052497947],
            [-35.8200815151, -7.4217657484],
            [-35.8317528702, -7.4278844342],
            [-35.8378663206, -7.4396868759],
            [-35.8351511408, -7.4502939081],
            [-35.8371031264, -7.4554057185],
            [-35.8274127367, -7.4532290658],
            [-35.8245283087, -7.4599124611],
            [-35.8367997651, -7.4683683426],
            [-35.8359549452, -7.4896841415],
            [-35.846963443, -7.4922186945],
            [-35.8536228928, -7.5057079417],
            [-35.8592233041, -7.5038443802],
            [-35.864119382, -7.510146709],
            [-35.8688440537, -7.5080037134],
            [-35.8714819434, -7.5124498333],
            [-35.8717967489, -7.5096521049],
            [-35.8738991884, -7.5119068815],
            [-35.8908764384, -7.5099798687],
            [-35.9002714596, -7.5157131406],
            [-35.9023975856, -7.5077057017],
            [-35.9147190052, -7.5058409974],
            [-35.9190646588, -7.512501595],
            [-35.937726181, -7.5053304269],
            [-35.9512669258, -7.4928769248],
            [-35.9538840282, -7.4952177453],
            [-35.9578010949, -7.4932384769],
            [-35.9637897852, -7.4882128857],
            [-35.9648657078, -7.4816218709],
            [-35.9726337565, -7.4792686477],
            [-35.9814252294, -7.4696165701],
            [-35.9913843215, -7.4671120848],
            [-35.9926848361, -7.4595736485],
            [-36.0094282384, -7.4532390794],
            [-36.0087613441, -7.4494002966],
            [-36.0210104377, -7.4435810214],
            [-36.0152152677, -7.4423721148],
            [-36.01629986, -7.4380125766],
            [-36.0076177072, -7.4379535476],
            [-36.0044681344, -7.4238487493],
            [-36.0170848204, -7.4218510844],
            [-36.0169234943, -7.4203805618],
            [-36.0035023289, -7.4106561325],
            [-35.9983111519, -7.4123453485],
            [-35.9994032624, -7.4077914082],
            [-36.0088199254, -7.4025711611],
            [-36.0088836549, -7.4024131298],
            [-36.0080282235, -7.3964698157],
            [-35.9990662106, -7.3899687702],
            [-36.003014839, -7.3842691945],
            [-36.003567626, -7.3805622836],
            [-35.9862370905, -7.367755026],
            [-35.9860662589, -7.3481001144],
            [-35.9871342629, -7.3453276901],
            [-35.9818143141, -7.3439145436],
            [-35.9829953934, -7.3401000462],
            [-35.9795559572, -7.3391963526],
            [-35.9827800692, -7.3219511096],
            [-35.9775760235, -7.3198882518],
            [-35.977632137, -7.3133746831],
            [-35.9768729362, -7.3014582947],
            [-35.9825784668, -7.2957756093],
            [-35.9616786676, -7.2977708538],
            [-35.9620130168, -7.2881743479],
            [-35.9570180716, -7.287239102],
            [-35.9506315705, -7.2793178542],
            [-35.9441708726, -7.2797734739],
            [-35.9446727595, -7.2846234691],
            [-35.9392318581, -7.2858162478],
            [-35.9091940063, -7.2861959032],
            [-35.8877496527, -7.286465295],
            [-35.8879806822, -7.2916847558],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2512606', name: 'Quixabá', description: 'Quixabá' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.0928039979, -7.0231792183],
            [-37.0868593723, -7.0230075075],
            [-37.0722176215, -7.0132706063],
            [-37.0674883859, -7.0195785895],
            [-37.0555713489, -7.0240927298],
            [-37.0589696516, -7.0372248561],
            [-37.0184232961, -7.0483002863],
            [-37.0048936774, -7.0514676731],
            [-37.0065939758, -7.0520879186],
            [-37.0394379288, -7.056776367],
            [-37.0449550455, -7.063412472],
            [-37.0599462006, -7.0670776569],
            [-37.064458053, -7.0713213616],
            [-37.0828085633, -7.0722642703],
            [-37.0882033012, -7.0808203867],
            [-37.0887904472, -7.0922345264],
            [-37.0930830362, -7.0995654351],
            [-37.0866377335, -7.1087284957],
            [-37.0960821826, -7.0994204034],
            [-37.1301986279, -7.0858734393],
            [-37.141372257, -7.0770025103],
            [-37.170118462, -7.0650074875],
            [-37.1767711067, -7.0663450208],
            [-37.1893625856, -7.0588857813],
            [-37.2133437048, -7.0813094723],
            [-37.2165140462, -7.0731655741],
            [-37.2386700057, -7.056527364],
            [-37.2330119499, -7.0454386974],
            [-37.230232304, -7.0464947908],
            [-37.2238039853, -7.038305159],
            [-37.2276966063, -7.019275243],
            [-37.2038070077, -7.0001738727],
            [-37.1726724428, -6.9818058551],
            [-37.1725739498, -6.9818493394],
            [-37.1522525721, -6.9907925951],
            [-37.1404040532, -7.0016347358],
            [-37.1297593819, -7.0020581422],
            [-37.1263581853, -7.0098596496],
            [-37.1148330118, -7.012140395],
            [-37.1042398604, -7.0205628013],
            [-37.0928039979, -7.0231792183],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2512705', name: 'Remígio', description: 'Remígio' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8191453475, -6.873825592],
            [-35.8091401945, -6.8797710252],
            [-35.8071057869, -6.8733909152],
            [-35.792786931, -6.8961258232],
            [-35.7920014918, -6.9150334041],
            [-35.7914976214, -6.9363071859],
            [-35.7910733946, -6.9377316149],
            [-35.7741289262, -6.9619369933],
            [-35.7760398081, -6.9837175646],
            [-35.7760685634, -6.9840400245],
            [-35.7741210188, -6.9888220425],
            [-35.7747300799, -6.9955008241],
            [-35.7777519113, -7.0033251322],
            [-35.7820793341, -7.0185473838],
            [-35.7939339363, -7.014027995],
            [-35.8042194929, -7.0200922674],
            [-35.8058053969, -7.0136174928],
            [-35.8108515367, -7.0101073534],
            [-35.8177666311, -6.9997037845],
            [-35.8173576268, -6.9873711309],
            [-35.8145305938, -6.9843895386],
            [-35.8230935422, -6.9796466533],
            [-35.8233324527, -6.9795149371],
            [-35.8327359918, -6.9743049724],
            [-35.8516675039, -6.9665813116],
            [-35.865215401, -6.9541908369],
            [-35.8849564962, -6.9510529424],
            [-35.8983887759, -6.9442309041],
            [-35.9048885322, -6.9374273093],
            [-35.9187916893, -6.9319981393],
            [-35.9408810686, -6.9375751316],
            [-35.950269421, -6.920003133],
            [-35.9514980948, -6.9090603163],
            [-35.9449869315, -6.9015198699],
            [-35.951618182, -6.890023335],
            [-35.9031636009, -6.8898914716],
            [-35.8929453136, -6.8764902335],
            [-35.8725391333, -6.8613045091],
            [-35.8490456359, -6.855295096],
            [-35.8191453475, -6.873825592],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512721',
        name: 'Pedro Régis',
        description: 'Pedro Régis',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3164777621, -6.624306621],
            [-35.2921165827, -6.6274349136],
            [-35.2873102299, -6.6256300517],
            [-35.2689111056, -6.6363331684],
            [-35.2611368518, -6.6413822157],
            [-35.2591264889, -6.6485463896],
            [-35.2462261173, -6.659336492],
            [-35.2450948924, -6.6644644659],
            [-35.2589806345, -6.6644008114],
            [-35.2425578453, -6.6950458875],
            [-35.2441593267, -6.6999557017],
            [-35.2533807401, -6.7045948004],
            [-35.2631062681, -6.7094879295],
            [-35.2719695036, -6.706644685],
            [-35.2823631367, -6.7080604193],
            [-35.2849018509, -6.7070567478],
            [-35.2914200386, -6.7138234697],
            [-35.2974366233, -6.717183677],
            [-35.3095325299, -6.7137804119],
            [-35.3125041324, -6.7082317552],
            [-35.319795896, -6.7078157668],
            [-35.3192155616, -6.6761310594],
            [-35.3224534319, -6.6664516653],
            [-35.333312386, -6.6520806445],
            [-35.3403613873, -6.6508780986],
            [-35.335171684, -6.6378612093],
            [-35.3348403942, -6.637027606],
            [-35.3283793556, -6.6208270533],
            [-35.3164777621, -6.624306621],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2512747', name: 'Riachão', description: 'Riachão' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.5701268464, -6.5326941583],
            [-35.5580406993, -6.5457562321],
            [-35.578831041, -6.5523513974],
            [-35.6021572213, -6.5439717008],
            [-35.6124973964, -6.5460154838],
            [-35.6161458101, -6.5473924229],
            [-35.6210452079, -6.5658024683],
            [-35.639786457, -6.5670703296],
            [-35.6568494395, -6.5754023511],
            [-35.6600125134, -6.5810137943],
            [-35.6748399639, -6.582783486],
            [-35.6898150536, -6.5907318172],
            [-35.7083448933, -6.5891506541],
            [-35.7192582071, -6.5686889981],
            [-35.7207800759, -6.5655482335],
            [-35.7127032285, -6.565419181],
            [-35.6917131059, -6.5543107263],
            [-35.6871057958, -6.5335636313],
            [-35.6639579185, -6.5004002031],
            [-35.653109445, -6.4914300143],
            [-35.6291844387, -6.500094155],
            [-35.5754949783, -6.5273309101],
            [-35.5701268464, -6.5326941583],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512754',
        name: 'Riachão do Bacamarte',
        description: 'Riachão do Bacamarte',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.635683212, -7.222385637],
            [-35.6311135135, -7.2274796036],
            [-35.6253780346, -7.225228362],
            [-35.622122951, -7.2292831114],
            [-35.6221461712, -7.2332560743],
            [-35.6291111367, -7.2332814839],
            [-35.6319869115, -7.2421742803],
            [-35.6305959784, -7.2537199041],
            [-35.6354904088, -7.2499203677],
            [-35.6446435808, -7.2506968483],
            [-35.6646285383, -7.258220498],
            [-35.6709551322, -7.2657932521],
            [-35.6782118855, -7.268795027],
            [-35.70381155, -7.2598254776],
            [-35.6996272812, -7.2257123993],
            [-35.6995961113, -7.2254596783],
            [-35.6991562006, -7.2218749445],
            [-35.6981636861, -7.2184155744],
            [-35.6952762376, -7.2172260298],
            [-35.671350921, -7.207373079],
            [-35.6475020928, -7.2187029909],
            [-35.635683212, -7.222385637],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512762',
        name: 'Riachão do Poço',
        description: 'Riachão do Poço',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.259399598, -7.1635477936],
            [-35.2812299392, -7.1879738068],
            [-35.2891460078, -7.1860692638],
            [-35.2939960708, -7.1849034736],
            [-35.3323669277, -7.1746255755],
            [-35.3265239701, -7.1564293667],
            [-35.31163873, -7.1512116703],
            [-35.3058625564, -7.1414189914],
            [-35.2874933917, -7.1085551955],
            [-35.2839010647, -7.1106340036],
            [-35.2850849516, -7.1164492759],
            [-35.2729386516, -7.1161209759],
            [-35.2750234853, -7.1211214899],
            [-35.2706233748, -7.1321009298],
            [-35.2610344569, -7.1375977443],
            [-35.2558171251, -7.1403149475],
            [-35.2556049708, -7.1452146655],
            [-35.2585165207, -7.1576258818],
            [-35.259399598, -7.1635477936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512788',
        name: 'Riacho de Santo Antônio',
        description: 'Riacho de Santo Antônio',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.07132572, -7.6039162326],
            [-36.0712920954, -7.6129480081],
            [-36.0755127127, -7.613936354],
            [-36.087367242, -7.6160589669],
            [-36.0894164282, -7.6125849392],
            [-36.0917071928, -7.6160635817],
            [-36.0963092767, -7.6160681342],
            [-36.0975462988, -7.6237915777],
            [-36.1034410204, -7.6196290967],
            [-36.1041575143, -7.6295475678],
            [-36.1110005086, -7.6230732623],
            [-36.1165990409, -7.6301544588],
            [-36.1098447379, -7.6361833676],
            [-36.1156065435, -7.6407019886],
            [-36.1234143155, -7.6359785841],
            [-36.1314684819, -7.6386580715],
            [-36.1257164945, -7.6442184467],
            [-36.1283545606, -7.6501457066],
            [-36.1262175549, -7.659611564],
            [-36.1309031811, -7.6679696591],
            [-36.1047359573, -7.7173203375],
            [-36.1071081613, -7.7249505338],
            [-36.1170649247, -7.7344850443],
            [-36.1189139809, -7.7515969803],
            [-36.1273599857, -7.7690433309],
            [-36.1351398682, -7.7616592309],
            [-36.1535683124, -7.7569736932],
            [-36.1581520774, -7.7523054831],
            [-36.1627360895, -7.7306289934],
            [-36.1594616505, -7.6936921047],
            [-36.1596635096, -7.688444338],
            [-36.1976328724, -7.6450574126],
            [-36.1930055104, -7.6457639196],
            [-36.1894482964, -7.6382270574],
            [-36.1857740374, -7.6410828405],
            [-36.1791552397, -7.6400189143],
            [-36.173762926, -7.635349274],
            [-36.1693136092, -7.6400568841],
            [-36.1609735887, -7.6363368541],
            [-36.1575365392, -7.6382462879],
            [-36.1519070331, -7.6351806798],
            [-36.1527475467, -7.6310368924],
            [-36.1459261681, -7.6242417093],
            [-36.1421702894, -7.623580011],
            [-36.1423525363, -7.6199139154],
            [-36.1365213914, -7.6226011057],
            [-36.1269433931, -7.6111046304],
            [-36.1205181455, -7.608643196],
            [-36.116080253, -7.6113116058],
            [-36.1033382692, -7.6016301433],
            [-36.0869535733, -7.6004594194],
            [-36.07132572, -7.6039162326],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512804',
        name: 'Riacho dos Cavalos',
        description: 'Riacho dos Cavalos',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.5093344324, -6.508290973],
            [-37.5083324786, -6.5261721837],
            [-37.5296057211, -6.5402664506],
            [-37.5393035698, -6.5437871447],
            [-37.5723618756, -6.5504459757],
            [-37.5806509867, -6.547329444],
            [-37.6036529173, -6.536303066],
            [-37.6138615095, -6.5145793178],
            [-37.6179493448, -6.5151763054],
            [-37.6202632152, -6.5278975815],
            [-37.6287453575, -6.5283164604],
            [-37.6290768019, -6.528332899],
            [-37.6438785583, -6.5322243967],
            [-37.6487640308, -6.5465694808],
            [-37.6655312201, -6.547585141],
            [-37.665493464, -6.5412897643],
            [-37.6659830697, -6.5367535418],
            [-37.6840638955, -6.5273449633],
            [-37.6846149307, -6.5242930248],
            [-37.6905275905, -6.5248866108],
            [-37.6916830237, -6.5189398487],
            [-37.701598667, -6.5140433894],
            [-37.7099613573, -6.5002429627],
            [-37.7174569098, -6.4990528457],
            [-37.7299657972, -6.4963161044],
            [-37.7330441752, -6.487857835],
            [-37.7332709885, -6.4435871295],
            [-37.7333108123, -6.4355082592],
            [-37.7335296421, -6.3905080198],
            [-37.7235870899, -6.3932717076],
            [-37.6385480317, -6.4168860286],
            [-37.5784340806, -6.4335577141],
            [-37.5851470818, -6.4401278565],
            [-37.5843763141, -6.4434202256],
            [-37.5753199981, -6.4491934094],
            [-37.5656389852, -6.4672202253],
            [-37.5557004929, -6.4731501589],
            [-37.5357403717, -6.4974067262],
            [-37.5169206163, -6.5075780775],
            [-37.5093344324, -6.508290973],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2512903',
        name: 'Rio Tinto',
        description: 'Rio Tinto',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.0247051271, -6.7856490355],
            [-35.0213619534, -6.7908851687],
            [-35.0150332529, -6.7889165647],
            [-35.0117216293, -6.7948623096],
            [-35.0065558885, -6.7936825001],
            [-35.0011045304, -6.7973188571],
            [-34.9960892389, -6.7946231792],
            [-34.995967617, -6.7871543167],
            [-34.9930275762, -6.7851633109],
            [-34.9808446011, -6.7842813944],
            [-34.9719739355, -6.793008084],
            [-34.9561393757, -6.7830260042],
            [-34.9538567393, -6.7796785576],
            [-34.9503611504, -6.764885234],
            [-34.9369559293, -6.7703618227],
            [-34.9256622718, -6.7705927887],
            [-34.9187790133, -6.7667806094],
            [-34.9183343905, -6.7699847423],
            [-34.9154750875, -6.7908051795],
            [-34.9085609529, -6.840231163],
            [-34.9023938486, -6.8546170539],
            [-34.9024413113, -6.8648635938],
            [-34.9169775441, -6.8718349547],
            [-34.9276902208, -6.8834609869],
            [-34.9344959138, -6.8898276675],
            [-34.9496671698, -6.8920340495],
            [-34.9633450515, -6.9000205421],
            [-34.9751948113, -6.9015121466],
            [-34.9888238663, -6.9077766204],
            [-35.037123078, -6.918917285],
            [-35.0589155181, -6.934425565],
            [-35.0754411465, -6.9347735912],
            [-35.0899923826, -6.9395496023],
            [-35.1006473786, -6.9381812344],
            [-35.1242766599, -6.8953754905],
            [-35.1242020948, -6.8953168774],
            [-35.1195961637, -6.8930763377],
            [-35.1033318962, -6.8895749652],
            [-35.0989079727, -6.8856148685],
            [-35.1005501709, -6.8649735928],
            [-35.0975385192, -6.8525002678],
            [-35.0926927185, -6.8471303127],
            [-35.091699832, -6.8433260368],
            [-35.0885566358, -6.8382088024],
            [-35.0917245953, -6.8331869243],
            [-35.0879191659, -6.8309873199],
            [-35.0878533181, -6.8250860893],
            [-35.0904759719, -6.8250025378],
            [-35.1058709568, -6.8245131189],
            [-35.109485376, -6.8243976378],
            [-35.1130650837, -6.8211683483],
            [-35.1320339184, -6.7765412887],
            [-35.1339457026, -6.7660197711],
            [-35.1341630971, -6.7481669766],
            [-35.1341806945, -6.7480216456],
            [-35.1341681549, -6.7477249374],
            [-35.1200102042, -6.7220241781],
            [-35.1178310241, -6.7072399654],
            [-35.113085508, -6.6750735628],
            [-35.1155625209, -6.6660927575],
            [-35.1215906312, -6.6506773487],
            [-35.1214030756, -6.6479367789],
            [-35.1058888185, -6.640720454],
            [-35.0939650561, -6.6273800054],
            [-35.0877978778, -6.6253809707],
            [-35.0577008979, -6.6090792144],
            [-35.0399913819, -6.5972639481],
            [-35.0258305382, -6.5922571641],
            [-35.0201971323, -6.593666334],
            [-35.0006693813, -6.5961823493],
            [-35.0054428323, -6.5976710654],
            [-35.0168712222, -6.6146556167],
            [-35.0215541111, -6.6238046141],
            [-35.0369315143, -6.6693002286],
            [-35.0312760245, -6.7035968766],
            [-35.0274180893, -6.7081179467],
            [-35.0558559734, -6.7190194112],
            [-35.056041509, -6.7190425286],
            [-35.0813404492, -6.7221440957],
            [-35.0863029983, -6.7249340773],
            [-35.0919934352, -6.7386416796],
            [-35.066695167, -6.7666287706],
            [-35.0602476844, -6.7737578614],
            [-35.0598941983, -6.7741490728],
            [-35.0447300996, -6.7743020642],
            [-35.0247051271, -6.7856490355],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513000',
        name: 'Salgadinho',
        description: 'Salgadinho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.7424816584, -7.0325565108],
            [-36.7537184727, -7.0425360648],
            [-36.7500372071, -7.0582551099],
            [-36.7509922193, -7.0795068676],
            [-36.7511642394, -7.0798402964],
            [-36.77812237, -7.1121251154],
            [-36.8097714192, -7.1500392731],
            [-36.8330807027, -7.1448203346],
            [-36.8441802083, -7.1461377519],
            [-36.8498448342, -7.1501975695],
            [-36.8582596194, -7.1487698463],
            [-36.8797872853, -7.1589439776],
            [-36.8944193663, -7.1597684718],
            [-36.904688036, -7.1516204682],
            [-36.9191687563, -7.154855545],
            [-36.9306872339, -7.1530459719],
            [-36.9309514101, -7.1496673493],
            [-36.9259157694, -7.1217665774],
            [-36.9232506243, -7.1175824733],
            [-36.9321825894, -7.109478885],
            [-36.9237597069, -7.1051281228],
            [-36.9224374789, -7.0872714597],
            [-36.916287199, -7.0802206219],
            [-36.8980436655, -7.0753765981],
            [-36.8740082645, -7.0614476985],
            [-36.8663523082, -7.0606273657],
            [-36.8580764049, -7.064141345],
            [-36.820459389, -7.0482899073],
            [-36.8041423658, -7.0303931209],
            [-36.7870162876, -7.0360648092],
            [-36.7810521521, -7.0340161777],
            [-36.7700452387, -7.0375454911],
            [-36.7418167384, -7.024889044],
            [-36.7424816584, -7.0325565108],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513109',
        name: 'Salgado de São Félix',
        description: 'Salgado de São Félix',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4177768428, -7.3687173249],
            [-35.4033801197, -7.4116420747],
            [-35.3917955484, -7.4079209333],
            [-35.3889211984, -7.4262725377],
            [-35.3826326599, -7.4266875844],
            [-35.3803626744, -7.4484138764],
            [-35.3823068296, -7.4487212425],
            [-35.4079104182, -7.4607236296],
            [-35.4154059429, -7.4717467921],
            [-35.4220282646, -7.4747672677],
            [-35.4340813842, -7.4703813181],
            [-35.4412409954, -7.4565668171],
            [-35.4517274472, -7.4525448279],
            [-35.4750282302, -7.4444907704],
            [-35.488962381, -7.4497503156],
            [-35.5029731913, -7.4556707706],
            [-35.5072758227, -7.4860861422],
            [-35.5059416592, -7.4969387649],
            [-35.5036907461, -7.499351428],
            [-35.5376123936, -7.4968866678],
            [-35.5408908807, -7.4934651012],
            [-35.5523983406, -7.4812036312],
            [-35.5522974374, -7.4753657482],
            [-35.5459728423, -7.471106157],
            [-35.5500251391, -7.4650453437],
            [-35.5433928698, -7.4493246042],
            [-35.5479628484, -7.4477903051],
            [-35.5510808584, -7.4411685895],
            [-35.5437406964, -7.4277328066],
            [-35.5430981307, -7.4096871478],
            [-35.5412686143, -7.4049702877],
            [-35.5391009836, -7.3978688339],
            [-35.5323628789, -7.3925869907],
            [-35.523251427, -7.3887809636],
            [-35.5227881896, -7.3852795874],
            [-35.5222408496, -7.3841563825],
            [-35.5217551239, -7.3831604055],
            [-35.5130296582, -7.3754989628],
            [-35.5044586662, -7.3744370648],
            [-35.4931951777, -7.3672798148],
            [-35.4860996867, -7.3647512966],
            [-35.4829821438, -7.3645202224],
            [-35.46348444, -7.3602913935],
            [-35.4444966059, -7.3539293575],
            [-35.4427412522, -7.3534954922],
            [-35.4481186554, -7.3468452287],
            [-35.4553812879, -7.3286160352],
            [-35.4377543783, -7.3189090524],
            [-35.4316940482, -7.3186560184],
            [-35.4174707299, -7.3224601887],
            [-35.4018332156, -7.3351864341],
            [-35.4099956353, -7.3397050439],
            [-35.410701897, -7.3453728374],
            [-35.4177768428, -7.3687173249],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513158',
        name: 'Santa Cecília',
        description: 'Santa Cecília',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.9314169378, -7.6810822032],
            [-35.9222832778, -7.681725897],
            [-35.917594038, -7.6769482449],
            [-35.8965443949, -7.6692714299],
            [-35.8894103202, -7.6628561903],
            [-35.8655994653, -7.662912509],
            [-35.8563801001, -7.6656257633],
            [-35.8346182253, -7.6606101819],
            [-35.8381874448, -7.6643902443],
            [-35.836244874, -7.6673995399],
            [-35.8328669899, -7.6663963441],
            [-35.8366524505, -7.6758961806],
            [-35.8292330818, -7.6808417479],
            [-35.827686366, -7.6897153757],
            [-35.8321746734, -7.7133401966],
            [-35.8279405344, -7.720182361],
            [-35.82881262, -7.7378098534],
            [-35.8249106948, -7.7415888019],
            [-35.8158979898, -7.7407699755],
            [-35.8155255762, -7.7462653592],
            [-35.8199909074, -7.748368602],
            [-35.8294620204, -7.7513358887],
            [-35.8327950432, -7.7494875583],
            [-35.8414152452, -7.7465727112],
            [-35.8619537474, -7.750623818],
            [-35.8717867496, -7.749128349],
            [-35.8813694244, -7.7557167507],
            [-35.8807113645, -7.7659326438],
            [-35.8825724701, -7.7715655805],
            [-35.8931653797, -7.7832624012],
            [-35.9028395939, -7.7911606444],
            [-35.9052653988, -7.7920158415],
            [-35.9144566123, -7.7896721496],
            [-35.9325494486, -7.7840134017],
            [-35.943024728, -7.7895760682],
            [-35.9586934309, -7.7868512439],
            [-35.9696262994, -7.789048902],
            [-35.9789599906, -7.8015805326],
            [-35.9924163238, -7.8000542805],
            [-35.9978897028, -7.8133453024],
            [-36.0029598904, -7.8124226017],
            [-36.0212553175, -7.8098282366],
            [-36.0350796276, -7.7957804669],
            [-36.0312398826, -7.7945513919],
            [-36.0296866634, -7.7857647822],
            [-36.0146447591, -7.7648952652],
            [-36.0110324075, -7.765557193],
            [-36.0096626693, -7.7578008795],
            [-36.0076447567, -7.7611669547],
            [-36.0053809587, -7.7588095241],
            [-35.9999859263, -7.7628520215],
            [-35.995987569, -7.7622220041],
            [-35.9926312446, -7.7433361153],
            [-35.9849202802, -7.7373677087],
            [-35.9909948005, -7.7367469033],
            [-35.9921306337, -7.7309717205],
            [-35.9851937904, -7.7312517063],
            [-35.9872736188, -7.7263145102],
            [-35.9838893681, -7.7177114479],
            [-35.9809674235, -7.7173912689],
            [-35.9788403234, -7.7052808967],
            [-35.9758537185, -7.7064008659],
            [-35.9744581757, -7.6943746337],
            [-35.9810059925, -7.6942410175],
            [-35.9818952442, -7.6916985706],
            [-35.9770669839, -7.6844354807],
            [-35.9715608359, -7.6683765288],
            [-35.9595486527, -7.6522763818],
            [-35.9525410469, -7.6493535397],
            [-35.9451953306, -7.6620344726],
            [-35.9340509738, -7.6684895774],
            [-35.9314169378, -7.6810822032],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513208',
        name: 'Santa Cruz',
        description: 'Santa Cruz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.043575111, -6.4515767356],
            [-38.0359083298, -6.459592932],
            [-38.0407256866, -6.4707752546],
            [-38.03382407, -6.4754480053],
            [-38.0264107741, -6.4743576962],
            [-38.0169603188, -6.4793288395],
            [-38.0191389815, -6.5029647363],
            [-38.0269955787, -6.5248016308],
            [-38.0256407592, -6.5276042822],
            [-38.0148941803, -6.5272447346],
            [-37.9975094349, -6.5273387605],
            [-37.9795230429, -6.5312334182],
            [-37.9619695652, -6.5694603019],
            [-37.9686994417, -6.5780739884],
            [-37.9732946658, -6.5929315753],
            [-37.9629601381, -6.6048782766],
            [-37.9621115288, -6.6092017123],
            [-37.9661569795, -6.6136247649],
            [-37.9775657558, -6.6086759026],
            [-37.9878672078, -6.6089506353],
            [-37.992593771, -6.6046125327],
            [-38.0112778674, -6.6115766969],
            [-38.035903009, -6.6142546156],
            [-38.0428995242, -6.6094417289],
            [-38.0486963364, -6.5984227266],
            [-38.0492826106, -6.5834105911],
            [-38.0739652963, -6.5819472423],
            [-38.0891898757, -6.5855345622],
            [-38.107416571, -6.6040077325],
            [-38.118033474, -6.5977563178],
            [-38.1193476267, -6.5946385278],
            [-38.1270231097, -6.5968591165],
            [-38.1421153741, -6.581063303],
            [-38.1547215685, -6.5745386232],
            [-38.1525934268, -6.5582354452],
            [-38.1525210494, -6.5576771809],
            [-38.1519238424, -6.5531007124],
            [-38.1400723057, -6.540159698],
            [-38.1253320508, -6.5326275759],
            [-38.1198631646, -6.5219815876],
            [-38.1167086733, -6.5222693033],
            [-38.0976389981, -6.5090916331],
            [-38.0950491166, -6.4931871972],
            [-38.0826690571, -6.485488644],
            [-38.0705177069, -6.4635278523],
            [-38.0662692428, -6.4600403585],
            [-38.0615879432, -6.4590489329],
            [-38.0588946046, -6.4527309948],
            [-38.053606635, -6.4532851315],
            [-38.0511038721, -6.442006842],
            [-38.0459995417, -6.4405317373],
            [-38.0430495974, -6.4429499977],
            [-38.043575111, -6.4515767356],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513307',
        name: 'Santa Helena',
        description: 'Santa Helena',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5711112589, -6.7047453758],
            [-38.5571829213, -6.706150076],
            [-38.5458713537, -6.6988351063],
            [-38.5403386926, -6.7000177776],
            [-38.5345572437, -6.7026552131],
            [-38.5221235431, -6.7039820789],
            [-38.5026052144, -6.7078529781],
            [-38.5027735485, -6.7174041532],
            [-38.4936802793, -6.7199015484],
            [-38.4889497306, -6.7259050056],
            [-38.4896530556, -6.735890186],
            [-38.4857355577, -6.7370262788],
            [-38.4900847514, -6.7494191072],
            [-38.4890967314, -6.757067123],
            [-38.4955897286, -6.7691345838],
            [-38.4946810559, -6.7855584836],
            [-38.5089388883, -6.7901092334],
            [-38.5170942995, -6.7970780071],
            [-38.5245157926, -6.7961187265],
            [-38.5468464577, -6.7910982546],
            [-38.5621792943, -6.796475784],
            [-38.5674355269, -6.7905317574],
            [-38.5970533513, -6.7871907459],
            [-38.6135745027, -6.7820373882],
            [-38.6286713146, -6.7773082687],
            [-38.6399145349, -6.7635889417],
            [-38.6523266964, -6.7593634686],
            [-38.6520495606, -6.7566513351],
            [-38.6496185138, -6.751835672],
            [-38.6571802989, -6.7403595525],
            [-38.6556227441, -6.7298433197],
            [-38.6659655383, -6.7194085832],
            [-38.67251926, -6.7063204421],
            [-38.6696828699, -6.7021480376],
            [-38.6727967659, -6.6968210213],
            [-38.6641089806, -6.6797895669],
            [-38.660229814, -6.678401847],
            [-38.6620069005, -6.673917854],
            [-38.6516112222, -6.672782522],
            [-38.6468631591, -6.6635961171],
            [-38.6466118867, -6.6538076901],
            [-38.6567277459, -6.6418897787],
            [-38.6549098543, -6.6300791016],
            [-38.648775888, -6.6271861886],
            [-38.6281065482, -6.6468256324],
            [-38.6207649555, -6.6489339738],
            [-38.6113780826, -6.6588289241],
            [-38.608542921, -6.6693246665],
            [-38.6023055345, -6.6702703573],
            [-38.5853560778, -6.6902257932],
            [-38.579017747, -6.7038351802],
            [-38.5711112589, -6.7047453758],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513356',
        name: 'Santa Inês',
        description: 'Santa Inês',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.6794901708, -7.5743161293],
            [-38.6675001905, -7.5856875819],
            [-38.631448399, -7.5866864055],
            [-38.6201099978, -7.5980767349],
            [-38.599503422, -7.6034137988],
            [-38.5834517083, -7.5962020726],
            [-38.5683105883, -7.5996884488],
            [-38.5435347708, -7.5969248328],
            [-38.5302734813, -7.6002005448],
            [-38.5230091215, -7.6153139469],
            [-38.525425326, -7.6340086198],
            [-38.5091611742, -7.6485083969],
            [-38.500216782, -7.6552134872],
            [-38.4964732951, -7.6515786055],
            [-38.4806631783, -7.649443259],
            [-38.4758512783, -7.6515191182],
            [-38.4700760223, -7.6517392422],
            [-38.4734437314, -7.6587755034],
            [-38.4699451864, -7.6652638544],
            [-38.4771687951, -7.6783325136],
            [-38.4749860282, -7.6866119787],
            [-38.4660221231, -7.6949118389],
            [-38.4547303876, -7.6966200851],
            [-38.449872497, -7.7025174818],
            [-38.4493867844, -7.7066062174],
            [-38.4567308259, -7.7176988005],
            [-38.4636230665, -7.7304830027],
            [-38.4901679399, -7.734253303],
            [-38.4954463382, -7.7384001308],
            [-38.4962224719, -7.7496235009],
            [-38.5162512059, -7.7489209074],
            [-38.5293291173, -7.7546999889],
            [-38.5458365627, -7.749042098],
            [-38.5597769476, -7.7566837626],
            [-38.5717504771, -7.7510664216],
            [-38.5744730462, -7.7539297881],
            [-38.5786767526, -7.7461907599],
            [-38.5896040012, -7.7552740567],
            [-38.59327319, -7.7539408512],
            [-38.5973836226, -7.7416271419],
            [-38.6072219196, -7.7390707418],
            [-38.6076143695, -7.7255397673],
            [-38.6163627129, -7.7130826499],
            [-38.6249749291, -7.7079106132],
            [-38.6260835488, -7.6943178073],
            [-38.6409633561, -7.6860560144],
            [-38.6417288457, -7.6772288405],
            [-38.6510582944, -7.676674343],
            [-38.650355261, -7.6681561532],
            [-38.6583116016, -7.6619046793],
            [-38.6632420206, -7.6571153942],
            [-38.6695845743, -7.6600755263],
            [-38.6724287739, -7.6583880163],
            [-38.6786177481, -7.6461505608],
            [-38.6863863802, -7.6346323837],
            [-38.6956380535, -7.632395366],
            [-38.7050525119, -7.6216362116],
            [-38.7147574178, -7.6215120551],
            [-38.717239438, -7.6264807291],
            [-38.7146195831, -7.6158656493],
            [-38.7179445127, -7.6089484023],
            [-38.7126678752, -7.6079462545],
            [-38.7106693979, -7.5862269531],
            [-38.702259074, -7.5806144665],
            [-38.6884625635, -7.5817790305],
            [-38.6794901708, -7.5743161293],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513406',
        name: 'Santa Luzia',
        description: 'Santa Luzia',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.9043830709, -6.7634368281],
            [-36.8997542338, -6.789407202],
            [-36.8966635016, -6.7917843387],
            [-36.8992849548, -6.803048891],
            [-36.8965686998, -6.8136917094],
            [-36.8683252494, -6.8303463332],
            [-36.8677691801, -6.8306739451],
            [-36.8592908684, -6.839520621],
            [-36.8650692909, -6.8560753335],
            [-36.8424042644, -6.8727017422],
            [-36.8358554502, -6.8687912302],
            [-36.8347674759, -6.8823278078],
            [-36.8282943881, -6.8833951232],
            [-36.8183743645, -6.874685098],
            [-36.813313338, -6.8815330312],
            [-36.801345645, -6.8841828863],
            [-36.7955296645, -6.886032583],
            [-36.7915901494, -6.8941789257],
            [-36.786278273, -6.8949067079],
            [-36.7845722045, -6.9023121145],
            [-36.7768478557, -6.9077131107],
            [-36.7744540822, -6.9145296229],
            [-36.7913148179, -6.9323211173],
            [-36.8662382862, -7.0124005559],
            [-36.8663523082, -7.0606273657],
            [-36.8740082645, -7.0614476985],
            [-36.8980436655, -7.0753765981],
            [-36.916287199, -7.0802206219],
            [-36.9292502974, -7.0574657417],
            [-36.937213727, -7.0468128594],
            [-36.9434942182, -7.0445666196],
            [-36.948199623, -7.0477604058],
            [-36.9590771961, -7.0476638517],
            [-36.9616231082, -7.0323789505],
            [-36.9412351097, -7.014502353],
            [-36.9358738521, -7.0106807525],
            [-36.9333735006, -6.9973004814],
            [-36.9333948876, -6.9925864605],
            [-36.9381082087, -6.9883758368],
            [-36.9430336982, -6.9822109429],
            [-36.9423669498, -6.9591384043],
            [-36.9483829778, -6.958170872],
            [-36.9670235049, -6.9658180117],
            [-36.9709987508, -6.963478705],
            [-36.970782468, -6.9542992919],
            [-36.9629404242, -6.9337997148],
            [-36.974839663, -6.9326110845],
            [-36.9881303056, -6.9357678676],
            [-37.0211993076, -6.9132993006],
            [-37.0212689429, -6.9132522593],
            [-37.0346850879, -6.9041327071],
            [-37.0273198226, -6.8917011327],
            [-37.0117719102, -6.8796067424],
            [-37.0103864857, -6.8740187578],
            [-37.0101244369, -6.8691001768],
            [-36.9549850365, -6.8176140882],
            [-36.9636180057, -6.8070065252],
            [-36.9624729117, -6.799847479],
            [-36.9659907568, -6.7903476007],
            [-36.9587591996, -6.7829006145],
            [-36.9567967753, -6.7900760689],
            [-36.9311836015, -6.7681729429],
            [-36.9098781882, -6.7565275321],
            [-36.9043830709, -6.7634368281],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513505',
        name: 'Santana de Mangueira',
        description: 'Santana de Mangueira',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.296221729, -7.5313381775],
            [-38.2937197644, -7.533070519],
            [-38.2838377303, -7.5304762565],
            [-38.2821880342, -7.5419930202],
            [-38.2791430295, -7.5439871155],
            [-38.27628476, -7.5634448433],
            [-38.2704160223, -7.5657494448],
            [-38.2726850396, -7.5726958652],
            [-38.2679832126, -7.5747764979],
            [-38.2647317305, -7.5697733753],
            [-38.2527732142, -7.5744348669],
            [-38.2473192007, -7.5687795847],
            [-38.2492011969, -7.5739797636],
            [-38.2426134097, -7.581016054],
            [-38.2258881398, -7.5856723299],
            [-38.2280374481, -7.5922682807],
            [-38.186098506, -7.6114808413],
            [-38.1831551496, -7.6129450785],
            [-38.1792141017, -7.6402897131],
            [-38.1993686979, -7.6427860305],
            [-38.2293522497, -7.6464995419],
            [-38.2413884771, -7.654585629],
            [-38.2465204864, -7.6611466663],
            [-38.2441194243, -7.667459546],
            [-38.2561501487, -7.6802368337],
            [-38.2715096689, -7.6970225719],
            [-38.283721122, -7.7027374275],
            [-38.2982072102, -7.7255164752],
            [-38.3105256077, -7.738669285],
            [-38.3155307793, -7.7280645723],
            [-38.3116772652, -7.7159053458],
            [-38.316905517, -7.7151165702],
            [-38.3158526629, -7.7087424499],
            [-38.3204216709, -7.6975499719],
            [-38.3263342069, -7.693296408],
            [-38.3300073552, -7.6957389546],
            [-38.3368459891, -7.6931685429],
            [-38.3443399516, -7.6945886259],
            [-38.3571441851, -7.6767539616],
            [-38.3612647751, -7.6858957081],
            [-38.369722252, -7.6922414534],
            [-38.3705843106, -7.6980071244],
            [-38.3819013923, -7.7070670348],
            [-38.403577902, -7.7140343103],
            [-38.400849808, -7.7201100616],
            [-38.4091547951, -7.730084981],
            [-38.412133704, -7.7296993814],
            [-38.4272722588, -7.7296283749],
            [-38.4338636543, -7.7200431038],
            [-38.4500311706, -7.6680708118],
            [-38.4462160775, -7.6439613084],
            [-38.4382518576, -7.6332683031],
            [-38.4451668353, -7.6246199113],
            [-38.4459089835, -7.6184309679],
            [-38.4385144421, -7.6070725495],
            [-38.4393086918, -7.6018431924],
            [-38.4484778638, -7.5949923627],
            [-38.4502402026, -7.5936756849],
            [-38.4294299274, -7.593930607],
            [-38.3883962465, -7.5944307197],
            [-38.389934165, -7.5801483132],
            [-38.3859736426, -7.5689852015],
            [-38.3925459114, -7.547099483],
            [-38.3853050383, -7.5395008138],
            [-38.3839210615, -7.5353138916],
            [-38.3717792804, -7.5365896959],
            [-38.345132663, -7.5339434228],
            [-38.3220509524, -7.5272445618],
            [-38.3096667432, -7.5184880928],
            [-38.2954752729, -7.5228164256],
            [-38.296221729, -7.5313381775],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513604',
        name: 'Santana dos Garrotes',
        description: 'Santana dos Garrotes',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.9665519354, -7.3127968635],
            [-37.9569014905, -7.3160685821],
            [-37.900008262, -7.3085074977],
            [-37.9067519503, -7.3178578881],
            [-37.9022183384, -7.3252130735],
            [-37.9109253486, -7.3347867536],
            [-37.910478884, -7.340504657],
            [-37.8990294207, -7.3357803118],
            [-37.8925739459, -7.3290542317],
            [-37.8815652073, -7.3372681873],
            [-37.8747961174, -7.3346984519],
            [-37.8651364594, -7.3446649795],
            [-37.8612095575, -7.3846960166],
            [-37.8607510805, -7.4111787549],
            [-37.8538011065, -7.4204210802],
            [-37.8587437056, -7.4304256665],
            [-37.8569226005, -7.4325540868],
            [-37.8551239561, -7.4335506087],
            [-37.8634117972, -7.4366965144],
            [-37.8858582682, -7.4441467492],
            [-37.9209521498, -7.4754366951],
            [-37.9394250241, -7.493188185],
            [-37.9448352711, -7.4935837831],
            [-37.9537721493, -7.5009292328],
            [-37.9718226627, -7.4865336507],
            [-37.9857263206, -7.4758178914],
            [-37.9960214096, -7.450927854],
            [-38.0040348554, -7.4408640636],
            [-38.004046884, -7.4407599389],
            [-38.0226898325, -7.4348906232],
            [-38.0327997437, -7.4282586955],
            [-38.0498661789, -7.41084814],
            [-38.0458115072, -7.4030193268],
            [-38.0529905563, -7.3938552787],
            [-38.0791251345, -7.3889160743],
            [-38.082569645, -7.3795701037],
            [-38.0801253442, -7.3755628085],
            [-38.0702442099, -7.3661502892],
            [-38.0700586143, -7.3569851652],
            [-38.0640604503, -7.3507016479],
            [-38.056202096, -7.3495620937],
            [-38.0525184161, -7.3170607111],
            [-38.0235632698, -7.2908677978],
            [-38.0094422316, -7.284653784],
            [-37.9666556096, -7.31276209],
            [-37.9665519354, -7.3127968635],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2513653', name: 'Santarém', description: 'Santarém' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4539191484, -6.4344584257],
            [-38.4531846627, -6.4696707649],
            [-38.4499468774, -6.5023792145],
            [-38.4518925598, -6.5166110687],
            [-38.4578809922, -6.5255699201],
            [-38.4674399678, -6.5310544203],
            [-38.4987421041, -6.5274826548],
            [-38.5032535958, -6.5164027762],
            [-38.5094456582, -6.5015494422],
            [-38.5130096074, -6.4929983495],
            [-38.5213656477, -6.4716790674],
            [-38.5347218075, -6.4445670005],
            [-38.5120291182, -6.4476328633],
            [-38.5047341226, -6.4462091307],
            [-38.5009526371, -6.4405133486],
            [-38.4894629887, -6.439211243],
            [-38.4884316996, -6.4391330068],
            [-38.4806832163, -6.4363012925],
            [-38.4684771889, -6.4114242299],
            [-38.4579549916, -6.410934249],
            [-38.4539191484, -6.4344584257],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513703',
        name: 'Santa Rita',
        description: 'Santa Rita',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-34.9696978606, -6.9213219298],
            [-34.9338721931, -6.9466813421],
            [-34.9215680344, -6.9553867255],
            [-34.91063714, -6.9493396588],
            [-34.9073096191, -6.9537996006],
            [-34.9054617803, -6.9501729073],
            [-34.9020495466, -6.9551612061],
            [-34.8992138625, -6.9538871626],
            [-34.8984549541, -6.9568900199],
            [-34.8905722608, -6.9594966235],
            [-34.8823218894, -6.9555821714],
            [-34.878820003, -6.959113416],
            [-34.8817135913, -6.9642480788],
            [-34.8704832952, -6.9657512805],
            [-34.8685273211, -6.9789793055],
            [-34.8705816015, -6.9850379925],
            [-34.8680100996, -7.0065115671],
            [-34.8592774996, -7.0267111996],
            [-34.8652184889, -7.0577169754],
            [-34.8916393853, -7.0950088644],
            [-34.9025231796, -7.0884051136],
            [-34.9118371888, -7.0787439142],
            [-34.9154541281, -7.0936387423],
            [-34.9085797571, -7.1021981513],
            [-34.9142526794, -7.1026082423],
            [-34.9115280299, -7.1067668452],
            [-34.9150847303, -7.115764287],
            [-34.9187506365, -7.1151887147],
            [-34.921220238, -7.1224661822],
            [-34.9258345876, -7.1184071057],
            [-34.9412539987, -7.1246710458],
            [-34.9539300853, -7.1445416411],
            [-34.9734694165, -7.1751786857],
            [-34.970247671, -7.1987112693],
            [-34.9678984149, -7.2155366241],
            [-34.9635903005, -7.2140500398],
            [-34.937166662, -7.212797354],
            [-34.9295268927, -7.2255095248],
            [-34.9349408247, -7.2386327809],
            [-34.9410051261, -7.2412879255],
            [-34.9435232956, -7.248536677],
            [-34.9497229086, -7.2753499958],
            [-34.9625062761, -7.2815668425],
            [-34.9686142408, -7.2821279821],
            [-34.9738052964, -7.2738651864],
            [-34.9908259108, -7.2677072221],
            [-35.0174966485, -7.2699980664],
            [-35.039276508, -7.2764032907],
            [-35.0434832383, -7.2783739915],
            [-35.0448503951, -7.2771157082],
            [-35.0446282076, -7.2744310587],
            [-35.0553740734, -7.2667489618],
            [-35.0671827114, -7.2288858822],
            [-35.0539957902, -7.1838529499],
            [-35.0421137948, -7.1433113533],
            [-35.0432238442, -7.1362822348],
            [-35.0389819753, -7.128799214],
            [-35.0473973149, -7.1275833315],
            [-35.0448365562, -7.1206979703],
            [-35.0466496059, -7.1155415658],
            [-35.05168468, -7.1012184354],
            [-35.058895778, -7.080692224],
            [-35.1610091686, -7.0318047807],
            [-35.1291554353, -7.0105509549],
            [-35.1273834157, -7.0094296975],
            [-35.1346343131, -7.0066411503],
            [-35.138171381, -6.9832905513],
            [-35.1459633349, -6.972845957],
            [-35.1395341494, -6.9626935553],
            [-35.1370509355, -6.9444416707],
            [-35.1323175014, -6.9404208617],
            [-35.1062003432, -6.9368284006],
            [-35.1006473786, -6.9381812344],
            [-35.0899923826, -6.9395496023],
            [-35.0754411465, -6.9347735912],
            [-35.0589155181, -6.934425565],
            [-35.037123078, -6.918917285],
            [-34.9888238663, -6.9077766204],
            [-34.9696978606, -6.9213219298],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513802',
        name: 'Santa Teresinha',
        description: 'Santa Teresinha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.4574695811, -6.9913192662],
            [-37.4484303426, -6.9972985114],
            [-37.4365824983, -6.9968564904],
            [-37.4330186195, -6.9976438863],
            [-37.4240874803, -6.9979795137],
            [-37.4171116289, -7.0043221733],
            [-37.410370425, -7.0054194041],
            [-37.3874654497, -6.9985705038],
            [-37.368917436, -6.9997149339],
            [-37.3556315784, -7.0418189408],
            [-37.3520665543, -7.0531067692],
            [-37.3484816637, -7.052839905],
            [-37.3466545213, -7.0842387973],
            [-37.3497876464, -7.1136844935],
            [-37.3612045403, -7.1120956801],
            [-37.3595259018, -7.1258876814],
            [-37.3895094931, -7.1301161045],
            [-37.3883227615, -7.1443109349],
            [-37.3878075556, -7.1504759486],
            [-37.387432382, -7.1635122492],
            [-37.4246130023, -7.1622750313],
            [-37.4262139211, -7.1754603533],
            [-37.4303665796, -7.1809943431],
            [-37.4369276082, -7.185277566],
            [-37.4461660118, -7.1813969943],
            [-37.4513901878, -7.1897907717],
            [-37.4623525175, -7.1947102167],
            [-37.471546585, -7.1968747558],
            [-37.4721705274, -7.206861865],
            [-37.4800658461, -7.2066823465],
            [-37.4940029647, -7.2267748339],
            [-37.4940820695, -7.2268888268],
            [-37.5428718693, -7.1092512773],
            [-37.5120183811, -7.1102199139],
            [-37.5135562147, -7.0869539285],
            [-37.5188572861, -7.0064079446],
            [-37.5192680045, -7.0001441186],
            [-37.5178648656, -7.0000299677],
            [-37.4931954622, -6.9990730524],
            [-37.4698082005, -6.9913825728],
            [-37.4574695811, -6.9913192662],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513851',
        name: 'Santo André',
        description: 'Santo André',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.5492800671, -7.2336052307],
            [-36.5585898775, -7.2449943245],
            [-36.5738836849, -7.2471314104],
            [-36.5603133978, -7.2689147394],
            [-36.5667323504, -7.3232812742],
            [-36.575110002, -7.3229763528],
            [-36.5838024086, -7.3358371658],
            [-36.6004201676, -7.3393528498],
            [-36.6069748172, -7.3458958337],
            [-36.6220722575, -7.3374961105],
            [-36.6512233455, -7.2825849557],
            [-36.6558332065, -7.2783325833],
            [-36.6617523744, -7.2794393358],
            [-36.660952175, -7.2678454577],
            [-36.6552236803, -7.264415868],
            [-36.6616136948, -7.2641986041],
            [-36.6633929716, -7.2587938302],
            [-36.6575788472, -7.2577850215],
            [-36.6577757643, -7.251645654],
            [-36.6606390344, -7.247536444],
            [-36.668831508, -7.2511732038],
            [-36.6808070045, -7.2408668867],
            [-36.6795831438, -7.2365250851],
            [-36.6691691341, -7.2144826723],
            [-36.6688359718, -7.2093201034],
            [-36.6744094303, -7.2093846606],
            [-36.6593037182, -7.1932932855],
            [-36.6562510134, -7.1861395967],
            [-36.6588976447, -7.1815205926],
            [-36.6610880664, -7.1743782497],
            [-36.6585860238, -7.1462599454],
            [-36.6619797524, -7.1302425761],
            [-36.636705581, -7.1294098404],
            [-36.6129939444, -7.1286287677],
            [-36.5913075447, -7.1722079728],
            [-36.5677040587, -7.1788669033],
            [-36.5570507439, -7.1855393537],
            [-36.551809326, -7.1892710988],
            [-36.5433872645, -7.2183367061],
            [-36.5261193621, -7.2283429985],
            [-36.5492800671, -7.2336052307],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513901',
        name: 'São Bento',
        description: 'São Bento',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.3957816272, -6.4007453521],
            [-37.3973276465, -6.4158864689],
            [-37.3862518973, -6.4256381986],
            [-37.3811518936, -6.4355761932],
            [-37.3809706094, -6.4363998627],
            [-37.3809045884, -6.4366974019],
            [-37.3906100151, -6.4524432441],
            [-37.3968894286, -6.5142006178],
            [-37.415367051, -6.5314803346],
            [-37.4360025979, -6.5328448825],
            [-37.4362216083, -6.5328422468],
            [-37.4445527298, -6.5326710597],
            [-37.4643815232, -6.5322639392],
            [-37.4890414897, -6.5123724513],
            [-37.4925132687, -6.509571566],
            [-37.494699723, -6.5235694876],
            [-37.5083324786, -6.5261721837],
            [-37.5093344324, -6.508290973],
            [-37.5169206163, -6.5075780775],
            [-37.5357403717, -6.4974067262],
            [-37.5557004929, -6.4731501589],
            [-37.5656389852, -6.4672202253],
            [-37.5753199981, -6.4491934094],
            [-37.5843763141, -6.4434202256],
            [-37.5851470818, -6.4401278565],
            [-37.5784340806, -6.4335577141],
            [-37.5769324749, -6.4205035582],
            [-37.5718131176, -6.4144170265],
            [-37.561531803, -6.4110139732],
            [-37.5500221201, -6.4151987058],
            [-37.5405166142, -6.4129023198],
            [-37.5241564177, -6.4068046658],
            [-37.5100840681, -6.4117396344],
            [-37.4900621551, -6.4056488012],
            [-37.4778933984, -6.4039052263],
            [-37.4596932902, -6.3946690722],
            [-37.4593339057, -6.3946249571],
            [-37.4123610527, -6.3904760198],
            [-37.3884887732, -6.3905806622],
            [-37.3957816272, -6.4007453521],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513927',
        name: 'São Bentinho',
        description: 'São Bentinho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.694323009, -6.9595329942],
            [-37.7272775617, -6.9914868733],
            [-37.739661355, -6.9537282203],
            [-37.7400878329, -6.9402537325],
            [-37.744446695, -6.9289601057],
            [-37.7429200583, -6.911647121],
            [-37.7508564137, -6.9088624279],
            [-37.7496703516, -6.9009314476],
            [-37.753748308, -6.8978719514],
            [-37.7540833001, -6.8903909229],
            [-37.7848574434, -6.8796570635],
            [-37.7839792939, -6.8489743347],
            [-37.7508738195, -6.8393257308],
            [-37.7512301747, -6.8132775588],
            [-37.7378163243, -6.802022015],
            [-37.7331339471, -6.7980924989],
            [-37.7032332305, -6.801003252],
            [-37.7013349218, -6.8011400982],
            [-37.6306958367, -6.8062183831],
            [-37.627006364, -6.8262380011],
            [-37.6330167325, -6.8408563756],
            [-37.6325282412, -6.8521992547],
            [-37.6360510681, -6.8589537131],
            [-37.6447255992, -6.8630276685],
            [-37.6583768695, -6.878936585],
            [-37.6684476109, -6.8840921925],
            [-37.6792018493, -6.8980358306],
            [-37.6806139482, -6.9051968397],
            [-37.6804802007, -6.9059099849],
            [-37.6800066315, -6.912543658],
            [-37.6849709037, -6.9174483273],
            [-37.6778562127, -6.9273802666],
            [-37.6767896215, -6.9381638139],
            [-37.6961139641, -6.9439597759],
            [-37.694323009, -6.9595329942],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513943',
        name: 'São Domingos do Cariri',
        description: 'São Domingos do Cariri',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.4086688908, -7.4941239796],
            [-36.4008647991, -7.5016933277],
            [-36.3947485257, -7.5009129146],
            [-36.3943378433, -7.5069221758],
            [-36.3903886709, -7.5054862426],
            [-36.386551624, -7.5094526082],
            [-36.3849134372, -7.5061038586],
            [-36.3818887903, -7.5109864403],
            [-36.371857752, -7.5136774462],
            [-36.3706663431, -7.5255463373],
            [-36.3667768183, -7.5261834548],
            [-36.3583544129, -7.5403061885],
            [-36.3526640905, -7.5418666275],
            [-36.3479469667, -7.5484508991],
            [-36.3282774454, -7.5490742176],
            [-36.3332499534, -7.5650076976],
            [-36.3151115653, -7.5921617581],
            [-36.3014655628, -7.5978297045],
            [-36.3191036258, -7.6103235342],
            [-36.331560915, -7.6024466512],
            [-36.3526366202, -7.5994273129],
            [-36.3613420415, -7.6037486661],
            [-36.3683052245, -7.6131270756],
            [-36.3667242773, -7.6199440919],
            [-36.3615004842, -7.6200796368],
            [-36.3658554024, -7.6358940862],
            [-36.3705487667, -7.6381613571],
            [-36.3595339898, -7.6613454959],
            [-36.3530528949, -7.6666352878],
            [-36.3572506536, -7.674071344],
            [-36.4454156137, -7.6741116947],
            [-36.4454440473, -7.6740185396],
            [-36.4429801679, -7.6534326668],
            [-36.4499676705, -7.6522448126],
            [-36.4529610033, -7.6573643726],
            [-36.4567450666, -7.6552647696],
            [-36.4586939108, -7.6591477344],
            [-36.4789466271, -7.6529901476],
            [-36.4618394505, -7.5986367381],
            [-36.427070636, -7.4883165419],
            [-36.4208643993, -7.4943640862],
            [-36.4086688908, -7.4941239796],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513968',
        name: 'São Domingos',
        description: 'São Domingos',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.8734572621, -6.8231767204],
            [-37.8903665353, -6.8406602834],
            [-37.9175260984, -6.8417045264],
            [-37.9280653961, -6.8549644348],
            [-37.9331938902, -6.8604055832],
            [-37.9416646076, -6.8635835303],
            [-37.9440993422, -6.8708304936],
            [-37.9971619818, -6.8724919698],
            [-38.0008202968, -6.8553431219],
            [-38.0116264443, -6.8478125864],
            [-38.0100276125, -6.8310787281],
            [-38.0099638006, -6.8309059606],
            [-38.0026594815, -6.82382358],
            [-37.9949656636, -6.8281732367],
            [-37.9897957027, -6.8265673316],
            [-37.9998670856, -6.8164038914],
            [-38.0096804199, -6.7972514123],
            [-38.0163297369, -6.7881039402],
            [-38.0141355327, -6.7807277539],
            [-38.0162063413, -6.7716750106],
            [-38.024508148, -6.7636902346],
            [-38.0096131376, -6.761291471],
            [-37.9813299716, -6.7484329722],
            [-37.9171634592, -6.7357561761],
            [-37.8974592739, -6.7341372984],
            [-37.8940963945, -6.7439665317],
            [-37.9064224689, -6.7570299582],
            [-37.897356412, -6.7618049348],
            [-37.8958506251, -6.7682933755],
            [-37.8996700461, -6.7753416577],
            [-37.8959341217, -6.7840198188],
            [-37.8970827028, -6.788372077],
            [-37.8901470083, -6.7929001793],
            [-37.8916154453, -6.8030868231],
            [-37.8921396922, -6.807288523],
            [-37.8759330104, -6.8120945203],
            [-37.8740072587, -6.8159351867],
            [-37.8766570742, -6.8223333885],
            [-37.8734572621, -6.8231767204],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2513984',
        name: 'São Francisco',
        description: 'São Francisco',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.0428995242, -6.6094417289],
            [-38.035903009, -6.6142546156],
            [-38.0112778674, -6.6115766969],
            [-37.992593771, -6.6046125327],
            [-37.9878672078, -6.6089506353],
            [-37.9775657558, -6.6086759026],
            [-37.9661569795, -6.6136247649],
            [-38.0171276629, -6.6464409341],
            [-38.012871017, -6.6682838912],
            [-38.0115638266, -6.6749909917],
            [-38.0161044794, -6.670068508],
            [-38.0386555042, -6.6685991205],
            [-38.0487929796, -6.6752692683],
            [-38.0690094027, -6.6727407018],
            [-38.0861134553, -6.6845556015],
            [-38.0870880937, -6.6884259368],
            [-38.0915665508, -6.6887772022],
            [-38.0938738262, -6.6693096399],
            [-38.0925616913, -6.665947281],
            [-38.1019799988, -6.644758113],
            [-38.099056147, -6.6305845239],
            [-38.1029941054, -6.6300237876],
            [-38.107416571, -6.6040077325],
            [-38.0891898757, -6.5855345622],
            [-38.0739652963, -6.5819472423],
            [-38.0492826106, -6.5834105911],
            [-38.0486963364, -6.5984227266],
            [-38.0428995242, -6.6094417289],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514008',
        name: 'São João do Cariri',
        description: 'São João do Cariri',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.3824538093, -7.2983266495],
            [-36.3485866058, -7.3133453776],
            [-36.3392207825, -7.3126483041],
            [-36.3305119826, -7.3071258087],
            [-36.3813651124, -7.4025792855],
            [-36.427070636, -7.4883165419],
            [-36.4618394505, -7.5986367381],
            [-36.4789466271, -7.6529901476],
            [-36.491837756, -7.6433971308],
            [-36.4944798399, -7.6323167029],
            [-36.5122564973, -7.6334061296],
            [-36.5126619503, -7.6372792508],
            [-36.5284016262, -7.6409656442],
            [-36.5413273101, -7.6349151162],
            [-36.5585171109, -7.6359865818],
            [-36.5580475021, -7.6323567585],
            [-36.576811841, -7.6255147312],
            [-36.5891228887, -7.6334512844],
            [-36.5999977935, -7.5819144484],
            [-36.5410355698, -7.5359212084],
            [-36.5304503226, -7.5276662167],
            [-36.5931837812, -7.4451359179],
            [-36.6345871072, -7.393439319],
            [-36.6023584839, -7.3782035038],
            [-36.6009684051, -7.3511344948],
            [-36.5371892531, -7.3509282287],
            [-36.4978442789, -7.3300673316],
            [-36.4778605574, -7.3142310139],
            [-36.4460495671, -7.3056804309],
            [-36.4425780874, -7.3095824801],
            [-36.4335238722, -7.3090016159],
            [-36.422591168, -7.3012626841],
            [-36.4034284951, -7.2998680238],
            [-36.3924944241, -7.302733575],
            [-36.3824538093, -7.2983266495],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514107',
        name: 'São João do Tigre',
        description: 'São João do Tigre',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.572252092, -7.9355627033],
            [-36.5796409059, -7.9482288881],
            [-36.5738498813, -7.9512628133],
            [-36.5728702963, -7.9555403627],
            [-36.5764432091, -7.9592339749],
            [-36.5865228651, -7.9582444246],
            [-36.6115376181, -7.9474860059],
            [-36.6131251234, -7.9561053572],
            [-36.6223148128, -7.9690975284],
            [-36.6228335054, -7.9816696835],
            [-36.6275139192, -7.9859948698],
            [-36.6278591835, -8.0136591767],
            [-36.627514255, -8.0553369543],
            [-36.6338720115, -8.0588965605],
            [-36.6534539157, -8.0699717],
            [-36.6572391035, -8.0751470902],
            [-36.6574926784, -8.0757201792],
            [-36.6569351028, -8.0816649865],
            [-36.6482958576, -8.0823185098],
            [-36.6469004897, -8.0920775016],
            [-36.6366472016, -8.0928343593],
            [-36.6275155924, -8.102445379],
            [-36.6282739232, -8.1106375889],
            [-36.6399813221, -8.1194977201],
            [-36.6675063839, -8.1238530214],
            [-36.6886915128, -8.1373567227],
            [-36.6941263325, -8.1434535778],
            [-36.6867122554, -8.1552653122],
            [-36.7083227201, -8.1558965965],
            [-36.7224700566, -8.1505516127],
            [-36.725442755, -8.1560352897],
            [-36.7154428081, -8.1669030944],
            [-36.7220663776, -8.167920516],
            [-36.7236333955, -8.1720555299],
            [-36.742270423, -8.1725765878],
            [-36.7498035954, -8.1752908416],
            [-36.7531693176, -8.191064868],
            [-36.761230777, -8.1958805713],
            [-36.7750348713, -8.1903079482],
            [-36.7874984117, -8.1894205504],
            [-36.7945085506, -8.1893680351],
            [-36.8054388674, -8.1950305875],
            [-36.8121717681, -8.2048807611],
            [-36.8613624817, -8.2220134136],
            [-36.8788890041, -8.2352312284],
            [-36.8956098299, -8.2404065774],
            [-36.907924199, -8.2526811033],
            [-36.922658372, -8.2694160718],
            [-36.9446061577, -8.2748395453],
            [-36.9473858167, -8.278706218],
            [-36.9469680346, -8.2906503713],
            [-36.951048433, -8.2964861768],
            [-36.9625203214, -8.2867078498],
            [-36.9701590723, -8.2734526521],
            [-36.9684244723, -8.2590822374],
            [-36.9588906619, -8.2494102075],
            [-36.958420337, -8.2437388258],
            [-36.9648142476, -8.215006449],
            [-36.9634470556, -8.2117597437],
            [-36.9601468802, -8.2121539532],
            [-36.957341358, -8.1985003993],
            [-36.9626943502, -8.1926089129],
            [-36.9638124911, -8.1819610655],
            [-36.9602253282, -8.1757147423],
            [-36.9529801861, -8.1769134259],
            [-36.9408015633, -8.1646036622],
            [-36.9363628527, -8.1377149944],
            [-36.9270549337, -8.1260352451],
            [-36.9222311111, -8.1121654436],
            [-36.9167178612, -8.1081744014],
            [-36.9164325928, -8.1012785062],
            [-36.8958647034, -8.0861450493],
            [-36.8963667687, -8.0716323457],
            [-36.8904023306, -8.0520459675],
            [-36.8819015565, -8.0495297475],
            [-36.8759878435, -8.0427666919],
            [-36.8752100193, -8.0269017774],
            [-36.8792438415, -8.0169803515],
            [-36.8680537987, -8.0118397621],
            [-36.8465547364, -7.9876557622],
            [-36.8381979645, -7.9831189725],
            [-36.81442288, -7.9562696043],
            [-36.7376131314, -7.9505730923],
            [-36.6734983254, -7.9439036443],
            [-36.572252092, -7.9355627033],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514206',
        name: 'São José da Lagoa Tapada',
        description: 'São José da Lagoa Tapada',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.1638501758, -6.8762812849],
            [-38.1505283788, -6.8951327189],
            [-38.1339104201, -6.8924272375],
            [-38.1227888593, -6.8972219163],
            [-38.105346304, -6.8866975431],
            [-38.0888705355, -6.887022648],
            [-38.0681991423, -6.8793419511],
            [-38.0406438107, -6.887273314],
            [-38.0301161473, -6.8871646812],
            [-38.0263064887, -6.8813639952],
            [-38.0142942152, -6.8842633767],
            [-38.0060705827, -6.943898428],
            [-38.0140476636, -6.9492194753],
            [-38.0260527489, -6.956091669],
            [-38.0347639263, -6.9743352499],
            [-38.0615127162, -6.9797382465],
            [-38.0726678251, -6.9901364653],
            [-38.0726386899, -6.9902453067],
            [-38.069384096, -7.0024888047],
            [-38.0887475003, -7.0084952015],
            [-38.0896716242, -7.0124371674],
            [-38.1322238322, -7.0077362418],
            [-38.1562922235, -7.0094375272],
            [-38.2050358822, -7.0098057424],
            [-38.2299128824, -7.0027129498],
            [-38.2536951109, -6.994296574],
            [-38.245117412, -6.9386007904],
            [-38.2524353808, -6.90477891],
            [-38.2569391162, -6.9042255097],
            [-38.2714190516, -6.8867633437],
            [-38.1961833265, -6.8691578128],
            [-38.1960315344, -6.869122475],
            [-38.1671538012, -6.8716053113],
            [-38.1638501758, -6.8762812849],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514305',
        name: 'São José de Caiana',
        description: 'São José de Caiana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.2739949313, -7.2018416063],
            [-38.2728342127, -7.2342654609],
            [-38.2674324296, -7.2334524118],
            [-38.2740205151, -7.2628209731],
            [-38.2686259383, -7.2674918192],
            [-38.2529642389, -7.2695475221],
            [-38.247698366, -7.2708825259],
            [-38.257095538, -7.2857351626],
            [-38.2617992317, -7.2898377925],
            [-38.2735770247, -7.2897907311],
            [-38.2823219855, -7.3052934456],
            [-38.297990235, -7.3094780262],
            [-38.3051539282, -7.3118217513],
            [-38.3224883922, -7.3296625022],
            [-38.3242388235, -7.3257020839],
            [-38.3265354895, -7.3205036386],
            [-38.3345868295, -7.321294732],
            [-38.3420224405, -7.3163179754],
            [-38.3483866416, -7.3192081595],
            [-38.3517858439, -7.3166940323],
            [-38.3683385097, -7.3184132702],
            [-38.4127133465, -7.3420986264],
            [-38.4148787464, -7.3431285972],
            [-38.4084685803, -7.3312494485],
            [-38.4126750996, -7.321149948],
            [-38.4134845049, -7.305695778],
            [-38.412771359, -7.3049301562],
            [-38.3888603427, -7.2832976903],
            [-38.3855741159, -7.2636417764],
            [-38.3765825122, -7.2604085901],
            [-38.3605060156, -7.2322654239],
            [-38.3521329696, -7.2343378241],
            [-38.3504816845, -7.2230016267],
            [-38.3382076665, -7.2012098427],
            [-38.3372818939, -7.192341411],
            [-38.328924066, -7.1838773154],
            [-38.3300280593, -7.1770957865],
            [-38.316742962, -7.1688140382],
            [-38.3180902123, -7.1594996702],
            [-38.3144836539, -7.1591996863],
            [-38.31169747, -7.1535792389],
            [-38.2980108739, -7.1564825249],
            [-38.2954837782, -7.1564247015],
            [-38.2953557028, -7.1574942546],
            [-38.2912173432, -7.1748459785],
            [-38.2903483154, -7.1979068735],
            [-38.2739949313, -7.2018416063],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514404',
        name: 'São José de Espinharas',
        description: 'São José de Espinharas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.2833744788, -6.6935136869],
            [-37.2898256729, -6.7126426302],
            [-37.2795763071, -6.7238324707],
            [-37.2635793621, -6.7317690063],
            [-37.2690215177, -6.7615837952],
            [-37.2736078058, -6.7663886544],
            [-37.2569854271, -6.7799940193],
            [-37.2602509636, -6.7869575155],
            [-37.2550756843, -6.7980671608],
            [-37.2548025642, -6.8106594076],
            [-37.2517163905, -6.8126989377],
            [-37.2342580933, -6.8242338167],
            [-37.2176365363, -6.8530493281],
            [-37.213041029, -6.8861076871],
            [-37.2122134327, -6.9059842536],
            [-37.2193312004, -6.9092673807],
            [-37.2453873435, -6.9088278176],
            [-37.2557751336, -6.9132508661],
            [-37.2849921132, -6.9144164132],
            [-37.2851286948, -6.9144201463],
            [-37.2991451314, -6.9153774897],
            [-37.346692763, -6.9271538897],
            [-37.3773315799, -6.9309498576],
            [-37.3863630553, -6.9360598038],
            [-37.3961596935, -6.9360927317],
            [-37.4065606326, -6.9318112382],
            [-37.4068356189, -6.9316423914],
            [-37.4092004954, -6.929253011],
            [-37.4340858253, -6.91653608],
            [-37.4616935038, -6.9128583781],
            [-37.4725643372, -6.9146641086],
            [-37.4846639202, -6.9124857342],
            [-37.4966278623, -6.8997491308],
            [-37.4940803636, -6.8852305684],
            [-37.503512612, -6.8713034536],
            [-37.5085525403, -6.8672605045],
            [-37.5003099485, -6.865722749],
            [-37.4993864482, -6.8613263442],
            [-37.5088000223, -6.8527070259],
            [-37.5170588232, -6.8366321207],
            [-37.5259165156, -6.8290406047],
            [-37.5264197648, -6.8206424582],
            [-37.5091144188, -6.8143737897],
            [-37.5059982893, -6.8100820398],
            [-37.5062285859, -6.7802728365],
            [-37.5186688507, -6.7698616036],
            [-37.5410851221, -6.7780017041],
            [-37.5521660156, -6.7745041251],
            [-37.5442414728, -6.7654651065],
            [-37.539153104, -6.7522373834],
            [-37.5426628535, -6.7423786196],
            [-37.5481882812, -6.7377762875],
            [-37.540431456, -6.7237032704],
            [-37.5182655528, -6.7120253461],
            [-37.4843562435, -6.7096362472],
            [-37.4555302283, -6.7048517665],
            [-37.4266362887, -6.7000552176],
            [-37.4069914932, -6.6967942865],
            [-37.3996275785, -6.6993278682],
            [-37.342370858, -6.7008456806],
            [-37.3139571424, -6.6885733503],
            [-37.297925095, -6.6911634582],
            [-37.2833744788, -6.6935136869],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514453',
        name: 'São José dos Ramos',
        description: 'São José dos Ramos',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3234152051, -7.1850138866],
            [-35.3084260016, -7.2045180996],
            [-35.3044206825, -7.2156347756],
            [-35.3044831209, -7.2162337842],
            [-35.3056288266, -7.2271982614],
            [-35.3025591217, -7.2262482684],
            [-35.2960463087, -7.234176089],
            [-35.2908284197, -7.2475236438],
            [-35.2869211018, -7.2657250508],
            [-35.2946162851, -7.2880014904],
            [-35.3005201957, -7.2904092223],
            [-35.31180837, -7.2839383405],
            [-35.3169091797, -7.2845537488],
            [-35.3219732431, -7.2912872481],
            [-35.330246896, -7.2813825712],
            [-35.3531869233, -7.2546668254],
            [-35.3681519559, -7.264925546],
            [-35.3809850608, -7.2521129727],
            [-35.3848673505, -7.2595931117],
            [-35.3969587453, -7.2622832717],
            [-35.4127901653, -7.2689226877],
            [-35.417358808, -7.2761411109],
            [-35.4496102245, -7.2545969026],
            [-35.4352113172, -7.2463686574],
            [-35.4312422862, -7.2496098269],
            [-35.4188712705, -7.2393845382],
            [-35.404840069, -7.2425657183],
            [-35.3979463789, -7.2390382218],
            [-35.3755350326, -7.2389316688],
            [-35.3843716824, -7.20853494],
            [-35.3494482766, -7.1938718812],
            [-35.3491377128, -7.1939518628],
            [-35.33700158, -7.1866245796],
            [-35.3335776668, -7.1794516591],
            [-35.3234152051, -7.1850138866],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514503',
        name: 'São José de Piranhas',
        description: 'São José de Piranhas',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4530469685, -6.9844305094],
            [-38.4468063593, -6.9870799341],
            [-38.4328044931, -6.9861565119],
            [-38.3873667086, -6.9865759408],
            [-38.3623017287, -6.9883108339],
            [-38.366946817, -7.0432972916],
            [-38.3422417929, -7.0874661891],
            [-38.3312760472, -7.1062615273],
            [-38.3310906128, -7.1070371524],
            [-38.3310545726, -7.1158690322],
            [-38.3309449134, -7.1421876326],
            [-38.3014740415, -7.1272298407],
            [-38.2839587381, -7.1291007647],
            [-38.2944346221, -7.1446878671],
            [-38.2954837782, -7.1564247015],
            [-38.2980108739, -7.1564825249],
            [-38.31169747, -7.1535792389],
            [-38.3144836539, -7.1591996863],
            [-38.3180902123, -7.1594996702],
            [-38.3331825181, -7.1599160607],
            [-38.3349677933, -7.1553865867],
            [-38.3441667081, -7.1547094438],
            [-38.3843968215, -7.1661932128],
            [-38.3827080605, -7.1757471332],
            [-38.3848883687, -7.1791992216],
            [-38.3934776153, -7.1787174905],
            [-38.4049843453, -7.1999123983],
            [-38.4063335343, -7.1974459492],
            [-38.4119339551, -7.1908130167],
            [-38.4321360829, -7.1981981181],
            [-38.4471964961, -7.1965535665],
            [-38.4507224997, -7.1947828183],
            [-38.453902127, -7.1842044505],
            [-38.518203666, -7.1822848531],
            [-38.5177041595, -7.1732193232],
            [-38.5219799384, -7.1704150026],
            [-38.5547654405, -7.1722074785],
            [-38.5551540149, -7.1722611353],
            [-38.5644572727, -7.174569917],
            [-38.5695823284, -7.1840530403],
            [-38.6153096179, -7.1823681802],
            [-38.6346746549, -7.1829460483],
            [-38.643849825, -7.18335548],
            [-38.6730580542, -7.1848879984],
            [-38.6874758066, -7.1897572046],
            [-38.6826614311, -7.1755767386],
            [-38.6767757429, -7.1705324657],
            [-38.6763645614, -7.1671683754],
            [-38.6848396151, -7.1609402278],
            [-38.6749389693, -7.1553181581],
            [-38.6745810411, -7.1434054064],
            [-38.6770612365, -7.1305316764],
            [-38.6681895304, -7.1169189229],
            [-38.6618004828, -7.1097756784],
            [-38.6568002872, -7.0938627614],
            [-38.6594469319, -7.0851406167],
            [-38.6663338805, -7.0812790227],
            [-38.6710975265, -7.0701309489],
            [-38.6768880239, -7.0666994361],
            [-38.6694951117, -7.047301726],
            [-38.6632893042, -7.0356372208],
            [-38.6397738979, -7.0463776304],
            [-38.6338606829, -7.0448647436],
            [-38.6118529853, -7.0546204327],
            [-38.600412807, -7.0548222042],
            [-38.588690607, -7.054526471],
            [-38.5760973443, -7.0337325157],
            [-38.5669898595, -7.0259944231],
            [-38.5668197837, -7.0258554024],
            [-38.561275407, -7.0252023362],
            [-38.5462252737, -7.0321276756],
            [-38.5292040675, -7.0117184174],
            [-38.5213347931, -7.0088060233],
            [-38.5140915748, -6.9987830967],
            [-38.5036841619, -6.9962243843],
            [-38.5033040229, -6.9894077925],
            [-38.4718292377, -6.9885823498],
            [-38.4533041826, -6.9843217317],
            [-38.4530469685, -6.9844305094],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514552',
        name: 'São José de Princesa',
        description: 'São José de Princesa',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.0879949438, -7.5812132258],
            [-38.0893174295, -7.584813193],
            [-38.085213077, -7.5840723766],
            [-38.0821276534, -7.5885583558],
            [-38.068591174, -7.5929435437],
            [-38.0650493539, -7.6025074641],
            [-38.0683028747, -7.6053415945],
            [-38.0643504103, -7.6108375548],
            [-38.0660996798, -7.6159375417],
            [-38.0729194716, -7.6160536588],
            [-38.0780541084, -7.6111205024],
            [-38.0842740953, -7.6420577574],
            [-38.0673910706, -7.641857881],
            [-38.0551186368, -7.6417116414],
            [-38.0511900405, -7.6429922993],
            [-38.0642166842, -7.6773321904],
            [-38.0607143576, -7.6954027161],
            [-38.0662228554, -7.7155077693],
            [-38.0788989932, -7.7250903915],
            [-38.0802693151, -7.738618414],
            [-38.0685568591, -7.7593028507],
            [-38.0691290484, -7.7719154272],
            [-38.074865645, -7.7887163577],
            [-38.0731330289, -7.7931099017],
            [-38.0753918124, -7.8049744543],
            [-38.0659555251, -7.8172571101],
            [-38.0690263356, -7.8257077094],
            [-38.0767192903, -7.8301702603],
            [-38.08020263, -7.828772575],
            [-38.082989124, -7.8276536718],
            [-38.0968902704, -7.8269177103],
            [-38.1197058005, -7.8185648043],
            [-38.1238033041, -7.812571719],
            [-38.1220864576, -7.8070253417],
            [-38.1308075235, -7.8034587709],
            [-38.1310756476, -7.7953495031],
            [-38.1309928945, -7.7950256972],
            [-38.1247923498, -7.7707228951],
            [-38.1169676973, -7.762337485],
            [-38.1195959276, -7.7558766542],
            [-38.1155265812, -7.737103554],
            [-38.1283514656, -7.735242087],
            [-38.1255809846, -7.7298965829],
            [-38.1290776679, -7.7252620382],
            [-38.1203549995, -7.7077654004],
            [-38.1137647431, -7.6867783124],
            [-38.1213598764, -7.6853108756],
            [-38.1275616852, -7.6798672565],
            [-38.1253242824, -7.6737133704],
            [-38.1103754889, -7.6613806697],
            [-38.132770289, -7.62502829],
            [-38.122163617, -7.597336796],
            [-38.1051010623, -7.571145174],
            [-38.1034158743, -7.5701428439],
            [-38.0928045306, -7.5736491388],
            [-38.0879949438, -7.5812132258],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514602',
        name: 'São José do Bonfim',
        description: 'São José do Bonfim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.2446338362, -7.1133037105],
            [-37.2468165897, -7.1930315946],
            [-37.2469335152, -7.197353383],
            [-37.2765846527, -7.1942178797],
            [-37.3033719033, -7.1913823767],
            [-37.3352139355, -7.2139851058],
            [-37.3344627599, -7.2076731936],
            [-37.3396978626, -7.197413423],
            [-37.3526767517, -7.1853544126],
            [-37.352799848, -7.1852848816],
            [-37.3653402675, -7.1738645612],
            [-37.3659026546, -7.1651275432],
            [-37.387432382, -7.1635122492],
            [-37.3878075556, -7.1504759486],
            [-37.3806248138, -7.153250802],
            [-37.3562080052, -7.1417601007],
            [-37.3527228809, -7.1438357382],
            [-37.336543003, -7.1395006857],
            [-37.3338635022, -7.1294646822],
            [-37.328015716, -7.1228727342],
            [-37.3284851867, -7.1138379875],
            [-37.3233866392, -7.111094936],
            [-37.313774157, -7.0942849167],
            [-37.3160707258, -7.0906277967],
            [-37.3140795829, -7.0844402484],
            [-37.3211833691, -7.0815016166],
            [-37.2874793688, -7.076017871],
            [-37.2873179021, -7.0761468761],
            [-37.287132178, -7.0762950133],
            [-37.2445471688, -7.1101619485],
            [-37.2446338362, -7.1133037105],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514651',
        name: 'São José do Brejo do Cruz',
        description: 'São José do Brejo do Cruz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.326949475, -6.1769140382],
            [-37.3007512062, -6.1887802753],
            [-37.2922470385, -6.1869438397],
            [-37.2886161155, -6.1905404428],
            [-37.2888647279, -6.1856439416],
            [-37.2792076936, -6.1870352847],
            [-37.2703328878, -6.1865154522],
            [-37.2664551001, -6.1913104185],
            [-37.2514492675, -6.1904580083],
            [-37.2467780628, -6.1928644311],
            [-37.248911199, -6.2015680089],
            [-37.2389454509, -6.20415187],
            [-37.2339307433, -6.2128809615],
            [-37.2511864612, -6.2260608097],
            [-37.2702113757, -6.2513167195],
            [-37.2743853647, -6.2810282343],
            [-37.2865079449, -6.281867735],
            [-37.3061166792, -6.2776672324],
            [-37.3181821213, -6.2792568639],
            [-37.3278506677, -6.3028059997],
            [-37.3336162887, -6.3079419246],
            [-37.3438133377, -6.3054261905],
            [-37.3464426223, -6.2988127399],
            [-37.3513619182, -6.2980248253],
            [-37.3706726755, -6.2822816443],
            [-37.383567514, -6.2813471984],
            [-37.3867655844, -6.2771278917],
            [-37.3942824203, -6.2863198423],
            [-37.4105295963, -6.2906685443],
            [-37.4165591601, -6.285016732],
            [-37.4332898679, -6.2864865745],
            [-37.4396017839, -6.2877947592],
            [-37.442312246, -6.2919988472],
            [-37.4479694593, -6.2796233592],
            [-37.4705836509, -6.2696702454],
            [-37.4804430548, -6.2587632257],
            [-37.4842250598, -6.2433643417],
            [-37.4665047196, -6.2325688432],
            [-37.4589012914, -6.2211946567],
            [-37.4452500697, -6.2164773704],
            [-37.4373856929, -6.2090289325],
            [-37.4347453579, -6.1992745166],
            [-37.430081675, -6.1999770551],
            [-37.4115418092, -6.1916873279],
            [-37.4009147151, -6.1923288138],
            [-37.3740444815, -6.1742000994],
            [-37.3663984244, -6.1736764136],
            [-37.3416384023, -6.1683187966],
            [-37.3326107253, -6.1703709333],
            [-37.326949475, -6.1769140382],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514701',
        name: 'São José do Sabugi',
        description: 'São José do Sabugi',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.7723916563, -6.7866629318],
            [-36.7691280204, -6.8383454128],
            [-36.7604054417, -6.8375650116],
            [-36.7298613012, -6.835993434],
            [-36.7446520515, -6.8791109956],
            [-36.7557274931, -6.8959218872],
            [-36.7596611444, -6.9141366298],
            [-36.7744540822, -6.9145296229],
            [-36.7768478557, -6.9077131107],
            [-36.7845722045, -6.9023121145],
            [-36.786278273, -6.8949067079],
            [-36.7915901494, -6.8941789257],
            [-36.7955296645, -6.886032583],
            [-36.801345645, -6.8841828863],
            [-36.813313338, -6.8815330312],
            [-36.8183743645, -6.874685098],
            [-36.8282943881, -6.8833951232],
            [-36.8347674759, -6.8823278078],
            [-36.8358554502, -6.8687912302],
            [-36.8424042644, -6.8727017422],
            [-36.8650692909, -6.8560753335],
            [-36.8592908684, -6.839520621],
            [-36.8677691801, -6.8306739451],
            [-36.8683252494, -6.8303463332],
            [-36.8965686998, -6.8136917094],
            [-36.8992849548, -6.803048891],
            [-36.8966635016, -6.7917843387],
            [-36.8997542338, -6.789407202],
            [-36.9043830709, -6.7634368281],
            [-36.9098781882, -6.7565275321],
            [-36.8739957209, -6.7427815486],
            [-36.8346920564, -6.7306691919],
            [-36.7887930497, -6.7720635112],
            [-36.7723916563, -6.7866629318],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514800',
        name: 'São José dos Cordeiros',
        description: 'São José dos Cordeiros',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.6829056214, -7.3528208688],
            [-36.6639698525, -7.3891298652],
            [-36.6743598589, -7.3811101058],
            [-36.6805781347, -7.3876858097],
            [-36.6920827782, -7.3864931046],
            [-36.7080583366, -7.3923500986],
            [-36.7118411681, -7.3951512608],
            [-36.7162289737, -7.4014471501],
            [-36.7270595768, -7.405610873],
            [-36.7268905514, -7.4107040666],
            [-36.7350217726, -7.4217719024],
            [-36.7374943958, -7.4523162527],
            [-36.7682414403, -7.4627892072],
            [-36.8341179937, -7.4852311155],
            [-36.8539183975, -7.4996489588],
            [-36.8835275755, -7.4927073752],
            [-36.9011125194, -7.4975422505],
            [-36.9012595565, -7.5014183891],
            [-36.9155571119, -7.5067718335],
            [-36.9236063392, -7.5031971113],
            [-36.9368330383, -7.5053285095],
            [-36.9499168049, -7.5005348787],
            [-36.96041627, -7.4849463136],
            [-36.9838852477, -7.4818541234],
            [-36.9850828291, -7.4751944707],
            [-36.9951132473, -7.4721818055],
            [-36.993722319, -7.4643033563],
            [-36.9969699558, -7.4589856742],
            [-37.0103903997, -7.4532281502],
            [-37.012836677, -7.4445764647],
            [-37.0208842956, -7.4397897096],
            [-37.0224607571, -7.4356200703],
            [-37.0232750211, -7.4334603032],
            [-37.0141676012, -7.4299610053],
            [-37.0172240778, -7.4241660378],
            [-37.0256373954, -7.4220557555],
            [-37.0130200122, -7.4036168143],
            [-37.0133617965, -7.4033856454],
            [-36.9796791778, -7.3887173253],
            [-36.9562877592, -7.3831709541],
            [-36.9317673545, -7.3811155208],
            [-36.9188476063, -7.3857118535],
            [-36.9030506743, -7.386338882],
            [-36.8966167447, -7.3826743079],
            [-36.8839761465, -7.3838265527],
            [-36.8762461947, -7.3762011916],
            [-36.8648050259, -7.3741712209],
            [-36.8600993978, -7.367451295],
            [-36.8365211552, -7.3713569198],
            [-36.8047968998, -7.3690881591],
            [-36.7739203849, -7.3712603385],
            [-36.7737149086, -7.3005635998],
            [-36.7567203336, -7.3054366052],
            [-36.7550257758, -7.3274495368],
            [-36.6829056214, -7.3528208688],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2514909',
        name: 'São Mamede',
        description: 'São Mamede',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.1073769608, -6.807487622],
            [-37.0941239835, -6.8074355197],
            [-37.0802465883, -6.8024040596],
            [-37.0844815879, -6.8127893315],
            [-37.0665976877, -6.8326572044],
            [-37.0596695135, -6.8426528742],
            [-37.0442953852, -6.8381345766],
            [-37.0350706865, -6.8461833362],
            [-37.0327887294, -6.8580672974],
            [-37.0214727378, -6.8598452606],
            [-37.022476684, -6.8732930348],
            [-37.0103864857, -6.8740187578],
            [-37.0117719102, -6.8796067424],
            [-37.0273198226, -6.8917011327],
            [-37.0346850879, -6.9041327071],
            [-37.0212689429, -6.9132522593],
            [-37.0211993076, -6.9132993006],
            [-36.9881303056, -6.9357678676],
            [-36.974839663, -6.9326110845],
            [-36.9629404242, -6.9337997148],
            [-36.970782468, -6.9542992919],
            [-36.9709987508, -6.963478705],
            [-36.9670235049, -6.9658180117],
            [-36.9483829778, -6.958170872],
            [-36.9423669498, -6.9591384043],
            [-36.9430336982, -6.9822109429],
            [-36.9381082087, -6.9883758368],
            [-36.9333948876, -6.9925864605],
            [-36.9333735006, -6.9973004814],
            [-36.9358738521, -7.0106807525],
            [-36.9412351097, -7.014502353],
            [-36.9616231082, -7.0323789505],
            [-36.9590771961, -7.0476638517],
            [-36.9753641627, -7.0569240064],
            [-36.9930782571, -7.0612872989],
            [-36.9971036147, -7.0608704781],
            [-37.0048936774, -7.0514676731],
            [-37.0184232961, -7.0483002863],
            [-37.0589696516, -7.0372248561],
            [-37.0555713489, -7.0240927298],
            [-37.0674883859, -7.0195785895],
            [-37.0722176215, -7.0132706063],
            [-37.0868593723, -7.0230075075],
            [-37.0928039979, -7.0231792183],
            [-37.1042398604, -7.0205628013],
            [-37.1148330118, -7.012140395],
            [-37.1263581853, -7.0098596496],
            [-37.1297593819, -7.0020581422],
            [-37.1404040532, -7.0016347358],
            [-37.1522525721, -6.9907925951],
            [-37.1725739498, -6.9818493394],
            [-37.1726724428, -6.9818058551],
            [-37.1739903137, -6.9729241768],
            [-37.1806355869, -6.964319659],
            [-37.1729883095, -6.9440708454],
            [-37.183113741, -6.9357781702],
            [-37.193304812, -6.9347316254],
            [-37.1778187197, -6.9214496527],
            [-37.1784585028, -6.9180535186],
            [-37.190133115, -6.9171797101],
            [-37.1979512308, -6.9058458999],
            [-37.2122134327, -6.9059842536],
            [-37.213041029, -6.8861076871],
            [-37.2176365363, -6.8530493281],
            [-37.2342580933, -6.8242338167],
            [-37.2135553331, -6.8244169167],
            [-37.1903310906, -6.8249634461],
            [-37.1518868122, -6.7979618023],
            [-37.1384102942, -6.785740924],
            [-37.1089036732, -6.7998200428],
            [-37.1073769608, -6.807487622],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515005',
        name: 'São Miguel de Taipu',
        description: 'São Miguel de Taipu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.1699035933, -7.1967081373],
            [-35.158702745, -7.2092614979],
            [-35.1550801319, -7.2066081542],
            [-35.1508949677, -7.2127720616],
            [-35.1584517678, -7.2292938342],
            [-35.1709213264, -7.2637464976],
            [-35.1713874217, -7.2723114359],
            [-35.1774068454, -7.2858550061],
            [-35.1816572316, -7.2954205495],
            [-35.1916766572, -7.2980118815],
            [-35.1931469328, -7.3107339034],
            [-35.2113816701, -7.303688494],
            [-35.2169310509, -7.3042868345],
            [-35.220026282, -7.2996978082],
            [-35.2233793474, -7.2921551273],
            [-35.2334595632, -7.2639347075],
            [-35.2417595143, -7.2406852583],
            [-35.2387822758, -7.2254762323],
            [-35.2321962775, -7.2167040589],
            [-35.2322282478, -7.2051194319],
            [-35.2300250083, -7.1915505748],
            [-35.2193996723, -7.1907374919],
            [-35.2015612915, -7.1873322229],
            [-35.1875059182, -7.1770392952],
            [-35.1870648431, -7.1769705866],
            [-35.1862270846, -7.1830047713],
            [-35.1819345864, -7.1832207123],
            [-35.1699035933, -7.1967081373],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515104',
        name: 'São Sebastião de Lagoa de Roça',
        description: 'São Sebastião de Lagoa de Roça',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.8418312334, -7.0563115351],
            [-35.8336970111, -7.0601326551],
            [-35.8268983726, -7.0703759472],
            [-35.8233490631, -7.0718609506],
            [-35.8161140022, -7.0748868357],
            [-35.8178607963, -7.0766928318],
            [-35.8148092852, -7.0847170249],
            [-35.8115630306, -7.0951624233],
            [-35.8133040768, -7.1021266577],
            [-35.8155576568, -7.1067951791],
            [-35.8178063335, -7.1092649369],
            [-35.8248258854, -7.121773548],
            [-35.8296197465, -7.1256791974],
            [-35.8325125484, -7.1238075921],
            [-35.8392064205, -7.1236348846],
            [-35.8417343861, -7.1156921751],
            [-35.8454118246, -7.1201008954],
            [-35.8520428902, -7.1166549202],
            [-35.8634035617, -7.1195732822],
            [-35.8682696262, -7.1120856276],
            [-35.8712090189, -7.1091433396],
            [-35.8739471502, -7.1064038104],
            [-35.8890149214, -7.0881397812],
            [-35.8961692932, -7.0838770376],
            [-35.8953625577, -7.0822269766],
            [-35.8863646361, -7.0638294656],
            [-35.8859277498, -7.0629355926],
            [-35.8809189815, -7.0526957016],
            [-35.8715462653, -7.0578200281],
            [-35.8639292864, -7.0568526482],
            [-35.855413245, -7.0503150412],
            [-35.8418312334, -7.0563115351],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515203',
        name: 'São Sebastião do Umbuzeiro',
        description: 'São Sebastião do Umbuzeiro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.8904023306, -8.0520459675],
            [-36.8963667687, -8.0716323457],
            [-36.8958647034, -8.0861450493],
            [-36.9164325928, -8.1012785062],
            [-36.9167178612, -8.1081744014],
            [-36.9222311111, -8.1121654436],
            [-36.9270549337, -8.1260352451],
            [-36.9363628527, -8.1377149944],
            [-36.9408015633, -8.1646036622],
            [-36.9529801861, -8.1769134259],
            [-36.9602253282, -8.1757147423],
            [-36.9638124911, -8.1819610655],
            [-36.9626943502, -8.1926089129],
            [-36.957341358, -8.1985003993],
            [-36.9601468802, -8.2121539532],
            [-36.9634470556, -8.2117597437],
            [-36.9648142476, -8.215006449],
            [-36.958420337, -8.2437388258],
            [-36.9588906619, -8.2494102075],
            [-36.9684244723, -8.2590822374],
            [-36.9701590723, -8.2734526521],
            [-36.9625203214, -8.2867078498],
            [-36.951048433, -8.2964861768],
            [-36.9545830909, -8.2989369052],
            [-36.9776770568, -8.2970032119],
            [-36.9908143365, -8.3025402218],
            [-37.0150042848, -8.2916611677],
            [-37.0203074277, -8.289258045],
            [-37.0397713714, -8.2659715507],
            [-37.0808614822, -8.265960813],
            [-37.0802785913, -8.2406686568],
            [-37.0661898866, -8.2325858821],
            [-37.0670768633, -8.2296892125],
            [-37.0805536052, -8.2214782095],
            [-37.0895507973, -8.2307525998],
            [-37.1000864919, -8.2332935396],
            [-37.1225726768, -8.2086533205],
            [-37.1257575653, -8.1910841936],
            [-37.1183193133, -8.1799771421],
            [-37.1179179624, -8.1749390461],
            [-37.1261100276, -8.1732710743],
            [-37.1408503206, -8.1768213133],
            [-37.1598828557, -8.1699876804],
            [-37.1630787823, -8.1582750457],
            [-37.15066159, -8.1496289411],
            [-37.1582714114, -8.1384568643],
            [-37.1528862017, -8.1270548067],
            [-37.1438299839, -8.1245840086],
            [-37.1328273156, -8.1232004856],
            [-37.1212607037, -8.1278586108],
            [-37.1179987866, -8.1255956671],
            [-37.1062751421, -8.1269960968],
            [-37.092582468, -8.1205751385],
            [-37.0837244632, -8.1103230793],
            [-37.0686156133, -8.1064964172],
            [-37.0611389269, -8.1004683653],
            [-37.0487201272, -8.0991330744],
            [-37.0429135632, -8.1028061968],
            [-37.0261141677, -8.099742059],
            [-37.0209867731, -8.0944416172],
            [-37.0208703806, -8.0944031478],
            [-37.0282797561, -8.0811904148],
            [-37.0319676901, -8.0693910688],
            [-37.0302958361, -8.0609333104],
            [-37.0380482664, -8.0436363491],
            [-37.0248045442, -8.0411185108],
            [-36.9881899174, -8.0533524714],
            [-36.9775985693, -8.0531975623],
            [-36.9687732057, -8.0601955738],
            [-36.9459661021, -8.0475684897],
            [-36.9332782647, -8.0439801231],
            [-36.9248038171, -8.0365681513],
            [-36.9182058862, -8.0287813618],
            [-36.9105184498, -8.0271352495],
            [-36.9049621267, -8.0188166563],
            [-36.8930969894, -8.0135962744],
            [-36.8792438415, -8.0169803515],
            [-36.8752100193, -8.0269017774],
            [-36.8759878435, -8.0427666919],
            [-36.8819015565, -8.0495297475],
            [-36.8904023306, -8.0520459675],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2515302', name: 'Sapé', description: 'Sapé' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.2070447074, -6.940451521],
            [-35.1839613992, -6.954482677],
            [-35.1787575547, -6.9502663483],
            [-35.1785516092, -6.9524059815],
            [-35.1547486065, -6.9400271144],
            [-35.148942577, -6.9421827839],
            [-35.1323175014, -6.9404208617],
            [-35.1370509355, -6.9444416707],
            [-35.1395341494, -6.9626935553],
            [-35.1459633349, -6.972845957],
            [-35.138171381, -6.9832905513],
            [-35.1346343131, -7.0066411503],
            [-35.1273834157, -7.0094296975],
            [-35.1291554353, -7.0105509549],
            [-35.1610091686, -7.0318047807],
            [-35.1457124641, -7.0453251687],
            [-35.1376706329, -7.052431289],
            [-35.1179180034, -7.0703087411],
            [-35.1281799869, -7.099346237],
            [-35.1396151468, -7.1317218623],
            [-35.1427470091, -7.1405916061],
            [-35.1521106049, -7.1490096926],
            [-35.1678272856, -7.1601852469],
            [-35.1723108122, -7.1639105888],
            [-35.1788895311, -7.1734438843],
            [-35.1870648431, -7.1769705866],
            [-35.1875059182, -7.1770392952],
            [-35.1904283521, -7.1761616174],
            [-35.1909409942, -7.1706060411],
            [-35.1966355458, -7.1744717637],
            [-35.2008772557, -7.1720584022],
            [-35.2091866463, -7.1748221884],
            [-35.2117571172, -7.1652656656],
            [-35.217067616, -7.1653110484],
            [-35.2172585612, -7.1585633488],
            [-35.2276937044, -7.1607274825],
            [-35.2270261486, -7.1498922234],
            [-35.231231903, -7.1489963343],
            [-35.2320884431, -7.1421071755],
            [-35.2375874784, -7.1378513285],
            [-35.2389982382, -7.1296866051],
            [-35.2357895087, -7.1228406869],
            [-35.2383578567, -7.1192292338],
            [-35.250780997, -7.121744201],
            [-35.2612082405, -7.1289116181],
            [-35.2610344569, -7.1375977443],
            [-35.2706233748, -7.1321009298],
            [-35.2750234853, -7.1211214899],
            [-35.2729386516, -7.1161209759],
            [-35.2850849516, -7.1164492759],
            [-35.2839010647, -7.1106340036],
            [-35.2874933917, -7.1085551955],
            [-35.278667707, -7.1047030252],
            [-35.2610279287, -7.0765266679],
            [-35.2609363616, -7.0763332433],
            [-35.2544144189, -7.0677979615],
            [-35.2531954256, -7.0578019559],
            [-35.2479585578, -7.0508281441],
            [-35.2583947516, -7.037239114],
            [-35.2556773895, -7.026331262],
            [-35.2557188301, -7.0128335306],
            [-35.2527758449, -6.9854507068],
            [-35.2633362063, -6.9639385531],
            [-35.2622604272, -6.9550287569],
            [-35.2563140901, -6.9525125136],
            [-35.2437704728, -6.9538057457],
            [-35.2221062793, -6.9469675911],
            [-35.2217832055, -6.946433068],
            [-35.2214829231, -6.9459389252],
            [-35.2192260591, -6.9422155404],
            [-35.2070447074, -6.940451521],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2515401', name: 'Seridó', description: 'Seridó' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.5343256322, -6.8367973958],
            [-36.5296105669, -6.8410336826],
            [-36.5268318371, -6.8373461133],
            [-36.5188544896, -6.8399743446],
            [-36.5175339381, -6.8453970498],
            [-36.5124893754, -6.8470404628],
            [-36.5115121691, -6.8444456748],
            [-36.5042978164, -6.8495039157],
            [-36.4889121448, -6.8361466625],
            [-36.482383683, -6.8391684086],
            [-36.4813306054, -6.8454597405],
            [-36.4758696764, -6.8447628322],
            [-36.4758051928, -6.8483533753],
            [-36.4686502101, -6.8455445951],
            [-36.4663804349, -6.8484096266],
            [-36.4644039751, -6.846153168],
            [-36.4475669156, -6.8483110206],
            [-36.438542408, -6.8430820122],
            [-36.4330241007, -6.8483488037],
            [-36.4265865206, -6.8481991357],
            [-36.4244507362, -6.8423723405],
            [-36.4137113532, -6.8529071857],
            [-36.4083691218, -6.854673813],
            [-36.4081436627, -6.8546745652],
            [-36.4056764827, -6.8458414526],
            [-36.3991480147, -6.8500360861],
            [-36.393745335, -6.8456938515],
            [-36.3874004702, -6.8480958863],
            [-36.38461052, -6.8454563136],
            [-36.3788394071, -6.8489870859],
            [-36.3746471659, -6.8471843154],
            [-36.3701066978, -6.8549554784],
            [-36.3639343133, -6.8604600263],
            [-36.3690893429, -6.8728336378],
            [-36.382189032, -6.8860096714],
            [-36.3766768, -6.8937366473],
            [-36.3624667959, -6.9129171545],
            [-36.3314477215, -6.9303415096],
            [-36.325772359, -6.9385256426],
            [-36.3342146156, -6.9470176465],
            [-36.351196868, -6.9555396602],
            [-36.3747991502, -6.9707589416],
            [-36.374974118, -6.9708714865],
            [-36.3936725124, -6.982930199],
            [-36.4447792593, -6.9896508615],
            [-36.4763734139, -6.9938052198],
            [-36.4731154004, -6.9493492932],
            [-36.4749798602, -6.9480463692],
            [-36.5442626905, -6.899622066],
            [-36.557686872, -6.8887350332],
            [-36.5676044131, -6.8743943583],
            [-36.566458508, -6.8661889036],
            [-36.5737440955, -6.8570548259],
            [-36.5694598858, -6.8476054456],
            [-36.5598210028, -6.8403706204],
            [-36.5433328603, -6.841695869],
            [-36.5380357771, -6.8434149782],
            [-36.5343256322, -6.8367973958],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515500',
        name: 'Serra Branca',
        description: 'Serra Branca',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.6743598589, -7.3811101058],
            [-36.6639698525, -7.3891298652],
            [-36.6354891195, -7.3923122393],
            [-36.6345871072, -7.393439319],
            [-36.5931837812, -7.4451359179],
            [-36.5304503226, -7.5276662167],
            [-36.5410355698, -7.5359212084],
            [-36.5999977935, -7.5819144484],
            [-36.6395557656, -7.5854164825],
            [-36.7119602776, -7.5918219229],
            [-36.7139825415, -7.5927293602],
            [-36.7087943886, -7.6211840528],
            [-36.699003219, -7.6747855746],
            [-36.6932133616, -7.678522178],
            [-36.6882983062, -7.6757221627],
            [-36.6794796715, -7.6771917573],
            [-36.6703767819, -7.6827936873],
            [-36.6613707483, -7.6799715849],
            [-36.6583253601, -7.6843366982],
            [-36.6689077931, -7.6834313117],
            [-36.7072241686, -7.6965669162],
            [-36.7125220567, -7.6948706352],
            [-36.7284357818, -7.7010391517],
            [-36.7297505979, -7.7049220849],
            [-36.74496126, -7.7140530942],
            [-36.7539095785, -7.7152127205],
            [-36.7638000507, -7.7283767019],
            [-36.7865582872, -7.6900341595],
            [-36.7965493454, -7.6731877869],
            [-36.7960960267, -7.6645454931],
            [-36.7954482806, -7.6616082048],
            [-36.7826957413, -7.656431233],
            [-36.7831606335, -7.6482543417],
            [-36.8099861559, -7.6457327895],
            [-36.8146478075, -7.6187784436],
            [-36.8230761891, -7.608321543],
            [-36.823187104, -7.6082486413],
            [-36.8393388118, -7.5932188466],
            [-36.8244098282, -7.5795296552],
            [-36.8385930968, -7.5585211702],
            [-36.8290385791, -7.5390448376],
            [-36.828511822, -7.5369647362],
            [-36.8740669916, -7.5348357468],
            [-36.8871675483, -7.5342224521],
            [-36.9005206427, -7.533596486],
            [-36.8759024908, -7.5156626185],
            [-36.8539183975, -7.4996489588],
            [-36.8341179937, -7.4852311155],
            [-36.7682414403, -7.4627892072],
            [-36.7374943958, -7.4523162527],
            [-36.7350217726, -7.4217719024],
            [-36.7268905514, -7.4107040666],
            [-36.7270595768, -7.405610873],
            [-36.7162289737, -7.4014471501],
            [-36.7118411681, -7.3951512608],
            [-36.7080583366, -7.3923500986],
            [-36.6920827782, -7.3864931046],
            [-36.6805781347, -7.3876858097],
            [-36.6743598589, -7.3811101058],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515609',
        name: 'Serra da Raiz',
        description: 'Serra da Raiz',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.4540026613, -6.7269986284],
            [-35.4749484724, -6.7403093456],
            [-35.4981428987, -6.7320623355],
            [-35.4960418724, -6.7302187223],
            [-35.4656610076, -6.70356406],
            [-35.45549368, -6.6889808868],
            [-35.4464099573, -6.6759551239],
            [-35.4230591511, -6.6667822662],
            [-35.4157773475, -6.6740186713],
            [-35.4182518832, -6.6829597851],
            [-35.4214782687, -6.6888907082],
            [-35.4305537754, -6.7065020996],
            [-35.4336781972, -6.7070797944],
            [-35.4391504921, -6.7119063927],
            [-35.4416794966, -6.7230468651],
            [-35.4540026613, -6.7269986284],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515708',
        name: 'Serra Grande',
        description: 'Serra Grande',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.3300280593, -7.1770957865],
            [-38.328924066, -7.1838773154],
            [-38.3372818939, -7.192341411],
            [-38.3382076665, -7.2012098427],
            [-38.3504816845, -7.2230016267],
            [-38.3521329696, -7.2343378241],
            [-38.3605060156, -7.2322654239],
            [-38.3765825122, -7.2604085901],
            [-38.3855741159, -7.2636417764],
            [-38.3888603427, -7.2832976903],
            [-38.412771359, -7.3049301562],
            [-38.4134845049, -7.305695778],
            [-38.4216458286, -7.3030264101],
            [-38.421380424, -7.2894220086],
            [-38.4165528796, -7.2809928263],
            [-38.4194719278, -7.2699041644],
            [-38.4161007771, -7.2612444646],
            [-38.4128035998, -7.253667066],
            [-38.4031963294, -7.2482331195],
            [-38.4115861373, -7.2378117174],
            [-38.4056303471, -7.2212811282],
            [-38.4061838693, -7.2072049738],
            [-38.4049843453, -7.1999123983],
            [-38.3934776153, -7.1787174905],
            [-38.3848883687, -7.1791992216],
            [-38.3827080605, -7.1757471332],
            [-38.3843968215, -7.1661932128],
            [-38.3441667081, -7.1547094438],
            [-38.3349677933, -7.1553865867],
            [-38.3331825181, -7.1599160607],
            [-38.3180902123, -7.1594996702],
            [-38.316742962, -7.1688140382],
            [-38.3300280593, -7.1770957865],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515807',
        name: 'Serra Redonda',
        description: 'Serra Redonda',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.6241248241, -7.1324476198],
            [-35.6202774161, -7.1533823352],
            [-35.610437323, -7.1606359563],
            [-35.6075926154, -7.1682756367],
            [-35.6226601751, -7.1784395725],
            [-35.6405378538, -7.183951507],
            [-35.6615882523, -7.1904424586],
            [-35.6638685617, -7.1947528195],
            [-35.6639576053, -7.1949215706],
            [-35.671350921, -7.207373079],
            [-35.6952762376, -7.2172260298],
            [-35.6981636861, -7.2184155744],
            [-35.6946859893, -7.2033783928],
            [-35.7036016233, -7.1914118913],
            [-35.709362719, -7.1842036268],
            [-35.7108383465, -7.1706325697],
            [-35.7107538267, -7.1668166546],
            [-35.6968436606, -7.159906778],
            [-35.6949519367, -7.1558823443],
            [-35.6878391606, -7.1558481871],
            [-35.6802077757, -7.1483501235],
            [-35.6719276454, -7.1469437812],
            [-35.6600435817, -7.1388730661],
            [-35.652753658, -7.1395824016],
            [-35.6474554743, -7.1449611946],
            [-35.634956285, -7.1328783302],
            [-35.6241248241, -7.1324476198],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2515906', name: 'Serraria', description: 'Serraria' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.6059404909, -6.8110047882],
            [-35.5997789134, -6.8110286356],
            [-35.5988365276, -6.8108438472],
            [-35.5743651812, -6.8060465131],
            [-35.5781105814, -6.8165462695],
            [-35.5800283685, -6.821922321],
            [-35.581441348, -6.8251342971],
            [-35.5874891319, -6.8388810989],
            [-35.6176019067, -6.8517072791],
            [-35.6304341669, -6.8535106378],
            [-35.6313205414, -6.8533965344],
            [-35.6414216933, -6.8542746926],
            [-35.6585728146, -6.8676978993],
            [-35.6739878146, -6.8662982764],
            [-35.6781400786, -6.8673277702],
            [-35.6875878498, -6.8618159399],
            [-35.6925618672, -6.8598065076],
            [-35.6991427641, -6.8460910213],
            [-35.7088167654, -6.8408222491],
            [-35.7016003466, -6.8328533688],
            [-35.7005482297, -6.8143185133],
            [-35.6791874753, -6.8270337726],
            [-35.6681948865, -6.8108437353],
            [-35.6681452218, -6.8107713294],
            [-35.6364569976, -6.8003095789],
            [-35.6280893352, -6.8066676467],
            [-35.6168239573, -6.8094687687],
            [-35.6059404909, -6.8110047882],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2515930',
        name: 'Sertãozinho',
        description: 'Sertãozinho',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.3720139955, -6.7476892417],
            [-35.3741391204, -6.7559962446],
            [-35.3904156043, -6.7611671715],
            [-35.408584614, -6.7654595887],
            [-35.4124050274, -6.7637812761],
            [-35.4176602888, -6.7578644875],
            [-35.4254313434, -6.7575718421],
            [-35.4330042832, -6.7526662924],
            [-35.4451143506, -6.7681825262],
            [-35.4465081959, -6.7556702406],
            [-35.4433251995, -6.7520285622],
            [-35.4431405537, -6.7513854061],
            [-35.4749484724, -6.7403093456],
            [-35.4540026613, -6.7269986284],
            [-35.4416794966, -6.7230468651],
            [-35.4391504921, -6.7119063927],
            [-35.4336781972, -6.7070797944],
            [-35.4253248555, -6.7135887044],
            [-35.4189246705, -6.7257976631],
            [-35.4168472421, -6.727065931],
            [-35.4166903073, -6.7272356973],
            [-35.412167639, -6.7321460513],
            [-35.3814281903, -6.7406777788],
            [-35.3720139955, -6.7476892417],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2515971', name: 'Sobrado', description: 'Sobrado' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.2270261486, -7.1498922234],
            [-35.2276937044, -7.1607274825],
            [-35.2172585612, -7.1585633488],
            [-35.217067616, -7.1653110484],
            [-35.2117571172, -7.1652656656],
            [-35.2091866463, -7.1748221884],
            [-35.2008772557, -7.1720584022],
            [-35.1966355458, -7.1744717637],
            [-35.1909409942, -7.1706060411],
            [-35.1904283521, -7.1761616174],
            [-35.1875059182, -7.1770392952],
            [-35.2015612915, -7.1873322229],
            [-35.2193996723, -7.1907374919],
            [-35.2300250083, -7.1915505748],
            [-35.2322282478, -7.2051194319],
            [-35.2358898753, -7.2051676247],
            [-35.2375975103, -7.2109623906],
            [-35.2519331052, -7.2132246144],
            [-35.2542565539, -7.2108220842],
            [-35.2698497487, -7.2137715709],
            [-35.2737485363, -7.2101013093],
            [-35.2813686709, -7.2126360849],
            [-35.2820804007, -7.2159282575],
            [-35.2857111651, -7.2124011276],
            [-35.2895151497, -7.2164111462],
            [-35.3034451636, -7.2141956468],
            [-35.3044206825, -7.2156347756],
            [-35.3084260016, -7.2045180996],
            [-35.3234152051, -7.1850138866],
            [-35.3335776668, -7.1794516591],
            [-35.3323669277, -7.1746255755],
            [-35.2939960708, -7.1849034736],
            [-35.2891460078, -7.1860692638],
            [-35.2812299392, -7.1879738068],
            [-35.259399598, -7.1635477936],
            [-35.2585165207, -7.1576258818],
            [-35.2556049708, -7.1452146655],
            [-35.2558171251, -7.1403149475],
            [-35.2610344569, -7.1375977443],
            [-35.2612082405, -7.1289116181],
            [-35.250780997, -7.121744201],
            [-35.2383578567, -7.1192292338],
            [-35.2357895087, -7.1228406869],
            [-35.2389982382, -7.1296866051],
            [-35.2375874784, -7.1378513285],
            [-35.2320884431, -7.1421071755],
            [-35.231231903, -7.1489963343],
            [-35.2270261486, -7.1498922234],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516003', name: 'Solânea', description: 'Solânea' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.7105891907, -6.6537984696],
            [-35.6975445157, -6.6574443486],
            [-35.6879148464, -6.6538693405],
            [-35.6832293516, -6.6589554126],
            [-35.6833125196, -6.6591701904],
            [-35.68857101, -6.6697957724],
            [-35.685190012, -6.6826927669],
            [-35.6777919773, -6.6874924887],
            [-35.6726325085, -6.7034606019],
            [-35.6726300031, -6.716796639],
            [-35.676165143, -6.7256139729],
            [-35.6731686501, -6.7316262969],
            [-35.663490605, -6.7466339926],
            [-35.6518802168, -6.754969154],
            [-35.6484932881, -6.7584898652],
            [-35.6474251034, -6.7622030123],
            [-35.643865886, -6.7745727665],
            [-35.6364569976, -6.8003095789],
            [-35.6681452218, -6.8107713294],
            [-35.6681948865, -6.8108437353],
            [-35.6791874753, -6.8270337726],
            [-35.7005482297, -6.8143185133],
            [-35.7019159792, -6.8146692947],
            [-35.705770222, -6.8192086799],
            [-35.7158930943, -6.8206738291],
            [-35.7197466169, -6.8247940569],
            [-35.7290981952, -6.8168585546],
            [-35.742222931, -6.8131222274],
            [-35.7521564823, -6.8249333855],
            [-35.7549548456, -6.8245172994],
            [-35.75919821, -6.8241678771],
            [-35.7624192243, -6.8237548833],
            [-35.7865216196, -6.8237796007],
            [-35.7889172628, -6.8187215773],
            [-35.8039969124, -6.7868733232],
            [-35.809771311, -6.769194912],
            [-35.7937936398, -6.756068056],
            [-35.797506626, -6.7526268089],
            [-35.7942260846, -6.7491168186],
            [-35.788840665, -6.7486159324],
            [-35.7854632134, -6.7149922246],
            [-35.7798683325, -6.7045695554],
            [-35.7821701365, -6.7007444251],
            [-35.7792774476, -6.6973965925],
            [-35.7803819497, -6.6908719816],
            [-35.7799356778, -6.6906615142],
            [-35.7599058694, -6.6905695228],
            [-35.7488870622, -6.685468948],
            [-35.7445436087, -6.676880423],
            [-35.7444479043, -6.6768744625],
            [-35.7422800781, -6.6767402686],
            [-35.7387434675, -6.6733094232],
            [-35.7216946165, -6.6701954179],
            [-35.7145976981, -6.6636015674],
            [-35.7105891907, -6.6537984696],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516102', name: 'Soledade', description: 'Soledade' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.2184301901, -7.1773767713],
            [-36.220019494, -7.1878567416],
            [-36.2242299175, -7.1970202143],
            [-36.2221835468, -7.20023245],
            [-36.2853630575, -7.1926979677],
            [-36.3257731506, -7.1883499305],
            [-36.4057226107, -7.2178896965],
            [-36.4181924328, -7.1990529181],
            [-36.4859906411, -7.1895711057],
            [-36.4881348238, -7.160001829],
            [-36.483351346, -7.1442792945],
            [-36.4697124794, -7.1324599567],
            [-36.471989516, -7.1216310602],
            [-36.4873134452, -7.0349379974],
            [-36.4876350704, -7.0319114693],
            [-36.4881806868, -7.026764672],
            [-36.4759791675, -7.0000293945],
            [-36.4763734139, -6.9938052198],
            [-36.4447792593, -6.9896508615],
            [-36.3936725124, -6.982930199],
            [-36.374974118, -6.9708714865],
            [-36.3747991502, -6.9707589416],
            [-36.351196868, -6.9555396602],
            [-36.3051047605, -7.044993584],
            [-36.2812592909, -7.091205762],
            [-36.2332606238, -7.1046195839],
            [-36.2332402737, -7.1047216216],
            [-36.2312324625, -7.1149392562],
            [-36.2250925284, -7.1212938038],
            [-36.2227699869, -7.1345843778],
            [-36.226254932, -7.1369105657],
            [-36.2196705803, -7.1545740845],
            [-36.2218333122, -7.1611910233],
            [-36.2182531106, -7.1616358609],
            [-36.2211094816, -7.1669881263],
            [-36.2184301901, -7.1773767713],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516151', name: 'Sossêgo', description: 'Sossêgo' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.1724818568, -6.6786550802],
            [-36.1569122273, -6.6801061235],
            [-36.1488841043, -6.6770299818],
            [-36.1392371847, -6.6793886755],
            [-36.1063410667, -6.6679235253],
            [-36.1493789519, -6.7171672853],
            [-36.149439207, -6.7172496612],
            [-36.1664037054, -6.7366551804],
            [-36.2485791362, -6.8307682813],
            [-36.2611053393, -6.8451221193],
            [-36.2587932641, -6.8091797692],
            [-36.2563541382, -6.7714100893],
            [-36.2560794058, -6.7671717652],
            [-36.2526621541, -6.7144720345],
            [-36.2526576967, -6.7143976035],
            [-36.250690425, -6.6841650149],
            [-36.2427904761, -6.6417541146],
            [-36.2427354161, -6.6414568723],
            [-36.2345188788, -6.6413060253],
            [-36.2275002548, -6.6468756798],
            [-36.2118781348, -6.6623274871],
            [-36.1901956965, -6.6736035472],
            [-36.1878811725, -6.6746832728],
            [-36.1724818568, -6.6786550802],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516201', name: 'Sousa', description: 'Sousa' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.180295819, -6.5977949099],
            [-38.1632776405, -6.6218183414],
            [-38.1453989509, -6.6049795887],
            [-38.1270231097, -6.5968591165],
            [-38.1193476267, -6.5946385278],
            [-38.118033474, -6.5977563178],
            [-38.107416571, -6.6040077325],
            [-38.1029941054, -6.6300237876],
            [-38.099056147, -6.6305845239],
            [-38.1019799988, -6.644758113],
            [-38.0925616913, -6.665947281],
            [-38.0938738262, -6.6693096399],
            [-38.0915665508, -6.6887772022],
            [-38.1014748792, -6.7229457386],
            [-38.1396923821, -6.7530403645],
            [-38.1355955993, -6.7875385459],
            [-38.1295132782, -6.8176229612],
            [-38.1189089645, -6.8531936146],
            [-38.1309107518, -6.8600409595],
            [-38.1329855876, -6.8713903003],
            [-38.1371767226, -6.8723825327],
            [-38.1505283788, -6.8951327189],
            [-38.1638501758, -6.8762812849],
            [-38.1671538012, -6.8716053113],
            [-38.1960315344, -6.869122475],
            [-38.1961833265, -6.8691578128],
            [-38.2714190516, -6.8867633437],
            [-38.2569391162, -6.9042255097],
            [-38.2758554182, -6.9103339322],
            [-38.2810263806, -6.9059841674],
            [-38.2914682565, -6.9091733454],
            [-38.3129727499, -6.897207527],
            [-38.3244583084, -6.8750663626],
            [-38.3167017532, -6.8421909907],
            [-38.3177051989, -6.8277086026],
            [-38.3092667133, -6.8091589272],
            [-38.306985322, -6.8041436848],
            [-38.2900909893, -6.7669988942],
            [-38.3533823361, -6.7715254876],
            [-38.3721464377, -6.772302359],
            [-38.3598930029, -6.7621482747],
            [-38.3559970342, -6.7573914818],
            [-38.3552243872, -6.6938205775],
            [-38.3550693869, -6.6812550407],
            [-38.3550639475, -6.6808108245],
            [-38.3505338953, -6.6592028521],
            [-38.3458748803, -6.6365747512],
            [-38.3411660131, -6.6285062039],
            [-38.3526158636, -6.6199606188],
            [-38.3527355232, -6.614372343],
            [-38.3381594948, -6.6174479531],
            [-38.3138388823, -6.6128629431],
            [-38.3026080052, -6.6266488732],
            [-38.2921906848, -6.6296836794],
            [-38.2833654111, -6.6457452908],
            [-38.2714214072, -6.6276576423],
            [-38.2635713325, -6.6202026662],
            [-38.262791564, -6.6129498284],
            [-38.2559212838, -6.6079190337],
            [-38.2459486396, -6.5973913205],
            [-38.185283609, -6.5977643392],
            [-38.180295819, -6.5977949099],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516300', name: 'Sumé', description: 'Sumé' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.96041627, -7.4849463136],
            [-36.9499168049, -7.5005348787],
            [-36.9368330383, -7.5053285095],
            [-36.9236063392, -7.5031971113],
            [-36.9155571119, -7.5067718335],
            [-36.9012595565, -7.5014183891],
            [-36.9011125194, -7.4975422505],
            [-36.8835275755, -7.4927073752],
            [-36.8539183975, -7.4996489588],
            [-36.8759024908, -7.5156626185],
            [-36.9005206427, -7.533596486],
            [-36.8871675483, -7.5342224521],
            [-36.8740669916, -7.5348357468],
            [-36.828511822, -7.5369647362],
            [-36.8290385791, -7.5390448376],
            [-36.8385930968, -7.5585211702],
            [-36.8244098282, -7.5795296552],
            [-36.8393388118, -7.5932188466],
            [-36.823187104, -7.6082486413],
            [-36.8230761891, -7.608321543],
            [-36.8146478075, -7.6187784436],
            [-36.8099861559, -7.6457327895],
            [-36.7831606335, -7.6482543417],
            [-36.7826957413, -7.656431233],
            [-36.7954482806, -7.6616082048],
            [-36.7960960267, -7.6645454931],
            [-36.7965493454, -7.6731877869],
            [-36.7865582872, -7.6900341595],
            [-36.7638000507, -7.7283767019],
            [-36.725660935, -7.7754044831],
            [-36.7337359251, -7.7882840156],
            [-36.7417738791, -7.7926404553],
            [-36.7463905216, -7.8023168134],
            [-36.7502560265, -7.8022321258],
            [-36.7524867687, -7.7998168547],
            [-36.7586924985, -7.8029987967],
            [-36.760314462, -7.8177233524],
            [-36.7692642618, -7.8167858883],
            [-36.7731661043, -7.8193664158],
            [-36.7943406505, -7.829609612],
            [-36.8032621769, -7.8276709033],
            [-36.8154135627, -7.8305409548],
            [-36.83360111, -7.84230217],
            [-36.8288170204, -7.8121424714],
            [-36.9613529157, -7.8307051678],
            [-36.975694571, -7.8083064684],
            [-36.9988697832, -7.7720906313],
            [-37.0126011239, -7.7506197836],
            [-37.0248680794, -7.7174433125],
            [-37.0222397459, -7.7165427946],
            [-37.0242929242, -7.65802919],
            [-37.0251413886, -7.633691267],
            [-37.0354357178, -7.6353458837],
            [-37.0462468767, -7.6244772406],
            [-37.0531128952, -7.6247845496],
            [-37.0708008349, -7.6117845338],
            [-37.0759508074, -7.6124718415],
            [-37.0856432092, -7.5956165829],
            [-37.0755468559, -7.5994838979],
            [-37.0693334308, -7.6093129073],
            [-37.05968674, -7.6112345895],
            [-37.0451941548, -7.6086921226],
            [-36.9883985737, -7.6302923358],
            [-36.9818880732, -7.6198370213],
            [-36.998984768, -7.6059865803],
            [-36.9982859667, -7.584821399],
            [-37.0018400152, -7.5794109157],
            [-36.9988807334, -7.5747199522],
            [-37.0020548513, -7.5675668823],
            [-37.0001923326, -7.5638960688],
            [-36.9781543215, -7.5427202894],
            [-37.0117818496, -7.5039765933],
            [-37.0228562355, -7.5017739102],
            [-37.024862048, -7.4962901868],
            [-37.0102210762, -7.4891494349],
            [-37.002912741, -7.4959808152],
            [-36.9838852477, -7.4818541234],
            [-36.96041627, -7.4849463136],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2516409',
        name: 'Campo de Santana',
        description: 'Campo de Santana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.6200521524, -6.4569239556],
            [-35.6165476579, -6.4622325855],
            [-35.5998617711, -6.4602101815],
            [-35.5918380398, -6.4712297163],
            [-35.5913857354, -6.4767577665],
            [-35.5810589071, -6.4853959557],
            [-35.5638645538, -6.4870682335],
            [-35.5644695306, -6.4913790712],
            [-35.5581876491, -6.4924807758],
            [-35.5517741272, -6.4904231546],
            [-35.5463684591, -6.4814658912],
            [-35.5404367895, -6.4825685365],
            [-35.5376318684, -6.4795046492],
            [-35.5333111797, -6.4826316143],
            [-35.5267709257, -6.4788732232],
            [-35.5255639499, -6.4758252593],
            [-35.5136286372, -6.477757423],
            [-35.5010846537, -6.4774020193],
            [-35.4954681427, -6.4828592078],
            [-35.4916572072, -6.4796843545],
            [-35.4789947599, -6.4863922974],
            [-35.4638991416, -6.4823112112],
            [-35.4597213133, -6.4763776734],
            [-35.4463724462, -6.4864425646],
            [-35.4423307252, -6.4789763754],
            [-35.4414040298, -6.4820694185],
            [-35.4417744918, -6.4861914155],
            [-35.4403000751, -6.5001452565],
            [-35.4273138075, -6.5089358138],
            [-35.429546491, -6.5288445585],
            [-35.4345964781, -6.5318654972],
            [-35.4322112379, -6.5355236539],
            [-35.435344936, -6.5600187945],
            [-35.4492202027, -6.5706687302],
            [-35.4434505922, -6.5760307636],
            [-35.4480963711, -6.6034751179],
            [-35.4538414293, -6.6118804234],
            [-35.4647106911, -6.611026939],
            [-35.4665094306, -6.6107276579],
            [-35.469890801, -6.611181143],
            [-35.4726390687, -6.6160387291],
            [-35.4812847544, -6.6162718778],
            [-35.4903869761, -6.6227846877],
            [-35.4941357619, -6.6351508291],
            [-35.5030460162, -6.6323603787],
            [-35.5033038158, -6.644521731],
            [-35.5081073415, -6.6476606063],
            [-35.5256537177, -6.644248451],
            [-35.5252107959, -6.6083520034],
            [-35.5286901397, -6.6050519917],
            [-35.5364694694, -6.6015850399],
            [-35.5513714167, -6.5759937132],
            [-35.556037889, -6.571811635],
            [-35.5651432148, -6.570385478],
            [-35.5669159424, -6.5664363273],
            [-35.5681603129, -6.5586045167],
            [-35.5580406993, -6.5457562321],
            [-35.5701268464, -6.5326941583],
            [-35.5754949783, -6.5273309101],
            [-35.6291844387, -6.500094155],
            [-35.653109445, -6.4914300143],
            [-35.6364723379, -6.45398821],
            [-35.6200521524, -6.4569239556],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516508', name: 'Taperoá', description: 'Taperoá' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.77812237, -7.1121251154],
            [-36.7519356271, -7.1214062635],
            [-36.7276766097, -7.114224415],
            [-36.7290760421, -7.1238178273],
            [-36.7118684222, -7.1414488827],
            [-36.6983670559, -7.134765858],
            [-36.6937840313, -7.1257738752],
            [-36.6745928475, -7.1235452566],
            [-36.6609848734, -7.1266943809],
            [-36.6619797524, -7.1302425761],
            [-36.6585860238, -7.1462599454],
            [-36.6610880664, -7.1743782497],
            [-36.6588976447, -7.1815205926],
            [-36.6562510134, -7.1861395967],
            [-36.6593037182, -7.1932932855],
            [-36.6744094303, -7.2093846606],
            [-36.6688359718, -7.2093201034],
            [-36.6691691341, -7.2144826723],
            [-36.6795831438, -7.2365250851],
            [-36.6834167751, -7.2411644906],
            [-36.7162744899, -7.2809349819],
            [-36.7074137657, -7.2912250882],
            [-36.701273195, -7.3181410624],
            [-36.7135877666, -7.3176705823],
            [-36.7152220973, -7.3137554741],
            [-36.719421507, -7.3163946639],
            [-36.7371919419, -7.3137033724],
            [-36.7493906993, -7.3045561304],
            [-36.7567203336, -7.3054366052],
            [-36.7737149086, -7.3005635998],
            [-36.7873606441, -7.310723031],
            [-36.7978315494, -7.3098349607],
            [-36.797075387, -7.3207327327],
            [-36.80140651, -7.3303511791],
            [-36.835976786, -7.3264153406],
            [-36.8702591684, -7.315137924],
            [-36.872902964, -7.314280148],
            [-36.9551574165, -7.2875732792],
            [-36.9586680973, -7.2864326839],
            [-36.9796949953, -7.2795985559],
            [-37.0145175614, -7.2503422075],
            [-37.0292700138, -7.2049945514],
            [-37.0292936098, -7.2049225504],
            [-37.0343006058, -7.1895159274],
            [-37.0188145603, -7.1900526573],
            [-37.0016016257, -7.1753310739],
            [-36.9922790804, -7.179715761],
            [-36.9763231426, -7.1600619449],
            [-36.9638642499, -7.1503655168],
            [-36.9544817312, -7.1485100146],
            [-36.9543383262, -7.1485170678],
            [-36.9440986424, -7.1513637463],
            [-36.9309514101, -7.1496673493],
            [-36.9306872339, -7.1530459719],
            [-36.9191687563, -7.154855545],
            [-36.904688036, -7.1516204682],
            [-36.8944193663, -7.1597684718],
            [-36.8797872853, -7.1589439776],
            [-36.8582596194, -7.1487698463],
            [-36.8498448342, -7.1501975695],
            [-36.8441802083, -7.1461377519],
            [-36.8330807027, -7.1448203346],
            [-36.8097714192, -7.1500392731],
            [-36.77812237, -7.1121251154],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516607', name: 'Tavares', description: 'Tavares' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.898057918, -7.5362035088],
            [-37.8946158288, -7.5382420037],
            [-37.899571148, -7.5485362658],
            [-37.8973101608, -7.5614788166],
            [-37.8637539016, -7.5611395533],
            [-37.8613827654, -7.5657026897],
            [-37.8635716301, -7.5806881996],
            [-37.8470593878, -7.5792480511],
            [-37.8445504882, -7.5855903685],
            [-37.8409393969, -7.5847891694],
            [-37.8406224645, -7.5848483542],
            [-37.8354681665, -7.5877101109],
            [-37.8167328636, -7.5968097424],
            [-37.8121163065, -7.6058119314],
            [-37.8022728621, -7.6104816005],
            [-37.7972868733, -7.6096103416],
            [-37.7971836108, -7.6145470755],
            [-37.7970282451, -7.6145545413],
            [-37.780496601, -7.6151178646],
            [-37.7736496442, -7.6255099642],
            [-37.757631389, -7.6337117777],
            [-37.7642401518, -7.6309742874],
            [-37.7668945279, -7.6570711667],
            [-37.7669832602, -7.6561414199],
            [-37.7676363381, -7.6561868423],
            [-37.7678067557, -7.6554889416],
            [-37.7670517389, -7.6554293843],
            [-37.7677380593, -7.648265552],
            [-37.7715706387, -7.6478281219],
            [-37.7757760074, -7.646220477],
            [-37.7760128644, -7.6343037466],
            [-37.780612926, -7.6266358596],
            [-37.790460103, -7.6255356874],
            [-37.8076228564, -7.6435519128],
            [-37.8435619391, -7.6467640929],
            [-37.8568106636, -7.6515606861],
            [-37.8693016981, -7.6671496548],
            [-37.8802501307, -7.6832408315],
            [-37.8970057319, -7.6904180499],
            [-37.9029643049, -7.697608825],
            [-37.9029367571, -7.6976409218],
            [-37.9029783497, -7.6976911932],
            [-37.9045026383, -7.696837108],
            [-37.9113321929, -7.6936421244],
            [-37.916188011, -7.6825775257],
            [-37.9227892744, -7.6791018069],
            [-37.9336810436, -7.6724347774],
            [-37.9373375833, -7.6630446654],
            [-37.9450392022, -7.6577946363],
            [-37.9433909025, -7.6509303294],
            [-37.946802886, -7.6457289696],
            [-37.9562221492, -7.6382546466],
            [-37.956192712, -7.6281206543],
            [-37.9527324801, -7.6233866624],
            [-37.9545059402, -7.6195050298],
            [-37.9535572936, -7.6182952803],
            [-37.9561878115, -7.5844350051],
            [-37.9328487724, -7.5708845585],
            [-37.9293485482, -7.5607411426],
            [-37.9398823935, -7.5556222891],
            [-37.9535267771, -7.5584780348],
            [-37.9582735555, -7.5520610123],
            [-37.968580793, -7.5537764659],
            [-37.976483905, -7.5466504853],
            [-37.9827906166, -7.5457493273],
            [-37.9846592084, -7.540034369],
            [-37.9837761748, -7.5370265649],
            [-37.982104924, -7.5392918342],
            [-37.9738839908, -7.5378703684],
            [-37.9737474042, -7.532213015],
            [-37.9795871223, -7.5253609979],
            [-37.9537721493, -7.5009292328],
            [-37.9448352711, -7.4935837831],
            [-37.9394250241, -7.493188185],
            [-37.9209521498, -7.4754366951],
            [-37.9006696509, -7.5292764513],
            [-37.898057918, -7.5362035088],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516706', name: 'Teixeira', description: 'Teixeira' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.1468612191, -7.263260365],
            [-37.1498704471, -7.2728824078],
            [-37.176583938, -7.3091464475],
            [-37.1963310261, -7.2942632226],
            [-37.2138332064, -7.2908957136],
            [-37.2325019093, -7.2746828121],
            [-37.23326959, -7.2739904884],
            [-37.2604764521, -7.2737543958],
            [-37.2774208779, -7.2747794321],
            [-37.287989876, -7.2878830813],
            [-37.3074695067, -7.2888358713],
            [-37.3136180832, -7.2889547139],
            [-37.3154354155, -7.279293467],
            [-37.3117732047, -7.2713835744],
            [-37.3056657364, -7.2617855604],
            [-37.309077876, -7.2532852952],
            [-37.3042072008, -7.2514507258],
            [-37.3156279037, -7.2413193843],
            [-37.3226427605, -7.2415149709],
            [-37.3244919378, -7.2339851391],
            [-37.3340996081, -7.2277956129],
            [-37.324982655, -7.2250589048],
            [-37.3220282253, -7.2142299928],
            [-37.3352139355, -7.2139851058],
            [-37.3033719033, -7.1913823767],
            [-37.2765846527, -7.1942178797],
            [-37.2469335152, -7.197353383],
            [-37.2101360107, -7.2044162012],
            [-37.1759725482, -7.230254904],
            [-37.1718238561, -7.2370248197],
            [-37.1454993492, -7.2460732332],
            [-37.1468612191, -7.263260365],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516755', name: 'Tenório', description: 'Tenório' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.5704091104, -6.9335801625],
            [-36.5745426971, -6.9379531885],
            [-36.5702500195, -6.9726783568],
            [-36.5748430082, -6.9776948332],
            [-36.5960347258, -6.97878716],
            [-36.5992029653, -6.9867615467],
            [-36.6145676376, -6.9903230443],
            [-36.6219386995, -6.9961864227],
            [-36.6181469057, -7.0013613955],
            [-36.6301869883, -7.0078992061],
            [-36.6321964302, -7.0138827377],
            [-36.6349433511, -7.0195714344],
            [-36.6531828294, -7.0092865813],
            [-36.6613702958, -7.0135537367],
            [-36.6756544132, -7.0145498783],
            [-36.6861741943, -7.0091326776],
            [-36.6948405231, -7.000951983],
            [-36.7014769184, -6.985619415],
            [-36.6958804382, -6.9762406588],
            [-36.6858302363, -6.9688963226],
            [-36.6714516749, -6.9715045604],
            [-36.6668601619, -6.9691924835],
            [-36.6614171175, -6.9611467975],
            [-36.6596321698, -6.9342213912],
            [-36.6545422194, -6.9278345529],
            [-36.6371117399, -6.9334038046],
            [-36.6161951398, -6.9336124234],
            [-36.6094584525, -6.9364335563],
            [-36.5676044131, -6.8743943583],
            [-36.557686872, -6.8887350332],
            [-36.5541423481, -6.8999675796],
            [-36.5559377178, -6.9094403899],
            [-36.5709905882, -6.9197015012],
            [-36.5704091104, -6.9335801625],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516805', name: 'Triunfo', description: 'Triunfo' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.5094456582, -6.5015494422],
            [-38.5032535958, -6.5164027762],
            [-38.5263652715, -6.5218242452],
            [-38.533127171, -6.5150688151],
            [-38.5323775137, -6.5217761467],
            [-38.5360995043, -6.5225035814],
            [-38.5319253783, -6.5489305177],
            [-38.5408212169, -6.549705651],
            [-38.5408061407, -6.5498108474],
            [-38.5384990329, -6.5738202872],
            [-38.5430180164, -6.5787283318],
            [-38.5451893063, -6.5903453424],
            [-38.5360862119, -6.5943129044],
            [-38.5387843377, -6.5984613825],
            [-38.5324051643, -6.6091610933],
            [-38.5276042843, -6.6185159233],
            [-38.5262371529, -6.6361122725],
            [-38.5154507148, -6.6355672267],
            [-38.5155768901, -6.6380704691],
            [-38.5159455687, -6.6453738928],
            [-38.508751464, -6.6602620509],
            [-38.4966631013, -6.6621177167],
            [-38.5076717048, -6.6718060848],
            [-38.5242342699, -6.6735119547],
            [-38.5193809697, -6.6947028444],
            [-38.5221235431, -6.7039820789],
            [-38.5345572437, -6.7026552131],
            [-38.5403386926, -6.7000177776],
            [-38.5458713537, -6.6988351063],
            [-38.5571829213, -6.706150076],
            [-38.5711112589, -6.7047453758],
            [-38.579017747, -6.7038351802],
            [-38.5853560778, -6.6902257932],
            [-38.6023055345, -6.6702703573],
            [-38.608542921, -6.6693246665],
            [-38.6113780826, -6.6588289241],
            [-38.6207649555, -6.6489339738],
            [-38.6281065482, -6.6468256324],
            [-38.648775888, -6.6271861886],
            [-38.6463058617, -6.6155811093],
            [-38.6288071005, -6.6041829219],
            [-38.6322856828, -6.5901365377],
            [-38.6425000574, -6.5807783824],
            [-38.6327279924, -6.5688111434],
            [-38.6322785152, -6.5620381794],
            [-38.6253080154, -6.5575843199],
            [-38.6262527908, -6.5525551434],
            [-38.6067315531, -6.5291158944],
            [-38.6016286821, -6.5256257215],
            [-38.5917435452, -6.518865624],
            [-38.570936837, -6.5135626709],
            [-38.5421954228, -6.5117415305],
            [-38.5420799093, -6.5116464435],
            [-38.5360034268, -6.5108627421],
            [-38.5367284143, -6.491659218],
            [-38.5130096074, -6.4929983495],
            [-38.5094456582, -6.5015494422],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2516904', name: 'Uiraúna', description: 'Uiraúna' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.4379925952, -6.4094767098],
            [-38.4256898542, -6.4137256694],
            [-38.4056815013, -6.4097927631],
            [-38.4026845281, -6.4093855247],
            [-38.4013883686, -6.4091607896],
            [-38.3911980856, -6.4158093804],
            [-38.3878351476, -6.4185513297],
            [-38.3826174636, -6.4243558751],
            [-38.3815869167, -6.4316593186],
            [-38.3747685245, -6.4369807868],
            [-38.3733121004, -6.4553824491],
            [-38.3634396449, -6.45324344],
            [-38.3606248241, -6.4615889583],
            [-38.3548679357, -6.4568227857],
            [-38.3434510984, -6.4562532781],
            [-38.3389741287, -6.4597274406],
            [-38.3324399666, -6.4577916523],
            [-38.3280869343, -6.4602573988],
            [-38.3179053773, -6.4733764906],
            [-38.3156337823, -6.4820212382],
            [-38.3124596677, -6.4878801482],
            [-38.3121440891, -6.4877346699],
            [-38.3116575504, -6.4884737166],
            [-38.3085642181, -6.4891279638],
            [-38.2971606783, -6.4868076875],
            [-38.2936783323, -6.4890025681],
            [-38.2883696391, -6.5052183395],
            [-38.2831041347, -6.5224942158],
            [-38.3051158433, -6.527312023],
            [-38.2985619304, -6.5593652675],
            [-38.3036578913, -6.5720940171],
            [-38.3183779245, -6.5774905926],
            [-38.334268993, -6.5779237738],
            [-38.3417353376, -6.5817249329],
            [-38.3478254243, -6.5805750917],
            [-38.3486820242, -6.5736963236],
            [-38.3604806156, -6.5684400114],
            [-38.3851963646, -6.5813997037],
            [-38.4098946789, -6.5999181573],
            [-38.4115326377, -6.6011464605],
            [-38.4360775304, -6.5742868344],
            [-38.45774172, -6.5683585166],
            [-38.4925324131, -6.5720093533],
            [-38.4947351704, -6.5562273999],
            [-38.4987421041, -6.5274826548],
            [-38.4674399678, -6.5310544203],
            [-38.4578809922, -6.5255699201],
            [-38.4518925598, -6.5166110687],
            [-38.4499468774, -6.5023792145],
            [-38.4531846627, -6.4696707649],
            [-38.4539191484, -6.4344584257],
            [-38.4579549916, -6.410934249],
            [-38.4684771889, -6.4114242299],
            [-38.4704883102, -6.3997788971],
            [-38.465457493, -6.3960119023],
            [-38.451712113, -6.3920912974],
            [-38.4484860806, -6.394177206],
            [-38.4476073131, -6.4029544669],
            [-38.4412595689, -6.4043039789],
            [-38.4379925952, -6.4094767098],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2517001',
        name: 'Umbuzeiro',
        description: 'Umbuzeiro',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-35.6240577793, -7.6080415501],
            [-35.6298283944, -7.6232786129],
            [-35.638745972, -7.6286686994],
            [-35.6400976362, -7.6365601094],
            [-35.6502872821, -7.6371772413],
            [-35.6516261263, -7.6442676143],
            [-35.6191798332, -7.6636720825],
            [-35.6221652807, -7.6695824618],
            [-35.6265177582, -7.6751257717],
            [-35.6449394995, -7.6919988104],
            [-35.6552310142, -7.6966386299],
            [-35.6572388096, -7.6967807138],
            [-35.6585698125, -7.6968202849],
            [-35.6748256096, -7.6997710534],
            [-35.6748597941, -7.699777614],
            [-35.680571391, -7.6999325904],
            [-35.6933869033, -7.6993711144],
            [-35.6950262865, -7.6935637704],
            [-35.6997452809, -7.6914465381],
            [-35.7078567598, -7.696936969],
            [-35.7112784125, -7.7048047193],
            [-35.7210736397, -7.7090520279],
            [-35.736218261, -7.7091886055],
            [-35.7427745095, -7.7133587911],
            [-35.7458846636, -7.7204380331],
            [-35.7461256838, -7.7209769708],
            [-35.7514812587, -7.7232527985],
            [-35.7645170978, -7.7244896761],
            [-35.7802485962, -7.7379417822],
            [-35.7896006848, -7.7400255857],
            [-35.791538542, -7.7400104762],
            [-35.7971575699, -7.7404235323],
            [-35.8057674201, -7.7462744437],
            [-35.8155255762, -7.7462653592],
            [-35.8158979898, -7.7407699755],
            [-35.8249106948, -7.7415888019],
            [-35.82881262, -7.7378098534],
            [-35.8279405344, -7.720182361],
            [-35.8321746734, -7.7133401966],
            [-35.827686366, -7.6897153757],
            [-35.8292330818, -7.6808417479],
            [-35.8366524505, -7.6758961806],
            [-35.8328669899, -7.6663963441],
            [-35.836244874, -7.6673995399],
            [-35.8381874448, -7.6643902443],
            [-35.8346182253, -7.6606101819],
            [-35.8169759156, -7.6625112015],
            [-35.813047395, -7.659647125],
            [-35.811512758, -7.6457667785],
            [-35.8076866764, -7.6425710143],
            [-35.7976886936, -7.6552130285],
            [-35.7780881198, -7.6429676717],
            [-35.7744674774, -7.6351091155],
            [-35.7630836348, -7.6385987625],
            [-35.7613488265, -7.6477866701],
            [-35.7496102125, -7.6513641401],
            [-35.7365295911, -7.6458266595],
            [-35.711466608, -7.6415761628],
            [-35.7111721689, -7.6415263749],
            [-35.6852772047, -7.6408094086],
            [-35.6766935176, -7.6361063455],
            [-35.6749446695, -7.6268140732],
            [-35.6701195118, -7.6219979616],
            [-35.6578419111, -7.6191797179],
            [-35.6519486156, -7.612438997],
            [-35.6341121956, -7.612252031],
            [-35.6240577793, -7.6080415501],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2517100', name: 'Várzea', description: 'Várzea' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-36.971459169, -6.7402436272],
            [-36.9715481075, -6.7650806836],
            [-36.9642275429, -6.7736534426],
            [-36.9523241726, -6.7747510867],
            [-36.9587591996, -6.7829006145],
            [-36.9659907568, -6.7903476007],
            [-36.9624729117, -6.799847479],
            [-36.9636180057, -6.8070065252],
            [-36.9549850365, -6.8176140882],
            [-37.0101244369, -6.8691001768],
            [-37.0103864857, -6.8740187578],
            [-37.022476684, -6.8732930348],
            [-37.0214727378, -6.8598452606],
            [-37.0327887294, -6.8580672974],
            [-37.0350706865, -6.8461833362],
            [-37.0442953852, -6.8381345766],
            [-37.0596695135, -6.8426528742],
            [-37.0665976877, -6.8326572044],
            [-37.0844815879, -6.8127893315],
            [-37.0802465883, -6.8024040596],
            [-37.0941239835, -6.8074355197],
            [-37.1073769608, -6.807487622],
            [-37.1089036732, -6.7998200428],
            [-37.1384102942, -6.785740924],
            [-37.1518868122, -6.7979618023],
            [-37.1285955643, -6.765575174],
            [-37.1197201012, -6.7668898214],
            [-37.0750773775, -6.7537322657],
            [-37.0590836594, -6.7490233066],
            [-37.0349209784, -6.7337644954],
            [-37.0118464464, -6.7158213026],
            [-37.0096467884, -6.714110578],
            [-37.0032522039, -6.7092107543],
            [-36.9896021741, -6.7087485946],
            [-36.9772829359, -6.7214536342],
            [-36.971459169, -6.7402436272],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2517209',
        name: 'Vieirópolis',
        description: 'Vieirópolis',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-38.2267698705, -6.4835489209],
            [-38.1966634342, -6.5504588883],
            [-38.1950826103, -6.5542561658],
            [-38.2118340963, -6.5551959105],
            [-38.2241860098, -6.579986664],
            [-38.2459486396, -6.5973913205],
            [-38.2559212838, -6.6079190337],
            [-38.262791564, -6.6129498284],
            [-38.2635713325, -6.6202026662],
            [-38.2714214072, -6.6276576423],
            [-38.2833654111, -6.6457452908],
            [-38.2921906848, -6.6296836794],
            [-38.3026080052, -6.6266488732],
            [-38.3138388823, -6.6128629431],
            [-38.3381594948, -6.6174479531],
            [-38.3527355232, -6.614372343],
            [-38.3424928223, -6.6029837492],
            [-38.3424400925, -6.5909318139],
            [-38.334268993, -6.5779237738],
            [-38.3183779245, -6.5774905926],
            [-38.3036578913, -6.5720940171],
            [-38.2985619304, -6.5593652675],
            [-38.3051158433, -6.527312023],
            [-38.2831041347, -6.5224942158],
            [-38.2883696391, -6.5052183395],
            [-38.269496277, -6.4940418199],
            [-38.2610635738, -6.4847467707],
            [-38.2561812022, -6.4828105389],
            [-38.2525380573, -6.485023531],
            [-38.2388787491, -6.4798984171],
            [-38.228672182, -6.4829476869],
            [-38.2267698705, -6.4835489209],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { id: '2517407', name: 'Zabelê', description: 'Zabelê' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.1049026413, -8.0480060469],
            [-37.0926521766, -8.0505652],
            [-37.0790699967, -8.0456286933],
            [-37.0681950378, -8.0332984913],
            [-37.0380482664, -8.0436363491],
            [-37.0302958361, -8.0609333104],
            [-37.0319676901, -8.0693910688],
            [-37.0282797561, -8.0811904148],
            [-37.0208703806, -8.0944031478],
            [-37.0209867731, -8.0944416172],
            [-37.0261141677, -8.099742059],
            [-37.0429135632, -8.1028061968],
            [-37.0487201272, -8.0991330744],
            [-37.0611389269, -8.1004683653],
            [-37.0686156133, -8.1064964172],
            [-37.0837244632, -8.1103230793],
            [-37.092582468, -8.1205751385],
            [-37.1062751421, -8.1269960968],
            [-37.1179987866, -8.1255956671],
            [-37.1212607037, -8.1278586108],
            [-37.1328273156, -8.1232004856],
            [-37.1438299839, -8.1245840086],
            [-37.1422155577, -8.1175324255],
            [-37.147475601, -8.0996801744],
            [-37.1433648775, -8.0875534149],
            [-37.1451671022, -8.0817777575],
            [-37.1565029479, -8.0641208369],
            [-37.1577500802, -8.050812494],
            [-37.1480565449, -8.0347019237],
            [-37.1492289992, -8.023326569],
            [-37.1298675771, -8.0241723368],
            [-37.1247964637, -8.039227012],
            [-37.1115368176, -8.0404452126],
            [-37.1049026413, -8.0480060469],
          ],
        ],
      },
    },
  ],
};
