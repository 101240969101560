import {
	Box,
	Text,
	Grid,
	GridItem,
	useBreakpointValue,
	Skeleton
} from '@chakra-ui/react';
import TeamCard from '../../Components/TeamCard/TeamCard';
import { ParticlesDesign } from '../../Components/Design/ParticlesDesign';

export default function Team() {
	const isDesktop = useBreakpointValue({ base: false, md: true });

	if (!isDesktop) {
		return (
			<Box padding={4}>
				<ParticlesDesign />

				<Box textAlign="center">
					<Text fontSize="2xl">Coordenadores</Text>
					<Grid templateColumns="repeat(2, 1fr)" gap={6}>
						<TeamCard imgSrc="/5.png" teamName={'Henrique Menezes'} />
						<TeamCard imgSrc="/5.png" teamName={'Italo Fittipaldi'} />
						<TeamCard
							imgSrc="/pascoal.jpg"
							email="pascoalgoncalves@ccsa.ufpb.br"
							teamName={'Pascoal Gonçalves'}
						/>
					</Grid>
				</Box>

				<Box textAlign="center" marginTop={8}>
					<Text fontSize="2xl">Desenvolvedores</Text>
					<Grid templateColumns="repeat(2, 1fr)" gap={6}>
						<TeamCard imgSrc="/cristhian.jpg" teamName={'Cristhian Cunha'} />
						<TeamCard
							imgSrc="/gustavo.jpg"
							email="gustavodias@cc.ci.ufpb.br"
							teamName={'Gustavo Dias'}
						/>
					</Grid>
				</Box>

				<Box textAlign="center" marginTop={8}>
					<Text fontSize="2xl">Pesquisadores</Text>
					<Grid templateColumns="repeat(2, 1fr)" gap={6}>
						<TeamCard imgSrc="/5.png" teamName={'Bolivar Pedrosa'} />
						<TeamCard imgSrc="/5.png" teamName={'Daniel Henrique'} />
						<TeamCard imgSrc="/isadora.jpeg" teamName={'Isadora Silva'} />
						<TeamCard imgSrc="/5.png" teamName={'Lucas Tomaz'} />
						<TeamCard imgSrc="/maria.jpg" teamName={'Maria Fernanda'} />
						<TeamCard imgSrc="/5.png" teamName={'Romberg Gondim'} />
					</Grid>
				</Box>
			</Box>
		);
	}

	// Desktop layout
	return (
		<Box>
			<Box alignItems={'flex-start'} textAlign={'initial'} margin={12}>
				<ParticlesDesign />
				<Grid templateColumns={'0.08fr 1.6fr 0.08fr 1.06fr 0.08fr'} gap={6}>
					<GridItem />
					<GridItem zIndex={1}>
						<Box alignItems={'center'} justifyContent={'center'}>
							<Text fontSize={'3xl'} style={{ textIndent: '2em' }}>
								Coordenadores
							</Text>
							<Grid templateColumns="repeat(3, minmax(200px, 1fr))" gap={6}>
								<TeamCard imgSrc="/5.png" teamName={'Henrique Menezes'} />
								<TeamCard imgSrc="/5.png" teamName={'Italo Fittipaldi'} />
								<TeamCard
									imgSrc="/pascoal.jpg"
									email="pascoalgoncalves@ccsa.ufpb.br"
									teamName={'Pascoal Gonçalves'}
								/>
							</Grid>
						</Box>
					</GridItem>
					<GridItem />
					<GridItem zIndex={1}>
						<Box>
							<Text fontSize={'3xl'} style={{ textIndent: '2em' }}>
								Desenvolvedores
							</Text>
							<Grid templateColumns="repeat(2, minmax(200px, 1fr))" gap={6}>
								<TeamCard
									imgSrc="/cristhian.jpg"
									teamName={'Cristhian Cunha'}
								/>
								<TeamCard
									imgSrc="/gustavo.jpg"
									email="gustavodias@cc.ci.ufpb.br"
									teamName={'Gustavo Dias'}
								/>
							</Grid>
						</Box>
					</GridItem>
					<GridItem />
				</Grid>
				<Grid>
					<Text fontSize={'3xl'} style={{ textIndent: '3.5em' }} zIndex={1}>
						Pesquisadores
					</Text>
					<Grid templateColumns="0.1fr 2.74fr 0.1fr">
						<GridItem />
						<GridItem>
							<Grid
								templateColumns="repeat(auto-fit, minmax(220px, 1fr))"
								gap={6}
							>
								{/* <TeamCard imgSrc="/5.png" teamName={'Bolivar Pedrosa'} /> */}
								{/* <TeamCard imgSrc="/5.png" teamName={'Daniel Henrique'} /> */}
								<TeamCard imgSrc="/isadora.jpeg" teamName={'Isadora Silva'} />
								{/* <TeamCard imgSrc="/5.png" teamName={'Lucas Tomaz'} /> */}
								<TeamCard imgSrc="/maria.jpg" teamName={'Maria Fernanda'} />
								{/* <TeamCard imgSrc="/5.png" teamName={'Romberg Gondim'} /> */}
							</Grid>
						</GridItem>
						<GridItem />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
