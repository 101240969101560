import { useState } from 'react';
import {
	ChakraProvider,
	Box,
	VStack,
	HStack,
	Button,
	Select,
	useBreakpointValue,
	FormLabel,
	useColorModeValue,
	Text,
	Divider,
	Input,
	Center,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { useQuery } from 'react-query';
import axios from 'axios';
import { saveAs } from 'file-saver';

function Database() {
	const [url, setUrl] = useState('');
	// IA == Indicador por Ano | SH == Série Histórica | ID == Indice por Ano
	const [dataTypeIA, setDataTypeIA] = useState('');
	const [dataTypeSH, setDataTypeSH] = useState('');
	const [indicator, setIndicator] = useState('');
	const [bruteQuery, setBruteQuery] = useState('');
	const [yearIA, setYearIA] = useState('');
	const [yearID, setYearID] = useState('');

	const isMobile = useBreakpointValue({ base: true, md: false });
	const buttonColor = useColorModeValue('gray.600', 'gray.200');

	const { data: yearsIndex, isFetching: isFetchingYearsIndex } = useQuery(
		'avaliableYearsIDSM',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br:444/avaliableyearsidsm',
			);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	);

	const { data: dataInfo, isFetching: isFetchingInfo } = useQuery(
		'indexLabelsJson',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br:444/indexlabels/json',
			);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	);

	async function DownloadDataIndicatorYear() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/indicator/xlsx/${dataTypeIA}/${indicator.toLowerCase()}/${yearIA}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(blob, `${dataTypeIA}${indicator.toLowerCase()}${yearIA}.xlsx`);
			return response.data;
		}
	}

	async function DownloadBruteQuery() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/brutequery/${bruteQuery.toLowerCase()}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(blob, `${bruteQuery.toLowerCase()}.xlsx`);
			return response.data;
		}
	}

	async function DownloadDataHistory() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/history/xlsx/${dataTypeSH}/${indicator.toLowerCase()}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(
				blob,
				`${dataTypeSH}${indicator.toLowerCase()}_serie_historica.xlsx`,
			);
			return response.data;
		}
	}

	async function DownloadDataIndex() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/idsmindex/xlsx/${yearID}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(blob, `IDSM${yearID}.xlsx`);
			return response.data;
		}
	}



	const handleChangeIndicatorIA = (event) => {
		setIndicator(event.target.value);
		console.log(event.target.value);
	};

	const handleChangeIndicatorSH = (event) => {
		setIndicator(event.target.value);
		console.log(event.target.value);
	};

	const handleChangeDataTypeIA = (event) => {
		setDataTypeIA(event.target.value);
		console.log(event.target.value);
	};
	const handleChangeDataTypeSH = (event) => {
		setDataTypeSH(event.target.value);
		console.log(event.target.value);
	};

	const handleChangeYearIA = (event) => {
		console.log(event.target.value);
		setYearIA(event.target.value);
		console.log(event.target.value);
	};
	const handleChangeYearID = (event) => {
		console.log(event.target.value);
		setYearID(event.target.value);
		console.log(event.target.value);
	};

	const handleBruteQuery = (event) => {
		console.log(event.target.value);
		setBruteQuery(event.target.value);
		console.log(event.target.value);
	};

	const elementosAno = () => {
		const listaAnos = dataInfo?.map((item) => {
			// console.log(item)
			let listaAnos = [];
			if (item.codigoIndicador === indicator) {
				const anos = item.anosDisponiveis.slice(1, -1).split(',').map(Number);
				anos.sort(function (a, b) {
					return a - b;
				});
				listaAnos = anos.map((year) => {
					return (
						<option key={year} value={year}>
							{year}
						</option>
					);
				});
			}
			return listaAnos;
		});
		return listaAnos;
	};

	const anosIndice = () => {
		const listaAnos = yearsIndex.map((year) => {
			console.log(year.anos)
					return (
						<option key={year.anos} value={year.anos}>
							{year.anos}
						</option>
						
					);
		});
		return listaAnos;
	}


	return (
		<VStack spacing={8} p={8}>
			{isMobile ? (
				<Box>
					<VStack
						spacing={2}
						width="80vw"
						minWidth="240px"
						maxWidth="1200px"
						alignItems="flex-start"
					>
						<FormLabel paddingLeft="6">Tipo do dado</FormLabel>
						<Select defaultValue="processed" onChange={handleChangeDataTypeIA}>
							{/* <option value="data">Base</option> */}
							<option value="processed">Processado</option>
							<option value="normalized">Normalizado</option>
						</Select>
						<FormLabel paddingLeft="6">Indicador</FormLabel>
						<Select
							placeholder="Selecione um indicador"
							onChange={handleChangeIndicatorIA}
						>
							{dataInfo?.map((indicator) => {
								return (
									<option
										key={indicator.temaIndicador}
										value={indicator.codigoIndicador}
									>
										{indicator.temaIndicador}
									</option>
								);
							})}
						</Select>
						<FormLabel paddingLeft="6">Ano</FormLabel>
						<Select
							placeholder="Selecione o ano de referencia"
							onChange={handleChangeYearIA}
						>
							{elementosAno()}
						</Select>
						<Box paddingTop="4">
							<Button
								leftIcon={<DownloadIcon />}
								width="80vw"
								minWidth="200px"
								maxWidth="1200px"
								onClick={DownloadDataIndicatorYear}
							>
								Download
							</Button>
						</Box>
					</VStack>
				</Box>
			) : (
				<Box>
					<Text marginBottom="12" fontSize="3xl">
						Base de dados
					</Text>
					<Box paddingBottom="8">
						<Divider orientation="horizontal" />
					</Box>
					<VStack>
						<Text marginBottom="4" fontSize="xl">
							Indicador por Ano
						</Text>
						<HStack spacing={4} alignItems="end">
							<Box flex={1}>
								<FormLabel paddingLeft="6">Tipo do dado</FormLabel>
								<Select
									placeholder="Selecione o tipo de dado"
									onChange={handleChangeDataTypeIA}
								>
									{/* <option value="data">Base</option> */}
									<option value="processed">Processado</option>
									<option value="normalized">Normalizado</option>
								</Select>
							</Box>

							<Box flex={1}>
								<FormLabel paddingLeft="6">Indicador</FormLabel>
								<Select
									placeholder="Selecione o indicador"
									onChange={handleChangeIndicatorIA}
								>
									{dataInfo?.map((indicator) => {
										console.log(indicator)
										return (
											<option
												key={indicator.codigoIndicador}
												value={indicator.codigoIndicador}
											>
												{indicator.temaIndicador}
											</option>
										);
									})}
								</Select>
							</Box>
							<Box flex={1}>
								<FormLabel paddingLeft="6">Ano</FormLabel>
								<Select
									placeholder="Selecione o ano"
									onChange={handleChangeYearIA}
								>
									{elementosAno()}
								</Select>
							</Box>
							<Box flex={1}>
								<Button
									leftIcon={<DownloadIcon />}
									colorScheme="blue"
									// _hover={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.6', 'idsm.darkBlueCoolors.6'),
									// }}
									// _active={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.7', 'idsm.darkBlueCoolors.7'),
									// }}
									//  backgroundColor={useColorModeValue('idsm.lightBlueCoolors.base', 'idsm.darkBlueCoolors.base')}
									//  color={useColorModeValue('white', 'idsm.lightBlueCoolors.1')}
									width="100%"
									onClick={DownloadDataIndicatorYear}
								>
									Download
								</Button>
							</Box>
						</HStack>
					</VStack>
					<Divider paddingTop={8} orientation="horizontal" />
					<VStack>
						<Text marginTop="8" marginBottom="4" fontSize="xl">
							Série Histórica
						</Text>
						<HStack spacing={4} alignItems="end">
							<Box flex={1}>
								<FormLabel paddingLeft="6">Tipo do dado</FormLabel>
								<Select
									placeholder="Selecione o tipo de dado"
									onChange={handleChangeDataTypeSH}
								>
									{/* <option value="data">Base</option> */}
									<option value="processed">Processado</option>
									<option value="normalized">Normalizado</option>
								</Select>
							</Box>

							<Box flex={1}>
								<FormLabel paddingLeft="6">Indicador</FormLabel>
								<Select
									placeholder="Selecione o indicador"
									onChange={handleChangeIndicatorSH}
								>
									{dataInfo?.map((indicator) => {
										return (
											<option
												key={indicator.codigoIndicador}
												value={indicator.codigoIndicador}
											>
												{indicator.temaIndicador}
											</option>
										);
									})}
								</Select>
							</Box>
							<Box flex={1}>
								<Button
									leftIcon={<DownloadIcon />}
									colorScheme="blue"
									// _hover={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.6', 'idsm.darkBlueCoolors.6'),
									// }}
									// _active={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.7', 'idsm.darkBlueCoolors.7'),
									// }}
									//  backgroundColor={useColorModeValue('idsm.lightBlueCoolors.base', 'idsm.darkBlueCoolors.base')}
									//  color={useColorModeValue('white', 'idsm.lightBlueCoolors.1')}
									width="100%"
									onClick={DownloadDataHistory}
								>
									Download
								</Button>
							</Box>
						</HStack>
					</VStack>
					<Divider paddingTop={8} orientation="horizontal" />
					<VStack>
						<Text marginTop="8" marginBottom="4" fontSize="xl">
							Indice de Desenvolvimento Municipal da Paraíba
						</Text>
						<HStack spacing={4} alignItems="end">
							<Box flex={2}>
								<FormLabel paddingLeft="6">Ano</FormLabel>
								<Select
									placeholder="Selecione o ano"
									onChange={handleChangeYearID}
								>
									{yearsIndex ? anosIndice() : null }
								</Select>
							</Box>
							<Box flex={1}>
								<Button
									leftIcon={<DownloadIcon />}
									colorScheme="blue"
									// _hover={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.6', 'idsm.darkBlueCoolors.6'),
									// }}
									// _active={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.7', 'idsm.darkBlueCoolors.7'),
									// }}
									//  backgroundColor={useColorModeValue('idsm.lightBlueCoolors.base', 'idsm.darkBlueCoolors.base')}
									//  color={useColorModeValue('white', 'idsm.lightBlueCoolors.1')}
									width="100%"
									onClick={DownloadDataIndicatorYear}
								>
									Download
								</Button>
							</Box>
						</HStack>
					</VStack>
					<Box paddingTop="12">
						<Divider orientation="horizontal" />
					</Box>
					<VStack>
						<Text marginTop="8" marginBottom="4" fontSize="xl">
							Baixar Tabela Bruta
						</Text>
						<HStack spacing={4} alignItems="end">
							<Box flex={2}>
								<FormLabel paddingLeft="6">Nome da Tabela</FormLabel>
								<Input placeholder='Insira o código da tabela' size='md' value={bruteQuery} onChange={handleBruteQuery} />
							</Box>
							<Box flex={1}>
								<Button
									leftIcon={<DownloadIcon />}
									colorScheme="blue"
									// _hover={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.6', 'idsm.darkBlueCoolors.6'),
									// }}
									// _active={{
									//   backgroundColor: useColorModeValue('idsm.lightBlueCoolors.7', 'idsm.darkBlueCoolors.7'),
									// }}
									//  backgroundColor={useColorModeValue('idsm.lightBlueCoolors.base', 'idsm.darkBlueCoolors.base')}
									//  color={useColorModeValue('white', 'idsm.lightBlueCoolors.1')}
									width="100%"
									onClick={DownloadBruteQuery}
								>
									Download
								</Button>
							</Box>
						</HStack>
					</VStack>
				</Box>
			)}
		</VStack>
	);
}

export default Database;
