import { Tooltip } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons';

export const IndicatorTip = ( {tip} ) => {
  console.log(tip)
  return(
    <Tooltip hasArrow bg='gray.300' color='black' label={tip} >
      <SearchIcon marginLeft={'2'}/>
    </Tooltip>
  )
}