import React, { useEffect, useState } from 'react';
import { Box, Text, Avatar, Flex } from '@chakra-ui/react';

export default function AuthorList ({autor}) {
  return (
            <Flex gap='4' alignItems='center' flexWrap='wrap' justifyContent={'center'}>
              <Avatar name={autor.nome} src={autor.img} />
              <Box>
                <Text>{autor.nome} {autor.sobrenome}</Text>
              </Box>
            </Flex>
    );
}