import { extendTheme} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { StyleConfig } from '@chakra-ui/theme-tools';

const config = {
  initialColorMode: 'dark', // 'dark' | 'light'
  useSystemColorMode: true,
};
const colors = {
  brandy: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
  myCustomScheme: {
    900: '#ebf8ff',
    800: '#596877',
    700: '#485969',
    600: '#36485a',
    500: '#223649',
    400: '#0C2237',
    300: '#0b1f32',
    200: '#0a1c2d',
    100: '#091929',
    50: '#081725',
  },
  idsm: {
    darkBlueCoolors: {
      9: '#081725',
      8: '#091929',
      7: '#0a1c2d',
      6: '#0b1f32',
      base: '#0C2237',
      4: '#223649',
      3: '#36485a',
      2: '#485969',
      1: '#596877',
    },
    lightBlueCoolors: {
      9: '#19435f',
      8: '#1b4a69',
      7: '#1e5173',
      6: '#21597E',
      base: '#0873BA',
      4: '#1e80c0',
      3: '#328cc6',
      2: '#4596cb',
      1: '#56a0d0',
    },
    greenCoolors: {
      9: '#027353',
      8: '#027e5b',
      7: '#028b64',
      6: '#02996e',
      base: '#02A879',
      4: '#19b085',
      3: '#2eb790',
      2: '#41be9a',
      1: '#52c4a3',
    },
    yellowCoolors: {
      9: '#9b842d',
      8: '#aa9131',
      7: '#bba036',
      6: '#ceb03b',
      base: '#E3C241',
      4: '#e6c852',
      3: '#e8cd62',
      2: '#ead270',
      1: '#ecd67d',
    },
    redCoolors: {
      9: '#9f302f',
      8: '#af3534',
      7: '#c13a39',
      6: '#d4403f',
      base: '#E94645',
      4: '#eb5756',
      3: '#ed6665',
      2: '#ef7473',
      1: '#f08180',
    },
  },
};
const styles = {
  global: ({ colorMode }) => ({
    '&::-webkit-scrollbar': {
      width: '2vw',
      minW: '10px',
      maxW: '16px'
    },
    '&::-webkit-scrollbar-track': {
      width: '10px',
      background: colorMode === 'dark' ? 'idsm.darkBlueCoolors.7' : 'gray.100',
    },	
    '&::-webkit-scrollbar-thumb': {
      background: colorMode === 'dark' ? 'idsm.darkBlueCoolors.9 ' : 'gray.200',
      borderRadius: '10px',
    },
    'html, body': {
      backgroundColor:
        colorMode === 'dark' ? 'idsm.darkBlueCoolors.9' : 'gray.50',
          

      // button: {
      //   color: colorMode === 'dark' ? 'idsm.lightBlueCoolors.1' : 'idsm.lightBlueCoolors.base',
      //   backgroundColor: colorMode === 'dark' ? 'idsm.darkBlueCoolors.7' : 'white',
      // }
    },
    Button: {
      color:
        colorMode === 'dark'
          ? 'idsm.lightBlueCoolors.1'
          : 'idsm.lightBlueCoolors.base',
      backgroundColor:
        colorMode === 'dark' ? 'idsm.darkBlueCoolors.7' : 'white',
    },
  }),
};
const CustomBadge = {
  baseStyle: ({ colorMode }) => ({
    bg: colorMode === 'dark' ? 'green.300' : 'green.500',
    color: colorMode === 'dark' ? 'gray.800' : 'white',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    letterSpacing: '0.02em',
    padding: '4px',
    borderRadius: '2px',
    fontSize: '12px',
  }),
  variants: {
    custom: ({ colorMode }) => ({
      bg: colorMode === 'dark' ? 'blue.200' : 'blue.500',
      padding: '8px',
    }),
  },
};
const components = {
  Button: ({ colorMode }) => ({
    baseStyle: {
      color:
        colorMode === 'dark'
          ? 'idsm.lightBlueCoolors.1'
          : 'idsm.lightBlueCoolors.base',
      backgroundColor:
        colorMode === 'dark' ? 'idsm.darkBlueCoolors.7' : 'white',
      _hover: {
        backgroundColor: '#005a9c',
      },
    },
  }),
};
// const components = {
//   Box: {
//     defaultProps: {
//     }
//   },
//   Button: {
//     defaultProps: {
//     }
//   }
// }

// const theme = extendTheme({ config, styles, colors, components: { CustomBadge } });

const theme = extendTheme({ config, styles, colors });
export default theme;

// darkBlue2: {
//   9: "#04111D",
//   8: "#061A27",
//   7: "#082331",
//   6: "#0A2C3B",
//   base: "#0C2237",
//   4: "#1E3D55",
//   3: "#345470",
//   2: "#4A728C",
//   1: "#608DA8"
// },
// darkBlue: {
//   9: '#010405',
//   8: '#03070C',
//   7: '#050B12',
//   6: '#080F19',
//   base: "#0C2237",
//   4:'#1B3B5E',
//   3: '#3C6E8C',
//   2: '#5D9CB9',
//   1: '#8FCCE6'
// },
// lightBlue: {
//   9: "#024A73",
//   8: "#025F8C",
//   7: "#0374A5",
//   6: "#0489BE",
//   base: "#0873BA",
//   4: "#3A90CA",
//   3: "#5DA8DA",
//   2: "#80C0EA",
//   1: "#A3D8FA"
// },
