import { useState, useEffect, useRef } from 'react';
import {
	Center,
	MenuButton,
	Select,
	useDimensions,
	extendTheme,
	ChakraProvider,
	Box,
	Text,
	Link,
	VStack,
	Code,
	Grid,
	theme,
	Flex,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	useColorModeValue,
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormHelperText,
	Input,
	GridItem,
	Fade,
	useDisclosure,
	List,
	ListItem,
	Button,
	Menu,
	MenuItem,
	MenuList,
	Tag,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	ListIcon,
	OrderedList,
	UnorderedList,
	Icon,
} from '@chakra-ui/react';

import { DownloadIcon, CloseIcon } from '@chakra-ui/icons';
import { MdSettings, MdCheckCircle } from 'react-icons/md';
import { useQuery } from 'react-query';
import axios from 'axios';
import useWindowDimensions from '../../Components/hooks/useWindowDimensions';
import Legenda from '../../Components/Legenda/legenda';
export default function Methodology() {
	const [element, setElement] = useState({});
	const { data: dataInfo, isFetching: isFetchingInfo } = useQuery(
		'indexLabelsJson',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br:444/indexlabels/json',
			);
			console.log(response.data);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 60 },
	);

	const methodologyElements = () => {
		let socialIndicators = [];
		let economicIndicators = [];
		let environmentalIndicators = [];
		let institutionalIndicators = [];
		console.log(dataInfo);
		console.log(dataInfo[0].dimensaoIndicador);
		dataInfo.map((indicator) => {
			console.log(indicator.dimensaoIndicador);
			switch (indicator.dimensaoIndicador) {
				case 'Social':
					socialIndicators.push(indicator);
					break;
				case 'Econômico':
					economicIndicators.push(indicator);
					break;
				case 'Ambiental':
					environmentalIndicators.push(indicator);
					break;
				case 'Institucional':
					institutionalIndicators.push(indicator);
					break;
				default:
					break;
			}
		});
		const indicatorsFull = [
			socialIndicators,
			economicIndicators,
			environmentalIndicators,
			institutionalIndicators,
		];
		console.log(indicatorsFull);
		const acordeao = indicatorsFull.map((axle, index) => {
			let tituloEixo = '';
			switch (axle[0].dimensaoIndicador) {
				case 'Social':
					tituloEixo = 'Indicadores Sociais';
					break;
				case 'Econômico':
					tituloEixo = 'Indicadores Econômicos';
					break;
				case 'Ambiental':
					tituloEixo = 'Indicadores Ambientais';
					break;
				case 'Institucional':
					tituloEixo = 'Indicadores Institucionais';
					break;
				default:
					break;
			}
			return (
				<AccordionItem
					border="1px solid"
					borderColor={useColorModeValue("gray.100", "idsm.darkBlueCoolors.8")}
					borderRadius="12"
					bg={useColorModeValue("gray.100", "idsm.darkBlueCoolors.7")}
					marginTop="4"
					marginBottom="4"
					key={index}
				>
					<h2>
						<AccordionButton
							borderRadius="12"
							bg={useColorModeValue("gray.100", "idsm.darkBlueCoolors.7")}
							_hover={{ backgroundColor: useColorModeValue('gray.200', 'idsm.darkBlueCoolors.base') }}
							key={index}
						>
							<Box
								color={useColorModeValue("idsm.darkBlueCoolors.base", "white")} 
								as="span"
								flex="1"
								textAlign="left"
								fontSize="1rem"
								key={index}
							>
								{tituloEixo}
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4} borderRadius="12">
						{axle.map((indicator, index) => {
							return (
								<Box
									flexDirection="column"
									alignItems="center"
									justifyContent="center"
									bg={useColorModeValue("white", "idsm.darkBlueCoolors.base")}
									borderRadius="6"
									marginTop="2"
									marginBottom="0.2rem"
									padding={["0.6rem", "0.8rem", "1rem", "0.8rem"]}
									fontSize={["0.6rem", "0.8rem", "1rem", "0.8rem"]}
									key={index}
								>
									<Box key={index}>
										<Flex flexDirection={'row'} alignItems="center" gap="2">
											<Icon as={MdCheckCircle} color="idsm.greenCoolors.base" />
											<Text>{indicator.temaIndicador}</Text>
										</Flex>
										<Grid
											templateColumns="repeat(2, 1fr)"
										>
											<GridItem>
												<Flex flexDirection={'row'} alignItems="center" gap="2">
													<Icon as={MdCheckCircle} color="idsm.redCoolors.base" />
													<Text>{indicator.codigoIndicador}</Text>
												</Flex>
											</GridItem>
											<GridItem>
												<Flex flexDirection={'row'} alignItems="center" gap="2">
													<Icon
														as={MdCheckCircle}
														color="idsm.lightBlueCoolors.base"
													/>
													<Text>{indicator.fonteIndicador}</Text>
												</Flex>
											</GridItem>
										</Grid>
										<Flex flexDirection={'row'} alignItems="center" gap="2">
											<Icon
												as={MdCheckCircle}
												color="idsm.yellowCoolors.base"
											/>
											<Text>{indicator.nomeIndicador}</Text>
										</Flex>
										
									</Box>
								</Box>
							);
						})}
					</AccordionPanel>
				</AccordionItem>
			);
		});
		return acordeao;
	};

	const legendItems = [
		{ label: 'Indicador', color: 'idsm.greenCoolors.base' },
		{ label: 'Código', color: 'idsm.redCoolors.base' },
		{ label: 'Descrição', color: 'idsm.yellowCoolors.base' },
		{ label: 'Fonte', color: 'idsm.lightBlueCoolors.base' },
		// Outras categorias...
	];
	return (
		<Box>
			<Center>
				<Box
					alignItems="flex-start"
					justifyContent="center"
					textAlign="initial"
					margin={4}
					w="100%"
					maxWidth="1200px"
				>
					<Text>Metodologia</Text>
					<Legenda legendItems={legendItems} />
					<Accordion allowToggle>
						{isFetchingInfo ? null : methodologyElements()}
					</Accordion>
				</Box>
			</Center>
		</Box>
	);
}
