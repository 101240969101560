import React, { useState } from 'react';
import { scroller, animateScroll as scroll } from 'react-scroll';
import {
	Box,
	Flex,
	Image,
	useColorModeValue,
	useBreakpointValue,
	Button,
	IconButton,
} from '@chakra-ui/react';
import { ParticlesDesign } from '../../Components/Design/ParticlesDesign';
import { Link as LinkReactRouterDom } from 'react-router-dom';
import { HomeCardDesktop } from '../../Components/HomeCard/HomeCard';
const App = () => {
	const [currentSectionIndex, setCurrentSectionIndex] = useState(1);
	const { innerWidth: widthPage, innerHeight: heightPage } = window;
	const numberOfSections = 8;
	const isDesktop = useBreakpointValue({ base: false, md: true });

	const logoImage = useColorModeValue('7.png', '6.png');
	const updateState = (index) => {
		setCurrentSectionIndex(currentSectionIndex + index);
	};

	const handleSwipedUp = () => {
		scrollToSection(1);
	};

	const createLateralButtons = () => {
		const scrolll = (i) => {
			if(i == 1){
				scroll.scrollToTop({
					duration: 800,
					elay: 0,
					smooth: 'easeInOutQuart',
				});
			} else {
				scroller.scrollTo('section' + i, {
					duration: 800,
					delay: 0,
					smooth: 'easeInOutQuart',
				});
			}
		}
		const imgUrls = ['headerLogo.png','Mapa de indicadores.png', 'Ranking.png', 'serie historica.png', 'Base de dados.png', 'Metodologia.png', 'Equipe.png', 'Equipe.png']
		let buttonsArr = []
		for (let i = 1; i <= numberOfSections; i++) {
			buttonsArr.push(
				<IconButton 
				height={'8dvh'} width={'8dvh'} borderRadius={'full'}
				padding={'2vh'}
				icon={<Image src={imgUrls[i-1]}/>}  objectFit={'fill'}
				onClick={() => scrolll(i)}
				bg={useColorModeValue('gray.200', 'idsm.darkBlueCoolors.base')}
				_hover={
						{
							backgroundColor: useColorModeValue('gray.300', 'idsm.darkBlueCoolors.7')
						}
					}
					/>
			// <Button height={'8dvh'} width={'8dvh'} margin={0} paddding={0} borderRadius={0} key={i} backgroundColor={useColorModeValue('gray.200', 'idsm.darkBlueCoolors.base')} 
			// _hover={
			// 	{
			// 		backgroundColor: useColorModeValue('gray.300', 'idsm.darkBlueCoolors.8')
			// 	}
			// }
			// onClick={() => scrolll(i)}>
			// 	<Flex height={'100%'} padding={0}>
			// 	<Image src={imgUrls[i-1]}  objectFit={'cover'} />

			// 	</Flex>
			// </Button>)
			)
		}
		return (buttonsArr)
	}


	return (
		<>
			{isDesktop ? 
			  <Box
				position={'fixed'}
				flex={1}
				top={0}
				right={0}
				width={'24'}
				height={'100%'}
				zIndex={2}
				>
					<Flex flexDir={'column'} width={'100%'} height='100%' alignItems={'center'} paddingTop={62} justifyContent={'center'} gap={'1vh'} >
						{createLateralButtons().map((item) => item)}
					</Flex>
				</Box>
			:
				null
			}
			
			
		<Flex
			alignItems="center"
			justifyContent="center"
			h="100%"
			width="100%"
		>
			
			<Box textAlign="center" px="4">
				<ParticlesDesign />
				<>
					<HomeCardDesktop
						isHome={true}
						heightPage={heightPage-62}
						cardTitle={'O que é o IDSM?'}
						cardText={'O Índice de Desenvolvimento Sustentável Municipal (IDSM) é uma ferramenta que identifica, coleta e analisa dados reais dos municípios, possibilitando a avaliação e o monitoramento de políticas públicas voltadas para o desenvolvimento sustentável. Composto por 26 indicadores, o indice permite a identificação da realidade socioeconômica, institucional e ambiental do município, e, o acompanhamento do seu progresso em relação a metas estabelecidas pela Organizações das Nações Unidas (ONU) com a Agenda 2030. Sua construção foi realizada com o objetivo de fornecer aos gestores municipais e organizações da sociedade civil um instrumento integrado de avaliação da qualidade de vida da população e da eficácia das políticas públicas.'}
						imgLinkUrl={logoImage}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section1'}
						isDesktop={isDesktop}
					/>
					<HomeCardDesktop
						heightPage={heightPage}
						cardTitle={'Mapa de Indicadores'}
						cardText={'Nesta seção, temos o mapa dos municípios do estado da Paraíba, através do qual é possível acessar os dados a respeito do município do seu interesse. Você também pode filtrar por anos, e as áreas dos indicadores.'}
						cardButtonText={['Acessar Mapa de Indicadores']}
						cardLinkUrl={['/map']}
						imgLinkUrl={'Mapa de indicadores.png'}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section2'}
						isDesktop={isDesktop}
					/>
					<HomeCardDesktop
						heightPage={heightPage}
						cardTitle={'Ranking dos Municípios'}
						cardText={'O Ranking do IDSM trás uma visualização comparativa dos municípios da paraíba com relação a suas performances baseados em indicadores com a Agenda 2030. Acompanhe o seu município!'}
						cardButtonText={['Ranking Geral', 'Ranking por Indicador']}
						cardLinkUrl={['/ranking/general', '/ranking/indicador']}
						imgLinkUrl={'Ranking.png'}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section3'}
						isDesktop={isDesktop}
					/>
					<HomeCardDesktop
						heightPage={heightPage}
						cardTitle={'Série Histórica'}
						cardText={'A série histórica permite que você possa visualizar e coletar os dados sobre a evolução de determinado indicador de um município paraibano ao longo dos anos.'}
						cardButtonText={['Série Histórica']}
						cardLinkUrl={['/history']}
						imgLinkUrl={'serie historica.png'}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section4'}
						isDesktop={isDesktop}
					/>
					<HomeCardDesktop
						heightPage={heightPage}
						cardTitle={'Base de Dados'}
						cardText={'Todos os dados utilizados para a construção dos nossos indicadores são disponibilizados para o público, sendo possível o download por ano, pela série histórica ou do nosso próprio Índice do Desenvolvimento Sustentável Municípal.'}
						cardButtonText={['Acesse a nossa base de dados']}
						cardLinkUrl={['/database']}
						imgLinkUrl={'Base de dados.png'}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section5'}
						isDesktop={isDesktop}
					/>
					<HomeCardDesktop
						heightPage={heightPage}
						cardTitle={'Metodologia'}
						cardText={'Todos os dados utilizados para a construção dos nossos indicadores são disponibilizados para o público, sendo possível o download por ano, pela série histórica ou do nosso próprio Índice do Desenvolvimento Sustentável.'}
						cardButtonText={['Metodologia']}
						cardLinkUrl={['/methodology']}
						imgLinkUrl={'Metodologia.png'}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section6'}
						isDesktop={isDesktop}
					/>
					<HomeCardDesktop
						heightPage={heightPage}
						cardTitle={'Equipe'}
						cardText={'O IDSM é coordenado pelos professores da UFPB: Henrique Menezes (prof. titular do curso de Relações Internacionais), Italo Fittipaldi (Professor do Departamento de Ciências Sociais e do Programa de Pós-Graduação em Ciência Política e Relações Internacionais) e Pascoal Gonçalves (prof. titular do curso de Relações Internacionais). Sob orientação dos professores, tanto o site quanto as nossas análises são desenvolvidas pelos nossos oito alunos extensionistas. Clique no botão abaixo para conhecer mais sobre a nossa equipe.'}
						cardButtonText={['Equipe']}
						cardLinkUrl={['/team']}
						imgLinkUrl={'Equipe.png'}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section7'}
						isDesktop={isDesktop}
					/>
					<HomeCardDesktop
						heightPage={heightPage}
						cardTitle={'Observatório'}
						cardText={'O Observatório do IDSM é um espaço de divulgação de análises e pesquisas sobre o desenvolvimento sustentável dos municípios paraibanos. Nesta seção, você pode acessar os nossos relatórios, artigos e notícias.'}
						cardButtonText={['Acessar Observatório']}
						cardLinkUrl={['/observatory']}
						imgLinkUrl={'Equipe.png'}
						imgAltText={'Imagem sobre o Índice de Desenvolvimento Sustentável'}
						sectionName={'section8'}
						isDesktop={isDesktop}
					/>
				</>
			</Box>
		</Flex>
		</>
	);
};

export default App;
